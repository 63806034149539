import {
    DisputeCollectionEnum,
    DisputeItemCollectionEnum,
    DisputeMandatesEnum,
    DisputeRegionEnum,
} from "@/enums/disputeCollectionEnum";

import {
    IDisputeBuyProduct,
    IDisputeRegion,
    IDisputeUser,
    IMandateUiActions,
} from "@/types/disputeCollection";
import {
    DisputeData,
    DisputePreview,
    DisputePreviewItem,
    IDisputeBuyProductVariationProperty,
    IDisputeDetailsCollection,
    IDisputeDetailsItems,
} from "@/types/disputeDetailsCollection";
import { BuyProductChargeModel } from "./buyActionCollectionModel";
import { BuyProductModel } from "./buyProductCollectionModel";
import { AgentCompanyModel } from "./agentCompanyCollectionModel";
import { ICurrency } from "@/types/currencyCollection";
import {
    ShipmentProductChargeModel,
    ShipmentProductPackageModel,
} from "./shipmentProductPackageCollection";

export class DisputeBuyProductModel {
    id: number;
    product_number: string;
    product_id: string;
    product_vid: string;
    cart_item_id: number;
    product_title: string;
    product_image: string;
    product_link: string;
    shipping_provider: string;
    total_quantity: number;
    status: string;
    created_at: string;
    constructor(dataItem: IDisputeBuyProduct) {
        this.id = dataItem?.id;
        this.product_number = dataItem?.product_number;
        this.product_id = dataItem?.product_id;
        this.product_title = dataItem?.product_title;
        this.product_link = dataItem?.product_link;
        this.shipping_provider = dataItem?.shipping_provider;
        this.status = dataItem?.status;
        this.total_quantity = dataItem?.total_quantity;
        this.product_image = dataItem?.product_image;
        this.cart_item_id = dataItem?.cart_item_id;
        this.product_vid = dataItem?.product_vid;
        this.created_at = dataItem?.created_at;
    }

    getId = () => this.id;
    getProductId = () => this.product_id;
    getProductTitle = () => this.product_title;
    getCreatedAt = () => this.created_at;
    getStatus = () => this.status;
    getShippingProvider = () => this.shipping_provider;
    getProductImage = () => this?.product_image;
}

export class RefundDetailsCurrencyModel {
    private readonly currency: ICurrency;

    constructor(currency: ICurrency) {
        this.currency = currency;
    }
    getId() {
        return this.currency.id;
    }
    getCurrencyCode() {
        return this.currency.code;
    }
    getName() {
        return this.currency.name;
    }
    getSymbol() {
        return this.currency.symbol;
    }
    getSymbolNative() {
        return this.currency.symbol_native;
    }
    getStatus() {
        return this.currency.status;
    }
}
export class DisputeRegionModel {
    object: DisputeRegionEnum.Region;
    id: number;
    name: string;
    code: string;
    store_type: string;
    store: string;
    status: string;
    created_at: string;
    currency?: RefundDetailsCurrencyModel | undefined;

    constructor(data: IDisputeRegion) {
        this.object = data?.object;
        this.id = data?.id;
        this.name = data?.name;
        this.code = data?.code;
        this.store_type = data?.store_type;
        this.store = data?.store;
        this.status = data?.status;
        this.created_at = data?.created_at;
        this.currency = undefined;
        if (data.currency) {
            this.currency = new RefundDetailsCurrencyModel(data.currency);
        }
    }

    getId = () => this.id;
    getName = () => this.name;
    getCode = () => this.code;
}

export class DisputeItemsModel {
    item: IDisputeDetailsItems;
    constructor(payload: IDisputeDetailsItems) {
        this.item = payload;
    }
    getItem = () => this.item;
}

export class DisputeBuyProductVariationPropertiesModel {
    _object: DisputeItemCollectionEnum.BuyProductVariationProperty;
    _id: number;
    _property_id: string;
    _property_name: string;
    _property_original_name: string;
    _property_value_id: string;
    _property_value_name: string;
    _property_value_color: string;
    _property_value_title: string;
    _property_value_thumb: string;
    _property_value_image: string;
    constructor(data: IDisputeBuyProductVariationProperty) {
        this._object = data?.object;
        this._id = data?.id;
        this._property_id = data?.property_id;
        this._property_name = data?.property_name;
        this._property_original_name = data?.property_original_name;
        this._property_value_color = data?.property_value_color;
        this._property_value_name = data?.property_value_name;
        this._property_value_id = data?.property_value_id;
        this._property_value_title = data?.property_value_title;
        this._property_value_thumb = data?.property_value_thumb;
        this._property_value_image = data?.property_value_image;
    }

    getId = () => this._id;
    getPropertyId = () => this._property_id;
    getPropertyName = () => this._property_name;
    getPropertyOriginalName = () => this._property_original_name;
    getPropertyValueId = () => this._property_value_id;
    getPropertyValueName = () => this._property_value_name;
    getPropertyValueColor = () => this._property_value_color;
    getPropertyValueTitle = () => this._property_value_title;
    getPropertyValueThumb = () => this._property_value_thumb;
    getPropertyValueImage = () => this._property_value_image;
}

export class DisputeBuyProductVriationModel {
    _object: DisputeItemCollectionEnum.BuyProductVariation;
    _id: number;
    _buy_product_id: number;
    _cart_item_variation_id: number;
    _sku_id: string;
    _image: string;
    _quantity: number;
    _unit_price: number;
    _total_price: number;
    _original_unit_price: number;
    _original_total_price: number;
    _properties: DisputeBuyProductVariationPropertiesModel[];
    constructor(data: any) {
        //FIXME : should fix the type  error
        this._object = data?.object;
        this._id = data?.id;
        this._buy_product_id = data?.buy_product_id;
        this._cart_item_variation_id = data?.cart_item_variation_id;
        this._sku_id = data?.sku_id;
        this._image = data?.image;
        this._quantity = data?.quantity;
        this._unit_price = data?.unit_price;
        this._total_price = data?.total_price;
        this._original_unit_price = data.original_unit_price;
        this._original_total_price = data?.original_total_price;
        this._properties = data?.properties?.data?.map(
            (item) => new DisputeBuyProductVariationPropertiesModel(item),
        );
    }

    getId = () => this._id;
    getBuyProductId = () => this._buy_product_id;
    getProperties = () => this._properties;
    getQuantity = () => this._quantity;
    getUnitPrice = () => this._unit_price;
    getTotalPrice = () => this._total_price;
    getOriginalUnitPrice = () => this._original_unit_price;
    getOriginalTotalPrice = () => this._original_total_price;
    getImage = () => this?._image;
}

export class DisputeItemModel {
    id: number;
    dispute_id: number;
    requested_amount: number;
    requested_quantity: number;
    approved_amount: number;
    approved_percentage: number;
    status: string;
    buy_product_variation?: DisputeBuyProductVriationModel;
    buy_product_charge?: BuyProductChargeModel;
    shipment_product_package?: ShipmentProductPackageModel;
    shipment_product_charge?: ShipmentProductChargeModel;
    constructor(payload: any) {
        //FIXME : should fix the type  error
        this.id = payload?.id;
        this.dispute_id = payload?.dispute_id;
        this.requested_amount = payload?.requested_amount;
        this.requested_quantity = payload?.requested_quantity;
        this.approved_amount = payload?.approved_amount;
        this.approved_percentage = payload?.approved_percentage;
        this.status = payload?.status;
        this.buy_product_variation =
            payload?.buy_product_variation &&
            new DisputeBuyProductVriationModel(payload?.buy_product_variation);
        this.buy_product_charge =
            payload?.buy_product_charge &&
            new BuyProductChargeModel(payload.buy_product_charge);
        this.shipment_product_charge =
            payload?.shipment_product_charge &&
            new ShipmentProductChargeModel(payload.shipment_product_charge);
    }
    getId = () => this.id;
    getDisputeId = () => this.dispute_id;
    getRequestedAmount = () => this.requested_amount;
    getApprovedAmount = () => this.approved_amount;
    getApprovedPercentage = () => this.approved_percentage;
    getStatus = () => this.status;
    getBuyProductVariation = () => this.buy_product_variation;
    getBuyProductCharge = () => this.buy_product_charge;
}

export interface DisputeItems {
    key: React.Key;
    item: DisputeItemModel;
}

export class DisputeMandatesModel {
    _object: DisputeMandatesEnum.DisputeMandate;
    _id: number;
    _mandate_to: string;
    _status: string;
    agent_company: AgentCompanyModel;
    total_assigned: number;
    total_agreed: number;
    customer_total_cost: number;
    moveon_total_profit: number;
    moveon_profit_percentage: number;
    ui_actions: IMandateUiActions;
    constructor(data) {
        this._object = data?.object;
        this._id = data?.id;
        this._mandate_to = data?.mandate_to;
        this._status = data?.status;
        this.agent_company =
            data?.agent_company && new AgentCompanyModel(data.agent_company);
        this.total_assigned = data?.total_assigned;
        this.total_agreed = data?.total_agreed;
        this.customer_total_cost = data?.customer_total_cost;
        this.moveon_total_profit = data?.moveon_total_profit;
        this.moveon_profit_percentage = data?.moveon_profit_percentage;
        this.ui_actions = data?.ui_actions;
    }
    getId = () => this._id;
    getMandateTo = () => this._mandate_to;
    getAgentCompany = () => this.agent_company;
    getStatus = () => this._status;
    getTotalAssigned = () => this.total_assigned;
    getTotalAgreed = () => this.total_agreed;
    getCustomerTotalCost = () => this.customer_total_cost;
    getMoveonTotalProfit = () => this.moveon_total_profit;
    getMoveonProfitPercentage = () => this.moveon_profit_percentage;
    getUiActions = () => this.ui_actions;
}

class DisputeUserModel {
    _object: string;
    _id: number;
    _name: string;
    _email: string;

    constructor(data: IDisputeUser) {
        this._object = data?.object;
        this._id = data?.id;
        this._name = data?.name;
        this._email = data?.email;
    }

    getUserName = () => this._name;
    getUserEmail = () => this._email;
}

export class DisputeDetailsModel {
    object: DisputeCollectionEnum.Dispute;
    id: number;
    created_at: string;
    dispute_number: string;
    intent: string;
    status: string;
    region: DisputeRegionModel;
    user: DisputeUserModel;
    buy_product: BuyProductModel;
    items: DisputeItems[];
    listItmes: IDisputeDetailsItems[];
    _mandates: DisputeMandatesModel[];
    constructor(dataItem: any) {
        //FIXME : should fix the type  error
        this.object = dataItem?.object;
        this.id = dataItem?.id;
        this.dispute_number = dataItem?.dispute_number;
        this.intent = dataItem?.intent;
        this.status = dataItem?.status;
        this.status = dataItem?.status;
        this.region =
            dataItem?.region && new DisputeRegionModel(dataItem.region);
        this.user = new DisputeUserModel(dataItem?.user);
        this.created_at = dataItem?.created_at;
        this.buy_product = new BuyProductModel(dataItem?.buy_product);
        this.items = dataItem?.items?.data?.map((data) => {
            return {
                key: data?.id,
                item: new DisputeItemModel(data),
            };
        });

        this.listItmes = dataItem?.items?.data;

        this._mandates = dataItem?.mandates?.data.map(
            (item) => new DisputeMandatesModel(item),
        );
    }

    getId = () => this.id;
    getStatus = () => this.status;
    getCreatedAt = () => this.created_at;
    getRegion = () => this.region;
    getBuyProduct = () => this.buy_product;
    getItems = () => this.items;
    getistItmes = () => this.listItmes;
    getMandates = () => (this?._mandates.length > 0 ? this._mandates : []);
    getUser = () => this.user;
}

export class DisputeDetailsCollectionModel {
    public data: DisputeDetailsModel;

    constructor(data: IDisputeDetailsCollection) {
        this.data = new DisputeDetailsModel(data?.data);
    }

    getData() {
        return this.data;
    }
}

export class DisputePreviewModel {
    message: string;
    data: DisputeDataModel;

    constructor(dataItem: DisputePreview) {
        this.message = dataItem.message;
        this.data = new DisputeDataModel(dataItem.data);
    }

    getMessage = () => this.message;
    getData = () => this.data;
}

export class DisputeDataModel {
    agent_total_cost: number;
    customer_total_cost: number;
    moveon_total_profit: number;
    moveon_profit_percentage: number;
    items: DisputePreviewItemModel[];

    constructor(dataItem: DisputeData) {
        this.agent_total_cost = dataItem.agent_total_cost;
        this.customer_total_cost = dataItem.customer_total_cost;
        this.moveon_total_profit = dataItem.moveon_total_profit;
        this.moveon_profit_percentage = dataItem.moveon_profit_percentage;
        this.items =
            dataItem?.items &&
            dataItem.items.map((item) => new DisputePreviewItemModel(item));
    }

    getAgentTotalCost = () => this.agent_total_cost;
    getCustomerTotalCost = () => this.customer_total_cost;
    getMoveonTotalProfit = () => this.moveon_total_profit;
    getMoveonProfitPercentage = () => this.moveon_profit_percentage;
    getItems = () => this.items;
}

export class DisputePreviewItemModel {
    item_id: number;
    agent_per_item_cost: number;
    agent_total_item_cost: number;
    customer_per_item_cost: number;
    customer_total_item_cost: number;
    moveon_per_item_profit: number;
    moveon_total_item_profit: number;
    moveon_profit_percentage: number;

    constructor(dataItem: DisputePreviewItem) {
        this.item_id = dataItem.item_id;
        this.agent_per_item_cost = dataItem.agent_per_item_cost;
        this.agent_total_item_cost = dataItem.agent_total_item_cost;
        this.customer_per_item_cost = dataItem.customer_per_item_cost;
        this.customer_total_item_cost = dataItem.customer_total_item_cost;
        this.moveon_per_item_profit = dataItem.moveon_per_item_profit;
        this.moveon_total_item_profit = dataItem.moveon_total_item_profit;
        this.moveon_profit_percentage = dataItem.moveon_profit_percentage;
    }

    getItemId = () => this.item_id;
    getAgentPerItemCost = () => this.agent_per_item_cost;
    getAgentTotalItemCost = () => this.agent_total_item_cost;
    getCustomerPerItemCost = () => this.customer_per_item_cost;
    getCustomerTotalItemCost = () => this.customer_total_item_cost;
    getMoveonPerItemProfit = () => this.moveon_per_item_profit;
    getMoveonTotalItemProfit = () => this.moveon_total_item_profit;
    getMoveonProfitPercentage = () => this.moveon_profit_percentage;
}
