import { DownloadOutlined, PayCircleOutlined } from "@ant-design/icons";
import { notification } from "antd";

import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ADMIN_INVOICE_PERMISSION_ENUM } from "@/consts/permission-enum/invoice-enum";
import { HarvestActionEnums } from "@/enums/harvestJobCollectionEnums";
import { useHarvestJobCreate } from "@/lib/core-react/hooks/private/useHarvestJob";
import { useInvoice } from "@/lib/core-react/hooks/private/useInvoice";
import { InvoiceModel } from "@/models/invoiceCollectionModel";
import { RegionModel } from "@/models/regionCollectionModel";
import { ExtendedMenuItemType } from "@/types";
import { IHarvestJobCreate } from "@/types/harvestBatchCollection";

interface IProps {
    setSelectedPayInvoiceData: React.Dispatch<
        React.SetStateAction<
            | {
                  region: RegionModel;
                  user_id: number;
                  token: string;
              }
            | undefined
        >
    >;
}
export const useCustomerInvoiceBulkActions = ({
    setSelectedPayInvoiceData,
}: IProps) => {
    const { createSession, isLoading } = useInvoice();
    const { createHarvest } = useHarvestJobCreate();

    const getActions = (selectedRows: InvoiceModel[]) => {
        const validSelectedRows = selectedRows.filter((row) => {
            const transactionSummary = row.getTransactionSummery();
            const due = transactionSummary?.due;

            return typeof due === "number" && due > 0;
        });

        const uniqueRegions = Array.from(
            new Set(
                validSelectedRows
                    .map((row) => row.getRegion())
                    .filter(
                        (region) => region !== undefined && region !== null,
                    ),
            ),
        );

        const uniqueRegionIds = Array.from(
            new Set(
                validSelectedRows
                    .map((row) => row.getRegion()?.getId())
                    .filter(
                        (region) => region !== undefined && region !== null,
                    ),
            ),
        );

        const uniqueCustomerIds = Array.from(
            new Set(
                validSelectedRows
                    .map((row) => row.getCustomer()?.getId())
                    .filter(
                        (customer) =>
                            customer !== null && customer !== undefined,
                    ),
            ),
        );

        const uniqueInvoiceIds = Array.from(
            new Set(
                validSelectedRows
                    .map((row) => row.getId())
                    .filter((id) => id !== undefined && id !== null),
            ),
        );

        const handlePayCustomerInvoice = async () => {
            if (uniqueCustomerIds.length > 1) {
                return notification.error({
                    message: "Error",
                    description:
                        "Selected rows must belong to a single customer",
                });
            }
            if (uniqueRegionIds.length > 1) {
                return notification.error({
                    message: "Error",
                    description: "Selected rows must belong to a single region",
                });
            }

            if (uniqueInvoiceIds.length === 0) {
                return notification.error({
                    message: "Error",
                    description: "No invoices have dues",
                });
            }

            const res = await createSession(
                uniqueCustomerIds[0],
                uniqueInvoiceIds,
            );

            if (res) {
                setSelectedPayInvoiceData({
                    region: uniqueRegions[0],
                    user_id: uniqueCustomerIds[0],
                    token: res.data.session_token,
                });
            }
        };

        const harvestJobCreateHandler = async () => {
            const payload: IHarvestJobCreate<object, object> = {
                type: HarvestActionEnums.CUSTOMER_INVOICE,
                context: {
                    filters: {
                        order_ids: selectedRows.map((e) => e.id),
                    },
                },
                settings: {
                    notification: {
                        email: true,
                        sms: true,
                    },
                },
            };

            await createHarvest(payload);
        };

        const items: ExtendedMenuItemType[] = [
            {
                permission: ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_VIEW,
                key: "download-invoice",
                icon: <DownloadOutlined />,
                onClick: () => harvestJobCreateHandler(),
                label: "Download Invoice",
            },
            ...(validSelectedRows.length
                ? [
                      {
                          permission:
                              ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_PAY_CUSTOMER_INVOICE,
                          label: isLoading
                              ? "Wait..."
                              : `Pay Customer Invoice (${validSelectedRows.length})`,
                          key: "pay-invoice",
                          icon: <PayCircleOutlined />,
                          onClick: () => handlePayCustomerInvoice(),
                      },
                  ]
                : []),
        ];

        return items.filter((x) =>
            checkActionPermission(x.permission, x, null),
        );
    };

    return { getActions };
};
