import { BuyProductModel } from "@/models/buyProductCollectionModel";
import { BuyProductVariationsTable } from "../../components";
import { Alert, Empty, Flex, Tabs } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { BuyProductPackage } from "../../components/BuyProductPackage";
import Trackings from "@/pages/Shipping/ShipmentProducts/EditShipmentProduct/Trackings/Trackings";
import { TabsProps } from "antd/lib";
import { tw } from "@/consts/theme/tailwindTheme";
import { TimelineTracking } from "@/pages/Shipping/newComponents/TimelineTracking";
import { TimelineTrackingModel } from "@/models/timelineTrackingModel";
import { useBuyProductGetTimelineTracking } from "@/lib/core-react/hooks/private";
import { useAtom } from "jotai";
import ActionViewDetailsModal from "../../ActionsNeeded/ActionViewDetailsModal";
import { buyProductTimelineTrackingAtom } from "@/lib/core-react/store/buyOrderAtoms/buyOrderAtoms";

interface Props {
    buyProduct: BuyProductModel;
}

export const QuickView = ({ buyProduct }: Props) => {
    const pageHeaderStyle = {
        padding: 0,
    };

    const { getTimelineTracking } = useBuyProductGetTimelineTracking();
    const [
        { data: timelineTrackingData, isLoading: isLoadingTimelineTracking },
    ] = useAtom(buyProductTimelineTrackingAtom);

    const handleTracking = (productId: number) => {
        getTimelineTracking(productId);
    };

    const items: TabsProps["items"] = [
        {
            key: "1",
            label: "Variations",
            children: (
                <PageHeader style={pageHeaderStyle} title="Variations">
                    <BuyProductVariationsTable
                        isInsideModal={true}
                        buyProduct={buyProduct}
                    />
                </PageHeader>
            ),
        },
        {
            key: "2",
            label: "Package",
            children: (
                <PageHeader style={pageHeaderStyle} title={"Package"}>
                    <BuyProductPackage
                        buyProductPackage={buyProduct.getBuyProductPackage()}
                    />
                </PageHeader>
            ),
        },
        {
            key: "3",
            label: "Tracking",
            children: buyProduct.getShipmentProduct() ? (
                <PageHeader style={pageHeaderStyle} title={"Tracking"}>
                    <Trackings
                        action="view"
                        onCloseModal={() => {}}
                        packages={buyProduct
                            .getShipmentProduct()
                            ?.getPackages()}
                        productId={buyProduct.getId()}
                    />
                </PageHeader>
            ) : (
                <Alert
                    style={{
                        width: 500,
                    }}
                    message="Tracking Not Available"
                    type="info"
                />
            ),
        },
        {
            key: "4",
            label: "Buy Product Actions",
            children:
                buyProduct.getBuyActions() &&
                buyProduct.getBuyActions().length > 0 ? (
                    <PageHeader
                        style={pageHeaderStyle}
                        title={"Buy Product Actions"}
                    >
                        {" "}
                        {buyProduct.getBuyActions().length ? (
                            <ActionViewDetailsModal
                                buyProductId={buyProduct.getId()}
                            />
                        ) : (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "25vh",
                                }}
                            >
                                <Empty
                                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                    imageStyle={{
                                        height: 60,
                                        marginTop: 10,
                                    }}
                                    description={
                                        <Alert
                                            message="No Action in this product"
                                            type="info"
                                        />
                                    }
                                />
                            </div>
                        )}
                    </PageHeader>
                ) : (
                    <Alert
                        style={{
                            width: 500,
                        }}
                        message="Buy Product Actions Not Available"
                        type="info"
                    />
                ),
        },
    ];

    return (
        <Flex vertical gap={tw.spacing[8]}>
            <Tabs defaultActiveKey="1" items={items} />
            {buyProduct && (
                <TimelineTracking
                    TimelineTrackingData={
                        timelineTrackingData &&
                        new TimelineTrackingModel(timelineTrackingData)
                    }
                    isInsideModal={true}
                    productId={buyProduct.getId()}
                    productNumber={buyProduct.getProductNumber()}
                    isLoading={isLoadingTimelineTracking}
                    handleTracking={handleTracking}
                    title="Timeline"
                />
            )}
        </Flex>
    );
};

export default QuickView;
