import {
    BuyProductChargeStatusEnum,
    IActionSequence,
    IActionSequenceAddonService,
    IActionSequenceAddonServiceCollection,
    IActionSequenceCollection,
    IBuyAction,
    IBuyActionCollection,
    IBuyActionShipping,
    IBuyActionShippingRate,
    IBuyActionShippingSlot,
    IBuyActionShippingSlotCollection,
    IBuyProduct,
    IBuyProductCharge,
    IBuyProductChargeCollection,
    IBuyProductVariation,
    IBuyProductVariationCollection,
    IBuyProductVariationPropertiesCollection,
    IBuyProductVariationProperty,
    ICurrency,
    IImage,
    IImageCollection,
    IMoveOnShipping,
    IRegion,
    IShippingCategory,
    IVariantMismatch,
    IVariantMismatchCollection,
} from "@/types/buyActionCollection";
import { IFilterType } from "@/types/filters";
import { IPagination } from "@/types/pagination";
import { formatDateTime } from "../utils/helpers";
import { StoreModel } from "./storeCollectionModel";
import { AgentCategoryPriceReadModel } from "./agentCategoryPriceReadCollectionModel";
import { CommonUserModel } from "./shared";
import { BuyOrderModel } from "./buyProductCollectionModel";

export class BuyProductVariationPropertyModel {
    public readonly object: string;
    public readonly id: number;
    public readonly property_id: string;
    public readonly property_name: string;
    public readonly property_original_name: string;
    public readonly property_value_id: string;
    public readonly property_value_name: string;
    public readonly property_value_color: string | null;
    public readonly property_value_title: string;
    public readonly property_value_image: string | null;
    public readonly property_value_thumb: string | null;

    constructor(data: IBuyProductVariationProperty) {
        this.object = data.object;
        this.id = data.id;
        this.property_id = data.property_id;
        this.property_name = data.property_name;
        this.property_original_name = data.property_original_name;
        this.property_value_id = data.property_value_id;
        this.property_value_name = data.property_value_name;
        this.property_value_color = data.property_value_color;
        this.property_value_title = data.property_value_title;
        this.property_value_image = data.property_value_image;
        this.property_value_thumb = data.property_value_thumb;
    }

    getId() {
        return this.id;
    }

    getPropertyId() {
        return this.property_id;
    }

    getPropertyName() {
        return this.property_name;
    }

    getPropertyOriginalName() {
        return this.property_original_name;
    }

    getPropertyValueId() {
        return this.property_value_id;
    }

    getPropertyValueName() {
        return this.property_value_name;
    }

    getPropertyValueColor() {
        return this.property_value_color;
    }

    getPropertyValueTitle() {
        return this.property_value_title;
    }

    getPropertyValueImage() {
        return this.property_value_image;
    }

    getPropertyValueThumb() {
        return this.property_value_thumb;
    }
}

export class BuyProductVariationPropertiesCollectionModel {
    public readonly object: string;
    public readonly data: BuyProductVariationPropertyModel[];

    constructor(data: IBuyProductVariationPropertiesCollection) {
        this.object = data.object;
        this.data = data.data.map(
            (item) => new BuyProductVariationPropertyModel(item),
        );
    }

    getPropertyById(id: number): BuyProductVariationPropertyModel | undefined {
        return this.data.find((property) => property.getId() === id);
    }

    getData() {
        return this.data;
    }
}

export class BuyProductVariationModel {
    public readonly object: string;
    public readonly id: number;
    public readonly buy_product_id: number;
    public readonly cart_item_variation_id: number;
    public readonly sku_id: string;
    public readonly image: string | null;
    public readonly quantity: number;
    public readonly unit_price: number;
    public readonly total_price: number;
    public readonly original_unit_price: number;
    public readonly original_total_price: number;
    public readonly properties: BuyProductVariationPropertiesCollectionModel;

    constructor(data: IBuyProductVariation) {
        this.object = data.object;
        this.id = data.id;
        this.buy_product_id = data.buy_product_id;
        this.cart_item_variation_id = data.cart_item_variation_id;
        this.sku_id = data.sku_id;
        this.image = data.image;
        this.quantity = data.quantity;
        this.unit_price = data.unit_price;
        this.total_price = data.total_price;
        this.original_unit_price = data.original_unit_price;
        this.original_total_price = data.original_total_price;
        this.properties = new BuyProductVariationPropertiesCollectionModel(
            data.properties,
        );
    }

    getId() {
        return this.id;
    }

    getBuyProductId() {
        return this.buy_product_id;
    }

    getCartItemVariationId() {
        return this.cart_item_variation_id;
    }

    getSkuId() {
        return this.sku_id;
    }

    getImage() {
        return this.image;
    }

    getQuantity() {
        return this.quantity;
    }

    getUnitPrice() {
        return this.unit_price;
    }

    getTotalPrice() {
        return this.total_price;
    }

    getOriginalUnitPrice() {
        return this.original_unit_price;
    }

    getOriginalTotalPrice() {
        return this.original_total_price;
    }

    getProperties() {
        return this.properties;
    }
}

export class BuyProductVariationCollectionModel {
    public readonly object: string;
    public readonly data: BuyProductVariationModel[];

    constructor(data: IBuyProductVariationCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => new BuyProductVariationModel(item));
    }

    getData() {
        return this.data;
    }

    getBuyProductVariationById(
        id: number,
    ): BuyProductVariationModel | undefined {
        return this.data.find((variation) => variation.getId() === id);
    }
}

// export class BuyProductCustomerAssociationModel {
//   public readonly object: string;
//   public readonly id: number;
//   public readonly buy_product_id: number;
//   public readonly agent_company_id: number;
//   public readonly include_tax: number;
//   public readonly sub_total: number;
//   public readonly tax_total: number;
//   public readonly total: number;
//   public readonly original_sub_total: number;
//   public readonly original_total: number;
//   public readonly discount_total: number;
//   public readonly fx_rate: number;
//   public readonly commission_rate: number;
//   public readonly commission_rate_type: string;
//   public readonly created_at: string;
//   public readonly purchase_note: string;
//   public readonly vendor_order_id: string;

//   constructor(data: IBuyProductCustomerAssociation) {
//     this.object = data.object;
//     this.id = data.id;
//     this.buy_product_id = data.buy_product_id;
//     this.agent_company_id = data.agent_company_id;
//     this.include_tax = data.include_tax;
//     this.sub_total = data.sub_total;
//     this.tax_total = data.tax_total;
//     this.total = data.total;
//     this.original_sub_total = data.original_sub_total;
//     this.original_total = data.original_total;
//     this.discount_total = data.discount_total;
//     this.fx_rate = data.fx_rate;
//     this.commission_rate = data.commission_rate;
//     this.commission_rate_type = data.commission_rate_type;
//     this.created_at = data.created_at;
//     this.purchase_note = data.purchase_note;
//     this.vendor_order_id = data.vendor_order_id;
//   }

//   getId() {
//     return this.id;
//   }

//   getPurchaseNote() {
//     return this.purchase_note;
//   }

//   getVendorOrderId() {
//     return this.vendor_order_id;
//   }

//   getBuyProductId() {
//     return this.buy_product_id;
//   }

//   getAgentCompanyId() {
//     return this.agent_company_id;
//   }

//   getIncludeTax() {
//     return this.include_tax;
//   }

//   getSubTotal() {
//     return this.sub_total;
//   }

//   getTaxTotal() {
//     return this.tax_total;
//   }

//   getTotal() {
//     return this.total;
//   }

//   getOriginalSubTotal() {
//     return this.original_sub_total;
//   }

//   getOriginalTotal() {
//     return this.original_total;
//   }

//   getDiscountTotal() {
//     return this.discount_total;
//   }

//   getFxRate() {
//     return this.fx_rate;
//   }

//   getCommissionRate() {
//     return this.commission_rate;
//   }

//   getCommissionRateType() {
//     return this.commission_rate_type;
//   }

//   getCreatedAt() {
//     return this.created_at;
//   }
// }

export class CurrencyModel {
    public readonly object: string;
    public readonly id: number;
    public readonly code: string;
    public readonly name: string;
    public readonly symbol: string;
    public readonly symbol_native: string;

    constructor(data: ICurrency) {
        this.object = data.object;
        this.id = data.id;
        this.code = data.code;
        this.name = data.name;
        this.symbol = data.symbol;
        this.symbol_native = data.symbol_native;
    }

    getId() {
        return this.id;
    }

    getCode() {
        return this.code;
    }

    getName() {
        return this.name;
    }

    getSymbol() {
        return this.symbol;
    }

    getSymbolNative() {
        return this.symbol_native;
    }
}

export class RegionModel {
    public readonly object: string;
    public readonly id: number;
    public readonly name: string;
    public readonly code: string;
    public readonly store_type: string;
    public readonly store: string;
    public readonly currency?: CurrencyModel;

    constructor(data: IRegion) {
        this.object = data.object;
        this.id = data.id;
        this.name = data.name;
        this.code = data.code;
        this.store_type = data.store_type;
        this.store = data.store;
        this.currency = data.currency
            ? new CurrencyModel(data.currency)
            : undefined;
    }

    getId() {
        return this.id;
    }

    getName() {
        return this.name;
    }

    getCode() {
        return this.code;
    }

    getStoreType() {
        return this.store_type;
    }

    getStore() {
        return this.store;
    }

    getCurrency() {
        return this.currency;
    }
}

export class ShippingCategoryModel {
    public readonly object: string;
    public readonly id: string;
    public readonly parent_id: string | null;
    public readonly name: string;
    public readonly slug: string;

    constructor(data: IShippingCategory) {
        this.object = data.object;
        this.id = data.id;
        this.parent_id = data.parent_id;
        this.name = data.name;
        this.slug = data.slug;
    }

    getId(): string {
        return this.id;
    }

    getObject(): string {
        return this.object;
    }

    getParentId(): string | null {
        return this.parent_id;
    }

    getName(): string {
        return this.name;
    }

    getSlug(): string {
        return this.slug;
    }
}

export class MoveOnShippingModel {
    public readonly object: string;
    public readonly id: number;
    public readonly shipping_type: string;
    public readonly shipping_mode: string;
    public readonly unit_type: string;
    public readonly rate: number;
    public readonly agent_category_price_read_id: number;
    public readonly shipping_category: ShippingCategoryModel;

    constructor(data: IMoveOnShipping) {
        this.object = data.object;
        this.id = data.id;
        this.shipping_type = data.shipping_type;
        this.shipping_mode = data.shipping_mode;
        this.unit_type = data.unit_type;
        this.rate = data.rate;
        this.agent_category_price_read_id = data.agent_category_price_read_id;
        this.shipping_category = new ShippingCategoryModel(
            data.shipping_category,
        );
    }

    getId() {
        return this.id;
    }

    getObject() {
        return this.object;
    }

    getShippingType() {
        return this.shipping_type;
    }

    getShippingMode() {
        return this.shipping_mode;
    }

    getUnitType() {
        return this.unit_type;
    }

    getRate() {
        return this.rate;
    }

    getAgentCategoryPriceReadId() {
        return this.agent_category_price_read_id;
    }

    getShippingCountry() {
        return this.shipping_category;
    }
}

export class BuyProductModel {
    public readonly object: string;
    public readonly id: number;
    public readonly product_number: string;
    public readonly product_id: string;
    public readonly product_vid: string;
    public readonly cart_item_id: number;
    public readonly product_title: string;
    public readonly product_image: string;
    public readonly product_link: string;
    public readonly shipping_provider: string;
    public readonly total_quantity: number;
    public readonly status: string;
    public readonly region: RegionModel;
    public readonly moveon_shipping?: MoveOnShippingModel;
    public readonly product_variations: BuyProductVariationCollectionModel;
    public readonly store: StoreModel;
    public readonly created_at: string;
    public readonly order: BuyOrderModel;
  
    constructor(data: IBuyProduct) {
      this.object = data.object;
      this.id = data.id;
      this.product_number = data.product_number;
      this.product_id = data.product_id;
      this.product_vid = data.product_vid;
      this.cart_item_id = data.cart_item_id;
      this.product_title = data.product_title;
      this.product_image = data.product_image;
      this.product_link = data.product_link;
      this.shipping_provider = data.shipping_provider;
      this.total_quantity = data.total_quantity;
      this.status = data.status;
      this.region = new RegionModel(data.region);
      this.moveon_shipping = data?.moveon_shipping
        ? new MoveOnShippingModel(data.moveon_shipping)
        : undefined;
  
      this.product_variations = new BuyProductVariationCollectionModel(
        data.product_variations,
      );
      this.store = new StoreModel(data.store);
      this.created_at = data.created_at;
      this.order = data.order && new BuyOrderModel(data.order)
    }
    getId() {
      return this.id;
    }

    getOrder(){
      return this.order;
    }
  
    getProductNumber() {
      return this.product_number;
    }

    getStore() {
        return this.store;
    }

    getMoveonShipping() {
        return this.moveon_shipping;
    }

    getProductId() {
        return this.product_id;
    }

    getProductVid() {
        return this.product_vid;
    }

    getCartItemId() {
        return this.cart_item_id;
    }

    getProductTitle() {
        return this.product_title;
    }

    getProductImage() {
        return this.product_image;
    }

    getProductLink() {
        return this.product_link;
    }

    getShippingProvider() {
        return this.shipping_provider;
    }

    getTotalQuantity() {
        return this.total_quantity;
    }

    getStatus() {
        return this.status;
    }

    getRegion() {
        return this.region;
    }

    getProductVariations() {
        return this.product_variations;
    }

    getCreatedAt() {
        return this.created_at;
    }
}

export class VariantMismatchModel {
    public readonly object: string;
    public readonly id: number;
    public readonly buy_product_variation_id: number;
    public readonly mismatch_type: string;
    public readonly new_quantity: number;
    public readonly new_unit_price: string;
    public readonly new_original_unit_price: string;
    public readonly suggested_sku_id: null | string | number;
    public readonly buy_product_variation?: BuyProductVariationModel;
    public readonly created_at: string;

    constructor(data: IVariantMismatch) {
        this.object = data.object;
        this.id = data.id;
        this.buy_product_variation_id = data.buy_product_variation_id;
        this.mismatch_type = data.mismatch_type;
        this.new_quantity = data.new_quantity;
        this.new_unit_price = data.new_unit_price;
        this.new_original_unit_price = data.new_original_unit_price;
        this.suggested_sku_id = data.suggested_sku_id;
        this.buy_product_variation = data?.buy_product_variation
            ? new BuyProductVariationModel(data.buy_product_variation)
            : undefined;
        this.created_at = data.created_at;
    }

    getObject(): string {
        return this.object;
    }

    getId(): number {
        return this.id;
    }

    getBuyProductVariationId(): number {
        return this.buy_product_variation_id;
    }

    getMismatchType(): string {
        return this.mismatch_type;
    }

    getNewQuantity(): number {
        return this.new_quantity;
    }

    getNewUnitPrice(): string {
        return this.new_unit_price;
    }

    getNewOriginalUnitPrice(): string {
        return this.new_original_unit_price;
    }

    getSuggestedSkuId(): null | string | number {
        return this.suggested_sku_id;
    }
    getBuyProductVariation() {
        return this.buy_product_variation;
    }

    getCreatedAt(): string {
        return this.created_at;
    }
}
export class VariantMismatchCollectionModel {
    public readonly object: string;
    public readonly data: VariantMismatchModel[];

    constructor(data: IVariantMismatchCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => new VariantMismatchModel(item));
    }
}

export class BuyProductChargeModel {
    public readonly object: string;
    public readonly id: number;
    public readonly charge_type: string;
    public readonly charged_by: string;
    public readonly fx_rate: number;
    public readonly commission_rate: number;
    public readonly commission_rate_type: string;
    public readonly original_amount: number;
    public readonly total_amount: number;
    public readonly created_at: string;
    public readonly fulfilment_status: BuyProductChargeStatusEnum;

    constructor(data: IBuyProductCharge) {
        this.object = data.object;
        this.id = data.id;
        this.charge_type = data.charge_type;
        this.charged_by = data.charged_by;
        this.fx_rate = data.fx_rate;
        this.commission_rate = data.commission_rate;
        this.commission_rate_type = data.commission_rate_type;
        this.original_amount = data.original_amount;
        this.total_amount = data.total_amount;
        this.created_at = data.created_at;
        this.fulfilment_status = data.fulfilment_status && data.fulfilment_status;
    }
    // Getter methods for accessing individual properties
    public getObject(): string {
        return this.object;
    }

    public getId(): number {
        return this.id;
    }

    public getChargeType(): string {
        return this.charge_type;
    }

    public getFulfilmentStatus(): BuyProductChargeStatusEnum {
        return this.fulfilment_status;
    }

    public getChargesTypeAsLabel() {
        return this.convertAsLabel(this.getChargeType());
    }

    private convertAsLabel(input: string): string {
        return input
            .split("-") // Split by hyphen (-)
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
            .join(" "); // Join the words with a space
    }

    public getChargedBy(): string {
        return this.charged_by;
    }

    public getFxRate(): number {
        return this.fx_rate;
    }

    public getCommissionRate(): number {
        return this.commission_rate;
    }

    public getCommissionRateType(): string {
        return this.commission_rate_type;
    }

    public getOriginalAmount(): number {
        return this.original_amount;
    }

    public getTotalAmount(): number {
        return this.total_amount;
    }

    public getCreatedAt(): string {
        return this.created_at;
    }
}
export class BuyProductChargeCollectionModel {
    public readonly object: string;
    public readonly data: BuyProductChargeModel[];

    constructor(data: IBuyProductChargeCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => new BuyProductChargeModel(item));
    }
}
export class ActionSequenceAddonServiceModel {
    public readonly object: string;
    public readonly id: number;
    public readonly name: string;
    public readonly addon_for: string;
    public readonly identifier: string;
    public readonly description: string;
    public readonly status: string;
    public readonly created_at: string | null;

    constructor(data: IActionSequenceAddonService) {
        this.object = data.object;
        this.id = data.id;
        this.name = data.name;
        this.addon_for = data.addon_for;
        this.identifier = data.identifier;
        this.description = data.description;
        this.status = data.status;
        this.created_at = data.created_at;
    }
    public getObject(): string {
        return this.object;
    }

    public getId(): number {
        return this.id;
    }

    public getName(): string {
        return this.name;
    }

    public getAddonFor(): string {
        return this.addon_for;
    }

    public getIdentifier(): string {
        return this.identifier;
    }

    public getDescription(): string {
        return this.description;
    }

    public getStatus(): string {
        return this.status;
    }

    public getCreatedAt(): string | null {
        return this.created_at;
    }
}
export class ActionSequenceAddonServiceCollectionModel {
    public readonly object: string;
    public readonly data: ActionSequenceAddonServiceModel[];

    constructor(data: IActionSequenceAddonServiceCollection) {
        this.object = data.object;
        this.data = data.data.map(
            (item) => new ActionSequenceAddonServiceModel(item),
        );
    }
}
export class BuyActionShippingModel {
    public readonly object: string;
    public readonly id: number;
    public readonly agent_warehouse_id: number;
    public readonly shipping_category: ShippingCategoryModel;
    public readonly agent_category_price_read_id: number;
    public readonly destination_warehouse_id: number;
    public readonly rate: string;
    public readonly created_at: string;

    constructor(data: IBuyActionShipping) {
        this.object = data.object;
        this.id = data.id;
        this.agent_warehouse_id = data.agent_warehouse_id;
        this.shipping_category = new ShippingCategoryModel(
            data.shipping_category,
        );
        this.agent_category_price_read_id = data.agent_category_price_read_id;
        this.destination_warehouse_id = data.destination_warehouse_id;
        this.rate = data.rate;
        this.created_at = data.created_at;
    }

    // Method to get object property
    public getObject(): string {
        return this.object;
    }

    // Method to get id property
    public getId(): number {
        return this.id;
    }

    // Method to get agent_warehouse_id property
    public getAgentWarehouseId(): number {
        return this.agent_warehouse_id;
    }

    // Method to get shipping_category property
    public getShippingCategory(): ShippingCategoryModel {
        return this.shipping_category;
    }

    // Method to get agent_category_price_read_id property
    public getAgentCategoryPriceReadId(): number {
        return this.agent_category_price_read_id;
    }

    // Method to get destination_warehouse_id property
    public getDestinationWarehouseId(): number {
        return this.destination_warehouse_id;
    }

    // Method to get rate property
    public getRate(): string {
        return this.rate;
    }

    // Method to get created_at property
    public getCreatedAt(): string {
        return this.created_at;
    }
}

export class ImageModel {
    public readonly object: string;
    public readonly id: number;
    public readonly title: string | null;
    public readonly alt: string | null;
    public readonly src: string;
    public readonly height: number | null;
    public readonly width: number | null;
    public readonly created_at: string;

    constructor(data: IImage) {
        this.object = data.object;
        this.id = data.id;
        this.title = data.title;
        this.alt = data.alt;
        this.src = data.src;
        this.height = data.height;
        this.width = data.width;
        this.created_at = data.created_at;
    }

    public getObject(): string {
        return this.object;
    }

    public getId(): number {
        return this.id;
    }

    public getTitle(): string | null {
        return this.title;
    }

    public getAlt(): string | null {
        return this.alt;
    }

    public getSrc(): string {
        return this.src;
    }

    public getHeight(): number | null {
        return this.height;
    }

    public getWidth(): number | null {
        return this.width;
    }

    public getCreatedAt(): string {
        return this.created_at;
    }
}

export class ImageCollectionModel {
    public readonly object: string;
    public readonly data: ImageModel[];

    constructor(data: IImageCollection) {
        this.object = data.object;
        this.data = data.data.map((image) => new ImageModel(image));
    }

    public getObject(): string {
        return this.object;
    }

    public getData(): ImageModel[] {
        return this.data;
    }
}

export class BuyActionShippingSlotModel {
    public readonly object: string;
    public readonly id: number;
    public readonly min_amount: number;
    public readonly max_amount: number;
    public readonly rate: number;
    public readonly created_at: string;

    constructor(data: IBuyActionShippingSlot) {
        this.object = data.object;
        this.id = data.id;
        this.min_amount = data.min_amount;
        this.max_amount = data.max_amount;
        this.rate = data.rate;
        this.created_at = data.created_at;
    }
}

export class BuyActionShippingSlotCollectionModel {
    public readonly object: string;
    public readonly data: BuyActionShippingSlotModel[];

    constructor(data: IBuyActionShippingSlotCollection) {
        this.object = data.object;
        this.data = data.data.map(
            (slot) => new BuyActionShippingSlotModel(slot),
        );
    }

    public getObject(): string {
        return this.object;
    }

    public getData(): BuyActionShippingSlotModel[] {
        return this.data;
    }
}

export class BuyActionShippingRateModel {
    public readonly object: string;
    public readonly id: number;
    public readonly rate: number;
    public readonly created_at: string;
    public readonly agent_category_price_read?: AgentCategoryPriceReadModel;
    public readonly slots: BuyActionShippingSlotCollectionModel;

    constructor(data: IBuyActionShippingRate) {
        this.object = data.object;
        this.id = data.id;
        this.rate = data.rate;
        this.created_at = data.created_at;
        this.agent_category_price_read = data.agent_category_price_read
            ? new AgentCategoryPriceReadModel(data.agent_category_price_read)
            : undefined;
        this.slots = new BuyActionShippingSlotCollectionModel(data.slots);
    }

    public getObject(): string {
        return this.object;
    }

    public getId(): number {
        return this.id;
    }

    public getRate(): number {
        return this.rate;
    }

    public getCreatedAt(): string {
        return this.created_at;
    }

    public getSlots(): BuyActionShippingSlotCollectionModel {
        return this.slots;
    }
}

export class ActionSequenceModel {
    public readonly object: string;
    public readonly id: number;
    public readonly type: string;
    public readonly perform_order: number;
    public readonly status: string;
    public readonly admin_note: string | null;
    public readonly customer_note: string | null;
    public readonly agent_note: string | null;
    public readonly images?: ImageCollectionModel;
    public readonly variant_mismatches?: VariantMismatchCollectionModel | null;
    public readonly buy_charge?: null | BuyProductChargeModel;
    public readonly minimum_order_quantity?: number;
    public readonly addons?: ActionSequenceAddonServiceCollectionModel;
    public readonly created_at: string;
    public readonly buy_action_shipping?: BuyActionShippingRateModel;
    public readonly shipping?: BuyActionShippingModel;

    constructor(data: IActionSequence) {
        this.object = data.object;
        this.id = data.id;
        this.type = data.type;
        this.perform_order = data.perform_order;
        this.status = data.status;
        this.admin_note = data.admin_note;
        this.customer_note = data.customer_note;
        this.agent_note = data.agent_note;
        this.minimum_order_quantity = data.minimum_order_quantity;
        this.variant_mismatches = data.variant_mismatches
            ? new VariantMismatchCollectionModel(data.variant_mismatches)
            : null;
        this.buy_charge = data.buy_charge
            ? new BuyProductChargeModel(data.buy_charge)
            : null;
        this.addons = data.addons
            ? new ActionSequenceAddonServiceCollectionModel(data.addons)
            : undefined;
        this.created_at = data.created_at;
        this.shipping = data.shipping
            ? new BuyActionShippingModel(data.shipping)
            : undefined;
        this.images = data.images
            ? new ImageCollectionModel(data.images)
            : undefined;
        this.buy_action_shipping = data.buy_action_shipping
            ? new BuyActionShippingRateModel(data.buy_action_shipping)
            : undefined;
    }
    getMinimumQuantity() {
        return this.minimum_order_quantity;
    }
    getShipping() {
        return this.shipping;
    }
    getAddonServices() {
        return this.addons?.data;
    }

    getActionShippingRate() {
        return this.buy_action_shipping;
    }

    getQuantityInitialState() {
        return (this.getVariantMismatches() as VariantMismatchModel[]).map(
            (item) => {
                return {
                    sku_id:
                        (
                            item?.getBuyProductVariation() as BuyProductVariationModel
                        )?.getSkuId() || "",
                    quantity: 0,
                };
            },
        );
    }
    getObject(): string {
        return this.object;
    }

    getId(): number {
        return this.id;
    }

    getType(): string {
        return this.type;
    }

    getPerformOrder(): number {
        return this.perform_order;
    }

    getStatus(): string {
        return this.status;
    }

    getAdminNote(): string | null {
        return this.admin_note;
    }

    getCustomerNote(): string | null {
        return this.customer_note;
    }

    getAgentNote(): string | null {
        return this.agent_note;
    }

    getVariantMismatches(): VariantMismatchModel[] | null {
        return this.variant_mismatches?.data || null;
    }

    getBuyCharges(): null | BuyProductChargeModel {
        return this.buy_charge || null;
    }

    getCreatedAt(): string {
        return this.created_at;
    }
}
export class ActionSequenceCollectionModel {
    public readonly object: string;
    public readonly data: ActionSequenceModel[];

    constructor(data: IActionSequenceCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => new ActionSequenceModel(item));
    }
}
export class BuyActionModel {
    public readonly object: string;
    public readonly id: number;
    public readonly action_number: string;
    public readonly buy_product_id: number;
    public readonly status: string;
    public readonly admin_note: string | null;
    public readonly customer_note: string | null;
    public readonly agent_note: string | null;
    public readonly sequences: ActionSequenceCollectionModel;
    public readonly buy_product: BuyProductModel;
    public readonly created_at: string;
    public readonly user: CommonUserModel;
  
    constructor(data: IBuyAction) {
      this.object = data.object;
      this.id = data.id;
      this.action_number = data.action_number;
      this.buy_product_id = data.buy_product_id;
      this.status = data.status;
      this.admin_note = data.admin_note;
      this.customer_note = data.customer_note;
      this.agent_note = data.agent_note;
      this.sequences =data.sequences &&  new ActionSequenceCollectionModel(data.sequences);
      this.buy_product =data.buy_product &&  new BuyProductModel(data.buy_product);
      this.created_at = data.created_at;
      this.user = data.user && new CommonUserModel(data.user)
    }
    getCategoryName() {
        return (
            this.getBuyProduct()
                .getMoveonShipping()
                ?.getShippingCountry()
                .getName() || ""
        );
    }
    getCurrencySymbolNative() {
        return this.getBuyProduct().getRegion()?.getCurrency()?.getCode() || "";
    }
    getObject(): string {
        return this.object;
    }

    getId(): number {
        return this.id;
    }

    getActionNumber(): string {
        return this.action_number;
    }

    getBuyProductId(): number {
        return this.buy_product_id;
    }

    getUser(){
      return this.user;
    }
  
    getStatus(): string {
        return this.status;
    }

    getAdminNote(): string | null {
        return this.admin_note;
    }

    getCustomerNote(): string | null {
        return this.customer_note;
    }

    getAgentNote(): string | null {
        return this.agent_note;
    }

    getSequences() {
        return this.sequences.data;
    }

    getBuyProduct() {
        return this.buy_product;
    }

    getCreatedAt(): string {
        return formatDateTime(this.created_at);
    }
}

export class BuyActionCollectionModel {
    public readonly object: string;
    public readonly data: BuyActionModel[];
    public readonly pagination: IPagination;
    public readonly filters: {
        [key: string]: IFilterType;
    };
    constructor(data: IBuyActionCollection) {
        this.object = data.object;
        this.pagination = data.pagination;
        this.filters = data.filters;
        this.data = data.data.map((item) => new BuyActionModel(item));
    }
    getData() {
        return this.data;
    }
    getFilters() {
        return this.filters;
    }
    getPagination() {
        return this.pagination;
    }
}
