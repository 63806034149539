import { IFilterType } from "./filters";
import { IPagination } from "./pagination";
import { IStore } from "./storeCollection";
import { IUser } from "./userCollection";
import { IDestinationWarehouse } from "./destinationWarehouseCollection";
import { IShippingCategory } from "./shippingCategory";
import { IShipmentProduct } from "./shipmentProductCollection";
import { IRegion } from "./regionCollection";
import { IAgentWarehouse } from "./agentWarehouseCollection";
import { CommissionRateTypeEnums } from "@/enums/exchangeListCollectionEnums";
import { IShippingCountry } from "./shippingCountryCollection";
import { IAgentCompany } from "./agentCompanyCollection";
import { buyProductStatusEnum } from "@/enums/buyProductEnum";
import { RateTypeEnums } from "@/enums/shippingCoreEnums";
import { ICustomerUser } from "./customerUserCollection";

export interface IBuyProductCustomerAssociation {
    agent_company_id: number;
    created_at: string;
    purchase_note: string;
    vendor_order_id: string;
    object: string;
    id: number;
    buy_product_id: number;
    include_tax: number;
    sub_total: number;
    tax_total: number;
    total: number;
    original_sub_total: number;
    original_total: number;
    discount_total: number;
    fx_rate: number;
    commission_rate: number;
    commission_rate_type: CommissionRateTypeEnums;
}
export interface IShippingSlot {
    object: string;
    id: number;
    min_amount: number;
    max_amount: number;
    rate: number;
}

export interface IShippingSlotCollection {
    object: string;
    data: IShippingSlot[];
}

export interface IMoveonShipping {
    object: string;
    id: number;
    agent_category_price_read_id: number;
    agent_warehouse: IAgentWarehouse;
    shipping_country: IShippingCountry;
    shipping_category: IShippingCategory;
    destination_warehouse: IDestinationWarehouse;
    shipping_type: string;
    shipping_mode: string;
    unit_type: string;
    rate: number;
    slots: IShippingSlotCollection;
}

export interface IBuyProductPackage {
    object: string;
    id: number;
    buy_product_id: number;
    weight: number;
    weight_unit: string;
    length: number;
    length_unit: string;
    width: number;
    width_unit: string;
    height: number;
    height_unit: string;
}

export interface IOrderHandler {
    object: "User";
    id: number;
    name: string;
    phone: string | null;
    email: string;
    type: string;
    is_active: number;
    created_at: string;
    is_drop_user?: boolean | undefined;
    shipping_mark?: string | undefined;
    email_verified_at?: string;
    order_handler?: string;
    billing_addr_id?: string;
    shipping_addr_id?: string;
    agent_company_id?: string;
    lock_version?: string;
    etag?: string;
    gravatar?: string;
    updated_at?: string;
}

export interface IBuyOrder {
    object: "BuyOrder";
    id: number;
    order_number: string;
    order_tag?: string | null;
    order_type: string;
    order_status: string;
    payment_status: string;
    note?: string | null;
    meta: any[];
    order_handler?: IOrderHandler;
    customer?: ICustomerUser;
    created_at: string;
    type: string;
    status: string;
    oauth_client_id: string;
    addresses: { [key: string]: string }[];
}

export interface Address {
    object: "Address";
    id: number;
    address: AddressDetails;
    country_id: number;
    ownerable_id: number;
    ownerable_type: string;
    display_address: AddressDetails;
    is_default_billing: number;
    is_default_shipping: number;
}

export interface ProductSeller {
    object: "ProductSeller";
    id: number;
    seller_id: string;
    seller_name: string;
}

// export interface ProductCustomerAssociation {
//     object: "BuyProductAgentAssociation";
//     id: number;
//     buy_product_id: number;
//     include_tax: number;
//     sub_total: number;
//     tax_total: number;
//     total: number;
//     original_sub_total: number;
//     original_total: number;
//     discount_total: number;
//     fx_rate: number;
//     commission_rate: number;
//     commission_rate_type: CommissionRateTypeEnums;
//     created_at: string;
// }

export interface BuyProductPackage {
    object: "BuyProductPackage";
    id: number;
    buy_product_id: number;
    weight: number;
    weight_unit: string;
    length: number;
    length_unit: string;
    width: number;
    width_unit: string;
    height: number;
    height_unit: string;
}

export interface TransactionSummary {
    paid: number;
    due: number;
    overpaid: number;
}

export interface ILabels {
    object: string;
    data: ILabelData[];
}

export interface ILabelData {
    object: string;
    id: number;
    label: string;
}

export interface ICustomerInvoice {
    object: "Invoice";
    id: number;
    invoice_number: string;
    status: string;
    total_amount: number;
    initial_amount: number;
    refundable_amount: number;
    transaction_summary: TransactionSummary;
    customer: IUser;
    created_at: string;
}
export interface IAgentInvoice {
    object: "Invoice";
    id: number;
    invoice_number: string;
    status: string;
    total_amount: number;
    initial_amount: number;
    refundable_amount: number;
    transaction_summary: TransactionSummary;
    customer: IUser;
    created_at: string;
}

export interface ShippingSlot {
    object: "ShippingSlot";
    id: number;
    min_amount: number;
    max_amount: number;
    rate: number;
}

export interface ShippingSlotCollection {
    object: "ShippingSlotCollection";
    data: ShippingSlot[];
}

export interface IAgentCategoryPriceRead {
    object: "AgentCategoryPriceRead";
    id: number;
    contain: string;
    shipping_type: string;
    shipping_mode: string;
    unit_type: string;
    rate: number;
    commission_rate: number | null;
    commission_rate_type: string | null;
    total_rate: number | null;
    min_shipping_time: number;
    max_shipping_time: number;
    shipping_time_unit: string;
    shipment_schedule: string;
}

interface AddressDetails {
    area: string;
    email: string;
    phone: string;
    country: string;
    district: string;
    address_1: string;
    address_2: string;
    last_name: string;
    first_name: string;
    postal_code: string;
    contact_person: string;
    street_address: string;
}

export interface IActionType {
    label: string;
    value: string;
}

export interface IBuyAction {
    object: string;
    id: number;
    action_number: string;
    buy_product_id: number;
    status: string;
    admin_note: string | null;
    customer_note: string | null;
    agent_note: string | null;
    action_type: IActionType[];
    created_at: string;
}
export interface IBuyActionCollection {
    object: "BuyActionCollection";
    data: IBuyAction[];
}

export interface IBuyProductCharge {
    object: string;
    id: number;
    ownable_type: string;
    ownable_id: number;
    charge_type: string;
    charged_by: string;
    fx_rate: number;
    commission_rate: number;
    commission_rate_type: string;
    original_amount: number;
    total_amount: number;
    created_at: string;
}

export interface IBuyProductChargeCollection {
    object: "BuyProductChargeCollection";
    data: IBuyProductCharge[];
}

export interface IBuyProductMoveOnShipping {
    object: "BuyProductMoveOnShipping";
    id: number;
    shipping_type: string;
    shipping_mode: string;
    unit_type: string;
    rate: number;
    agent_category_price_read: IAgentCategoryPriceRead;
    shipping_country: IShippingCountry;
    agent_warehouse: IAgentWarehouse;
    shipping_category: IShippingCategory;
    destination_warehouse: IDestinationWarehouse;
    slots: ShippingSlotCollection;
    commission_rate: number;
    commission_rate_type: RateTypeEnums;
}

export interface IBuyProductVariationProperty {
    object: "BuyProductVariationProperty";
    id: number;
    property_id: string;
    property_name: string;
    property_original_name: string;
    property_value_id: string;
    property_value_name: string;
    property_value_original_name: string;
    property_value_color?: string;
    property_value_title: string;
    property_value_image: string;
    property_value_thumb: string;
}

export interface IBuyProductVariationPropertiesCollection {
    object: "BuyProductVariationPropertiesCollection";
    data: IBuyProductVariationProperty[];
}

export interface IBuyProductVariation {
    object: "BuyProductVariation";
    id: number;
    buy_product_id: number;
    sku_id: string;
    image: string;
    quantity: number;
    unit_price: number;
    total_price: number;
    original_unit_price: number;
    original_total_price: number;
    properties: IBuyProductVariationPropertiesCollection;
    cart_item_variation_id?: number | null;
    sku_original_id: string;
    fulfilment_status: string;
}

export interface BuyProductVariationCollection {
    object: "BuyProductVariationCollection";
    data: IBuyProductVariation[];
}

export interface IBuyProductAgentAssociation {
    object: "BuyProductAgentAssociation";
    id: number;
    buy_product_id: number;
    agent_company_id: number;
    agent_company: IAgentCompany;
    include_tax: number;
    sub_total: number;
    tax_total: number;
    total: number;
    original_sub_total: number;
    original_total: number;
    discount_total: number;
    fx_rate: number;
    commission_rate: number;
    commission_rate_type: CommissionRateTypeEnums;
    purchase_note: string | null;
    vendor_order_id: string | null;
    created_at: string;
}

export type INumberOfPeopleInTeam =
    | "less_than_10_people"
    | "10_to_50_people"
    | "above_50_people";

export type IYearsOfExperience =
    | "less_than_1_year"
    | "1_to_5_years"
    | "above_5_years";

export interface IRegisteredCountryDetail {
    id: number;
    code: string;
    name: string;
    status: "active" | "inactive";
    created_at: string;
}

export interface IBuyProduct {
    object: "BuyProduct";
    id: number;
    product_number: string;
    product_id: string;
    product_vid: string;
    cart_item_id: number;
    product_title: string;
    product_image: string;
    product_link: string;
    shipping_provider: string;
    total_quantity: number;
    status: buyProductStatusEnum;
    source_type: string;
    source_id: number;
    shipping_address: Address;
    user: ICustomerUser;
    created_at: string;
    product_seller: ProductSeller;
    buy_product_package: BuyProductPackage;
    product_variations: BuyProductVariationCollection;
    reject_reason?: string;
    cancel_reason?: string;
    order?: IBuyOrder;
    store?: IStore;
    region: IRegion;
    product_customer_association?: IBuyProductCustomerAssociation;
    product_agent_association?: IBuyProductAgentAssociation;
    shipment_product?: IShipmentProduct;
    customer_invoice?: ICustomerInvoice;
    agent_invoice?: IAgentInvoice;
    buy_actions?: IBuyActionCollection;
    charges?: IBuyProductChargeCollection;
    moveon_shipping?: IBuyProductMoveOnShipping;
    customer_note: string | null;
}

export interface IBuyProductCollection {
    object: "BuyProductCollection";
    data: IBuyProduct[];
    pagination: IPagination;
    filters: IFilterType;
}

export enum cancelVariationsAndChargesTypeEnum {
    EVERYTHING = "everything",
    ALL_VARIATIONS = "all-variations",
    SPECIFIC_VARIATIONS = "specific-variations",
    ALL_CHARGES = "all-charges",
    SPECIFIC_CHARGES = "specific-charges",
}

export interface ICancelVariationsAndChargesPayload {
    type: cancelVariationsAndChargesTypeEnum;
    variation_ids?: number[] | null;
    charge_ids?: number[] | null;
    cancel_reason?: string;
}
