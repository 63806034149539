import React, { useState } from "react";
import { Card, Modal, Spin, Table, Tabs, Tag, Typography } from "antd";
import { useAtom } from "jotai";
import { PaginationModel } from "@/models/pagination";
import { formatDateTime } from "@/utils/helpers";
import {
    purchaseCartCollectionAtom,
    shippingCartAtom,
    wishlistAtom,
} from "@/lib/core-react/store/store";
import { CustomerUserModel } from "@/models/customerUserCollectionModel";
import { ShippingCartCollectionModel } from "@/models/shippingCartCollectionModel";
import { WishlistCollectionModel } from "@/models/wishlistCollectionModel";
import { StatusTag } from "@/components";
import WishlistTableColumns from "../../Wishlists/components/wishListTable";
import { getPurchaseCartColumns } from "../../PurchaseCarts/component/purchaseCartTable";
import { getShippingCartColumns } from "../../ShippingCarts/components/shippingCartTable";
import { useGetCartList } from "@/lib/core-react/hooks/private/usePurchaseCart";
import { useGetShippingCart } from "@/lib/core-react/hooks/private/useShippingCart";
import { useGetWishlist } from "@/lib/core-react/hooks/private/useWishlist";

const { Paragraph, Text } = Typography;

interface IProps {
    selectedUser: CustomerUserModel;
}

const CustomerDetailsViews: React.FC<IProps> = ({ selectedUser }) => {
    const { getCartList } = useGetCartList();
    const { getShippingCart } = useGetShippingCart();
    const { getWishlist } = useGetWishlist();

    const [isShowModal, setIsShowModal] = useState(false);
    const [activeTabKey, setActiveTabKey] = useState("basic_information");

    // Retrieve atom data
    const [
        {
            data: purchaseCartCollectionData,
            isLoading,
            error,
            unAuthorized,
            code,
        },
    ] = useAtom(purchaseCartCollectionAtom);
    const [
        {
            data: shippingCartData,
            isLoading: isShippingCartLoading,
            error: shippingError,
        },
    ] = useAtom(shippingCartAtom);
    const [
        {
            data: wishListData,
            isLoading: isWishListLoading,
            error: isWishListError,
        },
    ] = useAtom(wishlistAtom);

    const shippingCart =
        shippingCartData && new ShippingCartCollectionModel(shippingCartData);
    const wishListDataProcessed =
        wishListData && new WishlistCollectionModel(wishListData);

    const purchaseCartColumns = getPurchaseCartColumns(
        purchaseCartCollectionData?.pagination,
    );
    const shippingCartColumns = getShippingCartColumns(
        shippingCartData?.pagination,
    );
    const wishListColumns = WishlistTableColumns(wishListData?.pagination);

    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        getCartList({
            user_id: selectedUser.id,
            page: pageCount,
            per_page: pageSize,
        });
    };

    const handleShippingPaginationChange = (
        pageCount: number,
        pageSize: number,
    ) => {
        getShippingCart(
            `user_id=${selectedUser.id}&page=${pageCount}&per_page=${pageSize}`,
        );
    };

    const handleWishListPaginationChange = (
        pageCount: number,
        pageSize: number,
    ) => {
        getWishlist(
            `user_id=${selectedUser.id}&page=${pageCount}&per_page=${pageSize}`,
        );
    };

    const paginationConfig = PaginationModel.getPaginationConfig(
        purchaseCartCollectionData,
        handlePaginationChange,
    );
    const shippingPaginationConfig = PaginationModel.getPaginationConfig(
        shippingCart,
        handleShippingPaginationChange,
    );
    const wishListPaginationConfig = PaginationModel.getPaginationConfig(
        shippingCart,
        handleWishListPaginationChange,
    );

    const handleTabChange = (key: string) => {
        setActiveTabKey(key);
        if (key === "shipping_cart") {
            getShippingCart(`user_id=${selectedUser.id}`);
        } else if (key === "purchase_cart") {
            getCartList({ user_id: selectedUser.id });
        } else if (key === "wishList") {
            getWishlist(`user_id=${selectedUser.id}`);
        }
    };

    const handleNameClick = () => {
        setIsShowModal(true);
    };

    const tabsItems = [
        {
            key: "basic_information",
            label: "Basic Information",
            children: (
                <>
                    <Paragraph>
                        <Text>Name: </Text>
                        <Text strong>{selectedUser.getName()}</Text>
                    </Paragraph>
                    {selectedUser.getEmail() && (
                        <Paragraph>
                            <Text>Email: </Text>
                            <Text strong>{selectedUser.getEmail()}</Text>
                        </Paragraph>
                    )}
                    {selectedUser.getPhone() && (
                        <Paragraph>
                            <Text>Phone: </Text>
                            <Text strong>{selectedUser.getPhone()}</Text>
                        </Paragraph>
                    )}
                    {selectedUser.getShippingMark() && (
                        <Paragraph>
                            <Text>Shipping Mark: </Text>
                            <Text strong>{selectedUser.getShippingMark()}</Text>
                        </Paragraph>
                    )}
                    {selectedUser.getStatus() && (
                        <Paragraph>
                            <Text>Status: </Text>
                            <StatusTag
                                slug={selectedUser.getStatus()|| ""}
                                text={selectedUser.getStatus()|| ""}
                            />
                        </Paragraph>
                    )}
                    <div style={{ marginTop: 10 }}>
                        {selectedUser.getCreatedAt() && (
                            <Paragraph>
                                <Text>Created At: </Text>
                                <Tag color="purple">
                                    {formatDateTime(
                                        selectedUser.created_at,
                                    )}
                                </Tag>
                            </Paragraph>
                        )}
                    </div>
                    {selectedUser.getOrderHandler() && (
                        <Card
                            title="OrderHandler Details"
                            style={{ marginTop: 15 }}
                        >
                            <Paragraph>
                                <Text>Name: </Text>
                                <Text strong>
                                    {selectedUser.getOrderHandler()?.name}
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                <Text>Email: </Text>
                                <Text strong>
                                    {selectedUser.getOrderHandler()?.email}
                                </Text>
                            </Paragraph>
                            {selectedUser.getOrderHandler()?.phone && (
                                <Paragraph>
                                    <Text>Phone: </Text>
                                    <Text strong>
                                        {selectedUser.getOrderHandler()?.phone}
                                    </Text>
                                </Paragraph>
                            )}
                        </Card>
                    )}
                </>
            ),
        },
        {
            key: "purchase_cart",
            label: "Purchase Cart",
            children: (
                <Spin spinning={isLoading}>
                    {error ? (
                        <div>
                            {unAuthorized
                                ? "Unauthorized access"
                                : `Error: ${error} (Code: ${code})`}
                        </div>
                    ) : (
                        <Table
                            columns={purchaseCartColumns}
                            dataSource={purchaseCartCollectionData?.getProcessData()}
                            loading={isLoading}
                            bordered
                            pagination={paginationConfig}
                            rowKey="id"
                            style={{ marginTop: 10 }}
                            scroll={{ x: 1440 }}
                        />
                    )}
                </Spin>
            ),
        },
        {
            key: "wishList",
            label: "WishList",
            children: (
                <Spin spinning={isWishListLoading}>
                    {isWishListError ? (
                        <div>Error: {isWishListError}</div>
                    ) : (
                        <Table
                            columns={wishListColumns}
                            dataSource={wishListDataProcessed?.getProcessData()}
                            loading={isWishListLoading}
                            bordered
                            pagination={wishListPaginationConfig}
                            rowKey="id"
                            style={{ marginTop: 10 }}
                            scroll={{ x: 1440 }}
                        />
                    )}
                </Spin>
            ),
        },
        {
            key: "shipping_cart",
            label: "Shipping Cart",
            children: (
                <Spin spinning={isShippingCartLoading}>
                    {shippingError ? (
                        <div>Error: {shippingError}</div>
                    ) : (
                        <Table
                            columns={shippingCartColumns}
                            dataSource={shippingCart?.getProcessData()}
                            loading={isShippingCartLoading}
                            bordered
                            pagination={shippingPaginationConfig}
                            rowKey="id"
                            style={{ marginTop: 10 }}
                            scroll={{ x: 1440 }}
                        />
                    )}
                </Spin>
            ),
        },
    ];

    return (
        <>
            <div>
                <Paragraph>
                    <Text>Name: </Text>
                    <Text
                        strong
                        copyable
                        style={{ cursor: "pointer", color: "blue" }}
                        onClick={handleNameClick}
                    >
                        {selectedUser.getName()}
                    </Text>
                </Paragraph>
            </div>

            {isShowModal && (
                <Modal
                    title={`Customer (${selectedUser.getName()})`}
                    open={isShowModal}
                    footer={null}
                    onCancel={() => setIsShowModal(false)}
                    destroyOnClose
                    width="60%"
                >
                    <Tabs
                        defaultActiveKey="basic_information"
                        activeKey={activeTabKey}
                        onChange={handleTabChange}
                        items={tabsItems}
                    />
                </Modal>
            )}
        </>
    );
};

export default CustomerDetailsViews;
