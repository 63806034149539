import {
    Button,
    Col,
    Modal,
    Row,
    Table,
    Tag,
    Typography,
    Image,
    Dropdown,
    notification,
    Space,
    Tooltip,
} from "antd";
import useDataFilters from "@/hooks/useDataFilters";
import { useAtom } from "jotai";
import { buyActionCollectionAtom } from "@/lib/core-react/store/store";
import { ApiHelperModel } from "@/models/apiHelper";
import { useEffect, useState } from "react";
import {
    useTakeActionCancel,
    useTakeActionList,
    useTakeActionPerform,
    useTakeActionRefund,
} from "@/lib/core-react/hooks/private/useBuyAction";
import {
    BuyActionCollectionModel,
    BuyActionModel,
} from "@/models/buyActionCollectionModel";
import {
    ArrowRightOutlined,
    CloseOutlined,
    DownOutlined,
    EditOutlined,
    HddFilled,
    RollbackOutlined,
} from "@ant-design/icons";
import ActionViewDetailsModal from "./ActionViewDetailsModal";
import { PageHeader } from "@ant-design/pro-layout/es/components/PageHeader";
import useWindowWidth from "../../../lib/core-react/hooks/public/useWindowWidth";
import { StatusTag } from "../../../components";
import { IFilterType } from "../../../types/filters";
import { PaginationModel } from "../../../models/pagination";
import FiltersComponent from "../../../components/FiltersComponent";
import TextArea from "antd/es/input/TextArea";
import {
    IActionPerformPayload,
    IActionPerformPayloadSequence,
} from "@/types/buyActionCollection";
import Paragraph from "antd/lib/typography/Paragraph";
import { Link, useNavigate } from "react-router-dom";
import { formatString } from "@/utils/helper";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ADMIN_BUY_TAKE_ACTION_PERMISSION_ENUM } from "@/consts/permission-enum/take-actions";
import { truncateText } from "@/utils/helpers";

const ActionsNeeded = () => {
    const { getAllTakeActions } = useTakeActionList();
    const { takeActionPerform, isLoading: performLoading } =
        useTakeActionPerform();
    const { takeActionRefund, isLoading: refundLoading } =
        useTakeActionRefund();
    const { takeActionCancel, isLoading: cancelLoading } =
        useTakeActionCancel();

    const [{ data: buyActionCollectionData, isLoading, refetch }] = useAtom(
        buyActionCollectionAtom,
    );

    const [isShowCreateModal, setIsShowCreateModal] = useState<boolean>(false);
    const [selectedActionProduct, setSelectedActionProduct] = useState<
        BuyActionModel | undefined
    >(undefined);
    const [selectedAction, setSelectedAction] = useState(null);
    const [textAreaValue, setTextAreaValue] = useState("");
    const [openReject, setOpenReject] = useState<boolean>(false);

    const { Text } = Typography;
    const { width, isMobile } = useWindowWidth();
    const navigate = useNavigate();
    const {
        filters,
        isFirstCall,
        isFetched,
        handleFilterChange,
        handelFilterClear,
        initializeAvailableFilter,
        refetch: refetchFromFilter,
    } = useDataFilters();

    const AllTackActionsCollectionData =
        buyActionCollectionData &&
        new BuyActionCollectionModel(buyActionCollectionData);

    // Api Call
    useEffect(() => {
        if ((!isFetched && isFirstCall) || refetch || refetchFromFilter) {
            ApiHelperModel.makeGetRequest(filters || {}, getAllTakeActions);
        }
    }, [isFirstCall, isFetched, refetch, refetchFromFilter]);

    // // Getting all available filters
    useEffect(() => {
        if (!isFetched && AllTackActionsCollectionData?.getFilters()) {
            initializeAvailableFilter(filterData as IFilterType);
        }
    }, [
        isFetched,
        initializeAvailableFilter,
        AllTackActionsCollectionData?.getFilters(),
    ]);

    useEffect(() => {
        if (openReject) {
            setTextAreaValue("");
        }
    }, [openReject]);

    //const paginationData = AllTackActionsCollectionData?.pagination;

    const filterData = AllTackActionsCollectionData?.getFilters();

    const handleShowDetail = (record: any) => {
        setSelectedActionProduct(record);
        setIsShowCreateModal(record);
    };

    const handleRejectCancel = () => {
        setOpenReject(false);
    };

    //Take Action handlers =>
    const handleTakeActionNeeded = async () => {
        if (!selectedActionProduct) {
            return;
        }
        const sequences: IActionPerformPayloadSequence[] = selectedActionProduct
            .getSequences()
            .map((sequence) => {
                const sequenceData: IActionPerformPayloadSequence = {
                    action_type: sequence.getType(),
                    customer_note: textAreaValue,
                    sequence_id: sequence.id,
                };

                if (
                    [
                        "product-stock-out",
                        "sku-mismatched",
                        "quantity-update",
                    ].includes(sequence.getType())
                ) {
                    sequenceData.variants = selectedActionProduct
                        .getBuyProduct()
                        .getProductVariations()
                        .getData()
                        .map((variant) => ({
                            variant_id: variant.id,
                            sku_id: variant.sku_id,
                            new_quantity: variant.quantity, // Use the new quantity value here
                            original_unit_price: variant.getUnitPrice(),
                        }));
                }

                return sequenceData;
            });

        const payload: IActionPerformPayload = {
            customer_note: textAreaValue,
            action: selectedAction ? selectedAction : "",
            sequences: sequences,
        };

        try {
            let data: any;
            if (selectedAction === "purchase") {
                data = await takeActionPerform(
                    Number(selectedActionProduct?.getId()),
                    payload,
                );
            } else if (selectedAction === "refund") {
                data = await takeActionRefund(
                    Number(selectedActionProduct?.getId()),
                    payload,
                );
            } else if (selectedAction === "cancel") {
                data = await takeActionCancel(
                    Number(selectedActionProduct?.getId()),
                );
            }

            if (data) {
                setOpenReject(false);
            }
            setOpenReject(false);
        } catch (error: any) {
            if (error?.response?.data?.message) {
                notification["error"]({
                    message: error.response.data.message,
                });
            }
            setOpenReject(false);
        }
    };

    const handleMenuClick = (key: any, record: BuyActionModel) => {
        setSelectedActionProduct(record);
        setSelectedAction(key);
        setOpenReject(true);
    };

    // // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getAllTakeActions,
        );
    };

    const paginationConfig = PaginationModel.getPaginationConfig(
        AllTackActionsCollectionData,
        handlePaginationChange,
    );

    // Filter Handler
    const handleProductFilter = () => {
        if (filters) {
            ApiHelperModel.makeGetRequest(filters, getAllTakeActions);
        }
    };

    const columns = [
        // {
        //     title: "#SL",
        //     dataIndex: "id",
        //     width: 70,
        //     key: "sl",
        //     align: "center" as const,
        //     render: (_: string, __: BuyActionModel, index: number) => {
        //         return (
        //             <div>
        //                 {paginationData
        //                     ? (paginationData.current_page - 1) *
        //                           paginationData.per_page +
        //                       index +
        //                       1
        //                     : ""}
        //             </div>
        //         );
        //     },
        // },
        {
            title: "Product Info",
            dataIndex: "product_info",
            key: "product_info",
            render: (_: string, record: BuyActionModel) => {
                return (
                    <div>
                        <div style={{ display: "flex" }}>
                            <Image.PreviewGroup>
                                <Image
                                    width={80}
                                    src={record
                                        .getBuyProduct()
                                        .getProductImage()}
                                    style={{ borderRadius: 10 }}
                                />
                            </Image.PreviewGroup>

                            <div style={{ marginLeft: 10 }}>
                                <Typography.Text>
                                    <Text
                                        strong
                                        style={{ maxWidth: 150 }}
                                        ellipsis={{
                                            tooltip: record
                                                .getBuyProduct()
                                                .getProductTitle(),
                                        }}
                                    >
                                        <Link
                                            target="_blank"
                                            to={`${record.getBuyProduct().getProductLink() || ""}`}
                                        >
                                            {record
                                                .getBuyProduct()
                                                .getProductTitle()}
                                        </Link>
                                    </Text>
                                </Typography.Text>
                                <Paragraph style={{ textAlign: "left" }}>
                                    <Text>PB : </Text>
                                    <Text
                                        strong
                                        copyable={{
                                            text: record
                                                .getBuyProduct()
                                                .getProductNumber(),
                                        }}
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                            navigate(
                                                `/purchase/buy-products/detail/${record.getBuyProduct().getId()}`,
                                            )
                                        }
                                    >
                                        {record
                                            .getBuyProduct()
                                            .getProductNumber()}
                                    </Text>
                                </Paragraph>
                                <Paragraph style={{ textAlign: "left" }}>
                                    <Text>AN : </Text>
                                    <Tooltip title={record.getActionNumber()}>
                                        <Text
                                            strong
                                            copyable={{
                                                text: record.getActionNumber(),
                                            }}
                                        >
                                            {record
                                                .getActionNumber()
                                                .slice(0, 12)}
                                            ...
                                        </Text>
                                    </Tooltip>
                                </Paragraph>

                                {/* <Paragraph style={{ textAlign: "left" }}>
                                <Text>Customer Info: </Text>
                                <Tooltip title={record.getActionNumber()}>
                                    <Text
                                        strong
                                        copyable={{
                                            text: record.getActionNumber(),
                                        }}
                                    >
                                        {record.getActionNumber().slice(0, 12)}...
                                    </Text>
                                </Tooltip>
                                <
                            </Paragraph> */}

                                <Paragraph style={{ textAlign: "left" }}>
                                    <Text>Region : </Text>
                                    <StatusTag
                                        slug={record
                                            .getBuyProduct()
                                            .getRegion()
                                            .getCode()}
                                        text={record
                                            .getBuyProduct()
                                            .getRegion()
                                            .getCode()}
                                    />
                                </Paragraph>
                                <div style={{ display: "flex", marginTop: 5 }}>
                                    <StatusTag
                                        text={record
                                            .getBuyProduct()
                                            .getStatus()}
                                        slug={record
                                            .getBuyProduct()
                                            .getStatus()}
                                    />
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: 10 }}>
                            Created At :{" "}
                            <Tag color={"purple"}>{record.getCreatedAt()}</Tag>
                        </div>
                        <Button
                            style={{ padding: 0 }}
                            type="link"
                            onClick={() => handleShowDetail(record)}
                        >
                            View Details <ArrowRightOutlined />
                        </Button>
                    </div>
                );
            },
        },
        {
            title: "Customer Info",
            dataIndex: "user",
            key: "user",
            render: (_: string, record: BuyActionModel) => {
                return (
                    <div>
                        <div>
                            <Paragraph>
                                <Text>Name : </Text>
                                <Tooltip title={record.getUser().getName()}>
                                    <Text strong copyable={{text: record.getUser().getName()}}>
                                        {truncateText(
                                            record.getUser().getName(),
                                            15,
                                        )}
                                    </Text>
                                </Tooltip>
                            </Paragraph>
                            {record.getUser().getEmail() && (
                                <Paragraph>
                                    <Text>Email : </Text>
                                    <Tooltip
                                        title={record.getUser().getEmail()}
                                    >
                                        <Text strong  copyable={{ text: record.getUser().getEmail() }}>
                                            {truncateText(
                                                record.getUser().getEmail(),
                                                15,
                                            )}
                                        </Text>
                                    </Tooltip>
                                </Paragraph>
                            )}

                            {record.getUser().getPhone() && (
                                <Paragraph>
                                    <Text>Phone : </Text>
                                    <Text strong copyable ={{ text: record.getUser()?.getPhone()?.toString}}>
                                        {record.getUser().getPhone()}
                                    </Text>
                                </Paragraph>
                            )}
                        </div>
                    </div>
                );
            },
        },
        {
            title: "OrderHandler Info",
            dataIndex: "user",
            key: "user",
            render: (_: string, record: BuyActionModel) => {
                const order_handler = record
                    .getBuyProduct()
                    .getOrder()
                    .getOrderHandler();
                return (
                    <div>
                        <div>
                            <Paragraph>
                                <Text>Name: </Text>
                                <Tooltip title={order_handler?.name}>
                                    <Text strong copyable ={{text: order_handler?.name}}>
                                        {truncateText(order_handler?.name, 15)}
                                    </Text>
                                </Tooltip>
                            </Paragraph>
                            <Paragraph>
                                <Text>Email: </Text>
                                <Tooltip title={order_handler?.email}>
                                    <Text strong copyable ={{text:order_handler?.email }}>
                                        {truncateText(order_handler?.email, 15)}
                                    </Text>
                                </Tooltip>
                            </Paragraph>
                            {order_handler?.phone && (
                                <Paragraph>
                                    <Text>Phone: </Text>
                                    <Tooltip title={order_handler?.phone}>
                                        <Text strong copyable ={{text:order_handler?.phone}}>
                                            {order_handler?.phone}
                                        </Text>
                                    </Tooltip>
                                </Paragraph>
                            )}
                        </div>
                    </div>
                );
            },
        },
        {
            title: "Action Status",
            dataIndex: "status",
            key: "status",
            align: "center" as const,
            render: (_: string, record: BuyActionModel) => {
                return (
                    <div>
                        <Paragraph>
                            <StatusTag
                                text={record.getStatus()}
                                slug={record.getStatus()}
                            />
                        </Paragraph>
                    </div>
                );
            },
        },
        {
            title: "Issue Type",
            dataIndex: "issue_type",
            key: "issue_type",
            align: "center" as const,
            render: (_: string, record: BuyActionModel) => {
                return (
                    <div>
                        {record.getSequences().map((item) => (
                            <Tag
                                style={{ margin: 2 }}
                                key={item.getId()}
                                color="volcano"
                            >
                                {formatString(item.getType())}
                            </Tag>
                        ))}
                    </div>
                );
            },
        },
        {
            title: "Actions",
            key: "actions",
            fixed: isMobile ? "right" : undefined,
            hidden: !checkActionPermission(
                [
                    ADMIN_BUY_TAKE_ACTION_PERMISSION_ENUM.ADMIN_BUY_ACTION_PERFORM,
                    ADMIN_BUY_TAKE_ACTION_PERMISSION_ENUM.ADMIN_BUY_ACTION_CANCEL,
                    ADMIN_BUY_TAKE_ACTION_PERMISSION_ENUM.ADMIN_REFUND_ADD,
                ],
                "permissionCheck",
                null,
            ),
            width: 100,
            render: (_, record: BuyActionModel) => {
                const allItems = [
                    {
                        label: "Perform",
                        key: "purchase",
                        icon: <EditOutlined />,
                        permission:
                            ADMIN_BUY_TAKE_ACTION_PERMISSION_ENUM.ADMIN_BUY_ACTION_PERFORM,
                        onClick: () => handleMenuClick("purchase", record),
                        style: { margin: "5px", padding: "8px 16px" },
                    },
                    {
                        label: "Refund",
                        key: "refund",
                        icon: <RollbackOutlined />,
                        permission:
                            ADMIN_BUY_TAKE_ACTION_PERMISSION_ENUM.ADMIN_REFUND_ADD,
                        onClick: () => handleMenuClick("refund", record),
                        style: { margin: "5px", padding: "8px 16px" },
                    },
                    {
                        label: "Cancel",
                        key: "cancel",
                        icon: <CloseOutlined />,
                        permission:
                            ADMIN_BUY_TAKE_ACTION_PERMISSION_ENUM.ADMIN_BUY_ACTION_CANCEL,
                        onClick: () => handleMenuClick("cancel", record),
                        style: { margin: "5px", padding: "8px 16px" },
                    },
                ];
                // Filter items based on status
                const visibleItems = allItems.filter(
                    () =>
                        record.getStatus() !== "resolved" &&
                        record.getStatus() !== "cancelled",
                );
                return (
                    <Space size="middle">
                        <Dropdown menu={{ items: visibleItems }}>
                            <Button
                                hidden={
                                    record.getStatus() === "resolved" ||
                                    record.getStatus() === "cancelled"
                                }
                                icon={<HddFilled />}
                            >
                                {isMobile ? (
                                    <DownOutlined />
                                ) : (
                                    <>
                                        Actions <DownOutlined />{" "}
                                    </>
                                )}
                            </Button>
                        </Dropdown>
                    </Space>
                );
            },
        },
    ];

    return (
        <>
            <div>
                <PageHeader
                    ghost={false}
                    title="Actions Needed"
                    style={{ marginTop: "10px" }}
                    onBack={() => window.history.back()}
                >
                    {filters && Object.keys(filters).length > 0 && (
                        <Row>
                            <Col span={24}>
                                <FiltersComponent
                                    handleProductFilter={handleProductFilter}
                                    handleFilterChange={handleFilterChange}
                                    handelFilterClear={handelFilterClear}
                                    isFetched={isFetched}
                                    filters={filters}
                                    filtersData={filterData}
                                    isFromProductReceived={true}
                                />
                            </Col>
                        </Row>
                    )}
                    <div>
                        <Table
                            loading={isLoading}
                            rowKey={(r) => r.getId()}
                            bordered={true}
                            dataSource={AllTackActionsCollectionData?.getData()}
                            //@ts-ignore
                            columns={columns}
                            scroll={{ x: 1300 }}
                            pagination={paginationConfig}
                        />
                    </div>
                </PageHeader>
                {selectedActionProduct && (
                    <Modal
                        style={{ fontSize: 100 }}
                        title={`Action Needed For #${selectedActionProduct
                            ?.getBuyProduct()
                            .getProductNumber()}`}
                        open={isShowCreateModal}
                        onCancel={() => {
                            {
                                setIsShowCreateModal(false);
                            }
                        }}
                        onOk={() => {
                            {
                                setIsShowCreateModal(false);
                            }
                        }}
                        width={width < 600 ? "90vw" : "50vw"}
                        destroyOnClose
                    >
                        <ActionViewDetailsModal
                            handleClose={() => setIsShowCreateModal(false)}
                            selectedAction={selectedActionProduct}
                        />
                    </Modal>
                )}

                {openReject && (
                    <Modal
                        open={openReject}
                        title={`Are you sure you want to ${selectedAction ? selectedAction : ""}?`}
                        onCancel={handleRejectCancel}
                        footer={[
                            <Button key="back" onClick={handleRejectCancel}>
                                No
                            </Button>,
                            <Button
                                key="submit"
                                type="primary"
                                onClick={handleTakeActionNeeded}
                                loading={
                                    performLoading ||
                                    refundLoading ||
                                    cancelLoading
                                }
                            >
                                Yes
                            </Button>,
                        ]}
                    >
                        {selectedAction !== "cancel" && (
                            <TextArea
                                value={
                                    textAreaValue ? textAreaValue : undefined
                                }
                                onChange={(e) =>
                                    setTextAreaValue(e.target.value)
                                }
                                placeholder="Please add the Note..."
                                rows={4}
                            />
                        )}
                    </Modal>
                )}
            </div>
        </>
    );
};

export default ActionsNeeded;
