import {
    IProductSearchResult,
    IPriceMinimumRangeFacet,
    IRatingAverageFacet,
    ICategoriesFacet,
    IBrandFacet,
    ISellerFacet,
    IShopFacet,
    IFacet,
    ICountryFacet,
    IPropertiesFacet,
    IProductPriceRange,
    IWholesalePrice,
    IWholesalePriceCollection,
    IProductPrice,
    IResourceMeta,
    IProductDetailSearch,
    ISortOption,
    IStore,
    IRating,
    IRatingRange,
    IBasePriceRange,
} from "@/types/productSearchResult";
import { PaginationModel } from "./paginationModel";
import { formatNumber } from "@/helpers/formatNumber";
import {
    IMeasurementUnitBodyExtended,
    IProductGalleryExtended,
    IProductMeasurementExtended,
} from "@/types/productDetailExtended";
import { ICountry } from "@/types/countryCollection";

export interface IFacetField {
    name: string;
    count: number;
}

// Facet Model
export class FacetFieldModel {
    name: string;
    count: number;

    constructor(payload: IFacetField) {
        this.name = payload.name.trim();
        this.count = payload.count;
    }

    getName() {
        return this.name;
    }

    getCount() {
        return this.count;
    }
}

export class FacetRangeFieldModel {
    name: string;
    from: number | null;
    to: number | null;
    count: number;
    isChecked: boolean;

    constructor(payload: IRatingRange) {
        this.name = payload.name.trim().toLowerCase();
        this.from = payload.from;
        this.to = payload.to;
        this.count = payload.count;
        this.isChecked = false;
    }

    getName() {
        return this.name;
    }

    getFrom() {
        return this.from ? this.from : "";
    }

    getTo() {
        return this.to ? this.to : "";
    }

    getCount() {
        return this.count;
    }
}

export class PriceMinimumRangeFacetModel {
    object: string;
    data: FacetRangeFieldModel[];

    constructor(payload: IPriceMinimumRangeFacet) {
        this.object = payload.object;
        this.data = payload.data.map((item) => new FacetRangeFieldModel(item));
    }

    getData() {
        return this.data;
    }
}

export class RatingAverageFacetModel {
    object: string;
    data: FacetRangeFieldModel[];

    constructor(payload: IRatingAverageFacet) {
        this.object = payload.object;
        this.data = payload.data.map((item) => new FacetRangeFieldModel(item));
    }

    getData() {
        return this.data;
    }
}

export class CategoriesFacetModel {
    object: string;
    data: FacetFieldModel[];

    constructor(payload: ICategoriesFacet) {
        this.object = payload.object;
        this.data = payload.data.map((item) => new FacetFieldModel(item));
    }

    getData() {
        return this.data;
    }
}

export class BrandFacetModel {
    object: string;
    data: FacetFieldModel[];

    constructor(payload: IBrandFacet) {
        this.object = payload.object;
        this.data = payload.data.map((item) => new FacetFieldModel(item));
    }

    getData() {
        return this.data;
    }
}

export class SellerFacetModel {
    object: string;
    data: FacetFieldModel[];

    constructor(payload: ISellerFacet) {
        this.object = payload.object;
        this.data = payload.data.map((item) => new FacetFieldModel(item));
    }

    getData() {
        return this.data;
    }
}

export class ShopFacetModel {
    object: string;
    data: FacetFieldModel[];

    constructor(payload: IShopFacet) {
        this.object = payload.object;
        this.data = payload.data.map((item) => new FacetFieldModel(item));
    }

    getData() {
        return this.data;
    }
}

export class CountryFacetModel {
    object: string;
    data: FacetFieldModel[];

    constructor(payload: ICountryFacet) {
        this.object = payload.object;
        this.data = payload.data.map((item) => new FacetFieldModel(item));
    }

    getData() {
        return this.data;
    }
}

export class FacetValuesFieldModel {
    name: string;
    count: number;
    values: FacetFieldModel[];

    constructor(payload) {
        this.name = payload.name;
        this.count = payload.count;
        this.values = payload.values.map((value) => new FacetFieldModel(value));
    }

    getName() {
        return this.name;
    }

    getCount() {
        return this.count;
    }

    getValues() {
        return this.values;
    }
}

export class PropertiesFacetModel {
    object: string;
    data: FacetValuesFieldModel[];

    constructor(payload: IPropertiesFacet) {
        this.object = payload.object;
        this.data = payload.data.map((item) => new FacetValuesFieldModel(item));
    }

    getData() {
        return this.data;
    }
}

export class FacetModel {
    price_minimum_ranges?: FacetRangeFieldModel[];
    rating_average_ranges?: FacetRangeFieldModel[];
    categories?: FacetFieldModel[];
    brands?: FacetFieldModel[];
    sellers?: FacetFieldModel[];
    shops?: FacetFieldModel[];
    countries?: FacetFieldModel[];
    properties?: FacetValuesFieldModel[];

    constructor(payload: IFacet) {
        this.price_minimum_ranges =
            payload?.price_minimum_ranges &&
            payload.price_minimum_ranges.data.length > 0
                ? payload.price_minimum_ranges.data.map(
                      (item) => new FacetRangeFieldModel(item),
                  )
                : undefined;
        this.rating_average_ranges =
            payload?.rating_average_ranges &&
            payload.rating_average_ranges.data.length > 0
                ? payload.rating_average_ranges.data
                      .map((item) => new FacetRangeFieldModel(item))
                      .sort((a, b) => {
                          if (a.to && b.to) {
                              return a.to - b.to; // Sort in ascending order based on 'to' value
                          } else if (a.to) {
                              return -1; // Place items with 'to' values before those without
                          } else if (b.to) {
                              return 1; // Place items without 'to' after those with 'to'
                          } else {
                              return 0; // Leave items without 'to' unchanged relative to each other
                          }
                      })
                      .reverse()
                : undefined;
        this.categories =
            payload?.categories && payload.categories.data.length > 0
                ? payload.categories.data.map(
                      (item) => new FacetFieldModel(item),
                  )
                : undefined;
        this.brands =
            payload?.brands && payload.brands.data.length > 0
                ? payload.brands.data.map((item) => new FacetFieldModel(item))
                : undefined;
        this.sellers =
            payload?.sellers && payload.sellers.data.length > 0
                ? payload.sellers.data.map((item) => new FacetFieldModel(item))
                : undefined;
        this.shops =
            payload?.shops && payload.shops.data.length > 0
                ? payload.shops.data.map((item) => new FacetFieldModel(item))
                : undefined;
        this.countries =
            payload?.countries && payload.countries.data.length > 0
                ? payload.countries.data.map(
                      (item) => new FacetFieldModel(item),
                  )
                : undefined;
        this.properties =
            payload?.properties && payload.properties.data.length
                ? payload.properties.data.map(
                      (item) => new FacetValuesFieldModel(item),
                  )
                : undefined;
    }

    getPriceMinimumRanges() {
        return this.price_minimum_ranges;
    }

    getRatingAverageRanges() {
        return this.rating_average_ranges;
    }

    getCategories() {
        return this.categories;
    }

    getBrands() {
        return this.brands;
    }

    getSellers() {
        return this.sellers;
    }

    getShops() {
        return this.shops;
    }

    getCountries() {
        return this.countries;
    }

    getProperties() {
        return this.properties;
    }
}

export class WholesalePriceModel {
    object: "WholesalePrice";
    qty_from: number;
    original: number;
    discount: number | null;

    constructor(dataItem: IWholesalePrice) {
        this.object = dataItem.object;
        this.discount = dataItem.discount;
        this.original = dataItem.original;
        this.qty_from = dataItem.qty_from;
    }
    getDiscount = () => this.discount;
    getOriginal = () => this.original;
    getQtyFrom = () => this.qty_from;
}

export class WholesalePriceCollectionModel {
    public readonly object: string;
    public readonly data: WholesalePriceModel[];

    constructor(dataItem: IWholesalePriceCollection) {
        this.object = dataItem.object;
        this.data = dataItem.data.map((item) => new WholesalePriceModel(item));
    }
    getData = () => this.data;
}

export class ProductGalleryModel {
    id: string;
    alt?: string;
    url: string;
    thumb: string;
    title: string | null;

    constructor(dataItem: IProductGalleryExtended) {
        this.alt = dataItem.alt;
        this.url = dataItem.url;
        this.id = dataItem.id;
        this.thumb = dataItem.thumb;
        this.title = dataItem.title;
    }
}

export class MeasurementUnitBodyModel {
    public readonly object: string;
    public readonly value: number;
    public readonly unit: string;

    constructor(dataItem: IMeasurementUnitBodyExtended) {
        this.object = dataItem.object;
        this.unit = dataItem.unit;
        this.value = dataItem.value;
    }
}

export class WidthModel extends MeasurementUnitBodyModel {}

export class HeightModel extends MeasurementUnitBodyModel {}

export class LengthModel extends MeasurementUnitBodyModel {}

export class UnitWeightModel extends MeasurementUnitBodyModel {}

export class ProductMeasurementModel {
    public readonly object: string;
    public readonly unit_weight: UnitWeightModel;
    public readonly length?: LengthModel;
    public readonly width: WidthModel;
    public readonly height: HeightModel;

    constructor(dataItem: IProductMeasurementExtended) {
        this.object = dataItem.object;
        this.unit_weight = new UnitWeightModel(dataItem.unit_weight);
        this.height = new HeightModel(dataItem.height);
        this.width = new WidthModel(dataItem.width);
        this.length = dataItem.length
            ? new LengthModel(dataItem.length)
            : undefined;
    }
}

export class ResourceMetaModel {
    public readonly object: string;
    public readonly created_at: string;
    public readonly updated_at: string;

    constructor(dataItem: IResourceMeta) {
        this.object = dataItem.object;
        this.updated_at = dataItem.updated_at;
        this.created_at = dataItem.created_at;
    }
}

export class ProductPriceModel {
    readonly object: "ProductPrice";
    readonly discount: IProductPriceRange;
    readonly original: IProductPriceRange;
    readonly wholesales: IWholesalePriceCollection;
    readonly base: IBasePriceRange;

    constructor(data: IProductPrice) {
        this.object = data.object;
        this.discount = data.discount;
        this.original = data.original;
        this.wholesales = data.wholesales;
        this.base = data.base;
    }

    public getOriginalRawPrice(): number {
        return this.original.min_raw || this.original.max_raw || 0;
    }
    public getOriginalPrice(): number {
        return this.original.min || this.original.max || 0;
    }

    public getBasePriceTargetCurrency() {
        return this.base.target_currency;
    }
    public getDiscountPrice(): number {
        return (
            this.discount.min ||
            this.discount.max ||
            this.original.min ||
            this.original.max ||
            0
        );
    }
    public getDiscountWholesales(): WholesalePriceCollectionModel {
        return new WholesalePriceCollectionModel(this.wholesales);
    }

    getDiscountMin() {
        return this.discount.min;
    }
    getDiscountMax() {
        return this.discount.max;
    }
    getOriginalMin() {
        return this.original.min;
    }
    getOriginalMax() {
        return this.original.max;
    }
    getWholesales() {
        return this.wholesales;
    }
}

export class ProductDetailSearchModel {
    readonly object: "ProductDetail";
    readonly id: string;
    readonly etag: string;
    readonly title: string;
    readonly title_original: string;
    readonly slug: string;
    readonly image: string;
    readonly description_original: string;
    readonly stock: number;
    readonly sales: number;
    readonly resource_meta: IResourceMeta;
    readonly country: ICountry;
    readonly ratings: IRating;
    readonly price: IProductPrice;
    readonly store: IStore;

    constructor(dataItem: IProductDetailSearch) {
        this.object = dataItem.object;
        this.id = dataItem.id;
        this.etag = dataItem.etag;
        this.title = dataItem.title;
        this.title_original = dataItem.title_original;
        this.slug = dataItem.slug;
        this.image = dataItem.image;
        this.description_original = dataItem.description_original;
        this.stock = dataItem.stock;
        this.sales = dataItem.sales;
        this.resource_meta = dataItem.resource_meta;
        this.country = dataItem.country;
        this.ratings = dataItem.ratings;
        this.store = dataItem.store;
        this.price = dataItem.price;
    }
    getStock = () => this.stock;
    getSlug = () => this.slug;
    getCountry = () => this.country;
    getRatingsAverage = () => this.ratings.average;
    getRatingsCount = () => formatNumber(this.ratings.count);
    getSales = () => formatNumber(this.sales);
    getTitle = () => this.title;
    getId = () => this.id;
    getImage = () => this.image;
    getDescription = () => this.description_original;
    getPrice = () => new ProductPriceModel(this.price);
}

export class ProductSearchResultModel {
    object: "ProductSearchResult";
    data: ProductDetailSearchModel[];
    facet: IFacet;
    sort: ISortOption[];
    pagination: PaginationModel;

    constructor(payload: IProductSearchResult) {
        this.object = payload.object;
        this.data = payload.data.map(
            (item) => new ProductDetailSearchModel(item),
        );
        this.facet = payload.facet;
        this.sort = payload.sort;
        this.pagination = new PaginationModel(payload.pagination);
    }
    getData = (): ProductDetailSearchModel[] => this.data;

    getAllProductIds = (): string[] =>
        this.data.length > 0 ? this.data.map((item) => item.id) : [];

    getFacet = (): FacetModel => new FacetModel(this.facet);

    // getFacet = (): FacetModel | undefined =>
    //     this.facet ? new FacetModel(this.facet) : null;
    getSort = () => this.sort;
    getPagination = () => this.pagination;
}
