import { PageHeader } from "@ant-design/pro-layout";
import {
    Col,
    Modal,
    Row,
    Table,
    Tabs,
} from "antd";
import { useState, useEffect } from "react";
import { useAtom } from "jotai";

import { shippingCartAtom } from "@/lib/core-react/store/store";
import useDataFilters from "@/hooks/useDataFilters";
import { ApiHelperModel } from "@/models/apiHelper";
import { PaginationModel } from "@/models/pagination";
import { useGetShippingCart } from "@/lib/core-react/hooks/private/useShippingCart";
import {
    ShippingCartCollectionModel,
    ShippingCartItemPackageModel,
} from "@/models/shippingCartCollectionModel";
import { IFilterType } from "@/types/filters";

import FiltersComponent from "@/components/FiltersComponent";
import PackagesTable from "./components/PackagesTable";
import useWindowWidth from "@/lib/core-react/hooks/public/useWindowWidth";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import { getShippingCartColumns } from "./components/shippingCartTable";

const ShippingCarts = () => {
    const {
        allOptionsData: { regionOptionsData },
        onFetchFilterApi,
    } = useFiltersApiData();
    const { getShippingCart } = useGetShippingCart();
    const [{ data, isLoading, refetch }] = useAtom(shippingCartAtom);
    const [selectedRegion, setSelectedRegion] = useState<string | undefined>(
        undefined,
    );
    const [selectedPackage, setSelectedPackage] = useState<
        ShippingCartItemPackageModel[] | undefined
    >(undefined);

    const { isMobile } = useWindowWidth();
    useEffect(() => {
        onFetchFilterApi(filterResourceEnum.REGION);
    }, []);

    useEffect(() => {
        if (regionOptionsData.options.length) {
            const firstRegionId = regionOptionsData.options[0].value.toString();
            setSelectedRegion(firstRegionId);
        }
    }, [regionOptionsData.options.length]);

    useEffect(() => {
        if (selectedRegion) {
            getShippingCart(`region_id=${selectedRegion}`);
        }
    }, [selectedRegion]);

    const {
        filters,
        handleFilterChange,
        handelFilterClear,
        isFirstCall,
        isFetched,
        initializeAvailableFilter,
    } = useDataFilters();

    // Api Call
    useEffect(() => {
        if ((filters && !isFetched && isFirstCall) || refetch) {
            ApiHelperModel.makeGetRequest(filters, getShippingCart);
        }
    }, [isFirstCall, filters, isFetched, refetch]);

    const ShippingCartData = data && new ShippingCartCollectionModel(data);

    // Filter
    const filterData = ShippingCartData?.filters;

    // Pagination
    const columns = getShippingCartColumns(ShippingCartData?.pagination, setSelectedPackage);

    let filterWithoutRegin: IFilterType | undefined = undefined;
    if (filterData) {
        const { region_id: _, ...rest } = filterData as IFilterType;
        filterWithoutRegin = rest;
    }

    // Getting all available filters
    useEffect(() => {
        if (!isFetched && ShippingCartData?.filters) {
            initializeAvailableFilter(filterWithoutRegin as IFilterType);
        }
    }, [isFetched, initializeAvailableFilter, ShippingCartData?.filters]);

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getShippingCart,
        );
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        ShippingCartData,
        handlePaginationChange,
    );

    // Filter Handler
    const handleProductFilter = () => {
        ApiHelperModel.makeGetRequest(filters, getShippingCart);
    };

    const onChangeRegion = (key: string) => {
        setSelectedRegion(key);
    };

    // function removeRegionFilter(filters: IFilterType) {
    //   // Clone the filters object to avoid modifying the original
    //   const updatedFilters = JSON.parse(JSON.stringify(filters));

    //   // Check if "region_id" exists in the filters and remove it
    //   if (updatedFilters.hasOwnProperty("region_id")) {
    //     delete updatedFilters.region_id;
    //   }

    //   return updatedFilters;
    // }

    return (
        <>
            <div>
                <PageHeader
                    ghost={false}
                    title="Shipping Cart"
                    style={{ marginTop: "10px" }}
                    onBack={() => window.history.back()}
                >
                    <Tabs
                        type="card"
                        size="large"
                        activeKey={selectedRegion}
                        tabPosition="top"
                        animated
                        centered
                        onChange={onChangeRegion}
                        items={regionOptionsData.options.map((region) => {
                            return {
                                label: region.label,
                                key: region.value.toString(),
                                children: (
                                    <>
                                        {filters &&
                                            filterData &&
                                            Object.keys(filters).length > 0 && (
                                                <Row>
                                                    <Col span={24}>
                                                        <FiltersComponent
                                                            handleProductFilter={
                                                                handleProductFilter
                                                            }
                                                            handleFilterChange={
                                                                handleFilterChange
                                                            }
                                                            handelFilterClear={
                                                                handelFilterClear
                                                            }
                                                            isFetched={
                                                                isFetched
                                                            }
                                                            filters={filters}
                                                            filtersData={
                                                                filterWithoutRegin
                                                            }
                                                            isFromProductReceived={
                                                                true
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                            )}
                                        <Table
                                            style={{
                                                background: "yelllow",
                                                marginTop: 10,
                                            }}
                                            loading={isLoading}
                                            rowKey="id"
                                            bordered={true}
                                            dataSource={ShippingCartData?.getProcessData()}
                                            scroll={{ x: 1000 }}
                                            columns={columns}
                                            pagination={paginationConfig}
                                        />
                                    </>
                                ),
                            };
                        })}
                    />
                </PageHeader>
            </div>

            <Modal
                open={Boolean(selectedPackage)}
                onCancel={() => setSelectedPackage(undefined)}
                width={isMobile ? "90vw" : "70vw"}
                footer={null}
                title="Package Details"
            >
                {selectedPackage && <PackagesTable data={selectedPackage} />}
            </Modal>
        </>
    );
};

export default ShippingCarts;
