import {
    UserOutlined,
    BuildOutlined,
    AppstoreOutlined,
    GlobalOutlined,
    CarOutlined,
    EnvironmentOutlined,
    BorderHorizontalOutlined,
    MoneyCollectOutlined,
    ReadOutlined,
    RetweetOutlined,
    HeatMapOutlined,
    DiffOutlined,
} from "@ant-design/icons";
import permissionRoutes from "./route-base-permissions";
import { ADMIN_GENERIC_PERMISSION_ENUM } from "@/consts/permission-enum/generic-enum";

export interface MenuItem {
    title: string;
    icon?: React.ReactNode;
    hasSubMenu: boolean;
    requirePermission: boolean;
    permission: string[];
    subMenus?: MenuItem[];
    path?: string;
}
const menuItems: MenuItem[] = [
    {
        title: "Admin",
        icon: <UserOutlined />,
        hasSubMenu: true,
        requirePermission: true,
        permission: [
            ...permissionRoutes.manageAdminUserRoutePermissions,
            ...permissionRoutes.manageAdminAclPermissions,
            ...permissionRoutes.manageAdminImpersonateRoutePermission,
        ],
        subMenus: [
            {
                path: "/admin/users",
                title: "User",
                permission: permissionRoutes.manageAdminUserRoutePermissions,
                requirePermission: true,
                icon: null,
                hasSubMenu: false,
            },
            {
                path: "/admin/acl",
                title: "ACL",
                permission: permissionRoutes.manageAdminAclPermissions,
                requirePermission: true,
                icon: null,
                hasSubMenu: true,
                subMenus: [
                    {
                        path: "/admin/acl/overview",
                        title: "Overview",
                        permission: permissionRoutes.manageAdminAclPermissions,
                        requirePermission: true,
                        icon: null,
                        hasSubMenu: false,
                    },
                    {
                        path: "/admin/acl/roles",
                        title: "Role",
                        permission: permissionRoutes.manageAdminAclPermissions,
                        requirePermission: true,
                        icon: null,
                        hasSubMenu: false,
                    },
                ],
            },
            {
                path: "/admin/impersonate",
                title: "Impersonate",
                permission:
                    permissionRoutes.manageAdminImpersonateRoutePermission,
                requirePermission: false,
                icon: null,
                hasSubMenu: false,
            },
        ],
    },
    {
        title: "Customer",
        icon: <UserOutlined />,
        hasSubMenu: true,
        permission: [
            ...permissionRoutes.manageAdminCustomerRoutePermission,
            ADMIN_GENERIC_PERMISSION_ENUM.ADMIN_VIEW_CUSTOMER_WISHLIST,
            ADMIN_GENERIC_PERMISSION_ENUM.ADMIN_VIEW_CUSTOMER_SHIPPING_CART,
            ADMIN_GENERIC_PERMISSION_ENUM.ADMIN_VIEW_CUSTOMER_PURCHASE_CART,
            ADMIN_GENERIC_PERMISSION_ENUM.ADMIN_SUPPORT_INQUIRY_VIEW,
        ],
        requirePermission: false,
        subMenus: [
            {
                path: "/customer/users",
                title: "User",
                permission: permissionRoutes.manageAdminCustomerRoutePermission,
                requirePermission: true,
                icon: null,
                hasSubMenu: false,
            },
            {
                path: "/customer/wishlists",
                title: "Wishlist",
                permission: [
                    ADMIN_GENERIC_PERMISSION_ENUM.ADMIN_VIEW_CUSTOMER_WISHLIST,
                ],
                requirePermission: true,
                icon: null,
                hasSubMenu: false,
            },
            {
                path: "/customer/shipping-carts",
                title: "Shipping Cart",
                permission: [
                    ADMIN_GENERIC_PERMISSION_ENUM.ADMIN_VIEW_CUSTOMER_SHIPPING_CART,
                ],
                requirePermission: true,
                icon: null,
                hasSubMenu: false,
            },
            {
                path: "/customer/support-inquiry",
                title: "Talk to Expert",
                permission: [
                    ADMIN_GENERIC_PERMISSION_ENUM.ADMIN_SUPPORT_INQUIRY_VIEW,
                ],
                requirePermission: true,
                icon: null,
                hasSubMenu: false,
            },
            {
                path: "/customer/purchase-cart",
                title: "Purchase Cart",
                permission: [
                    ADMIN_GENERIC_PERMISSION_ENUM.ADMIN_VIEW_CUSTOMER_PURCHASE_CART,
                ],
                requirePermission: true,
                icon: null,
                hasSubMenu: false,
            },
        ],
    },
    {
        title: "Agent",
        icon: <BuildOutlined />,
        hasSubMenu: true,
        permission: [
            ...permissionRoutes.agentCompanyManageRoutePermissions,
            ...permissionRoutes.agentWarehouseManageRoutePermissions,
        ],
        requirePermission: true,
        subMenus: [
            {
                path: "/agent/companies",
                title: "Company",
                permission: permissionRoutes.agentCompanyManageRoutePermissions,
                requirePermission: true,
                icon: null,
                hasSubMenu: false,
            },
            {
                path: "/agent/warehouses",
                title: "Warehouse",
                permission:
                    permissionRoutes.agentWarehouseManageRoutePermissions,
                requirePermission: true,
                icon: null,
                hasSubMenu: false,
            },
        ],
    },
    // {
    //   title: "Discount Manage",
    //   icon: <UserOutlined />,
    //   hasSubMenu: true,
    //   permission:[],
    //   requirePermission: true,
    //   subMenus: [
    //     {
    //       path: "/discount-manage/discounts",
    //       title: "Discounts",
    //       permission: [],
    //       requirePermission: true,
    //       icon: null,
    //     },
    //     {
    //       path: "/discount-manage/discount-usage-histories",
    //       title: "Discount Usage History",
    //       permission: [],
    //       requirePermission: true,
    //       icon: null,
    //     },
    //   ],
    // },
    {
        title: "Refund",
        icon: <RetweetOutlined />,
        hasSubMenu: true,
        permission: permissionRoutes.manageRefundRoutePermissions,
        requirePermission: true,
        subMenus: [
            {
                path: "/refund/disputes",
                title: "Dispute",
                permission: permissionRoutes.manageRefundRoutePermissions,
                requirePermission: true,
                icon: null,
                hasSubMenu: false,
            },
        ],
    },

    {
        icon: <GlobalOutlined />,
        title: "Region",
        requirePermission: true,
        permission: [
            ...permissionRoutes.shippingCountryManageRoutePermissions,
            ...permissionRoutes.currencyManageRoutePermissions,
            ...permissionRoutes.countryManageRoutePermissions,
            ...permissionRoutes.languageManageRoutePermissions,
            ...permissionRoutes.regionManageRoutePermissions,
        ],
        hasSubMenu: true,
        subMenus: [
            {
                path: "/region/countries",
                title: "Country",
                requirePermission: true,
                permission: permissionRoutes.countryManageRoutePermissions,
                icon: null,
                hasSubMenu: false,
            },
            {
                path: "/region/currencies",
                title: "Currency",
                requirePermission: true,
                permission: permissionRoutes.currencyManageRoutePermissions,
                icon: null,
                hasSubMenu: false,
            },
            {
                path: "/region/languages",
                title: "Language",
                requirePermission: true,
                permission: permissionRoutes.languageManageRoutePermissions,
                icon: null,
                hasSubMenu: false,
            },
            {
                path: "/region/regions",
                title: "Region",
                requirePermission: true,
                icon: null,
                permission: permissionRoutes.regionManageRoutePermissions,
                hasSubMenu: false,
            },
            {
                path: "/region/shipping-countries",
                title: "Shipping Country",
                requirePermission: true,
                permission:
                    permissionRoutes.shippingCountryManageRoutePermissions,
                icon: null,
                hasSubMenu: false,
            },
        ],
    },
    {
        title: "Store",
        icon: <AppstoreOutlined />,
        hasSubMenu: true,
        permission: [
            ...permissionRoutes.manageStoreRoutePermissions,
            ...permissionRoutes.manageStoreExchangeRoutePermissions,
            ...permissionRoutes.manageStoreExchangeGroupRoutePermission,
        ],
        requirePermission: true,
        subMenus: [
            {
                path: "/store/stores",
                title: "Store",
                permission: [...permissionRoutes.manageStoreRoutePermissions],
                requirePermission: true,
                icon: null,
                hasSubMenu: false,
            },
            {
                path: "/store/store-fx",
                title: "Store FX",
                permission: [
                    ...permissionRoutes.manageStoreExchangeRoutePermissions,
                ],
                requirePermission: true,
                icon: null,
                hasSubMenu: false,
            },
            {
                path: "/store/fx-groups",
                title: "FX Group",
                permission: [
                    ...permissionRoutes.manageStoreExchangeGroupRoutePermission,
                ],
                requirePermission: true,
                icon: null,
                hasSubMenu: false,
            },
        ],
    },

    {
        icon: <CarOutlined />,
        title: "Shipping",
        requirePermission: true,
        permission: [
            ...permissionRoutes.shippingShipmentProductManageRoutePermissions,
            ...permissionRoutes.shippingCommissionManageRoutePermissions,
            ...permissionRoutes.shippingCategoryManageRoutePermissions,
            ...permissionRoutes.shippingBaseShippingCategoryManageRoutePermissions,
            ...permissionRoutes.destinationManageRoutePermissions,
        ],
        hasSubMenu: true,
        subMenus: [
            {
                path: "/shipping/shipment-orders",
                title: "Shipment Order",
                requirePermission: true,
                permission:
                    permissionRoutes.shippingShipmentProductManageRoutePermissions,
                icon: null,
                hasSubMenu: false,
            },
            {
                path: "/shipping/shipment-products",
                title: "Shipment Product",
                requirePermission: true,
                permission:
                    permissionRoutes.shippingShipmentProductManageRoutePermissions,
                icon: null,
                hasSubMenu: false,
            },
            {
                path: "/shipping/unlisted-packages",
                title: "Unclaimed Products",
                permission:
                    permissionRoutes.unlistedProductManageRoutePermissions,
                requirePermission: true,
                icon: null,
                hasSubMenu: false,
            },
            {
                path: "/shipping/destination-warehouses",
                title: "Destination Warehouse",
                permission: permissionRoutes.destinationManageRoutePermissions,
                requirePermission: true,
                icon: null,
                hasSubMenu: false,
            },
            // Base shipping category->Global Categories
            {
                path: "/shipping/global-categories",
                title: "Global Category",
                permission:
                    permissionRoutes.shippingBaseShippingCategoryManageRoutePermissions,
                requirePermission: true,
                icon: null,
                hasSubMenu: false,
            },
            // Shipping category->Global Categories
            {
                path: "/shipping/regional-categories",
                title: "Regional Category",
                permission:
                    permissionRoutes.shippingCategoryManageRoutePermissions,
                requirePermission: true,
                icon: null,
                hasSubMenu: false,
            },
            // {
            //   path: "/shipping/commission",
            //   permission: permissionRoutes.shippingCommissionManageRoutePermissions,
            //   title: "Commission",
            //   requirePermission: true,
            //   icon: null,
            // },
        ],
    },
    {
        icon: <BorderHorizontalOutlined />,
        title: "Purchase",
        requirePermission: true,
        permission: [
            ...permissionRoutes.manageRfqRoutePermissions,
            ...permissionRoutes.buyProductManageRoutePermissions,
            ...permissionRoutes.buyProductOrderManageRoutePermissions,
            ...permissionRoutes.buyProductTakeActionRoutePermissions,
        ],
        hasSubMenu: true,
        subMenus: [
            {
                path: "/purchase/buy-orders",
                title: "Buy Order",
                requirePermission: true,
                permission:
                    permissionRoutes.buyProductOrderManageRoutePermissions,
                icon: null,
                hasSubMenu: false,
            },
            {
                path: "/purchase/buy-products",
                title: "Buy Product",
                requirePermission: true,
                permission: permissionRoutes.buyProductManageRoutePermissions,
                icon: null,
                hasSubMenu: false,
            },
            {
                path: "/purchase/actions",
                title: "Action",
                requirePermission: true,
                permission:
                    permissionRoutes.buyProductTakeActionRoutePermissions,
                icon: null,
                hasSubMenu: false,
            },
        ],
    },
    {
        icon: <DiffOutlined />,
        title: "RFQ",
        requirePermission: true,
        permission: [...permissionRoutes.manageRfqRoutePermissions],
        hasSubMenu: true,
        subMenus: [
            {
                path: "/rfq/request",
                title: "RFQ Request",
                permission: permissionRoutes.manageRfqRoutePermissions,
                requirePermission: true,
                icon: null,
                hasSubMenu: false,
            },
        ],
    },

    {
        title: "Invoice",
        icon: <ReadOutlined />,
        hasSubMenu: true,
        requirePermission: true,
        permission: [
            ...permissionRoutes.customerInvoiceViewRoutePermissions,
            ...permissionRoutes.agentInvoiceViewRoutePermissions,
            ...permissionRoutes.agentInvoiceRuleGroupViewRoutePermissions,
        ],
        subMenus: [
            {
                path: "/invoice/customer-invoices",
                title: "Customer Invoice",
                permission:
                    permissionRoutes.customerInvoiceViewRoutePermissions,
                requirePermission: true,
                icon: null,
                hasSubMenu: false,
            },
            {
                path: "/invoice/agent-invoices",
                title: "Agent Invoice",
                permission: permissionRoutes.agentInvoiceViewRoutePermissions,
                requirePermission: true,
                icon: null,
                hasSubMenu: false,
            },
            {
                path: "/invoice/rules",
                title: "Rule",
                permission:
                    permissionRoutes.agentInvoiceRuleGroupViewRoutePermissions,
                requirePermission: true,
                icon: null,
                hasSubMenu: false,
            },
        ],
    },
    {
        icon: <MoneyCollectOutlined />,
        title: "Payment",
        requirePermission: true,
        hasSubMenu: true,
        permission: [
            ...permissionRoutes.paymentGateWayManageRoutePermissions,
            ...permissionRoutes.payoutGatewayManageRoutePermissions,
            ...permissionRoutes.gatewayTransactionsRoutePermissions,
        ],
        subMenus: [
            {
                path: "/payment/payment-gateways",
                title: "Payment Gateways",
                requirePermission: true,
                permission:
                    permissionRoutes.paymentGateWayManageRoutePermissions,
                icon: null,
                hasSubMenu: false,
            },
            {
                path: "/payment/cashout-gateways",
                title: "Cashout Gateway ",
                permission:
                    permissionRoutes.payoutGatewayManageRoutePermissions,
                requirePermission: true,
                icon: null,
                hasSubMenu: false,
            },
            {
                path: "/payment/transactions",
                permission:
                    permissionRoutes.gatewayTransactionsRoutePermissions,
                title: "Transaction",
                requirePermission: true,
                icon: null,
                hasSubMenu: false,
            },
        ],
    },
    {
        title: "Wallet",
        icon: <MoneyCollectOutlined />,
        hasSubMenu: true,
        permission: [
            ...permissionRoutes.masterWalletViewRoutePermissions,
            ...permissionRoutes.payoutRequestManageRoutePermissions,
        ],
        requirePermission: true,
        subMenus: [
            {
                path: "/wallet/overview",
                title: "Overview",
                permission: permissionRoutes.masterWalletViewRoutePermissions,
                requirePermission: true,
                icon: null,
                hasSubMenu: false,
            },
            {
                path: "/wallet/cashouts",
                title: "Cashout",
                permission:
                    permissionRoutes.payoutRequestManageRoutePermissions,
                requirePermission: true,
                icon: null,
                hasSubMenu: false,
            },
        ],
    },

    // product manage
    {
        icon: <EnvironmentOutlined />,
        title: "Service",
        requirePermission: false,
        permission: [
            ADMIN_GENERIC_PERMISSION_ENUM.ADMIN_VIEW_INVENTORY_PRODUCTS,
        ],
        hasSubMenu: true,
        subMenus: [
            {
                path: "/service/inventory",
                title: "Inventory",
                requirePermission: false,
                permission: [
                    ADMIN_GENERIC_PERMISSION_ENUM.ADMIN_VIEW_INVENTORY_PRODUCTS,
                ],
                icon: null,
                hasSubMenu: false,
            },
            {
                path: "/service/tools",
                title: "Tools",
                requirePermission: false,
                permission: [],
                icon: null,
                hasSubMenu: false,
            },
            // {
            //   path: "/service/addons",
            //   title: "Addons",
            //   permission:permissionRoutes.manageAddonsRoutePermissions,
            //   requirePermission: true,
            //   icon: null,
            //   hasSubMenu: false,
            // },
        ],
    },

    // incomplete-> TODO
    {
        title: "Campaigns",
        icon: <ReadOutlined />,
        hasSubMenu: true,
        permission: [ADMIN_GENERIC_PERMISSION_ENUM.ADMIN_MANAGE_CAMPAIGNS],
        requirePermission: true,
        subMenus: [
            {
                path: "/campaigns",
                title: "Campaign",
                permission: [
                    ADMIN_GENERIC_PERMISSION_ENUM.ADMIN_MANAGE_CAMPAIGNS,
                ],
                requirePermission: true,
                icon: null,
                hasSubMenu: false,
            },
        ],
    },
    // TODO -> Incomplete

    // {
    //   icon: <HomeOutlined />,
    //   title: "Destination",
    //   requirePermission: true,
    //   permission: permissionRoutes.destinationManageRoutePermissions,
    //   hasSubMenu: true,
    //   subMenus: [
    //     {
    //       path: "/destination/destination-warehouse",
    //       title: "Destination Warehouse",
    //       permission: permissionRoutes.destinationManageRoutePermissions,
    //       requirePermission: true,
    //       icon: null,
    //       hasSubMenu: false,
    //     },
    //   ],
    // },
    {
        title: "System",
        icon: <HeatMapOutlined />,
        hasSubMenu: true,
        requirePermission: true,
        permission: [...permissionRoutes.harvestViewRoutePermissions],
        subMenus: [
            {
                path: "/system/queue-tasks",
                title: "Queue Task",
                permission: permissionRoutes.harvestViewRoutePermissions,
                requirePermission: true,
                icon: null,
                hasSubMenu: false,
            },
        ],
    },
];

export default menuItems;
