import { Dispatch, SetStateAction } from "react";
import { Avatar, Card, Checkbox, Image, Tag } from "antd";

import { PropertyModel } from "@/models/productDetailExtendedModel";

import { IPropertyValue } from "..";
import ImageWithPlaceholder from "@/components/ImageWithPlaceholder";

interface IProps {
    selectedVariants: IPropertyValue[];
    setSelectedVariants: Dispatch<SetStateAction<IPropertyValue[] | undefined>>;
    property: PropertyModel;
}

export const FirstProperties = ({
    selectedVariants,
    setSelectedVariants,
    property,
}: IProps) => {
    const isChecked = (propertyValueId: string) => {
        return selectedVariants?.some(
            (variant) =>
                variant.propertyId === property.getId() &&
                variant.propertyValueId === propertyValueId,
        );
    };

    const handleChecked = (propertyValueId: string) => {
        setSelectedVariants((prevVariants) =>
            prevVariants?.map((variant) => {
                if (variant.propertyId === property.getId()) {
                    return { ...variant, propertyValueId: propertyValueId };
                }
                return variant;
            }),
        );
    };
    return (
        <Card title={property.getName()} size="small">
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    gap: 20,
                    marginTop: "16px",
                }}
            >
                {property
                    .getValues()
                    .getData()
                    .map((propertyValue) => {
                        return (
                            <div
                                key={propertyValue.getId()}
                                style={{ display: "flex", gap: 10 }}
                            >
                                {(propertyValue.getThumb() && (
                                    <Checkbox
                                        value={propertyValue.getId()}
                                        checked={isChecked(
                                            propertyValue.getId(),
                                        )}
                                        onChange={() =>
                                            handleChecked(propertyValue.getId())
                                        }
                                    >
                                        <Avatar
                                            shape="square"
                                            size={50}
                                            src={
                                                <ImageWithPlaceholder
                                                    preview={false}
                                                    src={propertyValue.getThumb()}
                                                />
                                            }
                                            alt=""
                                        />
                                        <div
                                            title={
                                                propertyValue.getName() ||
                                                propertyValue.getTitle()
                                            }
                                            style={{
                                                textTransform: "capitalize",
                                                maxWidth: 50,
                                                textOverflow: "ellipsis",
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            {propertyValue.getName()
                                                ? propertyValue.getName()
                                                : propertyValue.getTitle()}
                                        </div>
                                    </Checkbox>
                                )) ||
                                    (propertyValue.getImage() && (
                                        <Checkbox
                                            value={propertyValue.getId()}
                                            checked={isChecked(
                                                propertyValue.getId(),
                                            )}
                                            onChange={() =>
                                                handleChecked(
                                                    propertyValue.getId(),
                                                )
                                            }
                                        >
                                            <Avatar
                                                shape="square"
                                                size={50}
                                                src={
                                                    <Image
                                                        src={propertyValue.getImage()}
                                                    />
                                                }
                                            />
                                            <div
                                                style={{
                                                    textTransform: "capitalize",
                                                }}
                                            >
                                                {propertyValue.getName()
                                                    ? propertyValue.getName()
                                                    : propertyValue.getTitle()}
                                            </div>
                                        </Checkbox>
                                    )) ||
                                    (propertyValue.getColor() && (
                                        <Checkbox
                                            value={propertyValue.getId()}
                                            checked={isChecked(
                                                propertyValue.getId(),
                                            )}
                                            onChange={() =>
                                                handleChecked(
                                                    propertyValue.getId(),
                                                )
                                            }
                                        >
                                            <div
                                                style={{
                                                    backgroundColor:
                                                        propertyValue.getColor() as string,
                                                    width: "48.75px",
                                                    height: "48.75px",
                                                    borderRadius: "24px",
                                                }}
                                            />
                                        </Checkbox>
                                    )) ||
                                    (propertyValue.getName() && (
                                        <Checkbox
                                            value={propertyValue.getId()}
                                            checked={isChecked(
                                                propertyValue.getId(),
                                            )}
                                            onChange={() =>
                                                handleChecked(
                                                    propertyValue.getId(),
                                                )
                                            }
                                        >
                                            <Tag
                                                color="#2db7f5"
                                                style={{
                                                    padding: "5px",
                                                    fontSize: "16px",
                                                }}
                                            >
                                                {propertyValue.getName()}
                                            </Tag>
                                        </Checkbox>
                                    )) ||
                                    (propertyValue.getTitle() && (
                                        <Checkbox
                                            value={propertyValue.getId()}
                                            checked={isChecked(
                                                propertyValue.getId(),
                                            )}
                                            onChange={() =>
                                                handleChecked(
                                                    propertyValue.getId(),
                                                )
                                            }
                                        >
                                            <p
                                                style={{
                                                    textTransform: "uppercase",
                                                    height: "48.75px",
                                                    padding: "3px 3px 2px 2px",
                                                    borderRadius: "24px",
                                                    backgroundColor: "grey",
                                                }}
                                            >
                                                {propertyValue.getTitle()}
                                            </p>
                                        </Checkbox>
                                    ))}
                            </div>
                        );
                    })}
            </div>
        </Card>
    );
};
