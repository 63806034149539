import { useState } from "react";
import { useAtom } from "jotai";

import { useService } from "../../contexts";
import { getError } from "../utils/errors";
import {
    disputeCollectionAtom,
    disputeDetailsAtom,
    disputePreviewAtom,
} from "@/lib/core-react/store/store";
import { IDisputeApprovedFormData } from "@/types/disputeDetailsCollection";

export interface ApprovePurchasePayload {
    items: {
        id: number;
    }[];
}

export interface AgreeMendedPayload {
    mandate_id: number;
    agreed_assigned_amount: number;
}

// Dispute

export const useGetDisputes = () => {
    const [disputes, setDisputes] = useAtom(disputeCollectionAtom);
    const { disputeService } = useService();

    const getDisputes = async (params?: string): Promise<void> => {
        setDisputes({ ...disputes, isLoading: true, error: null });

        try {
            const response =
                await disputeService.disputeResource.getDisputes(params);
            setDisputes({
                ...disputes,
                data: response,
                isLoading: false,
                error: null,
            });
        } catch (error: any) {
            setDisputes({
                ...disputes,
                isLoading: false,
                refetch: false,
                error: getError(error),
                unAuthorized: error?.response?.status === 403,
            });

            throw error;
        }
    };

    return { getDisputes } as const;
};

export const useGetDispute = () => {
    const [disputeDetails, setDisputeDetails] = useAtom(disputeDetailsAtom);
    const { disputeService } = useService();
    const getDispute = async (params: number) => {
        setDisputeDetails({ isLoading: true, error: null });
        try {
            setDisputeDetails({ isLoading: true, error: null });
            const response =
                await disputeService.disputeResource.getDispute(params);
            setDisputeDetails({
                data: response,
                isLoading: false,
                error: null,
            });
            return;
        } catch (error) {
            setDisputeDetails({
                ...disputeDetails,
                isLoading: false,
                refetch: false,
                error: getError(error),
            });
        }
        return;
    };

    return { getDispute } as const;
};

// delete dispute

export const useGetDisputePreview = () => {
    const { disputeService } = useService();
    const [, setDisputePreviewDetails] = useAtom(disputePreviewAtom);

    const getDisputePreview = async (params: number) => {
        try {
            const response =
                await disputeService.disputeResource.getDisputePreview(params);
            setDisputePreviewDetails({
                // ... disputePreviewDetails,
                data: response,
                isLoading: false,
                error: null,
            });
            return response;
        } catch (error: any) {
            setDisputePreviewDetails({
                isLoading: false,
                error: getError(error),
            });
            throw error;
        }
    };

    return { getDisputePreview } as const;
};

// delete dispute
export const useDeleteDispute = () => {
    const { disputeService } = useService();
    const { getDisputes } = useGetDisputes();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");

    const deleteDispute = async (id: number) => {
        setIsLoading(true);
        try {
            await disputeService.disputeResource.deleteDispute(id);
            setIsLoading(false);
            await getDisputes();
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { deleteDispute, isLoading, isError } as const;
};

export const useApproveDispute = () => {
    const [_disputeDetails, setDisputeDetails] = useAtom(disputeDetailsAtom);
    const { getDisputes } = useGetDisputes();
    const { disputeService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");

    const approveDispute = async (
        id: number,
        payload: ApprovePurchasePayload,
    ) => {
        setIsLoading(true);
        try {
            const response =
                await disputeService.disputeResource.approveDispute(
                    id,
                    payload,
                );
            setDisputeDetails({ isLoading: true, error: null });
            const disputeDetailsResponse =
                await disputeService.disputeResource.getDispute(id);
            setIsLoading(false);
            setDisputeDetails({
                data: disputeDetailsResponse,
                isLoading: false,
                error: null,
            });
            await getDisputes();
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };
    return { approveDispute, isLoading, isError };
};

export const useAgreeMandate = () => {
    const [disputeDetails, setDisputeDetails] = useAtom(disputeDetailsAtom);
    const { disputeService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");

    const agreeMandate = async (id: number, payload: AgreeMendedPayload) => {
        setIsLoading(true);
        try {
            const response = await disputeService.disputeResource.agreeMandate(
                id,
                payload,
            );
            setDisputeDetails({ isLoading: true, error: null });
            const disputeDetailsResponse =
                await disputeService.disputeResource.getDispute(id);
            setIsLoading(false);
            setDisputeDetails({
                ...disputeDetails,
                data: disputeDetailsResponse,
                isLoading: false,
                error: null,
            });
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };
    return { agreeMandate, isLoading, isError };
};

export const useUpdateDispute = () => {
    const [disputeDetails, setDisputeDetails] = useAtom(disputeDetailsAtom);
    const { getDisputes } = useGetDisputes();
    const { disputeService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");

    const updateDispute = async (
        id: number,
        payload: IDisputeApprovedFormData,
    ) => {
        setIsLoading(true);
        try {
            const response = await disputeService.disputeResource.updateDispute(
                id,
                payload,
            );
            setIsLoading(false);
            setDisputeDetails({
                ...disputeDetails,
                data: response,
                isLoading: false,
                error: null,
            });
            await getDisputes();
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
        }
    };
    return { updateDispute, isLoading, isError };
};

// complete refund
export const useCompleteRefund = () => {
    const [disputeDetails, setDisputeDetails] = useAtom(disputeDetailsAtom);
    const { getDisputes } = useGetDisputes();
    const { disputeService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");

    const completeRefund = async (id: number) => {
        setIsLoading(true);
        try {
            const response =
                await disputeService.disputeResource.completeRefund(id);
            setIsLoading(false);
            setDisputeDetails({
                ...disputeDetails,
                data: response,
                isLoading: false,
                error: null,
            });
            await getDisputes();
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };
    return { completeRefund, isLoading, isError };
};

// get preview dispute-sattlement
export const useGetDisputeSettlementPreview = () => {
    const { disputeService } = useService();
    const [, setDisputePreviewDetails] = useAtom(disputePreviewAtom);

    const getDisputeSettlementPreview = async (params: number) => {
        try {
            const response =
                await disputeService.disputeResource.getDisputeSettlementPreview(
                    params,
                );
            setDisputePreviewDetails({
                data: response,
                isLoading: false,
                error: null,
            });
            return response;
        } catch (error: any) {
            setDisputePreviewDetails({
                isLoading: false,
                error: getError(error),
            });
            throw error;
        }
    };

    return { getDisputeSettlementPreview } as const;
};
