import Slider from "react-slick";
import { ProductDetailExtendedModel } from "@/models/productDetailExtendedModel";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ImageWithPlaceholder from "@/components/ImageWithPlaceholder";

type Props = {
    product: ProductDetailExtendedModel;
};
const ProductImageSlide = ({ product }: Props) => {
    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <Slider {...settings}>
            {product.getGallery().map((item) => (
                <div key={item.id} style={{ padding: 3, height: "100%" }}>
                    <ImageWithPlaceholder
                        src={item.getUrl()}
                        alt={item.getTitle()}
                        style={{
                            objectFit: "contain",
                            width: "100%",
                            maxHeight: "400px",
                            height: "100%",
                        }}
                    />
                </div>
            ))}
        </Slider>
    );
};

export default ProductImageSlide;
