import React, { createRef, useEffect } from "react";
import { Modal, Select, Form, Button } from "antd";
import type { FormInstance } from "antd";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";
import { responseTypeQuery } from "@/filters/constant";

interface OrderHandlerAssignProps {
    isModalOpen: boolean;
    handleCloseModal: () => void;
    handleSelectChange: (values: { order_handler_id: string }) => void;
    AssignLoading: boolean;
    orderHandlerId?: number | null;
}

const OrderHandlerAssign: React.FC<OrderHandlerAssignProps> = ({
    isModalOpen,
    handleCloseModal,
    handleSelectChange,
    AssignLoading,
    orderHandlerId,
}) => {
    const [form] = Form.useForm();
    const formRef: React.Ref<FormInstance<any>> = createRef();

    const {
        allOptionsData: { orderHandlerOptionsData },
        onFetchFilterApi,
    } = useFiltersApiData();

    useEffect(() => {
        onFetchFilterApi(filterResourceEnum.ORDER_HANDLER, {
            per_page: 500,
            ...responseTypeQuery.minimal,
        });
    }, []);

    useEffect(() => {
        if (orderHandlerId !== undefined) {
            form.setFieldsValue({ order_handler_id: orderHandlerId });
        } else {
            form.resetFields();
        }
    }, [orderHandlerId]);

    return (
        <Modal
            title="Select an Option"
            open={isModalOpen}
            onCancel={handleCloseModal}
            footer={false}
            destroyOnClose={true}
        >
            <Form
                form={form}
                layout="vertical"
                ref={formRef}
                onFinish={handleSelectChange}
                initialValues={{
                    order_handler_id: orderHandlerId ?? null, // Set the initial value
                }}
            >
                <Form.Item
                    name="order_handler_id"
                    label="Order Handler"
                    rules={[
                        { required: true, message: "OrderHandler is required" },
                    ]}
                >
                    <Select
                        showSearch
                        options={orderHandlerOptionsData.options}
                        placeholder="Please select the OrderHandler"
                    />
                </Form.Item>

                <Form.Item>
                    <Button
                        loading={AssignLoading}
                        type="primary"
                        htmlType="submit"
                        style={{ width: "100%" }}
                    >
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default OrderHandlerAssign;
