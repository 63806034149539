import { IPagination } from "@/types/pagination";
import {
    IShipmentProductCharge,
    IShipmentProductChargeCollection,
} from "@/types/shipmentProductChargeCollection";
import {
    IShipmentImage,
    IShipmentImageCollection,
    IShipmentProductPackage,
    IShipmentProductPackageCollection,
    IShipmentProductPackagePropertyVariation,
    IShipmentProductPackageVariation,
    IShipmentTracking,
} from "@/types/shipmentProductCollection";
import { CurrencyModel } from "./currencyCollectionModel";

export class ShipmentProductChargeModel {
    object: string;
    id: number;
    charge_type: string;
    charged_by: string;
    commission_rate: number;
    commission_rate_type: string;
    original_amount: number;
    total_amount: number;
    created_at: string;

    constructor(dataItem: IShipmentProductCharge) {
        this.object = dataItem.object;
        this.id = dataItem.id;
        this.charge_type = dataItem.charge_type;
        this.charged_by = dataItem.charged_by;
        this.commission_rate = dataItem.commission_rate;
        this.commission_rate_type = dataItem.commission_rate_type;
        this.original_amount = dataItem.original_amount;
        this.total_amount = dataItem.total_amount;
        this.created_at = dataItem.created_at;
    }

    getChargeType(): string {
        return this.charge_type;
    }
    getChargeBy(): string {
        return this.charged_by;
    }
    getCommissionRate(): number {
        return this.commission_rate;
    }
    getCommissionRateType(): string {
        return this.commission_rate_type;
    }
    getOriginalAmount(): number {
        return this.original_amount;
    }
    getTotalAmount(): number {
        return this.total_amount;
    }
    getCreatedAt(): string {
        return this.created_at;
    }
}

export class ShipmentProductChargeCollectionModel {
    object: string;
    data: ShipmentProductChargeModel[];

    constructor(dataItem: IShipmentProductChargeCollection) {
        this.object = dataItem.object;
        this.data = dataItem.data.map((d) => new ShipmentProductChargeModel(d));
    }

    getObject(): string {
        return this.object;
    }

    getData(): ShipmentProductChargeModel[] {
        return this.data;
    }
}

export class ShipmentTrackingModel {
    public readonly object: "ShipmentTracking";
    public readonly id: number;
    public readonly type: string;
    public readonly tracking: string;
    public readonly created_at: string;
    constructor(data: IShipmentTracking) {
        this.object = data.object;
        this.id = data.id;
        this.type = data.type;
        this.tracking = data.tracking;
        this.created_at = data.created_at;
    }
    getId = () => this.id;
    getType = () => this.type;
    getTracking = () => this.tracking;
}

export class ShipmentProductPackageVariationModel {
    public readonly object: "ShipmentProductPackageVariation";
    public readonly id: number;
    public readonly quantity: number;
    public readonly sku_id: string;
    public readonly properties: ShipmentProductPackagePropertyVariationModel[];

    constructor(data: IShipmentProductPackageVariation) {
        this.object = data.object;
        this.id = data.id;
        this.quantity = data.quantity;
        this.sku_id = data.sku_id;
        this.properties = data.properties.data.map(
            (p) => new ShipmentProductPackagePropertyVariationModel(p),
        );
    }

    getObject(): "ShipmentProductPackageVariation" {
        return this.object;
    }

    getId(): number {
        return this.id;
    }

    getQuantity(): number {
        return this.quantity;
    }

    getSkuId(): string {
        return this.sku_id;
    }

    getProperties(): ShipmentProductPackagePropertyVariationModel[] {
        return this.properties;
    }

    getFirstPropertyImageUrl(): string {
        try {
            if (this.getProperties()) {
                const property = this.getProperties().find(
                    (p) =>
                        p.getPropertyValueImage() || p.getPropertyValueThumb(),
                );
                const imgUrl =
                    property?.getPropertyValueImage() ||
                    property?.getPropertyValueThumb() ||
                    "";
                return imgUrl;
            } else {
                return "";
            }
        } catch (_error) {
            return "";
        }
    }
}

export class ShipmentProductPackagePropertyVariationModel {
    public readonly object: "ShipmentProductPackagePropertyVariation";
    public readonly id: number;
    public readonly property_id: string;
    public readonly property_name: string;
    public readonly property_original_name: string;
    public readonly property_value_id: string;
    public readonly property_value_name: string;
    public readonly property_value_title: string;
    public readonly property_value_color: string | null;
    public readonly property_value_image: string;
    public readonly property_value_thumb: string;

    constructor(data: IShipmentProductPackagePropertyVariation) {
        this.object = data.object;
        this.id = data.id;
        this.property_id = data.property_id;
        this.property_name = data.property_name;
        this.property_original_name = data.property_original_name;
        this.property_value_id = data.property_value_id;
        this.property_value_name = data.property_value_name;
        this.property_value_title = data.property_value_title;
        this.property_value_color = data.property_value_color;
        this.property_value_image = data.property_value_image;
        this.property_value_thumb = data.property_value_thumb;
    }

    getObject(): "ShipmentProductPackagePropertyVariation" {
        return this.object;
    }

    getId(): number {
        return this.id;
    }

    getPropertyId(): string {
        return this.property_id;
    }

    getPropertyName(): string {
        return this.property_name;
    }

    getPropertyOriginalName(): string {
        return this.property_original_name;
    }

    getPropertyValueId(): string {
        return this.property_value_id;
    }

    getPropertyValueName(): string {
        return this.property_value_name;
    }

    getPropertyValueTitle(): string {
        return this.property_value_title;
    }

    getPropertyValueColor(): string | null {
        return this.property_value_color;
    }

    getPropertyValueImage(): string {
        return this.property_value_image;
    }

    getPropertyValueThumb(): string {
        return this.property_value_thumb;
    }
}

export class ShipmentProductPackageCollectionModel {
    public readonly object: "ShipmentProductPackageCollection";
    public readonly data: ShipmentProductPackageModel[];
    public readonly pagination?: IPagination;

    constructor(data: IShipmentProductPackageCollection) {
        this.object = data.object;
        this.pagination = data.pagination;

        this.data = data.data.map(
            (item) => new ShipmentProductPackageModel(item),
        );
    }

    getObject(): "ShipmentProductPackageCollection" {
        return this.object;
    }

    getData() {
        return this.data;
    }

    getContains = () => {
        return this.data.map((item) => item.contains).join(",");
    };

    getTotalPackage = () => {
        return this.data.length;
    };

    getTotalProductPrice = () =>
        this.data.reduce((pre, curr) => pre + curr.getProductPrice(), 0);
    getTotalLength = () =>
        this.data.reduce((pre, curr) => pre + Number(curr.getLength()), 0);
    getTotalWidth = () =>
        this.data.reduce((pre, curr) => pre + Number(curr.getWidth()), 0);
    getTotalHeight = () =>
        this.data.reduce((pre, curr) => pre + Number(curr.getHeight()), 0);
    getTotalWeight = () =>
        this.data.reduce((pre, curr) => pre + Number(curr.getWeight()), 0);
    getTotalQuantity = () =>
        this.data.reduce((pre, curr) => pre + curr.getQuantity(), 0);
    getTotalCbm = () =>
        this.data.reduce((pre, curr) => pre + Number(curr.getCbm()), 0);
}

export class ShipmentProductPackageModel {
    public readonly object: "ShipmentProductPackage";
    public readonly id: number;
    public readonly package_number: string;
    public readonly product_price: number;
    public readonly quantity: number;
    public readonly weight: number;
    public readonly length: number;
    public readonly width: number;
    public readonly height: number;
    public readonly cbm: number;
    public readonly contains: string;
    public readonly contains_2: string | null;
    public readonly hs_code: string | null;
    public readonly carton_number: string | null;
    public readonly fulfillment_status: string;
    public readonly trackings?: ShipmentTrackingModel[];
    public readonly images: IShipmentImageCollection;
    public readonly variations?: ShipmentProductPackageVariationModel[];
    product_price_currency?: CurrencyModel;

    constructor(data: IShipmentProductPackage) {
        this.object = data.object;
        this.id = data.id;
        this.package_number = data.package_number;
        this.product_price = data.product_price;
        this.quantity = data.quantity;
        this.weight = data.weight;
        this.length = data.length;
        this.width = data.width;
        this.height = data.height;
        this.cbm = data.cbm;
        this.contains = data.contains;
        this.contains_2 = data.contains_2;
        this.hs_code = data.hs_code;
        this.carton_number = data.carton_number;
        this.fulfillment_status = data.fulfillment_status;
        this.trackings =
            data.trackings &&
            data?.trackings?.data.map((t) => new ShipmentTrackingModel(t));
        this.images = data.images;
        this.variations =
            data.variations &&
            data.variations.data.map(
                (v) => new ShipmentProductPackageVariationModel(v),
            );
        this.product_price_currency =
            data.product_price_currency &&
            new CurrencyModel(data.product_price_currency);
    }

    getTrackings(): ShipmentTrackingModel[] {
        return this.trackings ?? [];
    }
    getAbroadTracking = () => {
        return this.getTrackings().find(
            (x) => x.getType() === "abroad-courier-tracking",
        );
    };
    getObject(): "ShipmentProductPackage" {
        return this.object;
    }

    getId(): number {
        return this.id;
    }

    getPackageNumber(): string {
        return this.package_number;
    }

    getProductPrice(): number {
        return this.product_price;
    }

    getQuantity(): number {
        return this.quantity;
    }

    getWeight(): number {
        return this.weight;
    }

    getLength(): number {
        return this.length;
    }

    getWidth(): number {
        return this.width;
    }

    getHeight(): number {
        return this.height;
    }

    getCbm(): number {
        return this.cbm;
    }

    getContains(): string {
        return this.contains;
    }

    getContains2(): string | null {
        return this.contains_2;
    }

    getHsCode(): string | null {
        return this.hs_code;
    }

    getCartonNumber(): string | null {
        return this.carton_number;
    }

    getFulfillmentStatus(): string {
        return this.fulfillment_status;
    }

    getImages(): IShipmentImage[] {
        return this.images.data;
    }

    getVariations(): ShipmentProductPackageVariationModel[] {
        return this.variations ?? [];
    }
    getProductPriceCurrency = () => {
        return this.product_price_currency;
    };
}
