import React, { useEffect, useState } from "react";
import { Button, Checkbox, Col, Row, Table, Typography } from "antd";
import type { TableColumnsType } from "antd";
import { tw } from "@/consts/theme/tailwindTheme";
import useWindowWidth from "@/lib/core-react/hooks/public/useWindowWidth";
import { RightOutlined } from "@ant-design/icons";
import ImageWithPlaceholder from "@/components/ImageWithPlaceholder";
import {
    BuyProductModel,
    BuyProductVariationsModel,
} from "@/models/buyProductCollectionModel";
import {
    DisputePreviewModel,
    DisputePreviewItemModel,
    DisputeItems,
} from "@/models/disputeDetailsCollectionModel";

interface Props {
    buyProduct: BuyProductModel;
    isInsideModal?: boolean;
    disputePreview: DisputePreviewModel;
    onApprove: (itemIds: number[]) => void;
    disputeItems: DisputeItems[];
}

const VariationsTable: React.FC<Props> = ({
    buyProduct,
    isInsideModal = false,
    disputePreview,
    onApprove,
    disputeItems,
}) => {
    const { Paragraph, Text } = Typography;
    const [isExpand, setIsExpand] = useState(false);
    const [variations, setVariations] = useState<BuyProductVariationsModel[]>(
        [],
    );
    const [selectedItems, setSelectedItems] = useState<number[]>([]);
    const { width } = useWindowWidth();

    useEffect(() => {
        if (buyProduct.getProductVariations().length > 2) {
            if (!isExpand) {
                setVariations(buyProduct.getProductVariations().slice(0, 3));
            } else {
                setVariations(buyProduct.getProductVariations());
            }
        } else {
            setVariations(buyProduct.getProductVariations());
        }
    }, [isExpand, buyProduct]);

    const columnsTitleStyle = {
        fontSize: tw.fontSize.sm.fontSize,
        lineHeight: tw.fontSize.sm.lineHeight,
    };

    const onHeaderCellStyle = () => ({
        style: {
            padding: tw.spacing[1],
        },
    });

    const handleCheckboxChange = (itemId: number, checked: boolean) => {
        if (checked) {
            setSelectedItems([...selectedItems, itemId]);
        } else {
            setSelectedItems(selectedItems.filter((id) => id !== itemId));
        }
    };

    const handleApprove = () => {
        onApprove(selectedItems);
    };

    const handleSelectAll = (checked: boolean) => {
        if (checked) {
            const allItemIds = disputeItems.map((item) => item.item.getId());
            setSelectedItems(allItemIds);
        } else {
            setSelectedItems([]);
        }
    };

    const columns: TableColumnsType<DisputePreviewItemModel> = [
        {
            title: (
                <Checkbox
                    onChange={(e) => handleSelectAll(e.target.checked)}
                    checked={selectedItems.length === disputeItems.length}
                    indeterminate={
                        selectedItems.length > 0 &&
                        selectedItems.length < disputeItems.length
                    }
                />
            ),
            key: "select",
            width: 20,
            align: "center",
            render: () => {
                const previewItem = disputePreview
                    .getData()
                    .getItems()
                    .find(
                        (item) =>
                            item.getItemId() ===
                            disputeItems
                                .find((disputeItem) => disputeItem.item.getId())
                                ?.item.getId(),
                    );
                return (
                    <>
                        {previewItem && (
                            <Checkbox
                                onChange={(e) =>
                                    handleCheckboxChange(
                                        previewItem.getItemId(),
                                        e.target.checked,
                                    )
                                }
                                checked={selectedItems.includes(
                                    previewItem.getItemId(),
                                )}
                            />
                        )}
                    </>
                );
            },
        },
        {
            title: <Text style={columnsTitleStyle}>Meta</Text>,
            key: "id",
            width: 200,
            render: () => (
                <Row key={1}>
                    <Col span={6}>
                        <div
                            style={{
                                width: 42,
                                height: 42,
                                overflow: "hidden",
                            }}
                        >
                            {variations.map((v) => (
                                <ImageWithPlaceholder
                                    src={v.getImage()}
                                    alt={v.getImage()}
                                    // style={{ width: 42, height: 42 }}
                                />
                            ))}
                        </div>
                    </Col>
                    <Col span={16}>
                        <div>
                            {variations.map((v) =>
                                v.getProperties().map((singleProperty) => (
                                    <Typography key={singleProperty.id}>
                                        <Paragraph
                                            style={{
                                                margin: tw.spacing[0],
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    paddingRight:
                                                        tw.spacing["0.5"],
                                                    fontSize:
                                                        tw.fontSize.sm.fontSize,
                                                    lineHeight:
                                                        tw.fontSize.sm
                                                            .lineHeight,
                                                }}
                                            >
                                                {singleProperty.property_name}:
                                            </Text>
                                            <Text
                                                strong
                                                style={{
                                                    fontSize:
                                                        tw.fontSize.sm.fontSize,
                                                    lineHeight:
                                                        tw.fontSize.sm
                                                            .lineHeight,
                                                }}
                                            >
                                                {
                                                    singleProperty.property_value_name
                                                }
                                            </Text>
                                        </Paragraph>
                                    </Typography>
                                )),
                            )}
                        </div>
                    </Col>
                </Row>
            ),
        },
        {
            title: <Text style={columnsTitleStyle}>Refund Quantity</Text>,
            key: "refundQuantity",
            width: 70,
            align: "center",
            onHeaderCell: onHeaderCellStyle,
            render: () => {
                const previewItem = disputePreview
                    .getData()
                    .getItems()
                    .find(
                        (item) =>
                            item.getItemId() ===
                            disputeItems
                                .find((disputeItem) => disputeItem.item.getId())
                                ?.item.getId(),
                    );

                const requestedQuantity = disputeItems.find(
                    (disputeItem) =>
                        disputeItem.item.getId() === previewItem?.getItemId(),
                )?.item.requested_quantity;

                return (
                    <Typography>
                        <Paragraph style={{ margin: tw.spacing[0] }}>
                            <Text>{requestedQuantity}</Text>
                        </Paragraph>
                    </Typography>
                );
            },
        },
        {
            title: <Text style={columnsTitleStyle}>Customer Cost</Text>,
            key: "customerCost",
            width: 100,
            align: "center",
            onHeaderCell: onHeaderCellStyle,
            render: () => {
                const previewItem = disputePreview
                    .getData()
                    .getItems()
                    .find(
                        (item) =>
                            item.getItemId() ===
                            disputeItems
                                .find((disputeItem) => disputeItem.item.getId())
                                ?.item.getId(),
                    );
                return (
                    <Typography>
                        <Paragraph style={{ margin: tw.spacing[0] }}>
                            <Text>
                                Unit:{" "}
                                {previewItem
                                    ?.getCustomerPerItemCost()
                                    .toFixed(2)}{" "}
                                {buyProduct.getRegionCurrencyCode()}
                            </Text>
                        </Paragraph>
                        <Paragraph style={{ margin: tw.spacing[0] }}>
                            <Text>
                                Total:{" "}
                                {previewItem
                                    ?.getCustomerTotalItemCost()
                                    .toFixed(2)}{" "}
                                {buyProduct.getRegionCurrencyCode()}
                            </Text>
                        </Paragraph>
                    </Typography>
                );
            },
        },
        {
            title: <Text style={columnsTitleStyle}>Purchase Agent Cost</Text>,
            key: "agentCost",
            width: 100,
            align: "center",
            onHeaderCell: onHeaderCellStyle,
            render: () => {
                const previewItem = disputePreview
                    .getData()
                    .getItems()
                    .find(
                        (item) =>
                            item.getItemId() ===
                            disputeItems
                                .find((disputeItem) => disputeItem.item.getId())
                                ?.item.getId(),
                    );
                return (
                    <Typography>
                        <Paragraph style={{ margin: tw.spacing[0] }}>
                            <Text>
                                Unit:{" "}
                                {previewItem?.getAgentPerItemCost().toFixed(2)}{" "}
                                {buyProduct.getRegionCurrencyCode()}
                            </Text>
                        </Paragraph>
                        <Paragraph style={{ margin: tw.spacing[0] }}>
                            <Text>
                                Total:{" "}
                                {previewItem
                                    ?.getAgentTotalItemCost()
                                    .toFixed(2)}{" "}
                                {buyProduct.getRegionCurrencyCode()}
                            </Text>
                        </Paragraph>
                    </Typography>
                );
            },
        },
        {
            title: <Text style={columnsTitleStyle}>MoveOn Profit</Text>,
            key: "moveonProfit",
            width: 100,
            align: "center",
            onHeaderCell: onHeaderCellStyle,
            render: () => {
                const previewItem = disputePreview
                    .getData()
                    .getItems()
                    .find(
                        (item) =>
                            item.getItemId() ===
                            disputeItems
                                .find((disputeItem) => disputeItem.item.getId())
                                ?.item.getId(),
                    );
                return (
                    <Typography>
                        <Paragraph style={{ margin: tw.spacing[0] }}>
                            <Text>
                                Unit:{" "}
                                {previewItem
                                    ?.getMoveonPerItemProfit()
                                    .toFixed(2)}{" "}
                                {buyProduct.getRegionCurrencyCode()}
                            </Text>
                        </Paragraph>
                        <Paragraph style={{ margin: tw.spacing[0] }}>
                            <Text>
                                Total:{" "}
                                {previewItem
                                    ?.getMoveonTotalItemProfit()
                                    .toFixed(2)}{" "}
                                {buyProduct.getRegionCurrencyCode()}
                            </Text>
                        </Paragraph>
                    </Typography>
                );
            },
        },
    ];

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: "4px",
                }}
            >
                <Button
                    onClick={handleApprove}
                    disabled={selectedItems.length === 0}
                    type="primary"
                    size="middle"
                >
                    Approve Selected Items
                </Button>
            </div>
            <Table
                // @ts-ignore
                columns={columns}
                dataSource={disputeItems}
                rowKey="id"
                pagination={false}
                bordered
                size="small"
                style={{
                    width:
                        width >= 1440 && width < 1920
                            ? isInsideModal
                                ? "100%"
                                : 450
                            : "100%",
                    margin: 0,
                }}
                scroll={{ x: 720 }}
            />

            {buyProduct.getProductVariations().length > 3 && (
                <Button
                    style={{
                        border: tw.borderWidth[0],
                        marginTop: tw.spacing[2],
                    }}
                    onClick={() => setIsExpand(!isExpand)}
                >
                    <RightOutlined
                        style={{
                            transform: `rotate(-${tw.rotate[isExpand ? 90 : 0]})`,
                            transition: tw.transitionProperty.transform,
                            transitionDuration: tw.transitionDelay[300],
                        }}
                    />
                    See {!isExpand ? "more" : "less"}
                </Button>
            )}
        </div>
    );
};

export default VariationsTable;
