export enum PayoutGatewaySlugEnum {
    BKASH = "bkash",
    DBBL_BANK = "dbbl-bank",
    BRAC_BANK = "brac-bank",
    CITY_BANK = "city-bank",

    // UAE
    UAE_BANK_FOR_CITIZEN = "uae-bank-for-citizen",
    UAE_BANK_FOR_NON_CITIZEN = "uae-bank-for-non-citizen",
}

export enum PayoutGatewayTypeEnum {
    AUTOMATED = "automated",
    MOBILE_MERCHANT = "mobile-merchant",
    BANK_DEPOSIT = "bank-deposit",
}
