import { Button, Dropdown, Form, Modal, notification, Table } from "antd";
import { HddFilled } from "@ant-design/icons";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { tw } from "@/consts/theme/tailwindTheme";
import { BuyProductModel } from "@/models/buyProductCollectionModel";
import {
    useRejectBuyProduct,
    useSetCurrentFx,
} from "@/lib/core-react/hooks/private/usePurchase";
import { IModalActionsType, IModalData } from "../../../helpers/getModalTital";
import AgentInfo from "../components/AgentInfo";
import { ColumnsType } from "antd/es/table";
import { BuyProductInfo, BuyProductVariationsTable } from "../components";
import { useBuyProductActions } from "./useActions";
import { Key, useState, memo, useMemo, useCallback } from "react";
import { TablePaginationConfig } from "antd/lib";
import { RowSelectMethod } from "antd/es/table/interface";

import { showError } from "@/helpers/showError";
import { ADMIN_PURCHASE_PERMISSION_ENUM } from "@/consts/permission-enum/purchase-enum";
import useDataFilters from "@/hooks/useDataFilters";
import { BREAK_POINTS } from "@/consts/appConstants";
import useActionsProps from "@/helpers/useActionsProps";
import useRefetch from "@/hooks/useRefetch";
import { RejectPayload } from "@/types/buyOrderCollection";
import RejectProductModal from "@/pages/Shipping/components/handleRejectModal";
import { PayCustomerInvoiceModal } from "@/components/PayCustomerInvoice";
import { IPaymentSessionPayPayload } from "@/types/paymentSession";
import { useInvoice } from "@/lib/core-react/hooks/private/useInvoice";
import { RegionModel } from "@/models/regionCollectionModel";
import BuyProductsModalContainerForActions from "./BuyProductsModalContainerForActions";

interface Props {
    buyProductList: BuyProductModel[] | undefined;
    paginationConfig?: false | TablePaginationConfig;
    isLoading?: boolean;
    title?: string;
    selectedRowKeys?: Key[];
    isOrderPage?: boolean;
    onChangeRow?: (
        selectedRowKeys: Key[],
        selectedRows: BuyProductModel[],
        info: {
            type: RowSelectMethod;
        },
    ) => void;
}

const BuyProductsTable = ({
    buyProductList,
    paginationConfig,
    isLoading,
    selectedRowKeys = [],
    onChangeRow: onChange,
    isOrderPage = false,
}: Props) => {
    const [form] = Form.useForm(); // pay customer invoice form
    const { paySession, isLoading: isLoadingPaySession } = useInvoice();
    const { setCurrentFx } = useSetCurrentFx();
    const { rejectBuyProduct, isLoading: isRejectedLoading } =
        useRejectBuyProduct();
    const { handelSubFilterClear } = useDataFilters();
    const actionsProps = useActionsProps();
    const { refetchListApi } = useRefetch();
    const [buyProduct, setSelectedProduct] = useState<
        BuyProductModel | undefined
    >(undefined);
    const [selectedPayInvoiceData, setSelectedPayInvoiceData] = useState<{
        region: RegionModel;
        user_id: number;
        token: string;
    }>();
    const [modalOpenType, setModalOpenType] =
        useState<IModalActionsType>(false);

    const handleModal = useCallback(
        async (payload: IModalData) => {
            setModalOpenType(payload.action);
            switch (payload.action) {
                case "update_agent_category_price_read":
                    setSelectedProduct(payload.data);
                    break;
                case "update_fx":
                    setSelectedProduct(payload.data);
                    break;
                case "set_current_fx":
                    await handleSetCurrentFx(payload.data.productId);
                    break;
                case "approved_buy_product":
                    setSelectedProduct(payload.data);
                    break;
                case "reject_buy_product":
                    setSelectedProduct(payload.data.productId);
                    break;
                case "cancel_buy_product":
                    setSelectedProduct(payload.data);
                    break;

                case "take_actions_buy_product":
                    setSelectedProduct(payload.data);
                    break;
                case "update_shipping_price_buy_product":
                    setSelectedProduct(payload.data);
                    break;
                case "approve_and_assign_agent":
                    setSelectedProduct(payload.data);
                    break;
                case "approved_buy_product_bulk":
                    setSelectedProduct(payload.data);
                    break;
                case "approve_rfq_product":
                    setSelectedProduct(payload.data);
                    break;
                case "pay-invoice":
                    setSelectedPayInvoiceData(payload.data);
                    break;

                default:
                    break;
            }
        },
        [modalOpenType],
    );
    const onClearSingleSelectedState = () => {
        handelSubFilterClear();
        setSelectedProduct(undefined);
        setModalOpenType(false);
        handleModal({
            action: false,
            data: undefined,
        });
        setSelectedPayInvoiceData(undefined);
    };

    const handleSetCurrentFx = async (productId: number) => {
        if (productId) {
            Modal.confirm({
                title: "Are you sure to set the current FX?",
                content: "Select Ok, otherwise the process cannot success.",
                onOk: async () => {
                    try {
                        await setCurrentFx(productId);
                        notification["success"]({
                            message: "Update successfully",
                        });
                        await refetchListApi();
                        onClearSingleSelectedState();
                        return Promise.resolve();
                    } catch (error: any) {
                        showError(error);
                        return Promise.resolve();
                    }
                },
            });
        }
    };

    const handleRejectOk = async (value: { reject_reason: string }) => {
        const payload: RejectPayload = {
            reject_reason: value.reject_reason,
        };

        try {
            await rejectBuyProduct(Number(buyProduct), payload);
            notification.success({
                message: "Successfully rejected shipment product.",
            });
            await refetchListApi();
            onClearSingleSelectedState();
        } catch (error) {
            showError(error);
        }
    };

    const onFinishCustomerPay = async (values: IPaymentSessionPayPayload) => {
        if (selectedPayInvoiceData) {
            try {
                const { amount, gateway_id, wallet_id, ...restValues } = values;
                if (
                    typeof wallet_id === "undefined" &&
                    typeof gateway_id === "undefined"
                ) {
                    return notification.error({
                        message: "Please select a gateway or wallet",
                    });
                }

                const key = gateway_id ? "gateway_id" : "wallet_id";
                const id = gateway_id || wallet_id;

                const payload = {
                    amount,
                    [key]: id,
                    ...(Object.keys(restValues).length > 0 && {
                        gateway_data: { ...restValues },
                    }),
                };

                await paySession(
                    selectedPayInvoiceData.user_id,
                    selectedPayInvoiceData.token,
                    payload,
                );
                await refetchListApi();
                onClearSingleSelectedState();
                notification.success({
                    message: "Payment initiated successfully",
                });
            } catch (error) {
                showError(error, form);
            }
        }
    };

    const { getActions } = useBuyProductActions({
        handleModal,
    });

    const columns: ColumnsType<BuyProductModel> = useMemo(
        () => [
            {
                title: "Product Info",
                key: "product_info",
                width: 450,
                onHeaderCell: () => ({
                    style: {
                        backgroundColor: isOrderPage ? "white" : "",
                    },
                }),
                onCell: () => ({
                    style: { verticalAlign: "top" },
                }),
                render: (_, record) => {
                    return (
                        <>
                            <BuyProductInfo buyProduct={record} />
                        </>
                    );
                },
            },
            {
                title: "Variant",
                key: "Variant",
                align: "start",
                onHeaderCell: () => ({
                    style: {
                        backgroundColor: isOrderPage ? "white" : "",
                    },
                }),
                onCell: () => ({
                    style: { verticalAlign: "top" },
                }),
                render: (_, record) => {
                    return (
                        <BuyProductVariationsTable
                            isBuyOrderListPage={isOrderPage}
                            isListPage={isOrderPage ? false : true}
                            buyProduct={record}
                        />
                    );
                },
            },

            {
                title: "Agent Info ",
                key: "agentInfo",
                width: BREAK_POINTS.SM,

                onHeaderCell: () => ({
                    style: {
                        backgroundColor: isOrderPage ? "white" : "",
                    },
                }),
                onCell: () => ({
                    style: { verticalAlign: "top" },
                }),
                render: (record) => (
                    <>
                        <AgentInfo
                            handleModal={handleModal}
                            buyProduct={record}
                        />
                    </>
                ),
            },
            {
                ...actionsProps,
                onHeaderCell: () => ({
                    style: {
                        backgroundColor: isOrderPage ? "white" : "",
                    },
                }),
                onCell: () => ({ style: { padding: tw.spacing["2.5"] } }),
                hidden: !checkActionPermission(
                    [
                        ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_APPROVE,
                        ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_REJECT,
                        ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_FX_UPDATE,
                        ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_SHIPMENT_UPDATE,
                        //ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_CANCEL,
                    ],
                    "checkPermission",
                    null,
                ),
                render: (_, record) => {
                    return (
                        <Dropdown
                            menu={{
                                items: getActions(record).filter((x) =>
                                    checkActionPermission(
                                        x.permission,
                                        x,
                                        null,
                                    ),
                                ),
                            }}
                        >
                            <Button type="primary" icon={<HddFilled />} />
                        </Dropdown>
                    );
                },
            },
        ],
        [],
    );

    return (
        <>
            <BuyProductsModalContainerForActions
                onCancel={onClearSingleSelectedState}
                selectedRowKeys={[]}
                modalOpenType={modalOpenType}
                buyProduct={buyProduct}
                handleModal={handleModal}
                isLoading={false}
            >
                <Table
                    bordered
                    style={{
                        backgroundColor: tw.colors.white,
                        marginTop: tw.spacing[4],
                        width: "100%",
                    }}
                    rowHoverable={false}
                    loading={isLoading}
                    dataSource={buyProductList}
                    columns={columns}
                    rowSelection={
                        isOrderPage
                            ? undefined
                            : {
                                  type: "checkbox",
                                  selectedRowKeys,
                                  onChange,
                                  columnWidth: 60,
                                  fixed: true,
                              }
                    }
                    rowKey={(r) => r.getId()}
                    pagination={isOrderPage ? false : paginationConfig}
                    scroll={{ x: BREAK_POINTS.XL }}
                />
            </BuyProductsModalContainerForActions>

            <Modal
                width={"50%"}
                title="Pay Customer Invoice"
                open={
                    modalOpenType === "pay-invoice" &&
                    Boolean(selectedPayInvoiceData)
                }
                onCancel={() => {
                    onClearSingleSelectedState();
                }}
                onOk={form.submit}
                okText="Submit"
                okButtonProps={{ loading: isLoadingPaySession }}
                centered
                destroyOnClose={true}
            >
                {selectedPayInvoiceData && (
                    <PayCustomerInvoiceModal
                        form={form}
                        data={selectedPayInvoiceData}
                        onFinish={onFinishCustomerPay}
                    />
                )}
            </Modal>

            <Modal
                width={"40%"}
                open={modalOpenType === "reject_buy_product"}
                onCancel={() => {
                    onClearSingleSelectedState();
                }}
                footer={null}
                destroyOnClose={true}
                title={"Confirm Reject"}
            >
                {buyProduct && (
                    <RejectProductModal
                        onClose={onClearSingleSelectedState}
                        handleOk={handleRejectOk}
                        loading={isRejectedLoading}
                    />
                )}
            </Modal>
        </>
    );
};

export default memo(BuyProductsTable);

// fixed: Deploy issue
