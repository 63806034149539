import {
    IShipmentProduct,
    IShipmentProductCollection,
    IShipmentProductInvoice,
    IShipmentProductInvoiceTransactionSummary,
    IShipmentProductAddonService,
    IShipmentProductAddonServiceCollection,
    IShipmentProductAddonServicePriceCollection,
    IShipmentProductAddonServicePrices,
    IShipmentProductAddonServicePriceSlotCollection,
    IShipmentProductAddonServicePriceSlot,
    IShippingAddress,
    IDeliveryEnrollment,
    IDeliveryRequest,
    ICourierOption,
} from "@/types/shipmentProductCollection";
import { IFilterType } from "@/types/filters";
import {
    CommonProductImageCollectionModel,
    CommonProductImageModel,
} from "./shared";
import { ShippingModesEnums, ShippingTypesEnums } from "@/enums/shippingEnums";
import { PaginationModel } from "./paginationModel";
import { ProductSourceEnum, UnitTypeEnum } from "@/enums/shipForMeContextEnums";
import {
    IBuyOrder,
    IBuyProductChargeCollection,
    IOrderHandler,
} from "@/types/buyProductCollection";
import { BuyOrderModel, BuyProductModel } from "./buyProductCollectionModel";

import { IDestinationWarehouse } from "@/types/destinationWarehouseCollection";
import { ICurrency } from "@/types/currencyCollection";
import { IRegion } from "@/types/regionCollection";
import {
    IShipmentProductMoveOnAssociation,
    IShipmentProductMoveOnAssociationCommissionRate,
} from "@/types/shipmentProductMoveOnAssociation";
import { ICommonProductImageCollection } from "@/types/shared";
import { CountryModel } from "./countryCollectionModel";
import { ShipmentProductPackageModel } from "./shipmentProductPackageCollection";
import { RegionModel } from "./regionCollectionModel";
import { ShipmentProductAgentAssociationModel } from "./shipmentProductAgentAssociationModel";
import { CurrencyModel } from "./currencyCollectionModel";
import { ShipmentProductCustomerAssociationModel } from "./shipmentProductCustomerAssociationModel";
import { DestinationWarehouseModel } from "./destinationWarehouse";
import { BuyProductChargeModel } from "./buyActionCollectionModel";
import { IShippingCountry } from "@/types/shippingCountryCollection";
import { ICountry } from "@/types/countryCollection";
import { ShippingCountryModel } from "./shippingCountryCollectionModel";
import { ShippingCategoryModel } from "./shippingCategory";
import { ShipmentProductBidModel } from "./shipmentProductBidCollectionModel";
import { roundToTwoDecimal } from "@/helpers/roundToTwoDecimal";
import { RateTypeEnums } from "@/enums/shippingCoreEnums";
import { ICustomerUser } from "@/types/customerUserCollection";
import { CustomerUserModel } from "./customerUserCollectionModel";

export class ShipmentProductOrderModel {
    readonly object: "BuyOrder";
    readonly id: number;
    readonly order_number: string;
    readonly order_tag?: string | null;
    readonly order_type: string;
    readonly order_status: string;
    readonly payment_status: string;
    readonly note?: string | null;
    readonly meta: any[];
    readonly order_handler?: IOrderHandler;
    readonly customer?: ICustomerUser;
    readonly created_at: string;
    readonly type: string;
    readonly status: string;
    readonly oauth_client_id: string;
    readonly addresses: { [key: string]: string }[];

    constructor(payload: IBuyOrder) {
        this.object = payload.object;
        this.id = payload.id;
        this.order_number = payload.order_number;
        this.order_tag = payload.order_tag;
        this.order_type = payload.order_type;
        this.order_status = payload.order_status;
        this.payment_status = payload.payment_status;
        this.note = payload.note;
        this.meta = payload.meta;
        this.order_handler = payload.order_handler;
        this.customer = payload.customer;
        this.created_at = payload.created_at;
        this.type = payload.type;
        this.status = payload.status;
        this.oauth_client_id = payload.oauth_client_id;
        this.addresses = payload.addresses;
    }

    getId = () => this.id;
    getOrderHandlerName = () => this.order_handler?.name || "";
    getCustomer = () => this.customer;
    getCreatedAt = () => this.created_at;
    getPaymentStatus = () => this.payment_status;
    getOrderStatus = () => this.order_status;
    getOrderType = () => this.order_type;
    getOrderNumber = () => this.order_number;
}

export class ShipmentProductDestinationWarehouseModel {
    object: string;
    id: number;
    slug: string;
    name: string;
    country: CountryModel;

    constructor(data: IDestinationWarehouse) {
        this.id = data?.id;
        this.object = data?.object;
        this.slug = data?.slug;
        this.name = data?.name;
        this.country = new CountryModel(data?.country);
    }

    getName = () => this.name;
    getId = () => this.id;
    getCountry = () => this.country;
}

export class ShipmentProductInvoiceTransactionSummaryModel {
    paid: number;
    due: number;
    overpaid: number;

    constructor(data: IShipmentProductInvoiceTransactionSummary) {
        this.paid = data.paid;
        this.due = data.due;
        this.overpaid = data.overpaid;
    }

    getPaid = () => this.paid;
    getDue = () => this.due;
    getOverpaid = () => this.overpaid;
}

export class ShipmentProductInvoiceModel {
    object: string;
    id: number;
    invoice_number: string;
    type: null;
    product_number: null;
    status: string;
    total_amount: number;
    initial_amount: number;
    refundable_amount: number;
    transaction_summary: ShipmentProductInvoiceTransactionSummaryModel;
    created_at: string;

    constructor(data: IShipmentProductInvoice) {
        this.object = data.object;
        this.id = data.id;
        this.invoice_number = data.invoice_number;
        this.type = data.type;
        this.product_number = data.product_number;
        this.status = data.status;
        this.total_amount = data.total_amount;
        this.initial_amount = data.initial_amount;
        this.refundable_amount = data.refundable_amount;
        this.transaction_summary =
            new ShipmentProductInvoiceTransactionSummaryModel(
                data.transaction_summary,
            );
        this.created_at = data.created_at;
    }

    getId = () => this.id;
    getInvoiceNumber = () => this.invoice_number;
    getType = () => this.type;
    getProductNumber = () => this.product_number;
    getStatus = () => this.status;
    getTotalAmount = () => this.total_amount;
    getInitialAmount = () => this.initial_amount;
    getRefundableAmount = () => this.refundable_amount;
    getTransactionSummary = () => this.transaction_summary;
    getCreatedAt = () => this.created_at;
}

export class ShipmentProductAddonServicePriceSlotModel {
    object: string;
    id: number;
    addon_service_price_id: number;
    min_amount: number;
    max_amount: number;
    price: number;

    constructor(data: IShipmentProductAddonServicePriceSlot) {
        this.object = data.object;
        this.id = data.id;
        this.addon_service_price_id = data.addon_service_price_id;
        this.min_amount = data.min_amount;
        this.max_amount = data.max_amount;
        this.price = data.price;
    }

    getId = () => this.id;
    getAddonServicePriceId = () => this.addon_service_price_id;
    getMinAmount = () => this.min_amount;
    getMaxAmount = () => this.max_amount;
    getPrice = () => this.price;
}

export class ShipmentProductAddonServicePriceSlotCollectionModel {
    object: string;
    data: ShipmentProductAddonServicePriceSlotModel[];

    constructor(data: IShipmentProductAddonServicePriceSlotCollection) {
        this.object = data.object;
        this.data = data.data.map(
            (d) => new ShipmentProductAddonServicePriceSlotModel(d),
        );
    }

    getData = () => this.data;
}

export class ShipmentProductAddonServicePricesModel {
    object: string;
    id: number;
    addon_service_id: number;
    region_id: number;
    depends_on: string;
    is_primary: number;
    price: number;
    price_slots: ShipmentProductAddonServicePriceSlotCollectionModel;

    constructor(data: IShipmentProductAddonServicePrices) {
        this.object = data.object;
        this.id = data.id;
        this.addon_service_id = data.addon_service_id;
        this.region_id = data.region_id;
        this.depends_on = data.depends_on;
        this.is_primary = data.is_primary;
        this.price = data.price;
        this.price_slots =
            new ShipmentProductAddonServicePriceSlotCollectionModel(
                data.price_slots,
            );
    }

    getId = () => this.id;
    getAddonServiceId = () => this.addon_service_id;
    getRegionId = () => this.region_id;
    getDependsOn = () => this.depends_on;
    getIsPrimary = () => this.is_primary;
    getPrice = () => this.price;
    getPriceSlots = () => this.price_slots;
}

export class ShipmentProductAddonServicePriceCollectionModel {
    object: string;
    data: ShipmentProductAddonServicePricesModel[];

    constructor(data: IShipmentProductAddonServicePriceCollection) {
        this.object = data.object;
        this.data = data.data.map(
            (d) => new ShipmentProductAddonServicePricesModel(d),
        );
    }

    getData = () => this.data;
}

export class ShipmentProductAddonServiceModel {
    object: string;
    id: number;
    name: string;
    addon_for: string;
    identifier: string;
    description: string;
    status: string;
    image: CommonProductImageModel;
    prices: ShipmentProductAddonServicePriceCollectionModel;
    created_at: string;

    constructor(data: IShipmentProductAddonService) {
        this.object = data.object;
        this.id = data.id;
        this.name = data.name;
        this.addon_for = data.addon_for;
        this.identifier = data.identifier;
        this.description = data.description;
        this.status = data.status;
        this.image = new CommonProductImageModel(data.image);
        this.prices = new ShipmentProductAddonServicePriceCollectionModel(
            data.prices,
        );
        this.created_at = data.created_at;
    }

    getId = () => this.id;
    getName = () => this.name;
    getAddonFor = () => this.addon_for;
    getIdentifier = () => this.identifier;
    getDescription = () => this.description;
    getStatus = () => this.status;
    getImage = () => this.image;
    getPrices = () => this.prices;
    getCreatedAt = () => this.created_at;
}

export class ShipmentProductAddonServiceCollectionModel {
    object: string;
    data: ShipmentProductAddonServiceModel[];

    constructor(data: IShipmentProductAddonServiceCollection) {
        this.object = data.object;
        this.data = data.data.map(
            (d) => new ShipmentProductAddonServiceModel(d),
        );
    }

    getData = () => this.data;
}

export class ShipmentProductDeliveryRequestCourierOptionModel {
    object: string; // "CourierOption"
    id: number;
    provider_id: string; // e.g., "emirates_fly"
    price_type: string; // e.g., "custom"
    name: string; // e.g., "Emirates Fly"
    availability_status: string; // e.g., "available-for-everyone"
    flat_amount: number;
    note: string;
    created_at: string; // ISO date string

    constructor(data: ICourierOption) {
        this.availability_status = data.availability_status;
        this.created_at = data.created_at;
        this.flat_amount = data.flat_amount;
        this.id = data.id;
        this.name = data.name;
        this.note = data.note;
        this.object = data.object;
        this.price_type = data.price_type;
        this.provider_id = data.provider_id;
    }

    getAvailabilityStatus = () => this.availability_status;
    getCreatedAt = () => this.created_at;
    getFlatAmount = () => this.flat_amount;
    getId = () => this.id;
    getName = () => this.name;
    getNote = () => this.note;
    getPriceType = () => this.price_type;
    getProviderId = () => this.provider_id;
}

export class ShipmentProductDeliveryRequestModel {
    object: string; // "DeliveryRequest"
    id: number;
    request_number: string;
    type: string; // e.g., "buy-and-ship"
    status: string; // e.g., "delivered-to-customer"
    note: string | null;
    courier_note: string | null;
    total_weight: number;
    total_quantity: number;
    total_height: number;
    total_width: number;
    total_length: number;
    created_at: string; // ISO date string
    shipping_address: IShippingAddress;
    courier_option: ShipmentProductDeliveryRequestCourierOptionModel;

    constructor(data: IDeliveryRequest) {
        this.object = data.object;
        this.id = data.id;
        this.request_number = data.request_number;
        this.shipping_address = data.shipping_address;
        this.status = data.status;
        this.courier_note = data.courier_note;
        this.courier_option =
            new ShipmentProductDeliveryRequestCourierOptionModel(
                data.courier_option,
            );
        this.created_at = data.created_at;
        this.note = data.note;
        this.total_height = data.total_height;
        this.total_length = data.total_length;
        this.total_quantity = data.total_quantity;
        this.total_weight = data.total_weight;
        this.total_width = data.total_width;
        this.type = data.type;
    }

    getId = () => this.id;
    getRequestNumber = () => this.request_number;
    getShippingAddress = () => this.shipping_address;
    getContactPerson = () => {
        return (
            this.shipping_address.display_address?.contact_person ||
            this.shipping_address.address?.contact_person ||
            "N/A"
        );
    };
    getEmail = () =>
        this.shipping_address.display_address?.email ||
        this.shipping_address.address.email ||
        "N/A";
    getPhone = () =>
        this.shipping_address.display_address?.phone ||
        this.shipping_address.address.phone ||
        "N/A";
    getAddress = () =>
        ` ${this.shipping_address.display_address?.street_address}, ${this.shipping_address.display_address?.city || this.shipping_address.display_address?.area}, ${this.shipping_address.display_address?.country}`;
    getStatus = () => this.status;
    getCourierNote = () => this.courier_note;
    getCourierOption = () => this.courier_option;
    getCreatedAt = () => this.created_at;
    getNote = () => this.note;
    getTotalHeight = () => this.total_height;
    getTotalLength = () => this.total_length;
    getTotalQuantity = () => this.total_quantity;
    getTotalWeight = () => this.total_weight;
    getTotalWidth = () => this.total_width;
    getDimension = () =>
        `${this.total_width} X ${this.total_height} X ${this.total_length} `;
}

export class ShipmentProductDeliveryEnrollmentModel {
    object: string;
    id: number;
    delivery_request: ShipmentProductDeliveryRequestModel;
    created_at: string;

    constructor(data: IDeliveryEnrollment) {
        this.object = data.object;
        this.id = data.id;
        this.delivery_request = new ShipmentProductDeliveryRequestModel(
            data.delivery_request,
        );
        this.created_at = data.created_at;
    }

    getId = () => this.id;
    getDeliveryRequest = () => this.delivery_request;
    getCreatedAt = () => this.created_at;
}

export class ShipmentProductModel {
    object: "ShipmentProduct";
    id: number;
    product_number: string;
    images: ICommonProductImageCollection;
    shipping_category?: ShippingCategoryModel;
    shipping_address: IShippingAddress;
    shipment_instruction: string | null;
    shipping_mode: ShippingModesEnums;
    shipping_type: ShippingTypesEnums;
    status: string;
    created_at: string;
    status_label: string;
    total_quantity: number;
    total_weight: number;
    packages?: ShipmentProductPackageModel[];
    product_source: ProductSourceEnum;
    link: string | null;
    title: string;
    order: BuyOrderModel;
    reject_reason?: string;
    currency: ICurrency;
    shipping_country?: IShippingCountry;
    destination_country?: ICountry;
    region: IRegion;
    destination_warehouse?: DestinationWarehouseModel;
    addon_services: IShipmentProductAddonServiceCollection;
    customer_invoice: ShipmentProductInvoiceModel | null;
    agent_invoice: IShipmentProductInvoice | null;
    agent_association: ShipmentProductAgentAssociationModel | null;
    customer_association: ShipmentProductCustomerAssociationModel | null;
    moveon_association: ShipmentProductMoveOnAssociationModel | null;
    delivery_enrollments?: ShipmentProductDeliveryEnrollmentModel[];
    bids: ShipmentProductBidModel[];
    buy_product?: BuyProductModel;
    charges?: IBuyProductChargeCollection;
    user: CustomerUserModel;

    constructor(data: IShipmentProduct) {
        this.object = data.object;
        this.user = data.user && new CustomerUserModel(data.user);
        this.created_at = data.created_at;
        this.id = data.id;
        this.product_number = data.product_number;
        this.product_source = data.product_source;
        this.images =
            data.images && new CommonProductImageCollectionModel(data.images);
        this.shipping_country = data.shipping_country;
        this.link = data.link;
        this.title = data.title;
        this.order = data.order && new BuyOrderModel(data.order);
        this.currency = data.currency;
        this.status = data.status;
        this.status_label = data.status_label;
        this.total_quantity = data.total_quantity;
        this.total_weight = data.total_weight;
        this.shipping_address = data.shipping_address;
        this.shipment_instruction = data.shipment_instruction;
        this.destination_country = data.destination_country;
        this.reject_reason = data.reject_reason;
        this.destination_warehouse =
            data.destination_warehouse &&
            new DestinationWarehouseModel(data.destination_warehouse);
        this.region = data.region;
        this.shipping_category =
            data.shipping_category &&
            new ShippingCategoryModel(data.shipping_category);
        this.shipping_mode = data.shipping_mode;
        this.shipping_type = data.shipping_type;
        this.packages =
            data.packages &&
            data.packages.data.map(
                (pkg) => new ShipmentProductPackageModel(pkg),
            );
        this.addon_services = data.addon_services;
        this.customer_invoice =
            data.customer_invoice &&
            new ShipmentProductInvoiceModel(data.customer_invoice);
        this.agent_invoice = data.agent_invoice;
        this.agent_association =
            data.agent_association &&
            new ShipmentProductAgentAssociationModel(data.agent_association);
        this.customer_association =
            data.customer_association &&
            new ShipmentProductCustomerAssociationModel(
                data.customer_association,
            );
        this.moveon_association = data.moveon_association
            ? new ShipmentProductMoveOnAssociationModel(data.moveon_association)
            : null;
        this.delivery_enrollments =
            data.delivery_enrollments &&
            data.delivery_enrollments.data.map(
                (dr) => new ShipmentProductDeliveryEnrollmentModel(dr),
            );
        this.bids =
            data.bids &&
            data.bids.data.map((bid) => new ShipmentProductBidModel(bid));
        this.buy_product =
            data.buy_product && new BuyProductModel(data.buy_product);
    }

    getCustomerInvoiceId = () => this.customer_invoice?.id;
    getAgentInvoiceId = () => this.agent_invoice?.id;
    getTitle = () => this.title;
    getOrder = () => this.order;
    getProductSource = () => this.product_source;
    getIsHaveTracking = () => {
        const trackings = this.getPackages().flatMap((item) => {
            return item.trackings;
        });

        return trackings.length > 0;
    };

    getRejectReason() {
        return this.reject_reason;
    }

    getAddonServices = () =>
        new ShipmentProductAddonServiceCollectionModel(this.addon_services);
    getDestinationWarehouse = () => this.destination_warehouse;
    getDestinationCountry = () =>
        this.destination_country &&
        new RegionModel(this.destination_country as any);
    getRegion = () => new RegionModel(this.region);

    getCustomerAssociation = () => this.customer_association;
    getAgentAssociation = () => this.agent_association;
    getMoveonAssociation = () => this.moveon_association;
    getLink = (): string | null => this.link;
    getCurrency = () => new CurrencyModel(this.currency);
    getAgentInvoice = () => this.agent_invoice;
    getCustomerInvoice = () => this.customer_invoice;
    getDeliveryEnrollments = () => this.delivery_enrollments;

    getCustomerId(): number | undefined {
        return this.order.customer?.id;
    }
    getRegionCode(): string {
        return this.region?.code || "";
    }
    getRegionId = () => this.region.id;
    getRegionCurrencyCode(): string {
        return this.region?.currency?.code || "";
    }

    getShippingCategoryId(): string | undefined {
        return this.shipping_category ? this.shipping_category.id : undefined;
    }
    getDestinationWarehouseId(): number | undefined {
        return this.destination_warehouse
            ? this.destination_warehouse.id
            : undefined;
    }
    getDestinationWarehouseName(): string | undefined {
        return this.destination_warehouse
            ? this.destination_warehouse.name
            : "N/A";
    }
    getDestinationWarehouseFlag(): string | undefined {
        return this.destination_warehouse
            ? this.destination_warehouse.country.flag
            : "";
    }

    getDestinationCountryId(): number | undefined {
        return this.destination_country
            ? this.destination_country.id
            : undefined;
    }
    getShippingCountryId(): number | undefined {
        return this.shipping_country ? this.shipping_country.id : undefined;
    }

    getShippingCountry() {
        return (
            this.shipping_country &&
            new ShippingCountryModel(this.shipping_country)
        );
    }
    getShippingCountryName() {
        return this.shipping_country
            ? this.shipping_country.country?.name
            : "N/A";
    }

    getShippingCountryFlag() {
        return this.shipping_country ? this.shipping_country.country?.flag : "";
    }

    getBids = () => this.bids;
    getBuyProduct = () => this.buy_product;

    getId(): number {
        return this.id;
    }

    getProductNumber(): string {
        return this.product_number;
    }

    getImages() {
        return this.images.data;
    }
    getFirstImageSrc() {
        return this.images.data && this.images.data.length > 0
            ? this.images.data[0].src
            : "";
    }

    getShippingCategory() {
        return this.shipping_category;
    }

    getShippingAddress(): IShippingAddress {
        return this.shipping_address;
    }

    getShipmentInstruction(): string | null {
        return this.shipment_instruction;
    }

    getShippingMode(): ShippingModesEnums {
        return this.shipping_mode;
    }

    getShippingType(): ShippingTypesEnums {
        return this.shipping_type;
    }

    getStatus(): string {
        return this.status;
    }

    getStatusLabel(): string {
        return this.status_label;
    }

    getTotalQuantity(): number {
        return this.total_quantity;
    }

    getTotalWeight(): number {
        return this.total_weight;
    }

    getPackages(): ShipmentProductPackageModel[] {
        return this.packages ?? [];
    }

    getTrackingsCount(): number {
        const trackings =
            this.packages &&
            this.getPackages().flatMap((item) => item.trackings);
        return trackings && trackings.length > 0 ? trackings.length : 0;
    }

    getCreatedAt(): string {
        return this.created_at;
    }

    getById() {
        return this.id;
    }

    getShippingInstrucation() {
        return this.shipment_instruction;
    }

    getFullName() {
        return `${this.shipping_address.address.first_name}  ${this.shipping_address.address.last_name}`;
    }

    getCharges(): BuyProductChargeModel[] {
        return this.charges
            ? this.charges.data.map((c) => new BuyProductChargeModel(c))
            : [];
    }

    getAmountByUnitType = (type: "customer" | "agent") => {
        const customerAssociation = this.getCustomerAssociation();
        const agentAssociation = this.getAgentAssociation();

        if (type === "customer" && customerAssociation) {
            const unitType = customerAssociation.getUnitType();
            if (unitType === UnitTypeEnum.KG)
                return customerAssociation.getWeight() || 0;
            if (unitType === UnitTypeEnum.CBM)
                return customerAssociation.getCbm() || 0;
            if (unitType === UnitTypeEnum.PIECE)
                return customerAssociation.getPiece() || 0;
        } else if (type === "agent" && agentAssociation) {
            const unitType = agentAssociation.getUnitType();
            if (unitType === UnitTypeEnum.KG)
                return agentAssociation.getWeight() || 0;
            if (unitType === UnitTypeEnum.CBM)
                return agentAssociation.getCbm() || 0;
            if (unitType === UnitTypeEnum.PIECE)
                return agentAssociation.getPiece() || 0;
        }

        return 0;
    };

    getCalculatedRate = (type: "customer" | "agent") => {
        const amount = this.getAmountByUnitType(type);
        const customerAssociation = this.getCustomerAssociation();
        const agentAssociation = this.getAgentAssociation();

        if (type === "customer" && customerAssociation) {
            const matchedSlot = customerAssociation
                .getAssociationSlots()
                .find(
                    (slot) =>
                        slot.min_amount <= amount && slot.max_amount >= amount,
                );
            return matchedSlot
                ? roundToTwoDecimal(matchedSlot.rate)
                : roundToTwoDecimal(customerAssociation.getRate());
        } else if (type === "agent" && agentAssociation) {
            const matchedSlot = agentAssociation
                .getAssociationSlots()
                .find(
                    (slot) =>
                        slot.min_amount <= amount && slot.max_amount >= amount,
                );
            return matchedSlot
                ? roundToTwoDecimal(matchedSlot.rate)
                : roundToTwoDecimal(agentAssociation.getRate());
        }

        return 0;
    };
}

export class ShipmentProductMoveOnAssociationCommissionRateModel {
    object: string;
    id: number;
    shipping_type: string;
    shipping_mode: string;
    unit_type: string;
    rate: number;
    rate_type: string;
    max_cap: number | null;

    constructor(data: IShipmentProductMoveOnAssociationCommissionRate) {
        this.object = data.object;
        this.id = data.id;
        this.shipping_type = data.shipping_type;
        this.shipping_mode = data.shipping_mode;
        this.unit_type = data.unit_type;
        this.rate = data.rate;
        this.rate_type = data.rate_type;
        this.max_cap = data.max_cap;
    }

    getObject = () => this.object;
    getId = () => this.id;
    getShippingType = () => this.shipping_type;
    getShippingMode = () => this.shipping_mode;
    getUnitType = () => this.unit_type;
    getRate = () => this.rate;
    getRateType = () => this.rate_type;
    getMaxCap = () => this.max_cap;
}

export class ShipmentProductMoveOnAssociationModel {
    object: string;
    id: number;
    rate: number;
    rate_type: RateTypeEnums;
    max_cap: number | null;
    commission_rate: ShipmentProductMoveOnAssociationCommissionRateModel | null;

    constructor(data: IShipmentProductMoveOnAssociation) {
        this.object = data.object;
        this.id = data.id;
        this.rate = data.rate;
        this.rate_type = data.rate_type;
        this.max_cap = data.max_cap;
        this.commission_rate = data.commission_rate
            ? new ShipmentProductMoveOnAssociationCommissionRateModel(
                  data.commission_rate,
              )
            : null;
    }

    getObject = () => this.object;
    getId = () => this.id;
    getRate = () => this.rate;
    getRateType = () => this.rate_type;
    getMaxCap = () => this.max_cap;
    getCommissionRate = () => this.commission_rate;
}

export class ShipmentProductCollectionModel {
    object: string;
    data: ShipmentProductModel[];
    pagination: PaginationModel;
    filters: IFilterType | undefined;

    constructor(data: IShipmentProductCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => new ShipmentProductModel(item));
        this.pagination = new PaginationModel(data.pagination);
        this.filters = data.filters;
    }

    getData = () => this.data;
    getAllProducts = () => this.data;
}
