export enum TransactionsStatusEnums {
    PENDING = "pending",
    APPROVED = "approved",
    REJECTED = "rejected",
    REVERTED = "reverted",
}

export enum TransactionsStatusEnumsForAutomatic {
    PENDING = "pending",
    REQUIRE_MORE = "requires_more",
    AUTHORIZED = "authorized",
    FAILED = "failed",
    CANCELLED = "cancelled",
    REVERTED = "reverted",
}

export enum TransactionsRejectEnums {
    Invaild_Transaction = "Invaild Transaction",
    Duplicate_Transaction = "Duplicate Transaction",
    Invalid_Amount = "Invalid Amount",
    Technical_Error = "Technical Error",
    MerchantNotFound = "Merchant Not Found",
    others = "others",
}

export const TransactionStatus = {
    Pending: {
        name: "pending",
        color: "warning",
    },
    Approved: {
        name: "approved",
        color: "#00af91",
    },
    Rejected: {
        name: "rejected",
        color: "#e40017",
    },
    Reverted: {
        name: "reverted",
        color: "#4D4C7D",
    },
};
