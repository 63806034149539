import { useAtom } from "jotai";
import { harvestJobCollectionAtom } from "@/lib/core-react/store/store";
import { useService } from "@/lib/core-react/contexts";
import { getError } from "../utils/errors";
import { useEffect, useState } from "react";
import { IHarvestJobCreate } from "@/types/harvestBatchCollection";
import { notification } from "antd";

export const useHarvestJobList = () => {
    const [harvestJob, setHarvestJob] = useAtom(harvestJobCollectionAtom);

    const { harvestJobService } = useService();

    const getHarvestJobList = async (params?: string): Promise<void> => {
        try {
            const response =
                await harvestJobService.harvestJobResource.get(params);
            setHarvestJob({
                ...harvestJob,
                isLoading: false,
                data: response,
                refetch: false,
            });
        } catch (error: any) {
            setHarvestJob({
                ...harvestJob,
                isLoading: false,
                refetch: false,
                error: getError(error),
                code: error.response.status,
                unAuthorized: error.response.status === 403,
            });
            throw error;
        }
    };
    useEffect(() => {
        const intervalId = setInterval(() => {
            getHarvestJobList();
        }, 10000);

        return () => clearInterval(intervalId);
    }, []);

    return { getHarvestJobList } as const;
};

export const useHarvestJobCreate = () => {
    const { harvestJobService } = useService();
    const [createDetails, setCreateDetails] = useAtom(harvestJobCollectionAtom);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string | null>(null);

    const createHarvest = async (
        payload: IHarvestJobCreate<object, object>,
    ) => {
        setIsLoading(true);
        try {
            const response =
                await harvestJobService.harvestJobResource.create(payload);

            notification.success({ message: "Create Successfully" });
            setIsLoading(false);
            setCreateDetails({
                ...createDetails,
                refetch: true,
            });
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            notification.error({ message: getError(error) });

            throw error;
        }
    };

    return { createHarvest, isLoading, isError };
};
