import React, { CSSProperties } from "react";
import { Table, Typography, TablePaginationConfig } from "antd";
import { tw } from "@/consts/theme/tailwindTheme";
import { ColumnsType } from "antd/es/table";
import { UnlistedPackageModel } from "@/models/unlistedPackageCollectionModel";
import UnlistedPackageInfo from "./components/UnlistedPackageInfo";
import ShippingInfo from "./components/ShippingInfo";

interface IProps {
    data: UnlistedPackageModel[] | undefined;
    paginationConfig?: false | TablePaginationConfig;
    isLoading?: boolean;
    isOrderPage: boolean;
    selectedRowKeys: number[];
    setSelectedRowKeys: React.Dispatch<React.SetStateAction<number[]>>;
    selectedRows: UnlistedPackageModel[];
    setSelectedRows: React.Dispatch<
        React.SetStateAction<UnlistedPackageModel[]>
    >;
    onClearBulkSelectedState: () => void;
}
const { Text } = Typography;

const UnlistedPackagesTable = ({
    data,
    isLoading,
    paginationConfig,
    isOrderPage,
    selectedRowKeys,
    setSelectedRowKeys,
    setSelectedRows,
}: IProps) => {
    const onHeaderCellStyle: CSSProperties = {
        backgroundColor: isOrderPage ? "white" : "",
    };
    const columns: ColumnsType<UnlistedPackageModel> = [
        {
            title: "Product Info",
            dataIndex: "product-details",
            key: "product-details",
            width: 400,
            onHeaderCell: () => {
                return {
                    style: onHeaderCellStyle,
                };
            },
            onCell: () => ({
                style: { verticalAlign: "top", alignItems: "center" },
            }),
            render: (_: string, unlistedProduct) => (
                <>
                    <UnlistedPackageInfo
                        handleModal={() => {}}
                        unlistedPackageModel={unlistedProduct}
                    />
                </>
            ),
        },
        {
            title: "Shipping Info",
            key: "shipping-info",
            width: 400,
            onHeaderCell: () => ({
                style: onHeaderCellStyle,
            }),
            onCell: () => ({
                style: { verticalAlign: "top", alignItems: "center" },
            }),
            render: (_: string, unlistedProduct) => (
                <>
                    <ShippingInfo
                        handleModal={() => {}}
                        unlistedPackageModel={unlistedProduct}
                    />
                </>
            ),
        },
        {
            title: "Tracking Info",
            dataIndex: "tracking-info",
            key: "tracking-info",
            width: 350,
            onHeaderCell: () => ({
                style: onHeaderCellStyle,
            }),
            onCell: () => ({ style: { verticalAlign: "top" } }),
            render: (_: string, unlistedProduct) => {
                const tracking = unlistedProduct.getTrackings().getTracking();
                return (
                    <>
                        <Typography.Text>
                            <Text>Tracking: </Text>
                            <Text strong copyable>
                                {tracking}
                            </Text>
                        </Typography.Text>
                    </>
                );
            },
        },
    ];

    return (
        <div>
            <div>
                <Table
                    loading={isLoading}
                    dataSource={data}
                    columns={columns}
                    rowHoverable={false}
                    rowKey={(r) => r.id}
                    scroll={{ x: 1600 }} // Changed to accommodate total column widths
                    style={{
                        backgroundColor: "white",
                        boxShadow: tw.boxShadow.md,
                        marginTop: 24,
                        width: "100%", // Ensure table takes full container width
                    }}
                    bordered
                    pagination={isOrderPage ? false : paginationConfig}
                    rowSelection={
                        isOrderPage
                            ? undefined
                            : {
                                  selectedRowKeys: selectedRowKeys,
                                  type: "checkbox",
                                  onChange: (selectedRowKeys, selectedRows) => {
                                      const productIds =
                                          selectedRowKeys.map(Number);
                                      setSelectedRowKeys(productIds);
                                      setSelectedRows(selectedRows);
                                  },
                              }
                    }
                />
            </div>
        </div>
    );
};

export default UnlistedPackagesTable;
