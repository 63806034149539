import React, { useState } from "react";
import { Button, Space, Table, Tag, Tooltip, Modal, Input, Form } from "antd";
import type { TableColumnsType } from "antd";
import { DisputeMandatesModel } from "@/models/disputeDetailsCollectionModel";
import { DisputeMandateStatusEnum } from "@/enums/disputeCollectionEnum";

interface MandatesTableProps {
    mandates: DisputeMandatesModel[];
    handleAgreeMandate: (mandateId: number, total_agreed: number) => void;
    isLoading?: boolean;
    currency: string;
}

const MandatesTable: React.FC<MandatesTableProps> = ({
    mandates,
    handleAgreeMandate,
    isLoading,
    currency,
}) => {
    // State for modal and selected mandate
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedMandate, setSelectedMandate] =
        useState<DisputeMandatesModel | null>(null);
    const [form] = Form.useForm();

    // Handle modal submit
    const handleModalSubmit = () => {
        form.validateFields().then((values) => {
            if (selectedMandate) {
                handleAgreeMandate(
                    selectedMandate._id,
                    Number(values.agreeAmount),
                );
                setModalOpen(false);
                form.resetFields();
            }
        });
    };

    // Handle modal cancel
    const handleModalCancel = () => {
        setModalOpen(false);
        form.resetFields();
    };

    const columns: TableColumnsType<DisputeMandatesModel> = [
        {
            title: "Mandate To",
            dataIndex: "mandate_to",
            key: "_id",
            width: 100,
            render: (_tags: any, record) => {
                return (
                    <Space style={{ fontWeight: 700 }}>
                        {record?.getMandateTo()}
                    </Space>
                );
            },
        },

        {
            title: "status",
            dataIndex: "status",
            key: "_id",
            width: 200,
            render: (_tags: any, record) => {
                return (
                    <Space>
                        <Tag
                            color={
                                record?.getStatus() ===
                                DisputeMandateStatusEnum.AGREED
                                    ? "success"
                                    : "warning"
                            }
                        >
                            {record.getStatus()}
                        </Tag>
                    </Space>
                );
            },
        },
        {
            title: "Agent Company",
            dataIndex: "agent_company",
            key: "_id",
            width: 200,
            render: (_tags: any, record) => {
                return (
                    <Space>
                        {record?.getAgentCompany()?.getPrimaryName()
                            ? record?.getAgentCompany()?.getPrimaryName()
                            : "N/A"}
                    </Space>
                );
            },
        },
        {
            title: "Total Assigned",
            dataIndex: "total_assigned",
            key: "_id",
            width: 200,
            render: (_tags: any, record) => {
                return (
                    <Space>
                        {record?.getTotalAssigned()?.toFixed(2) || "N/A"}
                        {currency}
                    </Space>
                );
            },
        },
        {
            title: "Customer Total Cost",
            dataIndex: "customer_total_cost",
            key: "_id",
            width: 200,
            render: (_tags: any, record) => {
                return (
                    <Space>
                        {record?.getCustomerTotalCost()?.toFixed(2) || "N/A"}
                        {currency}
                    </Space>
                );
            },
        },
        {
            title: "Moveon Total Profit",
            dataIndex: "moveon_total_profit",
            key: "_id",
            width: 200,
            render: (_tags: any, record) => {
                return (
                    <Space>
                        {record?.getMoveonTotalProfit()?.toFixed(2) || "N/A"}
                        {currency}
                    </Space>
                );
            },
        },
        {
            title: "Moveon Profit Percentage",
            dataIndex: "moveon_profit_percentage",
            key: "_id",
            width: 200,
            render: (_tags: any, record) => {
                return (
                    <Space>
                        {record?.getMoveonProfitPercentage()?.toFixed(2) +
                            "%" || "N/A"}
                        {currency}
                    </Space>
                );
            },
        },

        {
            title: "Action",
            dataIndex: "action",
            key: "_id",
            width: 100,
            fixed: "right",
            render: (_tags: any, record) => {
                const canAgree = record?.getUiActions()?.can_agree;
                return (
                    <Tooltip
                        title={!canAgree ? "You can't agree this mandate" : ""}
                    >
                        <Button
                            disabled={!canAgree}
                            loading={isLoading}
                            type={canAgree ? "primary" : "default"}
                            size="middle"
                            style={{ marginRight: 10 }}
                            onClick={() => {
                                setSelectedMandate(record);
                                setModalOpen(true);
                            }}
                        >
                            Agree
                        </Button>
                    </Tooltip>
                );
            },
        },
    ];

    return (
        <>
            <p
                style={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    padding: 0,
                    marginTop: 0,
                    marginLeft: 0,
                    marginRight: 0,
                    marginBottom: 20,
                }}
            >
                Mandates
            </p>
            <Table
                style={{ maxHeight: 280, overflow: "auto", marginTop: 0 }}
                scroll={{ x: "100%" }}
                rowKey="_id"
                bordered={true}
                columns={columns}
                dataSource={mandates}
                pagination={false}
                loading={false}
            />

            {/* Agreement Amount Modal */}
            <Modal
                title="Enter Agreement Amount"
                open={modalOpen}
                onOk={handleModalSubmit}
                onCancel={handleModalCancel}
                okText="Submit"
                confirmLoading={isLoading}
            >
                <Form form={form} layout="vertical">
                    <Form.Item label="Agreement Amount" name="agreeAmount">
                        <Input
                            type="number"
                            placeholder="Enter amount"
                            addonAfter={currency}
                            step="0.01"
                        />
                    </Form.Item>
                    {selectedMandate && (
                        <div>
                            <p>Mandate Details:</p>
                            <p>
                                Total Assigned:{" "}
                                {selectedMandate.getTotalAssigned()?.toFixed(2)}{" "}
                                {currency}
                            </p>
                            <p>
                                Customer Total Cost:{" "}
                                {selectedMandate
                                    .getCustomerTotalCost()
                                    ?.toFixed(2)}{" "}
                                {currency}
                            </p>
                        </div>
                    )}
                </Form>
            </Modal>
        </>
    );
};

export default MandatesTable;
