import React, { memo, useState } from "react";
import { Alert, Button, Col, Flex, Popover, Row, Tag, Typography } from "antd";
import { IModalActionsType, IModalData } from "@/helpers/getModalTital";
import ShippingText from "../../../components/ShippingText";
import { ShipmentProductModel } from "@/models/shipmentProductCollectionModel";
import ShippingSlotsTable from "@/pages/BuyAndShip/components/ShippingRateTable";
import { ShippingInfoChangeModals } from "./ShippingInfoChangeModals";
import { ADMIN_SHIPPING_PERMISSION_ENUM } from "@/consts/permission-enum/admin-shipping-enum";
import { EditOutlined } from "@ant-design/icons";
import { agentRateChangeAllowedStatuses } from "@/pages/Shipping/ShipmentProducts/ShipmentProductsTable.tsx";
import { RateTypeEnums } from "@/enums/shippingCoreEnums";

interface Props {
    shipmentProductModel: ShipmentProductModel | undefined;
    isDetailPage: boolean;
    handleModal?: (payload: IModalData) => void;
}

const ShipmentProductShippingInfo: React.FC<Props> = ({
    shipmentProductModel,
    isDetailPage = true,
    handleModal: handleActionModal,
}) => {
    const [modalOpenType, setModalOpenType] =
        useState<IModalActionsType>(false);
    const handleModal = async (payload: IModalData) => {
        setModalOpenType(payload.action);
    };

    const onCancel = () => {
        handleModal({
            action: false,
            data: null,
        });
    };

    if (!shipmentProductModel) {
        return <Alert message="Shipment product not found" />;
    }

    return (
        <>
            <Flex vertical gap={8}>
                <div>
                    <ShippingText
                        isDetailPage={isDetailPage}
                        label="Shipping Category"
                        value={shipmentProductModel
                            .getShippingCategory()
                            ?.getName()}
                        onActionClick={() => {
                            handleModal({
                                action: "change_shipping_category",
                                data: shipmentProductModel,
                            });
                        }}
                        isEditable={!shipmentProductModel.getBuyProduct()}
                        permissionCheck={
                            ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_CHANGE_SHIPPING_CATEGORY
                        }
                    />

                    <ShippingText
                        label="Type"
                        value={shipmentProductModel.getShippingType()}
                        onActionClick={() => {
                            handleModal({
                                action: "change_shipping_type",
                                data: shipmentProductModel,
                            });
                        }}
                        isEditable={!shipmentProductModel.getBuyProduct()}
                        permissionCheck={
                            ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_CHANGE_SHIPPING_TYPE
                        }
                    />

                    <ShippingText
                        permissionCheck={[
                            ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_CHANGE_SHIPPING_MODE,
                        ]}
                        label="Mode"
                        value={shipmentProductModel.getShippingMode()}
                        onActionClick={() => {
                            handleModal({
                                action: "change_shipping_mode",
                                data: shipmentProductModel,
                            });
                        }}
                        isEditable={!shipmentProductModel.getBuyProduct()}
                    />

                    <ShippingText
                        label="Source"
                        value={shipmentProductModel.getShippingCountryName()}
                        flagUrl={shipmentProductModel.getShippingCountryFlag()}
                        onActionClick={() => {
                            handleModal({
                                action: "change_shipping_country",
                                data: shipmentProductModel,
                            });
                        }}
                        isEditable={!shipmentProductModel.getBuyProduct()}
                        permissionCheck={[
                            ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_CHANGE_SHIPPING_COUNTRY,
                        ]}
                    />
                    <ShippingText
                        label="Destination"
                        value={shipmentProductModel.getDestinationWarehouseName()}
                        flagUrl={shipmentProductModel.getDestinationWarehouseFlag()}
                        onActionClick={() => {
                            handleModal({
                                action: "change_destination_warehouse",
                                data: shipmentProductModel,
                            });
                        }}
                        isEditable={!shipmentProductModel.getBuyProduct()}
                        permissionCheck={[
                            ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_CHANGE_DESTINATION_WAREHOUSE,
                        ]}
                    />
                </div>
                {/* -------------------------Shipping Provider---------------------- */}

                <Flex gap={4} vertical>
                    <Typography.Text strong>
                        Shipping Provider :
                    </Typography.Text>

                    <Flex vertical gap={8}>
                        <ShippingText
                            label="Company"
                            value={
                                shipmentProductModel
                                    ?.getCustomerAssociation()
                                    ?.getAgentCompany()
                                    .getPrimaryName() || "N/A"
                            }
                        />
                        <ShippingText
                            label="Warehouse"
                            value={
                                shipmentProductModel
                                    ?.getCustomerAssociation()
                                    ?.getWarehouse()
                                    .getName() || "N/A"
                            }
                        />

                        <ShippingText
                            label="Warehouse Country"
                            value={shipmentProductModel
                                ?.getCustomerAssociation()
                                ?.getWarehouseAddress()
                                .getWarehouseCountry()}
                        />

                        <ShippingText
                            label="Warehouse location"
                            value={shipmentProductModel
                                ?.getCustomerAssociation()
                                ?.getWarehouseAddress()
                                .getWarehouseLocation()}
                        />
                    </Flex>
                </Flex>

                <ShippingText
                    label="Moveon Commission"
                    value={
                        shipmentProductModel.getMoveonAssociation()
                            ? `${shipmentProductModel
                                  .getMoveonAssociation()
                                  ?.getRate()}${
                                  shipmentProductModel
                                      .getMoveonAssociation()
                                      ?.getRateType() ===
                                  RateTypeEnums.PERCENTAGE
                                      ? "%"
                                      : ""
                              }`
                            : "N/A"
                    }
                />

                <Row gutter={12}>
                    <Col span={isDetailPage ? 24 : 12}>
                        {/* -------------------------Customer Rate---------------------- */}
                        <div>
                            <Flex align={"center"} flex={"1"}>
                                <Typography.Paragraph strong>
                                    Customer Rate
                                </Typography.Paragraph>
                                {agentRateChangeAllowedStatuses.includes(
                                    shipmentProductModel.getStatus(),
                                ) ? (
                                    <Button
                                        type="link"
                                        onClick={() => {
                                            handleActionModal &&
                                                handleActionModal({
                                                    action: "shipment_product_change_customer_rate",
                                                    data: shipmentProductModel,
                                                });
                                        }}
                                    >
                                        <EditOutlined />
                                    </Button>
                                ) : null}
                            </Flex>
                            {shipmentProductModel.getCustomerAssociation() ? (
                                <div style={{ margin: 0 }}>
                                    <ShippingText label="Rate">
                                        {/* Only show when it has actual value */}
                                        {shipmentProductModel.getCalculatedRate(
                                            "customer",
                                        ) ? (
                                            <Tag color="pink">
                                                {`${shipmentProductModel.getCalculatedRate("customer")} ${shipmentProductModel.getRegionCurrencyCode()} / ${shipmentProductModel.getCustomerAssociation()?.getUnitType()}`}
                                            </Tag>
                                        ) : (
                                            ""
                                        )}
                                    </ShippingText>
                                </div>
                            ) : (
                                <Typography.Paragraph>
                                    Rate : N/A
                                </Typography.Paragraph>
                            )}

                            {/* -------------------------List page ---------------------- */}
                            {shipmentProductModel
                                .getCustomerAssociation()
                                ?.getAssociationSlots().length ? (
                                <>
                                    {isDetailPage ? (
                                        <ShippingSlotsTable
                                            slots={shipmentProductModel
                                                .getCustomerAssociation()
                                                ?.getAssociationSlots()}
                                            regionCurrencyCode={shipmentProductModel.getRegionCurrencyCode()}
                                            unitType={shipmentProductModel
                                                .getCustomerAssociation()
                                                ?.getUnitType()}
                                        />
                                    ) : (
                                        <Popover
                                            trigger={["click"]}
                                            content={
                                                <>
                                                    <ShippingSlotsTable
                                                        slots={shipmentProductModel
                                                            .getCustomerAssociation()
                                                            ?.getAssociationSlots()}
                                                        regionCurrencyCode={
                                                            shipmentProductModel.getRegionCurrencyCode() ||
                                                            ""
                                                        }
                                                        unitType={shipmentProductModel
                                                            .getCustomerAssociation()
                                                            ?.getUnitType()}
                                                    />
                                                </>
                                            }
                                        >
                                            <Button
                                                type="link"
                                                style={{
                                                    padding: 0,
                                                }}
                                            >
                                                Slots
                                            </Button>
                                        </Popover>
                                    )}
                                </>
                            ) : (
                                <>
                                    <Button
                                        style={{
                                            padding: 0,
                                        }}
                                        type="link"
                                    >
                                        Slots :
                                        <Typography.Text>N/A</Typography.Text>
                                    </Button>
                                </>
                            )}
                        </div>
                    </Col>

                    {/* -------------------------Agent Rate---------------------- */}
                    <Col span={isDetailPage ? 24 : 12}>
                        <div style={{ marginTop: isDetailPage ? 12 : 0 }}>
                            <Flex align={"center"} flex={"1"}>
                                <Typography.Paragraph strong>
                                    Agent Rate
                                </Typography.Paragraph>
                                {agentRateChangeAllowedStatuses.includes(
                                    shipmentProductModel.getStatus(),
                                ) ? (
                                    <Button
                                        type="link"
                                        onClick={() => {
                                            handleActionModal &&
                                                handleActionModal({
                                                    action: "shipment_product_change_rate",
                                                    data: shipmentProductModel,
                                                });
                                        }}
                                    >
                                        <EditOutlined />
                                    </Button>
                                ) : null}
                            </Flex>

                            {shipmentProductModel.getAgentAssociation() ? (
                                <div style={{ margin: 0 }}>
                                    <ShippingText label="Rate">
                                        {/* Only show when it has actual value */}
                                        {shipmentProductModel.getCalculatedRate(
                                            "agent",
                                        ) ? (
                                            <Tag color="pink">
                                                {`${shipmentProductModel.getCalculatedRate("agent")} ${shipmentProductModel.getRegionCurrencyCode()} / ${shipmentProductModel.getAgentAssociation()?.getUnitType()}`}
                                            </Tag>
                                        ) : (
                                            ""
                                        )}
                                    </ShippingText>
                                </div>
                            ) : (
                                <Typography.Paragraph>
                                    Rate : N/A
                                </Typography.Paragraph>
                            )}
                            {/* -------------------------List page ---------------------- */}

                            {shipmentProductModel
                                .getAgentAssociation()
                                ?.getAssociationSlots().length ? (
                                <>
                                    {isDetailPage ? (
                                        <ShippingSlotsTable
                                            slots={shipmentProductModel
                                                .getAgentAssociation()
                                                ?.getAssociationSlots()}
                                            regionCurrencyCode={
                                                shipmentProductModel.getRegionCurrencyCode() ||
                                                ""
                                            }
                                            unitType={shipmentProductModel
                                                .getAgentAssociation()
                                                ?.getUnitType()}
                                        />
                                    ) : (
                                        <Popover
                                            trigger={["click"]}
                                            content={
                                                <>
                                                    <ShippingSlotsTable
                                                        slots={shipmentProductModel
                                                            .getAgentAssociation()
                                                            ?.getAssociationSlots()}
                                                        regionCurrencyCode={
                                                            shipmentProductModel.getRegionCurrencyCode() ||
                                                            ""
                                                        }
                                                        unitType={shipmentProductModel
                                                            .getAgentAssociation()
                                                            ?.getUnitType()}
                                                    />
                                                </>
                                            }
                                        >
                                            <Button
                                                type="link"
                                                style={{
                                                    padding: 0,
                                                }}
                                            >
                                                Slots
                                            </Button>
                                        </Popover>
                                    )}
                                </>
                            ) : (
                                <>
                                    <Button
                                        style={{
                                            padding: 0,
                                        }}
                                        type="link"
                                    >
                                        Slots :
                                        <Typography.Text>N/A</Typography.Text>
                                    </Button>
                                </>
                            )}
                        </div>
                    </Col>
                </Row>
            </Flex>

            <ShippingInfoChangeModals
                modalOpenType={modalOpenType}
                onCancel={onCancel}
                selectedProduct={shipmentProductModel}
                selectedProductIds={[shipmentProductModel.getId()]}
            />
        </>
    );
};

export default memo(ShipmentProductShippingInfo);
