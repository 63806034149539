import {
    Alert,
    Button,
    Flex,
    Modal,
    Space,
    Spin,
    Tag,
    Tooltip,
    Typography,
} from "antd";
import { useParams } from "react-router-dom";
import {
    EditOutlined,
    ControlOutlined,
    EyeOutlined,
    CustomerServiceOutlined,
    DownloadOutlined,
    LoadingOutlined,
    SyncOutlined,
} from "@ant-design/icons";
import { StatusTag } from "../../../components";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ADMIN_PURCHASE_PERMISSION_ENUM } from "@/consts/permission-enum/purchase-enum";
import Paragraph from "antd/es/typography/Paragraph";
import { BuyProductModel } from "@/models/buyProductCollectionModel";
import {
    getModalTitle,
    IModalActionsType,
    IModalData,
} from "@/helpers/getModalTital";
import ImageWithPlaceholder from "@/components/ImageWithPlaceholder";
import { roundToTwoDecimal } from "@/helpers/roundToTwoDecimal";
import { memo, Suspense, useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { tw } from "@/consts/theme/tailwindTheme";
import QuickView from "../BuyProducts/components/QuickView";
import ShippingText from "@/components/ShippingText";
import UpdateFxForm from "../BuyProducts/components/UpdateFxModal";
import useWindowWidth from "@/lib/core-react/hooks/public/useWindowWidth";

import { useInvoice } from "@/lib/core-react/hooks/private/useInvoice";
import { ADMIN_INVOICE_PERMISSION_ENUM } from "@/consts/permission-enum/invoice-enum";
import { useSyncBuyProducts } from "@/lib/core-react/hooks/private/usePurchase";
import useRefetch from "@/hooks/useRefetch";
import { showError } from "@/helpers/showError";
import { showSuccessAlert } from "@/helpers/showSuccess";
import { BUY_PRODUCT_DETAIL_PAGE } from "@/consts/pageRoute";
import getPagePathname from "@/helpers/getPagePathname";

interface Props {
    buyProduct: BuyProductModel | undefined;
    isDetailPage?: boolean;
}

const BuyProductInfo = ({ buyProduct }: Props) => {
    const { handledownloadData } = useInvoice();
    const { syncProducts, isLoading: isLoadingSync } = useSyncBuyProducts();
    const { refetchListApi, refetchDetailApi } = useRefetch();
    const [isLoading, setIsLoading] = useState(false);
    const { id } = useParams();

    const { Text } = Typography;

    const [modalOpenType, setModalOpenType] =
        useState<IModalActionsType>(false);

    const invoiceId = buyProduct?.getCustomerInvoice()?.getId();

    const { isMobile } = useWindowWidth();

    const handleModal = useCallback(
        async (payload: IModalData) => {
            setModalOpenType(payload.action);
        },
        [modalOpenType],
    );

    const handleDownloadInvoice = async () => {
        setIsLoading(true); // Set loading to true
        try {
            const response = await handledownloadData(
                Number(invoiceId),
                "customer",
            );

            if (response?.data?.download_url) {
                const downloadUrl = response.data.download_url;

                const link = document.createElement("a");
                link.href = downloadUrl;
                link.download = "";
                link.target = "_blank";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                console.error("Download URL not found in response");
            }
        } catch (error) {
            console.error("Error downloading invoice:", error);
        } finally {
            setIsLoading(false); // Reset loading to false after operation
        }
    };

    const handleSync = async () => {
        try {
            if (!buyProduct) {
                throw new Error("Product Id Not found");
            }
            await syncProducts({
                ids: [buyProduct.getId()],
            });

            showSuccessAlert(
                `Successfully Sync ${buyProduct.getProductNumber()}`,
            );
            await refetchListApi();
            if (id) {
                await refetchDetailApi(id);
            }
        } catch (error) {
            showError(error);
        }
    };

    if (!buyProduct) {
        return <Alert message="Product info not found" />;
    }

    const transactionSummary = (
        <>
            <Space
                style={{ marginTop: 12 }}
                direction="vertical"
                size={"small"}
            >
                {buyProduct.getTotalAmount() > 0 && (
                    <ShippingText label="Total">
                        <StatusTag
                            color="cyan-inverse"
                            slug=""
                            text={`${roundToTwoDecimal(
                                buyProduct.getTotalAmount(),
                            )} ${buyProduct.getRegionCurrencyCode()}`}
                        />
                    </ShippingText>
                )}
                {buyProduct.getPaid() > 0 && (
                    <ShippingText label="Paid">
                        <StatusTag
                            slug="paid"
                            text={`${roundToTwoDecimal(
                                buyProduct.getPaid(),
                            )} ${buyProduct.getRegionCurrencyCode()}`}
                        />
                    </ShippingText>
                )}

                {buyProduct.getDue() > 0 && (
                    <ShippingText label="Due">
                        <StatusTag
                            slug="due"
                            text={`${roundToTwoDecimal(
                                buyProduct.getDue(),
                            )} ${buyProduct.getRegionCurrencyCode()}`}
                        />
                    </ShippingText>
                )}
                {buyProduct.getRefundableAmount() > 0 && (
                    <ShippingText label="Refund">
                        <StatusTag
                            slug=""
                            color="red"
                            text={`${roundToTwoDecimal(
                                buyProduct.getRefundableAmount(),
                            )} ${buyProduct.getRegionCurrencyCode()}`}
                        />
                    </ShippingText>
                )}
            </Space>
        </>
    );

    return (
        <>
            <Flex vertical gap={12}>
                <Flex gap={16}>
                    <div>
                        <ImageWithPlaceholder
                            style={{
                                width: 150,
                                height: 150,
                            }}
                            src={buyProduct.getProductImage()}
                        />
                        {transactionSummary}
                    </div>

                    <div>
                        <Space direction="vertical">
                            <Paragraph>
                                <Text>Product No: </Text>
                                <Text strong copyable>
                                    {buyProduct.getProductNumber()}
                                </Text>
                            </Paragraph>
                            {buyProduct.order?.order_number && (
                                <Paragraph>
                                    <Text>Order No: </Text>
                                    <Text strong copyable>
                                        {buyProduct.order?.order_number}
                                    </Text>
                                </Paragraph>
                            )}
                            {buyProduct.getRegion()?.getCode() && (
                                <Paragraph>
                                    <Text> Region: </Text>

                                    <Text>
                                        <StatusTag
                                            slug={`${buyProduct.getRegion()?.getCode()}`}
                                            text={`${buyProduct.getRegion()?.getCode()}`}
                                            minWidth={48}
                                        />
                                    </Text>
                                </Paragraph>
                            )}
                            <Paragraph>
                                <Text> Status: </Text>
                                <StatusTag
                                    slug={buyProduct.status}
                                    text={buyProduct.status}
                                />
                            </Paragraph>

                            {buyProduct?.getRejectReason() && (
                                <Paragraph>
                                    <Text>Reject Reason: </Text>

                                    <Tooltip
                                        title={buyProduct?.getRejectReason()}
                                        placement="top"
                                    >
                                        <Text
                                            style={{
                                                textTransform: "capitalize",
                                                color: "red",
                                            }}
                                        >
                                            {buyProduct?.getRejectReason()}
                                        </Text>
                                    </Tooltip>
                                </Paragraph>
                            )}

                            {buyProduct.getCancelReason() && (
                                <Paragraph>
                                    <Text>Cancel Reason: </Text>

                                    <Tooltip
                                        title={buyProduct.getCancelReason()}
                                        placement="top"
                                    >
                                        <Text type="danger">
                                            {buyProduct.getCancelReason()}
                                        </Text>
                                    </Tooltip>
                                </Paragraph>
                            )}

                            <Paragraph>
                                <Text> Store: </Text>
                                <StatusTag
                                    slug={`${buyProduct.getStore()?.getIdentifier()}`}
                                    text={`${buyProduct.getStoreName()}`}
                                />
                            </Paragraph>

                            {buyProduct.product_seller && (
                                <Flex align="center" gap={4}>
                                    <Text style={{ whiteSpace: "nowrap" }}>
                                        Seller:{" "}
                                    </Text>

                                    <Text
                                        ellipsis={{
                                            tooltip:
                                                buyProduct.product_seller
                                                    .seller_name,
                                        }}
                                        strong
                                        style={{ width: 200 }}
                                    >
                                        {buyProduct.product_seller.seller_name}
                                    </Text>
                                </Flex>
                            )}

                            <Paragraph>
                                <Text> FX: </Text>

                                {/* {buyProduct.getCustomerFx() && (
                                    <Tooltip placement="top">
                                        <Tag
                                            style={{
                                                textTransform: "capitalize",
                                            }}
                                            color={"#EF4444"}
                                        >
                                            {roundToTwoDecimal(
                                                buyProduct.getCustomerFx(),
                                            )}
                                        </Tag>
                                    </Tooltip>
                                )} */}

                                {buyProduct.getCustomerFx() && (
                                    <Tooltip placement="top" title={"Total Fx"}>
                                        <Tag color="red">
                                            {roundToTwoDecimal(
                                                buyProduct.getCustomerTotalPercentageCommissionRate(),
                                            )}
                                        </Tag>
                                    </Tooltip>
                                )}
                                {buyProduct.getCustomerFx() &&
                                    checkActionPermission(
                                        ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_FX_UPDATE,

                                        <Button
                                            type="link"
                                            onClick={() => {
                                                if (handleModal) {
                                                    handleModal({
                                                        action: "update_fx",
                                                        data: buyProduct,
                                                    });
                                                }
                                            }}
                                            icon={<EditOutlined />}
                                        />,
                                        null,
                                    )}
                            </Paragraph>
                            <Paragraph>
                                <Text> Paid Parentage: </Text>

                                <Tag
                                    style={{
                                        textTransform: "capitalize",
                                    }}
                                    color={"pink-inverse"}
                                >
                                    {roundToTwoDecimal(
                                        buyProduct.getPaidPercentage(),
                                    )}
                                </Tag>
                            </Paragraph>

                            {buyProduct.source_type ===
                                "MoveOn\\RFQ\\Models\\RfqQuote" && (
                                <Paragraph>
                                    <Text> Source Type: </Text>

                                    <Tag
                                        style={{
                                            textTransform: "capitalize",
                                        }}
                                        color={"green-inverse"}
                                    >
                                        Rfq Product
                                    </Tag>
                                </Paragraph>
                            )}
                            {buyProduct.source_type === "CustomOrder" && (
                                <Paragraph>
                                    <Text> Source Type: </Text>

                                    <Tag
                                        style={{
                                            textTransform: "capitalize",
                                        }}
                                        color={"orange-inverse"}
                                    >
                                        Custom Order
                                    </Tag>
                                </Paragraph>
                            )}
                        </Space>
                    </div>
                </Flex>

                <Space direction="vertical">
                    {buyProduct.getCustomerNote() && (
                        <Typography.Text
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: 4,
                            }}
                        >
                            <Text>Customer Note: </Text>
                            <Text
                                strong
                                style={{ maxWidth: 250 }}
                                ellipsis={{
                                    tooltip: buyProduct.getCustomerNote(),
                                }}
                            >
                                {buyProduct.getCustomerNote()}
                            </Text>
                        </Typography.Text>
                    )}
                    <Typography.Text>
                        <Text
                            strong
                            style={{ maxWidth: 350 }}
                            ellipsis={{ tooltip: buyProduct.getProductTitle() }}
                        >
                            <Link
                                style={{
                                    maxWidth: 280,
                                    paddingLeft: tw.spacing[1],
                                }}
                                target="_blank"
                                to={`${buyProduct.getProductLink() || ""}`}
                            >
                                {buyProduct.getProductTitle()}
                            </Link>
                        </Text>
                    </Typography.Text>

                    {buyProduct?.order && (
                        <>
                            <Paragraph
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 4,
                                }}
                            >
                                <Text>
                                    <CustomerServiceOutlined /> Customer Info:
                                </Text>
                                <Text strong copyable>
                                    {buyProduct.order.customer?.name}
                                </Text>
                                <Tooltip
                                    title={
                                        <div>
                                            Name :{" "}
                                            <Text
                                                style={{ color: "white" }}
                                                copyable
                                            >
                                                {
                                                    buyProduct.order.customer
                                                        ?.name
                                                }
                                            </Text>
                                            <br />
                                            Email :{" "}
                                            <Text
                                                style={{ color: "white" }}
                                                copyable
                                            >
                                                {
                                                    buyProduct.order.customer
                                                        ?.email
                                                }
                                            </Text>
                                            <br />
                                            Phone :{" "}
                                            <Text
                                                style={{ color: "white" }}
                                                copyable
                                            >
                                                {
                                                    buyProduct.order.customer
                                                        ?.phone
                                                }
                                            </Text>
                                        </div>
                                    }
                                >
                                    <EyeOutlined
                                        style={{
                                            marginLeft: 8,
                                            cursor: "pointer",
                                        }}
                                    />
                                </Tooltip>
                            </Paragraph>
                        </>
                    )}
                    {buyProduct?.order?.order_handler && (
                        <>
                            <Paragraph
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 4,
                                }}
                            >
                                <Text>
                                    <ControlOutlined /> OrderHandler:
                                </Text>
                                <Text strong copyable>
                                    {buyProduct.order.order_handler?.name}
                                </Text>
                                <Tooltip
                                    title={
                                        buyProduct.order.order_handler?.email
                                    }
                                >
                                    <EyeOutlined
                                        style={{
                                            marginLeft: 8,
                                            cursor: "pointer",
                                        }}
                                    />
                                </Tooltip>
                            </Paragraph>
                        </>
                    )}
                </Space>

                <Space size="large">
                    {typeof invoiceId === "number" &&
                        checkActionPermission(
                            ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_VIEW,
                            <Button
                                type="dashed"
                                onClick={handleDownloadInvoice}
                                icon={
                                    isLoading ? (
                                        <LoadingOutlined />
                                    ) : (
                                        <DownloadOutlined />
                                    )
                                }
                            >
                                Invoice
                            </Button>,
                            null,
                        )}

                    {/* Quick View Button (Conditional) */}
                    {getPagePathname() !==
                    `${BUY_PRODUCT_DETAIL_PAGE}/${id}` ? (
                        <>
                            <Button
                                style={{ padding: 0 }}
                                type="link"
                                onClick={() => {
                                    if (handleModal) {
                                        handleModal({
                                            action: "quick_view_buy_product",
                                            data: buyProduct,
                                        });
                                    }
                                }}
                            >
                                Quick view
                            </Button>

                            {/* View Details Button (Conditional) */}
                            {checkActionPermission(
                                ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VIEW,
                                <Link
                                    target="_blank"
                                    to={`/purchase/buy-products/detail/${buyProduct.getId()}`}
                                    style={{ padding: 0 }}
                                >
                                    View Details
                                </Link>,
                                null,
                            )}
                        </>
                    ) : (
                        <></>
                    )}

                    <Button
                        style={{ padding: 0 }}
                        type="link"
                        htmlType="button"
                        onClick={handleSync}
                    >
                        <SyncOutlined spin={isLoadingSync} />
                    </Button>
                </Space>
            </Flex>

            <Modal
                title={getModalTitle(modalOpenType)}
                open={modalOpenType === "quick_view_buy_product"}
                destroyOnClose={true}
                width={
                    isMobile
                        ? "90%"
                        : modalOpenType === "quick_view_buy_product" ||
                            !isMobile
                          ? "70%"
                          : "70%"
                }
                onCancel={() => {
                    handleModal({
                        action: false,
                        data: null,
                    });
                }}
                footer={false}
            >
                {modalOpenType === "quick_view_buy_product" && (
                    <Suspense fallback={<Spin />}>
                        <QuickView buyProduct={buyProduct} />
                    </Suspense>
                )}
            </Modal>
            <Modal
                title={getModalTitle(modalOpenType)}
                open={modalOpenType === "update_fx"}
                destroyOnClose={true}
                width={
                    isMobile
                        ? "90%"
                        : modalOpenType === "update_fx" || !isMobile
                          ? 600
                          : "70%"
                }
                onCancel={() => {
                    handleModal({
                        action: false,
                        data: null,
                    });
                }}
                footer={false}
            >
                {modalOpenType === "update_fx" && (
                    <Suspense fallback={<Spin />}>
                        <UpdateFxForm
                            handleModal={handleModal}
                            fxData={{
                                product_id: buyProduct.getId(),
                                fxRate: buyProduct.getCustomerFx(),
                                commission_rate:
                                    buyProduct.getCustomerCommissionRate(),
                                commission_type:
                                    buyProduct.getCustomerCommissionRateType(),
                                totalFxAndCommission:
                                    buyProduct.getCustomerTotalCommissionAndFx(),
                                currencyCode:
                                    buyProduct.getRegionCurrencyCode(),
                            }}
                        />
                    </Suspense>
                )}
            </Modal>
        </>
    );
};

export default memo(BuyProductInfo);
