import { useEffect, useState } from "react";
import { useAtom } from "jotai/index";
import { PageHeader } from "@ant-design/pro-layout";
import {
    Card,
    Col,
    Result,
    Button,
    Row,
    Flex,
    Dropdown,
    Popconfirm,
    Typography,
} from "antd";
import {
    AgreeMendedPayload,
    ApprovePurchasePayload,
    useAgreeMandate,
    useApproveDispute,
    useCompleteRefund,
    useDeleteDispute,
    useGetDispute,
    useGetDisputePreview,
} from "@/lib/core-react/hooks/private/useDispute";
import {
    disputeDetailsAtom,
    disputePreviewAtom,
} from "@/lib/core-react/store/store";
import {
    DisputeDetailsCollectionModel,
    DisputePreviewModel,
} from "@/models/disputeDetailsCollectionModel";
import { useParams } from "react-router-dom";
import MandatesTable from "./MandatesTable";
import { useGetBuyProduct } from "@/lib/core-react/hooks/private";
import { buyProductAtom } from "@/lib/core-react/store/buyProducts/buyProductsAtoms";
import { BuyProductModel } from "@/models/buyProductCollectionModel";
import CustomerInfo from "@/pages/BuyAndShip/BuyProducts/components/CustomerInfo";
import OrderInfo from "@/pages/BuyAndShip/components/OrderInfo";
import useWindowWidth from "@/lib/core-react/hooks/public/useWindowWidth";
import { BuyProductInfo } from "@/pages/BuyAndShip/components";
import { tw } from "@/consts/theme/tailwindTheme";
import BuyProductCharges from "@/pages/BuyAndShip/BuyProducts/components/BuyProductCharges";
import VariationsTable from "./VariationsTable";
import { showError } from "@/helpers/showError";
import {
    CheckCircleOutlined,
    DownOutlined,
    HddFilled,
} from "@ant-design/icons";
import { ExtendedMenuItemType } from "@/types";
import { ADMIN_REFUND_PERMISSION_ENUM } from "@/consts/permission-enum/refund-enum";
import { FiTrash } from "react-icons/fi";
import DisputeSettlementPreviewModal from "./DisputeSettlementPreviewModal";
const { Text } = Typography;

const RefundDisputeDetails = () => {
    const isMobile = useWindowWidth();
    const { id, buyProductId } = useParams();
    const parsedId: number = Number(id);
    const parsedBuyProductId: number = Number(buyProductId);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedDisputeId, setSelectedDisputeId] = useState<number>();

    const { getDispute } = useGetDispute();
    const { getDisputePreview } = useGetDisputePreview();
    const { completeRefund } = useCompleteRefund();
    const { deleteDispute } = useDeleteDispute();

    const [{ data: disputeDetailsData, isLoading, error }] =
        useAtom(disputeDetailsAtom);
    const [{ data: disputePreviewData }] = useAtom(disputePreviewAtom);
    const [{ data: buyProductDetailData, isLoading: buyProductLoading }] =
        useAtom(buyProductAtom);

    const uiActions = disputeDetailsData?.data?.ui_actions;

    const { getBuyProduct } = useGetBuyProduct();
    const { approveDispute } = useApproveDispute();
    const { agreeMandate, isLoading: isAgreeMandateLoading } =
        useAgreeMandate();

    const disputeDetailsCollectionModelData =
        disputeDetailsData &&
        new DisputeDetailsCollectionModel(disputeDetailsData);
    const buyProduct =
        buyProductDetailData && new BuyProductModel(buyProductDetailData);

    const mandates = disputeDetailsCollectionModelData
        ?.getData()
        ?.getMandates();
    const disputePreview =
        disputePreviewData && new DisputePreviewModel(disputePreviewData);

    const handleApprove = async (itemIds: number[]) => {
        try {
            const payload: ApprovePurchasePayload = {
                items: itemIds.map((id) => ({ id })),
            };
            await approveDispute(parsedId, payload);
        } catch (error) {
            showError(error);
        }
    };

    const handleAgreeMandate = async (
        mandateId: number,
        total_agreed: number,
    ) => {
        try {
            const payload: AgreeMendedPayload = {
                agreed_assigned_amount: total_agreed,
                mandate_id: mandateId,
            };
            await agreeMandate(parsedId, payload);
        } catch (error) {
            showError(error);
        }
    };

    // Update the handleCompleteRefund function
    const handleCompleteRefund = async (id: number) => {
        setSelectedDisputeId(id);
        setIsModalVisible(true);
    };

    const onConfirmCompleteRefund = async (id: number) => {
        try {
            await completeRefund(id);
        } catch (error) {
            showError(error);
        }
    };

    const handleDeleteDispute = async (id: number) => {
        try {
            await deleteDispute(id);
            await getDispute(parsedId);
        } catch (error) {
            showError(error);
        }
    };

    useEffect(() => {
        getDispute(parsedId);
        getBuyProduct(parsedBuyProductId);
        getDisputePreview(parsedId);
    }, [parsedId]);

    if (error) {
        return (
            <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                extra={
                    <Button
                        onClick={() => window.history.back()}
                        type="primary"
                    >
                        Back Home
                    </Button>
                }
            />
        );
    }

    const cardStyle = {
        borderRadius: tw.borderRadius.lg,
        boxShadow: tw.boxShadow.DEFAULT,
    };

    const menuItems: ExtendedMenuItemType[] = [
        ...(uiActions?.can_complete
            ? [
                  {
                      permission:
                          ADMIN_REFUND_PERMISSION_ENUM.ADMIN_DISPUTE_VIEW,
                      label: "Complete",
                      key: "complete",
                      icon: <CheckCircleOutlined />,
                      onClick: () => {
                          handleCompleteRefund(parsedId);
                      },
                  },
              ]
            : []),
        ...(uiActions?.can_cancel
            ? [
                  {
                      key: "cancel_refund",
                      permission:
                          ADMIN_REFUND_PERMISSION_ENUM.ADMIN_DISPUTE_VIEW,
                      label: (
                          <Popconfirm
                              title="Cancel this refund"
                              description="Are you sure to cancel?"
                              onConfirm={() => handleDeleteDispute(parsedId)}
                              onCancel={() => {}}
                              okText="Yes"
                              placement="topLeft"
                              cancelText="No"
                              style={{
                                  color: "red",
                                  display: "flex",
                                  alignItems: "center",
                              }}
                          >
                              <FiTrash style={{ color: "red" }} />{" "}
                              <Text type="danger">Cancel Refund</Text>
                          </Popconfirm>
                      ),
                  },
              ]
            : []),
    ];

    return (
        <Card loading={isLoading}>
            <PageHeader
                ghost={false}
                onBack={() => window.history.back()}
                title={"Refund Details"}
                style={{ marginTop: "10px" }}
                extra={[
                    <Dropdown
                        menu={{
                            items: menuItems.filter((item) => item !== null),
                        }}
                    >
                        <Button icon={<HddFilled />}>
                            {isMobile ? (
                                <DownOutlined />
                            ) : (
                                <>
                                    Actions <DownOutlined />{" "}
                                </>
                            )}
                        </Button>
                    </Dropdown>,
                ]}
            >
                {/* Buy product Start */}

                {buyProduct && !isLoading ? (
                    <>
                        <Flex
                            style={{ width: "100%" }}
                            justify="space-between"
                            gap={24}
                        >
                            <div
                                style={{
                                    flex: 1,
                                    marginRight: isMobile ? 0 : 24,
                                }}
                            >
                                <Flex gap={24} vertical>
                                    <Card
                                        title="Product Info"
                                        style={{ ...cardStyle, width: "100%" }}
                                    >
                                        <BuyProductInfo
                                            isDetailPage={true}
                                            buyProduct={buyProduct}
                                        />
                                    </Card>
                                </Flex>
                            </div>
                            <div style={{ flex: 1 }}>
                                <Flex gap={24} vertical>
                                    {buyProduct &&
                                        buyProduct
                                            ?.getOrder()
                                            ?.getCustomer() && (
                                            <Card
                                                title="Customer"
                                                style={{
                                                    ...cardStyle,
                                                    width: "100%",
                                                }}
                                            >
                                                <CustomerInfo
                                                    user={
                                                        buyProduct
                                                            ?.getOrder()
                                                            ?.getCustomer() as any
                                                    }
                                                />
                                            </Card>
                                        )}
                                    <Card
                                        title="Order Info"
                                        style={{ ...cardStyle, width: "100%" }}
                                    >
                                        <OrderInfo
                                            oderNumber={buyProduct
                                                ?.getOrder()
                                                ?.getOrderNumber()}
                                            oderStatus={buyProduct
                                                ?.getOrder()
                                                ?.getOrderStatus()}
                                            oderType={buyProduct
                                                ?.getOrder()
                                                ?.getOrderType()}
                                            orderCreatedAt={buyProduct
                                                ?.getOrder()
                                                ?.getCreatedAt()}
                                            paymentStatus={buyProduct
                                                ?.getOrder()
                                                ?.getPaymentStatus()}
                                        />
                                    </Card>
                                </Flex>
                            </div>
                        </Flex>
                    </>
                ) : (
                    <></>
                )}

                {/* Buy product End */}

                {/* Dispute details start */}
                {/* ei table ta te 2ta data er combination hocche 1. buy product and 2nd hocce dispute collection so buy product er  */}
                <div style={{ marginTop: 24, paddingBottom: "24px" }}>
                    {disputeDetailsCollectionModelData?.getData().getItems() &&
                        buyProduct &&
                        (() => {
                            let hasRenderedBuyProductTable = false;
                            return disputeDetailsCollectionModelData
                                ?.getData()
                                .getItems()
                                .map((item, index) => {
                                    if (
                                        item?.item?.getBuyProductVariation() &&
                                        !hasRenderedBuyProductTable
                                    ) {
                                        hasRenderedBuyProductTable = true;
                                        return (
                                            <>
                                                {disputePreview?.getData() ? (
                                                    <>
                                                        <div
                                                            key={index}
                                                            style={{
                                                                marginTop:
                                                                    "16px",
                                                                marginBottom:
                                                                    "8px",
                                                                fontWeight:
                                                                    "bold",
                                                                fontSize:
                                                                    "1rem",
                                                            }}
                                                        >
                                                            Dispute Details
                                                        </div>
                                                        <VariationsTable
                                                            buyProduct={
                                                                buyProduct
                                                            }
                                                            isInsideModal={
                                                                false
                                                            }
                                                            disputePreview={
                                                                disputePreview
                                                            }
                                                            onApprove={
                                                                handleApprove
                                                            }
                                                            disputeItems={disputeDetailsCollectionModelData
                                                                ?.getData()
                                                                .getItems()}
                                                        />
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        );
                                    }
                                    if (item?.item.getBuyProductCharge()) {
                                        const currencyCode =
                                            disputeDetailsCollectionModelData
                                                ?.getData()
                                                ?.getBuyProduct()
                                                ?.getRegionCurrencyCode();

                                        const buyProductCharges =
                                            disputeDetailsCollectionModelData
                                                ?.getData()
                                                ?.getBuyProduct()
                                                ?.getCharges();
                                        return (
                                            <BuyProductCharges
                                                key={index}
                                                originalCurrencyCode={
                                                    currencyCode
                                                }
                                                buyProductCharges={
                                                    buyProductCharges
                                                }
                                                buyProductId={undefined}
                                                isListPage={false}
                                            />
                                        );
                                    }
                                    return null;
                                });
                        })()}
                </div>
                {/* Dispute details end */}

                {/* Mandate list */}
                {!isLoading && mandates && mandates.length ? (
                    <div
                        style={{
                            marginTop: 8,
                            paddingBottom: 24,
                        }}
                    >
                        <Card loading={isLoading || buyProductLoading}>
                            <Row gutter={20}>
                                <Col>
                                    <MandatesTable
                                        mandates={mandates}
                                        handleAgreeMandate={handleAgreeMandate}
                                        isLoading={isAgreeMandateLoading}
                                        currency={
                                            disputeDetailsCollectionModelData
                                                ?.getData()
                                                .getRegion()
                                                ?.currency?.getCurrencyCode() as string
                                        }
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </div>
                ) : (
                    <></>
                )}
            </PageHeader>
            {isModalVisible && (
                <DisputeSettlementPreviewModal
                    isVisible={isModalVisible}
                    onClose={() => setIsModalVisible(false)}
                    disputeId={selectedDisputeId}
                    onCompleteRefund={onConfirmCompleteRefund}
                    currency={buyProduct?.getRegion()?.currency?.getCode()}
                />
            )}
        </Card>
    );
};

export default RefundDisputeDetails;
