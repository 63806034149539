import { useService } from "@/lib/core-react/contexts";
import { defaultDataMinimal, isMinimalQuery } from "@/filters/utils";
import { SelectOptionModel } from "@/filters/Models/SelectOptionModel";
import { useState } from "react";
import { IResponseOptions } from "@/filters/enum/responseDataMinimal";
import { errorMessageMinimal } from "@/filters/constant";

export const useGetPayoutRequestsMinimal = () => {
    const { walletService } = useService();
    const [payoutRequestOptionsData, setPayoutRequestOptionsData] =
        useState<IResponseOptions>(defaultDataMinimal);

    const getPayoutRequests = async (params: string): Promise<void> => {
        try {
            if (!isMinimalQuery(params)) {
                throw new Error(errorMessageMinimal);
            }
            setPayoutRequestOptionsData((prev) => ({
                ...prev,
                isLoading: true,
            }));

            const response =
                await walletService.walletResource.getPayoutRequests(params);

            const options =
                response && response.data
                    ? new SelectOptionModel(response.data).getOptions()
                    : [];

            setPayoutRequestOptionsData({
                isLoading: false,
                options,
            });
        } catch (_error) {
            setPayoutRequestOptionsData({
                isLoading: false,
                options: [],
            });
        }
    };

    return { getPayoutRequests, payoutRequestOptionsData };
};

export const useGetPayoutGatewaysMinimal = () => {
    const { walletService } = useService();
    const [payoutGatewayOptionsData, setPayoutGatewayOptionsData] =
        useState<IResponseOptions>(defaultDataMinimal);

    const getPayoutGateways = async (params?: string): Promise<void> => {
        try {
            if (params && !isMinimalQuery(params)) {
                throw new Error(errorMessageMinimal);
            }

            const response =
                await walletService.walletResource.getPayoutGateWays(params);

            const options =
                response && response.data
                    ? new SelectOptionModel(response.data.data).getOptions()
                    : [];

            setPayoutGatewayOptionsData({
                isLoading: false,
                options,
            });
        } catch (_) {
            setPayoutGatewayOptionsData({
                isLoading: false,
                options: [],
            });
        }
    };

    return { getPayoutGateways, payoutGatewayOptionsData };
};
