import { BaseResource } from "../public";
import { SequencesDataModel } from "@/models/buyProductCollectionModel";
import {
    IAddBuyOrderAddProductFormData,
    IAddVariationsPayload,
    IUpdateVariationsFormData,
} from "@/types/buyOrderDetail";
import { IBuyOrderPlacePayload } from "@/types/buyOrderPlace";
import {
    BulkApprovedPayload,
    BulkRejectPayload,
    IBuyOrderCollection,
    IOrderHandlerRequest,
    IShippingRateUpdatePayload,
    IShippingUpdatePayload,
    RejectPayload,
} from "@/types/buyOrderCollection";
import { ResponsePromise } from "@/lib/core/request/types/typings";
import {
    IBuyProductCollection,
    ICancelVariationsAndChargesPayload,
} from "@/types/buyProductCollection";
import { ICustomOrderPayload } from "@/pages/BuyAndShip/BuyOrders/components/BuyOrderCustomOrderCreate/components/CreateOrderSteps/CreateOrderSteps";

class BuyProductsResource extends BaseResource {
    getBuyOrders(
        params?: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise<IBuyOrderCollection> {
        const path = `/api/purchase/admin/buy-product/v1/buy-products${
            params ? `?${params}` : ""
        }`;
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    getBuyProducts(
        params?: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise<IBuyProductCollection> {
        const path = `/api/purchase/admin/buy-product/v1/buy-products/raw${
            params ? `?${params}` : ""
        }`;
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    getBuyProduct(
        buy_product_id: number,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `/api/purchase/admin/buy-product/v1/buy-products/${buy_product_id}`;
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    approveBuyProduct(
        productID: number,
        payload: {
            commission_rate: number | null;
            agent_company_id: number | null;
        },
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `/api/purchase/admin/buy-product/v1/buy-products/${productID}/approve`;
        return this.client.request("PUT", path, payload, {}, customHeaders);
    }

    bulkApproveBuyProduct(
        payload: BulkApprovedPayload,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `/api/purchase/admin/buy-product/v1/buy-products/bulk/approve-multiple`;
        return this.client.request("PUT", path, payload, {}, customHeaders);
    }

    rejectBuyProduct(
        productID: number,
        payload: RejectPayload,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `/api/purchase/admin/buy-product/v1/buy-products/${productID}/reject`;
        return this.client.request("PUT", path, payload, {}, customHeaders);
    }

    bulkRejectBuyProduct(
        payload: BulkRejectPayload,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `/api/purchase/admin/buy-product/v1/buy-products/bulk/reject-multiple`;
        return this.client.request("PUT", path, payload, {}, customHeaders);
    }

    buyProductTakeActions(
        buy_product_id: number,
        payload: SequencesDataModel,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `/api/buy-take-action/admin/buy-product/v1/buy-products/${buy_product_id}/actions`;
        return this.client.request("POST", path, payload, {}, customHeaders);
    }
    getBuyOrdersDetail(
        orderId: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `/api/purchase/admin/buy-order/v1/buy-orders/${orderId}/detail`;
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    // Variations Manage
    addVariations(
        buyProductId: number,
        payload: IAddVariationsPayload,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `/api/purchase/admin/buy-product/v1/buy-products/${buyProductId}/add-variations`;
        return this.client.request("PUT", path, payload, {}, customHeaders);
    }

    updateVariations(
        buyProductId: number,
        payload: IUpdateVariationsFormData,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `/api/purchase/admin/buy-product/v1/buy-products/${buyProductId}/update-variations`;
        return this.client.request("PUT", path, payload, {}, customHeaders);
    }

    deleteVariations(
        buyProductId: number,
        payload: { ids: number[] },
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `/api/purchase/admin/buy-product/v1/buy-products/${buyProductId}/delete-variations`;
        return this.client.request("DELETE", path, payload, {}, customHeaders);
    }

    // Products manage
    addProduct(
        buyOrderId: string,
        region: string,
        locale: string,
        payload: IAddBuyOrderAddProductFormData,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `/api/purchase/admin/buy-order/v1/buy-orders/${buyOrderId}/add-product?region=${region}&locale=${locale}`;
        return this.client.request("POST", path, payload, {}, customHeaders);
    }

    deleteProduct(
        id: number,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `/api/purchase/admin/buy-product/v1/buy-products/${id}`;
        return this.client.request(
            "DELETE",
            path,
            undefined,
            {},
            customHeaders,
        );
    }

    buyProductOrderPlace(
        payload: IBuyOrderPlacePayload,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `/api/purchase/admin/buy-product/v1/buy-products`;
        return this.client.request("POST", path, payload, {}, customHeaders);
    }

    updateFx(
        id: number,
        payload: { fx_rate: number; commission_rate: number | null },
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `/api/purchase/admin/buy-product/v1/buy-products/${id}/update-fx`;
        return this.client.request("PUT", path, payload, {}, customHeaders);
    }

    setCurrentFx(id: number) {
        const path = `/api/purchase/admin/buy-product/v1/buy-products/${id}/set-current-fx`;
        return this.client.request(
            "PUT",
            path,
            {},
            {},
            { withCredentials: true },
        );
    }

    buyProductUpdateShipping(
        productID: number,
        payload: IShippingUpdatePayload,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `/api/purchase/admin/buy-product/v1/buy-products/${productID}/update-shipment`;
        return this.client.request("PUT", path, payload, {}, customHeaders);
    }

    buyProductUpdateShippingPrice(
        productID: number,
        payload: IShippingRateUpdatePayload,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `/api/purchase/admin/buy-product/v1/buy-products/${productID}/update-shipment-price`;
        return this.client.request("PUT", path, payload, {}, customHeaders);
    }
    cancelVariationsAndCharges(
        buyProductId: number,
        payload: ICancelVariationsAndChargesPayload,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `/api/purchase/admin/buy-product/v1/buy-products/${buyProductId}/cancel`;
        return this.client.request("PUT", path, payload, {}, customHeaders);
    }

    sync(
        payload: {
            ids: number[];
        },
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `/api/purchase/admin/buy-product/v1/buy-products/sync-to-elastic`;
        return this.client.request("POST", path, payload, {}, customHeaders);
    }
    createCustomOrder(
        payload: ICustomOrderPayload,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `/api/purchase/admin/buy-product/v1/buy-products/custom-order`;
        return this.client.request("POST", path, payload, {}, customHeaders);
    }

    // buyProductUpdateShippingCategoryPrice(
    //   productID: number,
    //   payload: IUpdateShippingCategoriyPrice,
    //   customHeaders: Record<string, any> = {},
    // ): ResponsePromise {
    //   const path = `/api/purchase/admin/buy-product/v1/buy-products/${productID}/change-shipping-agent-category-price`;
    //   return this.client.request("PUT", path, payload, {}, customHeaders);
    // }

    orderHandlerAssign(
        payload: IOrderHandlerRequest,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `/api/core/admin/order-handler/v1/order-handlers/assign`;
        return this.client.request("POST", path, payload, {}, customHeaders);
    }
}

export default BuyProductsResource;
