import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
    Button,
    Col,
    Form,
    InputNumber,
    Row,
    Table,
    Typography,
    Badge,
    Flex,
} from "antd";
import {
    useBuyProductAddVariations,
    useGetBuyProduct,
} from "@/lib/core-react/hooks/private/usePurchase";
import { BuyOrderOperationEnum } from "@/enums/buyOrderCollectionEnums";
import { ShipmentProductOperationEnum } from "@/enums/shipmentProductCollectionEnums";
import { IPropertyValueMap } from ".";
import { showError } from "@/helpers/showError";
import { showSuccessAlert } from "@/helpers/showSuccess";
import ImageWithPlaceholder from "@/components/ImageWithPlaceholder";
import { ColumnsType } from "antd/es/table";
import { SkuModelExtended } from "@/models/productDetailExtendedModel";
import { IVariation } from "@/types/buyOrderDetail";
import usePageQuery from "@/helpers/usePageQuery";
interface IProps {
    mappedSkuProperties: IPropertyValueMap;
}

export const InventoryProductVariationTable = ({
    mappedSkuProperties,
}: IProps) => {
    const location = useLocation();
    const navigate = useNavigate();
    const operation = new URLSearchParams(location.search).get("operation");
    const buyProductId = new URLSearchParams(location.search).get(
        "buyProductId",
    );
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [selectedRows, setSelectedRows] = useState<SkuModelExtended[]>([]);
    const { goBack } = usePageQuery();
    const {
        addVariations: addVariationsBuyProduct,
        isLoading: addVariantLoading,
    } = useBuyProductAddVariations();
    const {
        getBuyProduct,
        data: { data: buyProductDetail },
    } = useGetBuyProduct();

    const [form] = Form.useForm();
    const variationsData = Object.values(mappedSkuProperties).filter(
        (variant) => {
            if (!buyProductDetail) return true;

            const match = buyProductDetail.product_variations.data.some(
                (item) => item.sku_id === variant.getId(),
            );
            return !match;
        },
    );

    useEffect(() => {
        if (buyProductId && BuyOrderOperationEnum.AddBuyOrderProductVariant) {
            getBuyProduct(Number(buyProductId));
        }
    }, [buyProductId]);
    const handleAddVariationsBuyProduct = async (variations: IVariation[]) => {
        try {
            await addVariationsBuyProduct(Number(buyProductId), {
                variations,
            });

            showSuccessAlert("Variant added successfully");
            form.resetFields();
            setSelectedRowKeys([]);
            setSelectedRows([]);
            if (buyProductId) {
                navigate(`${goBack()}`);
            }
        } catch (error) {
            showError(error);
        }
    };

    const handleAddButton = async () => {
        switch (operation) {
            case BuyOrderOperationEnum.AddBuyOrderProductVariant:
                form.submit();
                break;
            default:
                break;
        }
    };

    const isFloatingActionButtonShow = () => {
        return (
            operation === BuyOrderOperationEnum.AddBuyOrderProductVariant ||
            operation === BuyOrderOperationEnum.AddBuyOrderProduct ||
            operation ===
                ShipmentProductOperationEnum.AddShipmentProductPackage ||
            operation === BuyOrderOperationEnum.PurchaseCartItemAdd
        );
    };

    const floatingActionButtonType = () => {
        return selectedRows.length === 0 ? "default" : "primary";
    };

    const getButtonText = () => {
        switch (operation) {
            case BuyOrderOperationEnum.AddBuyOrderProductVariant:
                return "Add Variations";
            default:
                return "";
        }
    };

    const onFinish = async (values) => {
        try {
            if (selectedRows.length === 0) {
                throw new Error("Please Select Variations");
            }
            const originalUnitPriceValues = values.original_unit_price;
            const quantityValues = values.quantity;

            const selectedVariations = selectedRows
                .map((variant) => {
                    return {
                        sku_id: variant.id,
                        original_unit_price:
                            originalUnitPriceValues[variant.id],
                        quantity: quantityValues[variant.id],
                    };
                })
                .filter((item) => {
                    if (!item.original_unit_price || !item.quantity) {
                        throw new Error(
                            `sku id ${item.sku_id} price and quantity not found`,
                        );
                    } else {
                        return true;
                    }
                });
            await handleAddVariationsBuyProduct(selectedVariations);
        } catch (error) {
            showError(error);
        }
    };

    // ---------------------------ui-----------------------------
    const columns: ColumnsType<SkuModelExtended> = [
        {
            title: "Meta",
            dataIndex: "image",
            key: "image",
            align: "center",
            render: (_: string, record) => {
                return (
                    <>
                        <Flex gap={4} align="flex-start">
                            <div style={{ marginTop: "10px" }}>
                                <ImageWithPlaceholder
                                    style={{ width: 50, height: 50 }}
                                    src={`${record.getPropertyImageUrl()}`}
                                />
                            </div>
                            <div style={{ marginTop: "10px" }}>
                                {record.getPropertyAssociations() &&
                                    record.getPropertyAssociations().getData()
                                        .length &&
                                    record
                                        .getPropertyAssociations()
                                        .getData()
                                        ?.map((singleProperty) => {
                                            const isLongValue =
                                                singleProperty.property_value_name
                                                    ? singleProperty
                                                          .property_value_name
                                                          .length > 0
                                                    : false;

                                            return (
                                                <Flex
                                                    gap={4}
                                                    key={singleProperty.id}
                                                    style={{ margin: 0 }}
                                                >
                                                    <Typography.Paragraph
                                                        style={{
                                                            paddingRight: 8,
                                                        }}
                                                    >
                                                        {
                                                            singleProperty.property_name
                                                        }
                                                        :
                                                    </Typography.Paragraph>

                                                    <Typography.Paragraph
                                                        strong
                                                        ellipsis={{
                                                            tooltip: isLongValue
                                                                ? singleProperty.property_value_name
                                                                : undefined,
                                                        }}
                                                        style={{
                                                            maxWidth: "100px",
                                                            display:
                                                                "inline-block",
                                                            overflow: "hidden",
                                                            textOverflow:
                                                                "ellipsis",
                                                            whiteSpace:
                                                                "nowrap",
                                                            // fontSize: TEXT_SIZE,
                                                        }}
                                                    >
                                                        {
                                                            singleProperty.property_value_name
                                                        }
                                                    </Typography.Paragraph>
                                                </Flex>
                                            );
                                        })}
                            </div>
                        </Flex>
                    </>
                );
            },
        },

        {
            title: "Stock",
            key: "Stock",
            align: "center",
            render: (_: string, record) => {
                return (
                    <div>
                        <Typography.Text type="secondary">
                            {record?.getStock()}{" "}
                        </Typography.Text>
                    </div>
                );
            },
        },
        {
            title: "Original Unit Price",
            key: "original_unit_price",
            align: "center",
            render: (_: string, record) => {
                return (
                    <Form.Item
                        initialValue={record.price.original || 0}
                        name={["original_unit_price", record.id]}
                    >
                        <Flex align="center" gap={8}>
                            <Typography.Text style={{ minWidth: 32 }}>
                                {record.price.currency}
                            </Typography.Text>
                            <InputNumber
                                defaultValue={record.price.original || 0}
                                onStep={(value) => {
                                    form.setFieldValue(
                                        ["original_unit_price", record.id],
                                        value,
                                    );
                                }}
                                style={{ width: "100%" }}
                                min={0.000000000001}
                                onChange={(value) => {
                                    form.setFieldValue(
                                        ["original_unit_price", record.id],
                                        value,
                                    );
                                }}
                            />
                        </Flex>
                    </Form.Item>
                );
            },
        },

        {
            title: "Quantity",
            key: "Quantity",
            align: "center" as const,
            render: (_: string, record) => {
                return (
                    <Form.Item
                        name={["quantity", record.id]}
                        initialValue={
                            record
                                .getPrice()
                                .getWholesales()
                                .getData()[0]
                                ?.getQtyFrom() || 1
                        }
                    >
                        <Flex align="center" gap={8}>
                            <InputNumber
                                defaultValue={
                                    record
                                        .getPrice()
                                        .getWholesales()
                                        .getData()[0]
                                        ?.getQtyFrom() || 1
                                }
                                style={{ width: "100%" }}
                                min={1}
                                onChange={(value) =>
                                    form.setFieldValue(
                                        ["quantity", record.id],
                                        value,
                                    )
                                }
                            />
                        </Flex>
                    </Form.Item>
                );
            },
        },
    ];

    return (
        <div style={{ marginTop: 24 }}>
            <div style={{ marginTop: 24 }}>
                <Row gutter={16}>
                    <Col sm={24} lg={18}>
                        <Form form={form} onFinish={onFinish}>
                            <Table
                                size="small"
                                title={() => (
                                    <Typography.Text strong>
                                        Variations
                                    </Typography.Text>
                                )}
                                bordered
                                columns={columns}
                                dataSource={variationsData}
                                rowKey="id"
                                pagination={false}
                                rowSelection={{
                                    selectedRowKeys,
                                    onChange: (selectedKeys, rows) => {
                                        setSelectedRowKeys(selectedKeys);
                                        setSelectedRows(rows);
                                    },
                                }}
                                locale={{
                                    emptyText:
                                        BuyOrderOperationEnum.AddBuyOrderProductVariant &&
                                        buyProductDetail &&
                                        buyProductDetail.product_variations.data
                                            .length > 0 &&
                                        variationsData.length === 0 ? (
                                            <Typography.Text type="danger">
                                                The Product All Variation
                                                Already Added at Buy Product.
                                            </Typography.Text>
                                        ) : undefined,
                                }}
                            />
                        </Form>
                    </Col>
                    <Col sm={24} lg={6}>
                        {/* {selectedRows.length > 0 ? (
                            <SelectedItems
                                quantity={form.getFieldsValue().quantity}
                                mappedSkuProperties={mappedSkuProperties}
                            />
                        ) : (
                            <div>No items selected</div>
                        )} */}
                    </Col>
                </Row>
            </div>
            {isFloatingActionButtonShow() && (
                <div
                    style={{
                        position: "fixed",
                        bottom: "20px",
                        right: "16px",
                        zIndex: 99999,
                    }}
                >
                    <Badge count={selectedRows.length}>
                        <Button
                            type={floatingActionButtonType()}
                            onClick={handleAddButton}
                            disabled={selectedRows.length === 0}
                            loading={addVariantLoading}
                            style={{ width: "150px" }}
                        >
                            {getButtonText()}
                        </Button>
                    </Badge>
                </div>
            )}
        </div>
    );
};
