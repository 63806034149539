import { IProductDetailParams } from "@/types/productDetailExtended";
import { BaseResource } from "../public";
import { ResponsePromise } from "../../request/types/typings";
import { IProductSearchResult } from "@/types/productSearchResult";
class ProductResource extends BaseResource {
    //Product List
    async getProductList(
        params: Record<string, any>,
        payload?: Record<string, any>,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise<IProductSearchResult> {
        const endpoint = `/api/product/admin/v1/products/search${this.addQuery(params)}`;
        return await this.client.request(
            "POST",
            endpoint,
            payload,
            {},
            customHeaders,
        );
    }

    //Product Details
    async getProductDetails(
        params: Record<string, any>,
        productId: string,
    ): ResponsePromise {
        const query = this.addQuery(params);
        const path = `/api/product/admin/v1/products/find/${productId}${query}`;
        const response = this.client.request("GET", path);
        return response;
    }

    async getProductById(
        productId: string,
        params: Omit<IProductDetailParams, "url">,
    ): ResponsePromise {
        const path = `/api/product/admin/v1/products/find/${productId}${this.addQuery(params)}`;
        const response = this.client.request("GET", path);
        return response;
    }
    async getProductByUrl(params: IProductDetailParams): ResponsePromise {
        const path = `/api/product/admin/v1/products/find${this.addQuery(params)}`;
        const response = this.client.request("GET", path);
        return response;
    }

    //Product description
    async getDescription({
        params,
        productId,
    }: {
        params: Record<string, any>;
        productId: string;
    }): ResponsePromise {
        const path = `/api/product/admin/v1/products/find/${productId}/description${this.addQuery(params)}`;
        const response = this.client.request("GET", path);
        return response;
    }
}

export default ProductResource;
