import { PageHeader } from "@ant-design/pro-layout";
import {
    Button,
    Col,
    DatePicker,
    Dropdown,
    Flex,
    Form,
    Modal,
    Popover,
    Row,
    Select,
    Space,
    Table,
    TableColumnsType,
    Tag,
    Typography,
} from "antd";
import FiltersComponent from "@/components/FiltersComponent";
import useDataFilters from "@/hooks/useDataFilters";
import { useAtom } from "jotai";

import { rfqRequestListAtom } from "@/lib/core-react/store/store";
import { ApiHelperModel } from "@/models/apiHelper";

import { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined, HddFilled } from "@ant-design/icons";
import moment from "moment";
import { Link } from "react-router-dom";
import { rejectReasonOptions } from "./data/rejectReasonOptions";
import {
    RfqRequestCollectionModel,
    RfqRequestModel,
    RfwImagesModel,
} from "@/models/rfqRequestCollectionModel";
import {
    useGetRfq,
    useUpdateRfqApprove,
    useUpdateRfqReject,
} from "@/lib/core-react/hooks/private/useRfq";
import { IRfqRequestsStatus } from "@/lib/core/resources/private/rfqResource";
import StatusTag from "@/components/StatusTag/StatusTag";
import { ExtendedMenuItemType } from "@/types";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ADMIN_RFQ_PERMISSION_ENUM } from "@/consts/permission-enum/RFQ-enum";
import { PaginationModel } from "@/models/pagination";
import CommonError from "@/components/Error/CommonError";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";
import LoaderSmall from "@/components/CustomFormElement/LoaderSmall";
import { defaultQuery } from "@/consts/defaultQuery";
import ImagesModal from "./components/ImagesModal";
import CustomerInfo from "../BuyAndShip/BuyProducts/components/CustomerInfo";
import { showSuccessAlert } from "@/helpers/showSuccess";
import { showError } from "@/helpers/showError";
import ImageWithPlaceholder from "@/components/ImageWithPlaceholder";
import useActionsProps from "@/helpers/useActionsProps";
import { BREAK_POINTS } from "@/consts/appConstants";
import ShippingText from "@/components/ShippingText";
import { formatDateTime } from "@/utils/helpers";

const RfqManage = () => {
    const [selectedItem, setSelectedItem] = useState<RfqRequestModel>();
    const [form] = Form.useForm();
    const [actionType, setActionType] = useState<"approve" | "reject" | null>(
        null,
    );
    const { Text } = Typography;

    const [isOpenImageModal, setIsOpenImageModal] = useState(false);

    const [selectedProduct, setSelectedProduct] = useState<RfwImagesModel[]>(
        [],
    );
    const actionsProps = useActionsProps();
    const onClose = () => {
        setIsOpenImageModal(false);
    };

    const imagesDetailsHandler = (product) => {
        setSelectedProduct(product);
        setIsOpenImageModal(true);
    };

    const { getRequestsList } = useGetRfq();
    const { updateRequestsApprove, isLoading: isApproveLoading } =
        useUpdateRfqApprove();
    const { updateRequestsReject, isLoading: isRejectLoading } =
        useUpdateRfqReject();
    const {
        allOptionsData: { agentCompanyOptionsData },
        onFetchFilterApi,
    } = useFiltersApiData();

    const [
        {
            data: requestsData,
            isLoading: isLoadingRequests,
            error,
            unAuthorized,
        },
    ] = useAtom(rfqRequestListAtom);

    const {
        filters,
        handleFilterChange,
        handelFilterClear,
        isFetched,
        initializeAvailableFilter,
        refetch: refetchFromFilter,
        isFirstCall,
    } = useDataFilters();

    const handleGetApiRequest = () => {
        ApiHelperModel.makeGetRequest(filters, getRequestsList);
    };

    useEffect(() => {
        handleGetApiRequest();
        onFetchFilterApi(filterResourceEnum.AGENT_COMPANY);
    }, [refetchFromFilter]);

    const rfqRequestCollectionModel =
        requestsData && new RfqRequestCollectionModel(requestsData);
    const filterData = rfqRequestCollectionModel?.getFilters();

    useEffect(() => {
        if (!isFetched && rfqRequestCollectionModel) {
            initializeAvailableFilter(rfqRequestCollectionModel?.getFilters());
        }
    }, [isFetched, initializeAvailableFilter]);

    useEffect(() => {
        if (
            (filters && !isFetched && isFirstCall) ||
            refetchFromFilter ||
            refetchFromFilter
        ) {
            ApiHelperModel.makeGetRequest(filters, getRequestsList);
        }
    }, [isFirstCall, filters, isFetched, refetchFromFilter, refetchFromFilter]);

    // Filter Handler
    const handleProductFilter = () => {
        ApiHelperModel.makeGetRequest(
            {
                ...defaultQuery,
                ...filters,
            },
            getRequestsList,
        );
    };

    // ------------------------------ Modal -> --------------------------- //

    const showModal = (type: "approve" | "reject", record: RfqRequestModel) => {
        setActionType(type);
        setSelectedItem(() => record);
    };

    const handleCancel = () => {
        setActionType(null);
        form.resetFields();
    };

    // ------------------------------ Approved -> --------------------------- //

    const handleApprove = async (inputData) => {
        try {
            const time = moment(inputData.valid_until.$d).format(
                "YYYY-MM-DD HH:mm:ss",
            );
            const payload: IRfqRequestsStatus = {
                source_agent_company_ids: inputData?.source_agent_company_ids,
                valid_until: time,
            };
            const id = selectedItem?.getId();

            await updateRequestsApprove({
                requestId: `${id}`,
                payload,
            });

            showSuccessAlert("Successfully RFQ Request Approved");
            handleGetApiRequest();
            form.resetFields();
            setActionType(null);
        } catch (error) {
            showError(error, form);
        }
    };

    const handleReject = async (inputData) => {
        try {
            const payload = {
                reject_reason: inputData?.reject_reason,
            };

            const id = selectedItem?.getId();

            await updateRequestsReject({
                requestId: `${id}`,
                payload,
            });

            showSuccessAlert("Successfully RFQ Request Rejected");

            handleGetApiRequest();
            setActionType(null);
            form.resetFields();
        } catch (error) {
            showError(error);
        }
    };

    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...defaultQuery,
                ...filters,

                ...pageInfo,
            },
            getRequestsList,
        );
    };

    const paginationConfig = PaginationModel.getPaginationConfig(
        requestsData,
        handlePaginationChange,
    );

    if (unAuthorized || error) {
        return <CommonError unAuthorized={unAuthorized} message={error} />;
    }

    const columns: TableColumnsType<RfqRequestModel> = [
        {
            title: "Product Info",
            dataIndex: "product_info",
            key: "product_info",
            render: (_tags: any, record) => {
                return (
                    <Flex gap={12}>
                        <div>
                            <ImageWithPlaceholder
                                style={{
                                    width: 100,
                                    height: 100,
                                }}
                                src={record.getFirstImageUrl()}
                            />

                            <Button
                                style={{ padding: 0, marginTop: 12 }}
                                type="link"
                                onClick={() =>
                                    imagesDetailsHandler(record?.getRfqImages())
                                }
                            >
                                View All Images
                            </Button>
                        </div>

                        <div>
                            <Space direction="vertical" size={2}>
                                <Typography.Text
                                    style={{
                                        padding: 0,
                                        margin: 0,
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    <Text>Request Number : </Text>
                                    <Text copyable strong>
                                        {record?.getRequestedNumber()}
                                    </Text>
                                </Typography.Text>

                                <Typography.Text>
                                    <Text>Request ID : </Text>
                                    <Text strong>{record?.getId()}</Text>
                                </Typography.Text>

                                <Typography.Text>
                                    <Text>Product Name : </Text>
                                    <Text strong>
                                        {record?.getProductName()}
                                    </Text>
                                </Typography.Text>

                                <p
                                    style={{
                                        whiteSpace: "nowrap",
                                        padding: 0,
                                        margin: 0,
                                    }}
                                >
                                    <span>Product link : </span>
                                    <Link
                                        style={{ fontWeight: "bold" }}
                                        to={record?.getProductLink()}
                                        target="_blank"
                                    >
                                        view
                                    </Link>
                                </p>

                                <Typography.Text>
                                    <Text>Purchase Quantity : </Text>
                                    <Text strong>
                                        {record?.getPurchaseQuantity()}
                                    </Text>
                                </Typography.Text>

                                <ShippingText label="Region">
                                    <Popover
                                        content={`${record?.getRegionName()}`}
                                    >
                                        <StatusTag
                                            slug={record?.getRegionCode()}
                                            text={record?.getRegionCode()}
                                        />
                                    </Popover>
                                </ShippingText>

                                <p
                                    style={{
                                        margin: "0 0",
                                        whiteSpace: "nowrap",
                                        marginBottom: 4,
                                    }}
                                >
                                    <span>Status: </span>
                                    <StatusTag
                                        text={record?.getStatus()}
                                        slug={record?.getStatus()}
                                    />
                                </p>
                                {record?.getValidUntil() && (
                                    <ShippingText label="Valid Until">
                                        <StatusTag
                                            color="purple"
                                            slug={""}
                                            text={`${formatDateTime(record.getValidUntil())}`}
                                        />
                                    </ShippingText>
                                )}

                                <Typography.Text>
                                    <Text>Descriptions : </Text>
                                    <Text strong>
                                        {" "}
                                        {record?.getProductDescription()}
                                    </Text>
                                </Typography.Text>
                            </Space>
                        </div>
                    </Flex>
                );
            },
        },

        {
            title: "Shipping",
            dataIndex: "shipping",
            colSpan: 1,
            key: "shipping",
            render: (_tags: any, record) => {
                const expireTime = record?.getValidUntil();
                return (
                    <Row>
                        <Col>
                            <Space direction="vertical" size={2}>
                                <Typography.Text>
                                    <Text>Shipping Type :</Text>
                                    <Text strong>
                                        {" "}
                                        {record?.getShippingType()}
                                    </Text>
                                </Typography.Text>
                                <Typography.Text>
                                    <Text>Shipping Mode :</Text>
                                    <Text strong>
                                        {" "}
                                        {record?.getShippingMode()}
                                    </Text>
                                </Typography.Text>
                                <Typography.Text
                                    style={{
                                        margin: "0 0",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    <Text>Shipping Category :</Text>
                                    <Text strong>
                                        {" "}
                                        <Tag color="blue">
                                            {record.getShippingCategory().name}
                                        </Tag>
                                    </Text>
                                </Typography.Text>
                                {expireTime && (
                                    <Typography.Text>
                                        <Text>Valid Until :</Text>
                                        <Text strong> {expireTime}</Text>
                                    </Typography.Text>
                                )}

                                {record?.getShippingCountry()?.country
                                    ?.code && (
                                    <Typography.Text>
                                        <Text>Source Country :</Text>{" "}
                                        <StatusTag
                                            slug={
                                                record?.getShippingCountry()
                                                    .country.code
                                            }
                                            text={
                                                record?.getShippingCountry()
                                                    .country.name
                                            }
                                        />
                                    </Typography.Text>
                                )}
                                <Typography.Text>
                                    <Text>Destination Warehouse :</Text>

                                    <Text strong>
                                        {record?.getWarehouseName()}
                                    </Text>
                                </Typography.Text>
                            </Space>
                        </Col>
                    </Row>
                );
            },
        },

        {
            title: "User",
            dataIndex: "user",
            key: "user",
            width:300,
            render: (_tags: any, record) => {
                return <CustomerInfo user={record.user} />;
            },
        },

        {
            ...actionsProps,
            hidden: !checkActionPermission(
                ADMIN_RFQ_PERMISSION_ENUM.ADMIN_RFQ_MANAGE,
                "checkPermission",
                null,
            ),
            render: (_key: string, record) => {
                const items: ExtendedMenuItemType[] = [
                    {
                        permission: ADMIN_RFQ_PERMISSION_ENUM.ADMIN_RFQ_MANAGE,
                        icon: <EditOutlined />,
                        key: "approve",
                        isVisible: !["closed", "approved"].includes(
                            record.getStatus(),
                        ),
                        label: (
                            <span onClick={() => showModal("approve", record)}>
                                Approve
                            </span>
                        ),
                    },
                    {
                        permission: ADMIN_RFQ_PERMISSION_ENUM.ADMIN_RFQ_MANAGE,
                        icon: <DeleteOutlined />,
                        key: "reject",
                        isVisible: record.getStatus() !== "closed",
                        label: (
                            <span onClick={() => showModal("reject", record)}>
                                Reject
                            </span>
                        ),
                    },
                ];

                const filterItemsVisible = items.filter(
                    (item2) => item2.isVisible,
                );
                if (filterItemsVisible.length > 0) {
                    return (
                        <Dropdown
                            menu={{
                                items: filterItemsVisible.filter((x) =>
                                    checkActionPermission(
                                        x.permission,
                                        x,
                                        null,
                                    ),
                                ),
                            }}
                        >
                            <Button icon={<HddFilled />}></Button>
                        </Dropdown>
                    );
                }
            },
        },
    ];

    return (
        <>
            <Row>
                <Col span={24}>
                    <PageHeader
                        style={{ marginTop: "10px" }}
                        ghost={false}
                        title="RFQ Manage"
                        onBack={() => window.history.back()}
                    >
                        <FiltersComponent
                            handleProductFilter={handleProductFilter}
                            handleFilterChange={handleFilterChange}
                            handelFilterClear={handelFilterClear}
                            isFetched={isFetched}
                            filters={filters}
                            filtersData={filterData}
                            isFromProductReceived={true}
                        />

                        <div style={{ marginTop: 20 }}>
                            <Table
                                rowHoverable={false}
                                columns={columns}
                                dataSource={rfqRequestCollectionModel?.getData()}
                                rowSelection={{
                                    type: "checkbox",
                                    onSelect: function (record, selected) {
                                        if (selected) {
                                            setSelectedItem(() => record);
                                        }
                                    },
                                }}
                                pagination={paginationConfig}
                                bordered
                                loading={isLoadingRequests || isLoadingRequests}
                                rowKey="id"
                                scroll={{ x: BREAK_POINTS.XL }}
                            />
                        </div>

                        <Modal
                            title={`RFQ ${actionType?.toUpperCase()}`}
                            open={actionType === "approve"}
                            onCancel={handleCancel}
                            destroyOnClose={true}
                            footer={false}
                        >
                            <Form
                                form={form}
                                onFinish={handleApprove}
                                layout="vertical"
                            >
                                <>
                                    <Form.Item
                                        style={{ width: "100%" }}
                                        name="valid_until"
                                        label="Valid Until"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Please provide valid until date ",
                                            },
                                        ]}
                                    >
                                        <DatePicker
                                            style={{
                                                width: "100%",
                                            }}
                                            showTime
                                            format={"DD/MM/YYYY"}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label="Source Agent Company"
                                        name="source_agent_company_ids"
                                        style={{ color: "red" }}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Please select at least one Company",
                                            },
                                        ]}
                                    >
                                        <Select
                                            mode="multiple"
                                            allowClear={true}
                                            showSearch
                                            optionFilterProp="label"
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? "")
                                                    .toLowerCase()
                                                    .localeCompare(
                                                        (
                                                            optionB?.label ?? ""
                                                        ).toLowerCase(),
                                                    )
                                            }
                                            style={{ width: "100%" }}
                                            placeholder="Please select a company"
                                            loading={
                                                agentCompanyOptionsData.isLoading
                                            }
                                            options={
                                                agentCompanyOptionsData.options
                                            }
                                            notFoundContent={
                                                agentCompanyOptionsData.isLoading
                                                    ? LoaderSmall
                                                    : null
                                            }
                                        />
                                    </Form.Item>
                                </>

                                <Flex justify="end">
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        loading={isApproveLoading}
                                    >
                                        Approved
                                    </Button>
                                </Flex>
                            </Form>
                        </Modal>

                        <Modal
                            title={`RFQ ${actionType?.toUpperCase()}`}
                            open={actionType === "reject"}
                            onCancel={handleCancel}
                            footer={false}
                            destroyOnClose={true}
                        >
                            <Form
                                form={form}
                                onFinish={handleReject}
                                layout="vertical"
                            >
                                {actionType === "reject" && (
                                    <>
                                        <Form.Item
                                            label="Reject Reason"
                                            name="reject_reason"
                                            style={{ color: "red" }}
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Please select al least one option",
                                                },
                                            ]}
                                        >
                                            <Select
                                                placeholder="Please select reason"
                                                options={rejectReasonOptions}
                                            />
                                        </Form.Item>
                                    </>
                                )}
                                <Flex justify="end">
                                    <Button
                                        loading={isRejectLoading}
                                        type="primary"
                                        htmlType="submit"
                                    >
                                        Reject
                                    </Button>
                                </Flex>
                            </Form>
                        </Modal>
                        <ImagesModal
                            open={isOpenImageModal}
                            cancelHandler={onClose}
                            rfq_images={selectedProduct}
                        />
                    </PageHeader>
                </Col>
            </Row>
        </>
    );
};

export default RfqManage;
