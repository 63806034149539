import { RecoveryFlow, UiNode, UpdateRecoveryFlowBody } from "@ory/client";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";

import { Flow, Values } from "../pkg";
import { handleFlowError } from "../pkg/errors";
import { Col, Row, Space, Typography } from "antd";
import {
    Link,
    useLocation,
    useNavigate,
    useSearchParams,
} from "react-router-dom";
import ory from "../pkg/sdk";
import logoImg from "/logo.svg";
const Recovery = () => {
    const [flow, setFlow] = useState<RecoveryFlow>();
    const [searchParams] = useSearchParams();

    const navigate = useNavigate();
    const location = useLocation();

    const returnTo = searchParams.get("return_to");
    const flowId = searchParams.get("flow");

    useEffect(() => {
        if (!location || flow) {
            return;
        }

        if (flowId) {
            ory.getRecoveryFlow({ id: String(flowId) })
                .then(({ data }) => {
                    setFlow(data);
                })
                .catch(handleFlowError(navigate, "recovery", setFlow));
            return;
        }

        ory.createBrowserRecoveryFlow({
            returnTo: String(returnTo || ""),
        })
            .then(({ data }) => {
                setFlow(data);
            })
            .catch(handleFlowError(navigate, "recovery", setFlow))
            .catch((err: AxiosError) => {
                if (err.response?.status === 400) {
                    setFlow(
                        err.response
                            ? (err.response.data as RecoveryFlow)
                            : undefined,
                    );
                    return;
                }

                return Promise.reject(err);
            });
    }, [flowId, navigate, location, returnTo, flow, searchParams]);

    const getEmail = (nodes: Array<UiNode> | undefined) => {
        if (!nodes) return null;

        for (let i = 0; i < nodes.length; i++) {
            const attributes = nodes[i].attributes;
            if (
                (attributes && attributes.node_type !== "input") ||
                attributes?.name === "email"
            ) {
                return attributes.node_type !== "text"
                    ? attributes.node_type !== "script"
                        ? attributes.node_type !== "img"
                            ? attributes.node_type !== "a"
                                ? attributes?.value
                                : ""
                            : ""
                        : ""
                    : "";
            }
        }
        return null;
    };

    const email = getEmail(flow?.ui.nodes);

    const onSubmit = async (values: Partial<Values>): Promise<void> => {
        navigate(`/auth/recovery?flow=${flow?.id}`, { replace: true });

        ory.updateRecoveryFlow({
            flow: String(flow?.id),
            updateRecoveryFlowBody: values as UpdateRecoveryFlowBody,
        })
            .then(({ data }) => {
                setFlow(data);
            })

            .catch(handleFlowError(navigate, "recovery", setFlow))
            .catch((err: AxiosError) => {
                switch (err.response?.status) {
                    case 400:
                        setFlow(
                            err.response
                                ? (err.response.data as RecoveryFlow)
                                : undefined,
                        );
                        return;
                }

                throw err;
            });
    };

    return (
        <Row
            justify="space-around"
            align="middle"
            style={{ background: "#fff", height: "100vh" }}
        >
            <Col xs={24} md={6} sm={8}>
                <div
                    style={{
                        textAlign: "center",
                        padding: "30px",
                        marginTop: "50px",
                        marginBottom: "20px",
                    }}
                >
                    <Link to={"/auth-admin/login"}>
                        <img src={logoImg} alt="logo" />
                    </Link>
                </div>
                <Space direction={"vertical"} size={16}>
                    {email ? (
                        <>
                            <Typography.Title
                                level={2}
                                style={{ margin: 0, textAlign: "center" }}
                            >
                                Enter recovery code
                            </Typography.Title>
                            <Typography.Text style={{ textAlign: "center" }}>
                                Enter 6 digit number that sent to {email}
                            </Typography.Text>
                        </>
                    ) : (
                        <>
                            <Typography.Title
                                level={2}
                                style={{ margin: 0, textAlign: "center" }}
                            >
                                Forgot password?
                            </Typography.Title>
                            <Typography.Text style={{ textAlign: "center" }}>
                                No problem, Just enter your email address below.
                                Well send you a link/code to reset it.
                            </Typography.Text>
                        </>
                    )}

                    <Flow onSubmit={onSubmit} flow={flow} />
                </Space>
            </Col>
        </Row>
    );
};

export default Recovery;
