import React, { useEffect } from "react";
import { Button, Card, Flex, Progress, Tag, Typography } from "antd";
import {
    HarvestBatchCollectionModel,
    HarvestBatchModel,
} from "@/models/harvestBatchCollectionModel";
import { useAtom } from "jotai";
import { harvestJobCollectionAtom } from "@/lib/core-react/store/store";
import useDataFilters from "@/hooks/useDataFilters";
import { ApiHelperModel } from "@/models/apiHelper";
import { useNavigate } from "react-router-dom";
import { UserTypeEnums } from "@/enums/harvestJobCollectionEnums";
import { formatString } from "@/utils/helper";
import { useHarvestJobList } from "@/lib/core-react/hooks/private/useHarvestJob";
import { formatDateTime } from "@/utils/helpers";
import { DownloadOutlined, PrinterOutlined } from "@ant-design/icons";

interface HarvestJobDrawerProps {
    open: boolean;
    onClose: () => void;
}

const HarvestJobPopupOver: React.FC<HarvestJobDrawerProps> = () => {
    const { Text } = Typography;
    const [{ data: harvestJobListData, refetch }] = useAtom(
        harvestJobCollectionAtom,
    );
    const navigate = useNavigate();

    const { getHarvestJobList } = useHarvestJobList();

    const { isFirstCall, isFetched } = useDataFilters();

    // Api Call
    useEffect(() => {
        if ((!isFetched && isFirstCall) || refetch) {
            ApiHelperModel.makeGetRequest({}, getHarvestJobList);
        }
    }, [isFirstCall, isFetched, refetch]);

    const HarvestJobCollectionData =
        harvestJobListData &&
        new HarvestBatchCollectionModel(harvestJobListData);

    const handleButtonRedirect = () => {
        navigate("/system/queue-tasks");
    };

    const getColorForType = (status: string) => {
        switch (status) {
            case UserTypeEnums.AGENT:
                return "#265073";
            case UserTypeEnums.ADMIN:
                return "#0E46A3";
            case UserTypeEnums.DW_ADMIN:
                return "#000000";
        }
    };

    const getMessageByStatus = (item: HarvestBatchModel) => {
        const result = item.getResult();
        const message = result?.getMessage() || item.getType();

        switch (item.getStatus()) {
            case "completed":
                return formatString(`${message}`);
            case "pending":
                return formatString(`${message}`);
            case "failed":
                return formatString(`${message}`);
            default:
                return formatString(message);
        }
    };

    return (
        <>
            <div style={{ maxHeight: "450px", overflowY: "auto" }}>
                {HarvestJobCollectionData &&
                    HarvestJobCollectionData.getData().map((item) => {
                        const progress = item.getProgress()
                            ? item.getProgress()
                            : 0;
                        const isProgressComplete = progress === 100;
                        const downloadUrl = item
                            .getResult()
                            ?.getData()
                            ?.getDownloadUrls()[0];
                        const message = getMessageByStatus(item);
                        return (
                            <Card key={item.getId()} style={{ marginTop: 10 }}>
                                <div style={{ flex: 1 }}>
                                    <p style={{ marginTop: "-8px" }}>
                                        <Tag color="success">{message}</Tag>
                                    </p>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <div style={{ flex: 1 }}>
                                        <p style={{ marginTop: -5 }}>
                                            <Text>
                                                {item.getUser().getName()}
                                                <strong
                                                    style={{
                                                        color: `${getColorForType(
                                                            item
                                                                .getUser()
                                                                .getType(),
                                                        )}`,
                                                    }}
                                                >
                                                    (
                                                    {formatString(
                                                        item
                                                            .getUser()
                                                            .getType(),
                                                    )}
                                                    )
                                                </strong>
                                            </Text>
                                        </p>

                                        <Tag
                                            style={{
                                                marginTop: -3,
                                                marginBottom: -3,
                                            }}
                                            color="purple"
                                        >
                                            {formatDateTime(
                                                item.getCreatedAt(),
                                            )}
                                        </Tag>

                                        <p style={{ marginBottom: -8 }}>
                                            {item.getTags().data.map((e) => (
                                                <Tag
                                                    key={e.getTag()}
                                                    color={"default"}
                                                >
                                                    {e.getTag()}
                                                </Tag>
                                            ))}
                                        </p>
                                    </div>
                                    <div>
                                        {!isProgressComplete && (
                                            <Flex gap="small">
                                                <Progress
                                                    type="circle"
                                                    percent={
                                                        progress ? progress : 0
                                                    }
                                                    size={40}
                                                />
                                            </Flex>
                                        )}
                                        {isProgressComplete &&
                                            item
                                                .getResult()
                                                ?.getActionValue() ===
                                                "print" && (
                                                <Button
                                                    type="dashed"
                                                    icon={<PrinterOutlined />}
                                                    //onClick={handlePrint}
                                                >
                                                    <a
                                                        href={downloadUrl}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        download
                                                    >
                                                        {item
                                                            .getResult()
                                                            ?.getActionLabel()}
                                                    </a>
                                                </Button>
                                            )}

                                        {isProgressComplete &&
                                            item
                                                .getResult()
                                                ?.getActionValue() ===
                                                "download" && (
                                                <Button
                                                    type="dashed"
                                                    icon={<DownloadOutlined />}
                                                >
                                                    <a
                                                        href={downloadUrl}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        download
                                                    >
                                                        {item
                                                            .getResult()
                                                            ?.getActionLabel()}
                                                    </a>
                                                </Button>
                                            )}
                                    </div>
                                </div>
                            </Card>
                        );
                    })}
            </div>

            <div
                style={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Button
                    type="dashed"
                    shape="round"
                    onClick={handleButtonRedirect}
                >
                    View All
                </Button>
            </div>
        </>
    );
};

export default HarvestJobPopupOver;
