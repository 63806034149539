import { useAtom } from "jotai";
import { useService } from "@/lib/core-react/contexts";
import {
    inventoryProductDetailAtom,
    inventoryProductSearchResultAtom,
} from "@/lib/core-react/store/store";
import { useState } from "react";
import { ProductSearchResultModel } from "@/models/ProductSearchCollectionModel";
import { getErrorMessage } from "@/helpers/getErrorMessages";
import { getErrorCode } from "@/helpers/getErrorCode";
import { IProductDetailParams } from "@/types/productDetailExtended";
import { ProductDetailExtendedModel } from "@/models/productDetailExtendedModel";
interface ErrorProps {
    code: number;
    message: string;
}
export const useGetSearchProducts = () => {
    const [productSearchResult, setProductSearchResult] = useAtom(
        inventoryProductSearchResultAtom,
    );
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<ErrorProps | undefined>(undefined);

    const { productService } = useService();
    const getProducts = async (
        params: { region: string; locale: string; [key: string]: any },
        payload?: Record<string, any>,
    ): Promise<void> => {
        setIsLoading(true);
        setError(undefined);

        try {
            const response =
                await productService.productResource.getProductList(
                    params,
                    payload,
                );
            if (response) {
                const processData = new ProductSearchResultModel(response);
                setProductSearchResult(processData);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setError({
                code: getErrorCode(error),
                message: getErrorMessage(error),
            });
            throw error;
        }
    };
    return {
        getProducts,
        isLoading,
        data: productSearchResult,
        error,
    } as const;
};

export const useGetProductByUrl = () => {
    const [productDetailResult, setProductDetailResult] = useAtom(
        inventoryProductDetailAtom,
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<ErrorProps | undefined>(undefined);

    const { productService } = useService();
    const getProductDetailByUrl = async (
        params: IProductDetailParams,
    ): Promise<void> => {
        try {
            setIsLoading(true);
            setError(undefined);
            const response =
                await productService.productResource.getProductByUrl(params);
            if (response) {
                const processData =
                    response && new ProductDetailExtendedModel(response.data);
                setProductDetailResult(processData);
            }
            setIsLoading(false);
        } catch (error: any) {
            setIsLoading(false);
            setError({
                code: getErrorCode(error),
                message: getErrorMessage(error),
            });
            throw error;
        }
    };
    return {
        getProductDetailByUrl,
        isLoading,
        data: productDetailResult,
        error,
    } as const;
};

export const useGetProductById = () => {
    const [productDetailResult, setProductDetailResult] = useAtom(
        inventoryProductDetailAtom,
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<ErrorProps | undefined>(undefined);

    const { productService } = useService();
    const getProductDetailById = async (
        productId: string,
        params: Omit<IProductDetailParams, "url">,
    ): Promise<void> => {
        try {
            setIsLoading(true);
            setError(undefined);
            const response =
                await productService.productResource.getProductById(
                    productId,
                    params,
                );
            if (response) {
                const processData = new ProductDetailExtendedModel(
                    response.data,
                );
                setProductDetailResult(processData);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setError({
                code: getErrorCode(error),
                message: getErrorMessage(error),
            });
            throw error;
        }
    };
    return {
        getProductDetailById,
        isLoading,
        data: productDetailResult,
        error,
    } as const;
};

export const useGetProductDescription = () => {
    const { productService } = useService();

    const [data, setData] = useState<
        | {
              message: string;
              data: string;
          }
        | undefined
    >(undefined);

    const getProductDescription = async (
        params: Record<string, any>,
        productId: string,
    ): Promise<
        | {
              message: string;
              data: string;
          }
        | undefined
    > => {
        const response = await productService.productResource.getDescription({
            params,
            productId,
        });

        setData(response);
        return response;
    };
    return { getProductDescription, data } as const;
};
