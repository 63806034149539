import { ColumnsType } from "antd/es/table";
import { IPurchaseCartExtended } from "@/types/PurchaseCartCollection";
import { Typography, Tooltip, Tag } from "antd";
import ImageWithPlaceholder from "@/components/ImageWithPlaceholder";
import StatusTag from "@/components/StatusTag";
import { formatDateTime } from "@/utils/helpers";
import VariationsColumn from "./variationsColums";

const { Text, Paragraph, Link } = Typography;

export const getPurchaseCartColumns = (paginationData: any): ColumnsType<IPurchaseCartExtended> => [
    {
        title: "S/N",
        key: "serialNumber",
        render: (_: unknown, __: IPurchaseCartExtended, index: number) => {
            const currentPage = paginationData?.current_page || 1;
            const pageSize = paginationData?.total || 10;
            return (currentPage - 1) * pageSize + index + 1;
        },
        width: 50,
    },
    {
        title: "User Info",
        dataIndex: "user",
        key: "user",
        render: (_, record: IPurchaseCartExtended) => (
            <>
                {record.user?.name && (
                    <Paragraph>
                        <Text>Name: </Text>
                        <Text strong copyable>
                            {record.user.name}
                        </Text>
                    </Paragraph>
                )}
                {record.user?.email && (
                    <Paragraph>
                        <Text>Email: </Text>
                        <Text strong copyable>
                            {record.user.email}
                        </Text>
                    </Paragraph>
                )}
                {record.user?.phone && (
                    <Paragraph>
                        <Text>Phone: </Text>
                        <Text strong copyable>
                            {record.user.phone}
                        </Text>
                    </Paragraph>
                )}
                {record.created_at && (
                    <Paragraph>
                        <Text>Created At: </Text>
                        <Tag color="purple">{formatDateTime(record.created_at)}</Tag>
                    </Paragraph>
                )}
            </>
        ),
    },
    {
        title: "Product Info",
        dataIndex: "product_image",
        key: "product_image",
        render: (_, record) => {
            const currencyData = record.currency?.code;
            return (
                <div style={{ display: "flex", gap: 12 }}>
                    <ImageWithPlaceholder style={{ width: 120 }} src={record.product_image} />
                    <div>
                        <Text>
                            <Tooltip title={record.product_title.length > 30 ? record.product_title : ""}>
                                <Link target="_blank" rel="noreferrer">
                                    {record.product_title.length > 30
                                        ? `${record.product_title.slice(0, 30)}...`
                                        : record.product_title}
                                </Link>
                            </Tooltip>
                        </Text>
                        <Paragraph>
                            <Text>Region: </Text>
                            <StatusTag slug={record.region.code} text={record.region.code} />
                        </Paragraph>
                        <Paragraph>
                            <Text>Quantity: </Text>
                            <Text>{record.total_quantity}</Text>
                        </Paragraph>
                        <Paragraph>
                            <Text>Original Price: </Text>
                            <Text>{currencyData} {record.original_total}</Text>
                        </Paragraph>
                        <Paragraph>
                            <Text>Total Price: </Text>
                            <Text>{currencyData} {record.total}</Text>
                        </Paragraph>
                    </div>
                </div>
            );
        },
    },
    {
        title: "Variations",
        dataIndex: "variations",
        key: "variations",
        render: (_, record: IPurchaseCartExtended) => (
            <VariationsColumn variations={record.variations} record={record} />
        ),
    },
    {
        title: "Shipping Information",
        dataIndex: "shipping_information",
        key: "shipping_information",
        render: (_, record) => (
            <>
                <Paragraph>
                    <Tooltip title="Shipping Provider">
                        <Text>S/P: </Text>
                    </Tooltip>
                    <Text strong copyable>{record.shipping_provider}</Text>
                </Paragraph>
                <Paragraph>
                    <Tooltip title="Agent Warehouse">
                        <Text>A/W: </Text>
                    </Tooltip>
                    <Text strong>{record.moveon_shipping.agent_warehouse.name}</Text>
                </Paragraph>
                <Paragraph>
                    <Tooltip title="Destination Warehouse">
                        <Text>D/W: </Text>
                    </Tooltip>
                    <Text strong>{record.moveon_shipping.destination_warehouse.name}</Text>
                </Paragraph>
            </>
        ),
    },
];
