import React, { createContext, useContext } from "react";
import {
    CoreService,
    CourierService,
    CustomerUserService,
    DestinationService,
    ShippingAgentService,
    ShippingCoreService,
    ShippingService,
    StoreService,
    WishlistService,
    V1Service,
    PurchaseService,
    AclService,
    ShippingCartService,
    AddressService,
    PaymentService,
    ProductService,
    WalletService,
    InvoiceService,
    CampaignService,
    AddonService,
    BuyTakeActionService,
    DiscountService,
    TrackService,
    HarvestJobService,
    PurchaseCartService,
    AdminUserService,
    GatewayService,
    HsCodeService,
    InvoicePaymentRuleService,
    StripiService,
} from "@/lib/core/services/private";
import { StorageService } from "@/lib/core/services/public";
import DisputeService from "@/lib/core/services/private/disputeService copy";
import RfqService from "@/lib/core/services/private/rfqService";
import GenericService from "@/lib/core/services/private/genericService";

interface IServiceContextState {
    shippingCartService: ShippingCartService;
    coreService: CoreService;
    courierService: CourierService;
    customerUserService: CustomerUserService;
    destinationService: DestinationService;
    shippingAgentService: ShippingAgentService;
    shippingCoreService: ShippingCoreService;
    shippingService: ShippingService;
    storeService: StoreService;
    wishlistService: WishlistService;
    v1Service: V1Service;
    aclService: AclService;
    storageService: StorageService;
    purchaseService: PurchaseService;
    addressService: AddressService;
    paymentService: PaymentService;
    productService: ProductService;
    walletService: WalletService;
    invoiceService: InvoiceService;
    invoicePaymentService: InvoicePaymentRuleService;
    addonService: AddonService;
    buyTakeActionsService: BuyTakeActionService;
    campaignService: CampaignService;
    discountService: DiscountService;
    disputeService: DisputeService;
    trackService: TrackService;
    rfqService: RfqService;
    harvestJobService: HarvestJobService;
    purchaseCartService: PurchaseCartService;
    adminUserService: AdminUserService;
    gatewayService: GatewayService;
    hsCodeService: HsCodeService;
    stripiService: StripiService;
    genericService: GenericService;
}

const ServiceContext = createContext<IServiceContextState | null>(null);

export const useService = () => {
    const context = useContext(ServiceContext);
    if (!context) {
        throw new Error("useService must be used within a ServiceProvider");
    }
    return context;
};

interface ServiceProviderProps {
    children: React.ReactNode;
}

export const ServiceProvider = ({ children }: ServiceProviderProps) => {
    const aclService = new AclService();
    const coreService = new CoreService();
    const courierService = new CourierService();
    const customerUserService = new CustomerUserService();
    const destinationService = new DestinationService();
    const shippingAgentService = new ShippingAgentService();
    const shippingCoreService = new ShippingCoreService();
    const shippingService = new ShippingService();
    const storeService = new StoreService();
    const wishlistService = new WishlistService();
    const v1Service = new V1Service();
    const storageService = new StorageService();
    const purchaseService = new PurchaseService();
    const shippingCartService = new ShippingCartService();
    const addressService = new AddressService();
    const paymentService = new PaymentService();
    const productService = new ProductService();
    const walletService = new WalletService();
    const invoiceService = new InvoiceService();
    const campaignService = new CampaignService();
    const addonService = new AddonService();
    const buyTakeActionsService = new BuyTakeActionService();
    const discountService = new DiscountService();
    const disputeService = new DisputeService();
    const trackService = new TrackService();
    const rfqService = new RfqService();
    const harvestJobService = new HarvestJobService();
    const purchaseCartService = new PurchaseCartService();
    const adminUserService = new AdminUserService();
    const gatewayService = new GatewayService();
    const invoicePaymentService = new InvoicePaymentRuleService();
    const hsCodeService = new HsCodeService();
    const stripiService = new StripiService();
    const genericService = new GenericService();

    return (
        <ServiceContext.Provider
            value={{
                invoicePaymentService,
                aclService,
                coreService,
                courierService,
                customerUserService,
                destinationService,
                discountService,
                shippingAgentService,
                shippingCoreService,
                shippingService,
                storeService,
                wishlistService,
                v1Service,
                storageService,
                purchaseService,
                shippingCartService,
                trackService,
                addressService,
                paymentService,
                productService,
                walletService,
                invoiceService,
                campaignService,
                addonService,
                buyTakeActionsService,
                disputeService,
                rfqService,
                harvestJobService,
                purchaseCartService,
                adminUserService,
                gatewayService,
                hsCodeService,
                stripiService,
                genericService,
            }}
        >
            {children}
        </ServiceContext.Provider>
    );
};
