import {
    Button,
    Dropdown,
    Flex,
    Form,
    MenuProps,
    message,
    Row,
    Space,
} from "antd";
import {
    SearchOutlined,
    DownloadOutlined,
    DownOutlined,
    RightOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import {
    download_table_as_csv,
    exportToCsv,
    formatDateTime,
} from "@/utils/helpers";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { IFilterType } from "@/types/filters";
import CustomElement from "@/components/CustomFormElement/CustomElement";
import { tw } from "@/consts/theme/tailwindTheme";
import useWindowWidth from "@/lib/core-react/hooks/public/useWindowWidth";
import { useHarvestJobCreate } from "@/lib/core-react/hooks/private/useHarvestJob";
import { IHarvestJobCreate } from "@/types/harvestBatchCollection";

type MenuItem = Exclude<MenuProps["items"], undefined>[number];
type ExtendedMenuItem = MenuItem & {
    isFilterItemShow: boolean | undefined;
};

interface IFiltersComponentProps {
    handleProductFilter: () => void;
    handleFilterChange: (fields: IFilterType) => void;
    handelFilterClear: () => void;
    isFetched: boolean;
    filters: IFilterType | null | undefined;
    filtersData: IFilterType | null | undefined;
    isFromProductReceived?: boolean;
    extra?: any;
    excelTitle?: [] | undefined | null;
    excelValue?: [] | undefined | null;
    title?: string;
    handleShipmentExport?: (type: string) => void;
    isShipmentProduct?: boolean;
    isDeliveryRequest?: boolean;
    handleDeliveryExport?: (type: string) => void;
    isExcelExportNeeded?: boolean;
    filterSize?: string;
    isSubFilter?: boolean;
    harvestKey?: string;
    isLoading?: boolean;
}
const FiltersComponent = (props: IFiltersComponentProps) => {
    const { isMobile } = useWindowWidth();
    const [expand, setExpand] = useState<boolean>(false);
    const [filterVisible, setFilterVisible] = useState<boolean>(true);
    const {
        handleProductFilter,
        handleFilterChange,
        handelFilterClear,
        filters,
        filtersData,
        extra,
        excelTitle,
        excelValue,
        isFromProductReceived,
        title,
        handleShipmentExport,
        isShipmentProduct,
        isDeliveryRequest,
        handleDeliveryExport,
        isExcelExportNeeded,
        filterSize,
        isSubFilter,
        harvestKey,
        isLoading = false,
    } = props;

    const { createHarvest } = useHarvestJobCreate();

    if (!filters || !filtersData) {
        return null;
    }

    const availableRegionId = filters.region_id
        ? (filters.region_id as string)
        : undefined;

    let filterData = Object.keys(filtersData);

    if (filterData?.length > 8) {
        filterData = filterData.slice(0, 8);

        if (expand) {
            filterData = Object.keys(filtersData);
        } else if (!expand) {
            filterData = filterData.slice(0, 8);
        }
    }

    const handleExport = (value: string) => {
        const today = new Date();
        if (value === "excel" && excelValue && excelTitle) {
            exportToCsv(
                `${title ? title : "Export"}-${formatDateTime(today)}.csv`,
                excelTitle.concat(excelValue),
            );
        } else {
            message.warning(
                "No data to export, Please contact with IT department",
            );
        }
    };

    // Define the items for the Menu dynamically
    const menuItems: ExtendedMenuItem[] = [
        {
            key: "1",
            label: "Customize CSV",
            onClick: () => handleExport("excel"),
            isFilterItemShow: isExcelExportNeeded,
        },
        {
            isFilterItemShow: isExcelExportNeeded,
            key: "2",
            label: "Full Table CSV",
            onClick: () => download_table_as_csv("moveOn_table_to_export"),
        },
        {
            isFilterItemShow: isShipmentProduct,
            key: "3",
            label: "Shipment Excel",
            onClick: () => handleShipmentExport && handleShipmentExport("xlsx"),
        },
        {
            isFilterItemShow: isShipmentProduct,
            key: "4",
            label: "Shipment CSV",
            onClick: () => handleShipmentExport && handleShipmentExport("csv"),
        },
        {
            isFilterItemShow: isShipmentProduct,
            key: "5",
            label: "Shipment PDF",
            onClick: () => handleShipmentExport && handleShipmentExport("pdf"),
        },
        {
            isFilterItemShow: isDeliveryRequest,
            key: "6",
            label: "Delivery Excel",
            onClick: () => handleDeliveryExport && handleDeliveryExport("xlsx"),
        },
        {
            isFilterItemShow: isDeliveryRequest,
            key: "7",
            label: "Delivery PDF",
            onClick: () => handleDeliveryExport && handleDeliveryExport("pdf"),
        },
    ];

    const harvestJobCreateHandler = () => {
        if (!harvestKey) {
            throw new Error("harvestKey is required");
        }

        const { product_status, ...remainingFilters } = filters;
        const payload: IHarvestJobCreate<object, object> = {
            type: harvestKey,
            context: {
                filters: {
                    ...remainingFilters,
                    statuses: product_status,
                },
            },
            settings: {
                notification: {
                    email: true,
                    sms: true,
                },
            },
        };

        try {
            createHarvest(payload);
        } catch (_e) {
            /* empty */
        }
    };

    const menuHarvest: ExtendedMenuItem[] = [
        {
            key: "1",
            label: "CSV",
            onClick: harvestJobCreateHandler,
            isFilterItemShow: true,
        },
    ];

    if (filters && Object.keys(filters).length <= 0) {
        return <></>;
    }
    return (
        <div
            style={{
                backgroundColor: tw.colors.white,
                borderRadius: tw.borderRadius.DEFAULT,
                boxShadow: tw.boxShadow.DEFAULT,
                padding: isMobile ? tw.padding[3] : tw.padding[5],
                marginBottom: 24,
            }}
        >
            <>
                <Flex justify="end">
                    <Button
                        style={{
                            border: tw.borderWidth[0],
                        }}
                        onClick={() => setFilterVisible(!filterVisible)}
                    >
                        <DownOutlined
                            style={{
                                textAlign: "end",
                                transform: `rotate(${tw.rotate[filterVisible ? 0 : 90]})`,
                                transition: tw.transitionProperty.transform,
                                transitionDuration: tw.transitionDelay[300],
                            }}
                        />
                    </Button>
                </Flex>

                {filterVisible && (
                    <Form
                        onFinish={handleProductFilter}
                        labelCol={{ span: 12 }}
                        layout="vertical"
                        size={filterSize ? (filterSize as SizeType) : "middle"}
                    >
                        <Row gutter={{ lg: 24 }}>
                            {filterData &&
                                Array.isArray(filterData) &&
                                filterData.map((el) => {
                                    const fieldElement = filtersData[el];
                                    const fieldProps = {
                                        type: fieldElement.type,
                                        options: fieldElement.values,
                                        handleChange: handleFilterChange,
                                        name: el,
                                        label: fieldElement.label,
                                        placeholder: fieldElement?.placeholder
                                            ? fieldElement.placeholder
                                            : null,
                                        fieldValue: filters[el],
                                        resource: fieldElement?.resource
                                            ? fieldElement.resource
                                            : null,
                                        data: fieldElement?.data
                                            ? fieldElement.data
                                            : null,
                                    };

                                    return (
                                        <CustomElement
                                            availableRegionId={
                                                availableRegionId
                                            }
                                            key={el}
                                            {...fieldProps}
                                        />
                                    );
                                })}
                        </Row>
                        <Flex
                            justify={
                                Object.keys(filtersData).length > 8
                                    ? "space-between"
                                    : "end"
                            }
                        >
                            {Object.keys(filtersData).length > 8 && (
                                <Button
                                    style={{
                                        border: tw.borderWidth[0],
                                    }}
                                    onClick={() => setExpand(!expand)}
                                >
                                    <RightOutlined
                                        style={{
                                            transform: `rotate(-${tw.rotate[expand ? 90 : 0]})`,
                                            transition:
                                                tw.transitionProperty.transform,
                                            transitionDuration:
                                                tw.transitionDelay[300],
                                        }}
                                    />
                                    See {!expand ? "more" : "less"}
                                </Button>
                            )}

                            <Form.Item noStyle style={{ width: tw.width.full }}>
                                <Space>
                                    {!isFromProductReceived && (
                                        <Dropdown
                                            menu={{
                                                items: menuItems.filter(
                                                    (item) =>
                                                        item.isFilterItemShow,
                                                ),
                                            }}
                                        >
                                            <Button
                                                type={"primary"}
                                                style={{ marginLeft: 5 }}
                                                // icon={<HddFilled />}
                                            >
                                                Export <DownOutlined />
                                            </Button>
                                        </Dropdown>
                                    )}
                                    {isShipmentProduct && (
                                        <Button
                                            onClick={() =>
                                                handleShipmentExport &&
                                                handleShipmentExport(
                                                    "packing-list",
                                                )
                                            }
                                            type="dashed"
                                        >
                                            Packing List
                                        </Button>
                                    )}
                                    {extra && (
                                        <Button
                                            style={{ width: "100%" }}
                                            type="primary"
                                            icon={<DownloadOutlined />}
                                        >
                                            EXCEL
                                        </Button>
                                    )}
                                    {!isSubFilter && (
                                        <Button
                                            style={{ width: "100%" }}
                                            onClick={() => {
                                                handelFilterClear();
                                            }}
                                            type="dashed"
                                        >
                                            Clear
                                        </Button>
                                    )}
                                    {harvestKey && (
                                        <Dropdown
                                            menu={{
                                                items: menuHarvest.filter(
                                                    (item) =>
                                                        item.isFilterItemShow,
                                                ),
                                            }}
                                        >
                                            <Button
                                                type={"primary"}
                                                style={{ marginLeft: 5 }}
                                                // icon={<HddFilled />}
                                            >
                                                Export <DownOutlined />
                                            </Button>
                                        </Dropdown>
                                    )}
                                    <Button
                                        style={{ width: "100%" }}
                                        type="primary"
                                        htmlType={"submit"}
                                        loading={isLoading}
                                        icon={<SearchOutlined />}
                                    >
                                        Submit
                                    </Button>
                                </Space>
                            </Form.Item>
                        </Flex>
                    </Form>
                )}
            </>
        </div>
    );
};

export default FiltersComponent;
