import {
    DownOutlined,
    EditOutlined,
    HddFilled,
    PlusOutlined,
} from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import {
    Button,
    Col,
    Dropdown,
    Modal,
    Row,
    Table,
    Tag,
    Typography,
} from "antd";
import { StatusTag } from "@/components";
import FiltersComponent from "@/components/FiltersComponent";
import useDataFilters from "@/hooks/useDataFilters";
import { useAtom } from "jotai";
import { useAdminUserList } from "@/lib/core-react/hooks/private/useAdminUser";
import useWindowWidth from "@/lib/core-react/hooks/public/useWindowWidth";
import { adminUserCollection } from "@/lib/core-react/store/store";
import { ApiHelperModel } from "@/models/apiHelper";
import { PaginationModel } from "@/models/pagination";
import { UserCollectionModel, UserModel } from "@/models/userCollectionModel";
import { useEffect, useState } from "react";
import { IFilterType } from "@/types/filters";
import { formatDate } from "@/utils/helpers";
import { v4 } from "uuid";
import UpdateAdminUser from "./components/updateAdminUser";
import { MenuInfo } from "rc-menu/lib/interface";
import CreateAdminUser from "./components/createAdminUser";
import { generateColor } from "@/utils/helper";
import { RoleCollectionModel } from "@/models/roleCollectionModel";
import UpdateAdminUserRoleOnly from "./components/updateOnlyRoles";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ExtendedMenuItemType } from "@/types";
import { ADMIN_USER_PERMISSION_ENUM } from "@/consts/permission-enum/admin-user-enum";
import { useGetRole } from "@/lib/core-react/hooks/private";
import QueryString from "qs";
import { responseTypeQuery } from "@/filters/constant";
import { FiLogIn } from "react-icons/fi";
import ImpersonateForm from "@/components/ImpersonateSessionCreate/impersonate";
import { UserType } from "@/types/userCollection";
import ImpersonateCreateModalTitle from "@/components/ImpersonateFormModalTitle";
import CommonError from "@/components/Error/CommonError";
import ResetAdminUserPassword from "@/pages/AdminManage/User/components/resetAdminUserPassword.tsx";

export const AdminUser = () => {
    const { getAdminUser } = useAdminUserList();
    const [
        { data: adminUserData, isLoading, refetch, code, error, unAuthorized },
    ] = useAtom(adminUserCollection);
    const { isMobile } = useWindowWidth();

    const [isShowUpdateModal, setIsShowUpdateModal] = useState<boolean>(false);
    const [isShowResetPassModal, setIsShowResetPassModal] =
        useState<boolean>(false);
    const [selectedAdminUser, setSelectedAdminUser] = useState<
        UserModel | undefined
    >(undefined);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedRoles, setSelectedRoles] = useState<UserModel | undefined>(
        undefined,
    );
    const [isShowCreateModal, setIsShowCreateModal] = useState<boolean>(false);
    const [isShowImpersonateModal, setIsShowImpersonateModal] =
        useState<boolean>(false);

    const { getRole } = useGetRole();

    const { Text } = Typography;
    const {
        filters,
        isFirstCall,
        isFetched,
        initializeAvailableFilter,
        handleFilterChange,
        handelFilterClear,
        refetch: refetchFromFilter,
    } = useDataFilters();

    // Api Call
    useEffect(() => {
        if (
            (filters && !isFetched && isFirstCall) ||
            refetch ||
            refetchFromFilter
        ) {
            ApiHelperModel.makeGetRequest(filters, getAdminUser);
        }
    }, [isFirstCall, filters, isFetched, refetch, refetchFromFilter]);

    const adminUserCollectionData =
        adminUserData && new UserCollectionModel(adminUserData);

    // Filter
    const filterData = adminUserCollectionData?.filters;

    // Pagination
    const paginationData = adminUserCollectionData?.pagination;

    // Getting all available filters
    useEffect(() => {
        if (!isFetched && adminUserCollectionData?.filters) {
            initializeAvailableFilter(filterData as IFilterType);
        }
    }, [
        isFetched,
        initializeAvailableFilter,
        adminUserCollectionData?.filters,
    ]);

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getAdminUser,
        );
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        adminUserCollectionData,
        handlePaginationChange,
    );

    // Filter Handler
    const handleProductFilter = () => {
        ApiHelperModel.makeGetRequest(filters, getAdminUser);
    };

    const handleMenuClick = async (e: MenuInfo, record: UserModel) => {
        setSelectedAdminUser(record);
        if (e.key === "update") {
            setIsShowUpdateModal(true);
            await getRole(QueryString.stringify(responseTypeQuery.minimal));
        } else if (e.key === "reset-password") {
            setIsShowResetPassModal(true);
            await getRole(QueryString.stringify(responseTypeQuery.minimal));
        } else if (e.key === "login") {
            setIsShowImpersonateModal(true);
        }
    };

    const handleRoleChange = async (value) => {
        setIsModalVisible(true);
        setSelectedRoles(value);
        await getRole(QueryString.stringify(responseTypeQuery.minimal));
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const columns = [
        {
            title: "#SL",
            dataIndex: "id",
            key: "sl",
            align: "center" as const,
            width: 70,
            render: (_: string, __: UserModel, index: number) => {
                return (
                    <div>
                        {paginationData
                            ? (paginationData.current_page - 1) *
                                  paginationData.per_page +
                              index +
                              1
                            : ""}
                    </div>
                );
            },
        },
        {
            title: "Customer Details",
            dataIndex: "name",
            hidden: !isMobile,
            key: "name",
            render: (_text: string, record: UserModel) => {
                const status = record.is_active === 1 ? "Active" : "Inactive";
                return (
                    <>
                        <span style={{ fontWeight: "bold" }}>Name: </span>{" "}
                        <Text>{record.getName()}</Text>
                        <br />
                        <span style={{ fontWeight: "bold" }}>Email: </span>
                        <Text>{record.getEmail()}</Text>
                        <br />
                        <span style={{ fontWeight: "bold", marginTop: 10 }}>
                            Status:{" "}
                        </span>
                        <StatusTag
                            text={status.toLowerCase()}
                            slug={status.toLowerCase()}
                        />
                        <br />
                        <span style={{ fontWeight: "bold", marginTop: 10 }}>
                            Created At:
                        </span>{" "}
                        <Tag style={{ marginTop: 10 }} color="purple">
                            {formatDate(record.getCreatedAt())}
                        </Tag>
                    </>
                );
            },
        },
        {
            title: "Name",
            dataIndex: "name",
            hidden: isMobile,
            key: "name",
            width:80,
            render: (text: string) => {
                return (
                    <>
                        <Row>{text}</Row>
                    </>
                );
            },
        },
        {
            title: "Email",
            dataIndex: "email",
            hidden: isMobile,
            key: "email",
            render: (email: string) => {
                return (
                    <>
                        <Text>{email}</Text>
                    </>
                );
            },
        },
        {
            title: "Role",
            dataIndex: "roles",
            key: "roles",
            hidden: !isMobile,
            render: (role: RoleCollectionModel, record: UserModel) => {
                if (role.data.length === 0) {
                    return <Text type="danger">No Available Roles</Text>;
                }
                return (
                    <>
                        {role.data.map((singleRole, index) => (
                            <Tag
                                style={{ marginTop: 10 }}
                                key={index}
                                color={generateColor(singleRole.name)}
                            >
                                {singleRole.name}
                            </Tag>
                        ))}
                        {checkActionPermission(
                            ADMIN_USER_PERMISSION_ENUM.ADMIN_USER_MANAGE,
                            <EditOutlined
                                onClick={() => handleRoleChange(record)}
                            />,
                            null,
                        )}
                    </>
                );
            },
        },
        {
            title: "Role",
            dataIndex: "roles",
            hidden: isMobile,
            key: "roles",
            render: (role: RoleCollectionModel, record: UserModel) => {
                if (role.data.length === 0) {
                    return <Text type="danger">No Available Roles</Text>;
                }
                return (
                    <>
                        {role.data.map((singleRole, index) => (
                            <Tag
                                style={{ marginTop: 10 }}
                                key={index}
                                color={generateColor(singleRole.name)}
                            >
                                {singleRole.name}
                            </Tag>
                        ))}
                        {checkActionPermission(
                            ADMIN_USER_PERMISSION_ENUM.ADMIN_USER_MANAGE,
                            <EditOutlined
                                onClick={() => handleRoleChange(record)}
                            />,
                            null,
                        )}
                    </>
                );
            },
        },
        {
            title: "Active",
            dataIndex: "is_active",
            hidden: isMobile,
            key: "is_active",
            render: (is_active: number) => {
                const status = is_active === 1 ? "Active" : "Inactive";
                return (
                    <>
                        <StatusTag
                            text={status.toLowerCase()}
                            slug={status.toLowerCase()}
                        />
                    </>
                );
            },
        },
        {
            title: "Created At",
            dataIndex: "created_at",
            hidden: isMobile,
            key: "created_at",
            render: (created_at: string) => {
                return (
                    <>
                        <Tag color="purple">{formatDate(created_at)}</Tag>
                    </>
                );
            },
        },
        {
            title: "Actions",
            dataIndex: "actions",
            fixed: isMobile ? "right" : undefined,
            hidden: !checkActionPermission(
                [
                    ADMIN_USER_PERMISSION_ENUM.ADMIN_USER_MANAGE,
                    ADMIN_USER_PERMISSION_ENUM.ADMIN_IMPERSONATE_SESSION_CREATE,
                ],
                "permissionCheck",
                null,
            ),
            width: 100,
            render: (_: string, record: UserModel) => {
                const items: ExtendedMenuItemType[] = [
                    {
                        icon: <EditOutlined />,
                        label: "Update",
                        key: "update",
                        permission:
                            ADMIN_USER_PERMISSION_ENUM.ADMIN_USER_MANAGE,
                        onClick: async (e) => handleMenuClick(e, record),
                    },
                    {
                        icon: <EditOutlined />,
                        label: "Reset Password",
                        key: "reset-password",
                        permission:
                            ADMIN_USER_PERMISSION_ENUM.ADMIN_USER_MANAGE,
                        onClick: async (e) => handleMenuClick(e, record),
                    },
                    {
                        icon: <FiLogIn />,
                        label: "Create Impersonate",
                        key: "login",
                        permission:
                            ADMIN_USER_PERMISSION_ENUM.ADMIN_IMPERSONATE_SESSION_CREATE,
                        onClick: async (e) => handleMenuClick(e, record),
                    },
                ];

                return (
                    <Dropdown
                        menu={{
                            items: items.filter((x) =>
                                checkActionPermission(x.permission, x, null),
                            ),
                        }}
                    >
                        <Button icon={<HddFilled />}>
                            {isMobile ? (
                                <DownOutlined />
                            ) : (
                                <>
                                    Actions <DownOutlined />{" "}
                                </>
                            )}
                        </Button>
                    </Dropdown>
                );
            },
        },
    ];

    if (!isLoading && error) {
        return (
            <CommonError
                unAuthorized={unAuthorized}
                message={error}
                code={code}
            />
        );
    }

    return (
        <>
            <Row>
                <Col span={24}>
                    <PageHeader
                        ghost={false}
                        style={{ marginTop: "10px" }}
                        title={"Admin User"}
                        extra={[
                            checkActionPermission(
                                ADMIN_USER_PERMISSION_ENUM.ADMIN_USER_MANAGE,
                                <Button
                                    key={v4()}
                                    onClick={() => {
                                        setIsShowCreateModal(true);
                                    }}
                                    icon={<PlusOutlined />}
                                    type="primary"
                                >
                                    Create Admin
                                </Button>,
                                null,
                            ),
                        ]}
                        onBack={() => window.history.back()}
                    >
                        {filters && Object.keys(filters).length > 0 && (
                            <Row>
                                <Col span={24}>
                                    <FiltersComponent
                                        handleProductFilter={
                                            handleProductFilter
                                        }
                                        handleFilterChange={handleFilterChange}
                                        handelFilterClear={handelFilterClear}
                                        isFetched={isFetched}
                                        filters={filters}
                                        filtersData={filterData}
                                        isFromProductReceived={true}
                                    />
                                </Col>
                            </Row>
                        )}
                        <Table
                            style={{ marginTop: 10 }}
                            //@ts-ignore
                            columns={columns}
                            dataSource={adminUserCollectionData?.data}
                            loading={isLoading}
                            size="middle"
                            pagination={paginationConfig}
                            rowKey="id"
                            scroll={{ x: 700 }}
                        />
                    </PageHeader>
                </Col>
            </Row>
            {selectedAdminUser && (
                <Modal
                    title={`Update Admin User`}
                    open={isShowUpdateModal}
                    footer={false}
                    onCancel={() => setIsShowUpdateModal(false)}
                    destroyOnClose={true}
                >
                    <UpdateAdminUser
                        selectedAdminUser={selectedAdminUser}
                        setIsShowUpdateModal={setIsShowUpdateModal}
                    />
                </Modal>
            )}
            {selectedAdminUser && (
                <Modal
                    title={`Reset Admin User Password`}
                    open={isShowResetPassModal}
                    footer={false}
                    onCancel={() => setIsShowResetPassModal(false)}
                    destroyOnClose={true}
                >
                    <ResetAdminUserPassword
                        selectedAdminUser={selectedAdminUser}
                        setIsShowResetPassModal={setIsShowResetPassModal}
                    />
                </Modal>
            )}
            {isShowImpersonateModal && selectedAdminUser && (
                <Modal
                    title={
                        <ImpersonateCreateModalTitle
                            name={selectedAdminUser.getName()}
                            email={selectedAdminUser.getEmail()}
                            title=""
                        />
                    }
                    open={isShowImpersonateModal}
                    destroyOnClose={true}
                    footer={false}
                    onCancel={() => setIsShowImpersonateModal(false)}
                >
                    <ImpersonateForm
                        userType={UserType.Admin}
                        selectedUser={{ id: selectedAdminUser.getId() }}
                        setIsModalVisible={setIsShowImpersonateModal}
                    />
                </Modal>
            )}

            {isShowCreateModal && (
                <Modal
                    title={`Create Admin User`}
                    open={isShowCreateModal}
                    destroyOnClose={true}
                    footer={false}
                    onCancel={() => setIsShowCreateModal(false)}
                >
                    <CreateAdminUser
                        setIsShowCreateModal={setIsShowCreateModal}
                    />
                </Modal>
            )}

            {selectedRoles && (
                <Modal
                    title="Select Roles"
                    open={isModalVisible}
                    footer={false}
                    onCancel={handleCancel}
                    destroyOnClose={true}
                >
                    <UpdateAdminUserRoleOnly
                        selectedRoles={selectedRoles}
                        setIsModalVisible={setIsModalVisible}
                    />
                </Modal>
            )}
        </>
    );
};
