import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ADMIN_BUY_TAKE_ACTION_PERMISSION_ENUM } from "@/consts/permission-enum/take-actions";
import {
    IActionsProps,
    IBuyProductBulkActionsProps,
} from "@/helpers/getModalTital";
import { BuyProductModel } from "@/models/buyProductCollectionModel";
import { ExtendedMenuItemType } from "@/types";
import {
    CheckCircleOutlined,
    CheckCircleTwoTone,
    CloseCircleOutlined,
    DownloadOutlined,
    EditOutlined,
    FunctionOutlined,
    InteractionOutlined,
    PayCircleOutlined,
} from "@ant-design/icons";
import { ADMIN_PURCHASE_PERMISSION_ENUM } from "@/consts/permission-enum/purchase-enum";
import { notification } from "antd";
import { ADMIN_INVOICE_PERMISSION_ENUM } from "@/consts/permission-enum/invoice-enum";
import { useInvoice } from "@/lib/core-react/hooks/private/useInvoice";
import { RegionModel } from "@/models/regionCollectionModel";

export const useBuyProductActions = ({ handleModal }: IActionsProps) => {
    const {
        handledownloadData,
        createSession,
        isLoading: isLoadingSession,
    } = useInvoice();

    const handlePayCustomerInvoice = async (
        region: RegionModel,
        user_id: number,
        invoice_ids: number[],
    ) => {
        const res = await createSession(user_id, invoice_ids);

        if (res) {
            handleModal({
                action: "pay-invoice",
                data: { region, user_id, token: res.data.session_token },
            });
        }
    };

    const handleDownloadInvoice = async (invoiceId: number) => {
        try {
            const response = await handledownloadData(invoiceId, "customer");

            if (response?.data?.download_url) {
                const downloadUrl = response.data.download_url;

                const link = document.createElement("a");
                link.href = downloadUrl;
                link.download = "";
                link.target = "_blank";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                console.error("Download URL not found in response");
            }
        } catch (error) {
            console.error("Error downloading invoice:", error);
        }
    };

    const getActions = (buyProduct: BuyProductModel) => {
        const invoiceId = buyProduct.getCustomerInvoice()?.getId();
        const userId = buyProduct.getUserId();
        const region = buyProduct.getRegion();

        const shouldDisplayPayMenuItem = () => {
            const customerInvoice = buyProduct.getCustomerInvoice();
            if (customerInvoice) {
                return customerInvoice.getTransactionSummary().getDue() > 0;
            }
            return false;
        };

        const items: ExtendedMenuItemType[] = [
            {
                permission: [
                    ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_FX_UPDATE,
                ],
                label: "Update Fx",
                key: "update_fx",
                icon: <FunctionOutlined />,
                onClick: () => {
                    handleModal({
                        action: "update_fx",
                        data: buyProduct,
                    });
                },
            },
            {
                permission: [
                    ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_FX_UPDATE,
                ],
                key: "set_current_fx",
                label: "Set Current FX",
                icon: <FunctionOutlined />,
                onClick: () => {
                    handleModal({
                        action: "set_current_fx",
                        data: { productId: buyProduct.getId() },
                    });
                },
            },
            {
                permission: [
                    ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_SHIPMENT_UPDATE,
                ],
                key: "update_shipping_price_buy_product",
                icon: <EditOutlined />,

                onClick: () =>
                    handleModal({
                        action: "update_shipping_price_buy_product",
                        data: buyProduct,
                    }),
                label: "Edit Shipping Price",
            },
            {
                permission: [
                    ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_SHIPMENT_UPDATE,
                ],
                key: "update_agent_category_price_read",
                icon: <EditOutlined />,

                onClick: () =>
                    handleModal({
                        action: "update_agent_category_price_read",
                        data: buyProduct,
                    }),
                label: "Edit Shipping Info",
            },
            {
                permission: [
                    ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_CANCEL,
                ],
                label: "Cancel",
                key: "buy-product-cancel",
                icon: <CloseCircleOutlined />,
                onClick: () =>
                    handleModal({
                        action: "cancel_buy_product",
                        data: buyProduct,
                    }),
            },
            {
                permission: ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_VIEW,
                label: isLoadingSession ? "Wait..." : "Download Invoice",
                key: "download-invoice",
                icon: <DownloadOutlined />,
                disabled: isLoadingSession,
                onClick: () => handleDownloadInvoice(Number(invoiceId)),
            },
            ...(shouldDisplayPayMenuItem()
                ? [
                      {
                          label: isLoadingSession
                              ? "Wait..."
                              : "Pay Customer Invoice",
                          key: "pay-customer-invoice",
                          permission:
                              ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_PAY_CUSTOMER_INVOICE,
                          icon: <PayCircleOutlined />,
                          onClick: async () => {
                              if (userId && region) {
                                  handlePayCustomerInvoice(region, userId, [
                                      Number(invoiceId),
                                  ]);
                              } else {
                                  notification.error({
                                      message: "region and user id not found",
                                  });
                              }
                          },
                          disabled: isLoadingSession,
                      },
                  ]
                : []), // Only include Pay if due > 0
        ];

        if (buyProduct.isCanTakeActions()) {
            items.unshift({
                permission:
                    ADMIN_BUY_TAKE_ACTION_PERMISSION_ENUM.ADMIN_BUY_ACTION_ADD,
                key: "4",
                icon: <InteractionOutlined />,
                onClick: () =>
                    handleModal({
                        action: "take_actions_buy_product",
                        data: buyProduct,
                    }),
                label: "Take Actions",
            } as ExtendedMenuItemType);
        }

        if (
            buyProduct.status === "buy-pending" &&
            buyProduct.source_type === "MoveOn\\RFQ\\Models\\RfqQuote"
        ) {
            items.unshift(
                {
                    permission: [
                        ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_APPROVE,
                    ],
                    key: "approve_rfq_product",
                    icon: <CheckCircleOutlined />,
                    onClick: () =>
                        handleModal({
                            action: "approve_rfq_product",
                            data: buyProduct,
                        }),

                    label: "Approve RFQ Product",
                },
                {
                    permission: [
                        ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_REJECT,
                    ],
                    label: "Reject",
                    key: "reject_buy_product",
                    icon: <CloseCircleOutlined />,

                    onClick: () =>
                        handleModal({
                            action: "reject_buy_product",
                            data: { productId: buyProduct.getId() },
                        }),
                },
            );
        } else if (
            buyProduct.status === "buy-pending" &&
            buyProduct.source_type !== "MoveOn\\RFQ\\Models\\RfqQuote"
        ) {
            items.unshift(
                {
                    permission: [
                        ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_APPROVE,
                    ],
                    key: "approve_and_assign_agent",
                    icon: <CheckCircleOutlined />,
                    onClick: () =>
                        handleModal({
                            action: "approve_and_assign_agent",
                            data: buyProduct,
                        }),

                    label: "Approve",
                },
                {
                    permission: [
                        ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_REJECT,
                    ],
                    label: "Reject",
                    key: "reject_buy_product",
                    icon: <CloseCircleOutlined />,

                    onClick: () =>
                        handleModal({
                            action: "reject_buy_product",
                            data: { productId: buyProduct.getId() },
                        }),
                },
            );
        }

        return items.filter((x) =>
            checkActionPermission(x.permission, x, null),
        );
    };

    return { getActions };
};

const verifyEligibleStatus = (selectedRows: BuyProductModel[]) => {
    // allowed statuses
    const allowedStatuses = ["buy-pending", "buy-reject", "buy-approved"];

    // Check if all selected rows have a status in the allowed list
    const isAllSelectedRowsInAllowedStatus = selectedRows.every((row) =>
        allowedStatuses.includes(row.status),
    );

    if (!isAllSelectedRowsInAllowedStatus) {
        notification.error({
            message: "Error",
            description:
                "Only products with status 'Pending', 'Reject' or 'Approved' can be approved.",
        });
        return false;
    }

    return true;
};

export const useBuyProductBulkActions = ({
    selectedRows,
    handleModal,
}: IBuyProductBulkActionsProps) => {
    const validSelectedRows = selectedRows.filter((row) => {
        const transactionSummary = row
            .getCustomerInvoice()
            ?.getTransactionSummary();
        const due = transactionSummary?.due;

        return typeof due === "number" && due > 0;
    });

    const uniqueRegions = Array.from(
        new Set(
            validSelectedRows
                .map((row) => row.getRegion())
                .filter((region) => region !== undefined && region !== null),
        ),
    );

    const uniqueRegionIds = Array.from(
        new Set(
            validSelectedRows
                .map((row) => row.getRegion()?.getId())
                .filter((region) => region !== undefined && region !== null),
        ),
    );

    const uniqueCustomerIds = Array.from(
        new Set(validSelectedRows.map((row) => row.getUserId())),
    );

    const uniqueInvoiceIds = Array.from(
        new Set(
            validSelectedRows
                .map((row) => row.getCustomerInvoiceId())
                .filter((id) => id !== undefined && id !== null),
        ),
    );

    const { createSession, isLoading: isLoadingSession } = useInvoice();

    const handlePayCustomerInvoice = async () => {
        if (uniqueCustomerIds.length > 1) {
            return notification.error({
                message: "Error",
                description: "Selected rows must belong to a single customer",
            });
        }
        if (uniqueRegionIds.length > 1) {
            return notification.error({
                message: "Error",
                description: "Selected rows must belong to a single region",
            });
        }

        if (uniqueInvoiceIds.length === 0) {
            return notification.error({
                message: "Error",
                description: "No invoices have dues",
            });
        }
        if (uniqueCustomerIds.length > 0 && uniqueCustomerIds[0]) {
            const res = await createSession(
                uniqueCustomerIds[0],
                uniqueInvoiceIds,
            );

            if (res) {
                handleModal({
                    action: "pay-invoice",
                    data: {
                        region: uniqueRegions[0],
                        user_id: uniqueCustomerIds[0],
                        token: res.data.session_token,
                    },
                });
            }
        } else {
            notification.error({
                message: "user not found",
            });
        }
    };

    const shouldDisplayPayMenuItem = () => {
        return validSelectedRows.length;
    };

    const getActions = (selectedRows: BuyProductModel[]) => {
        const bulkItems: ExtendedMenuItemType[] = [
            {
                permission:
                    ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_APPROVE,
                icon: <CheckCircleTwoTone />,
                label: "Approved Bulk",
                key: "approve-and-assign-agent",
                onClick: () => {
                    if (!verifyEligibleStatus(selectedRows)) {
                        return;
                    }
                    handleModal({
                        action: "approved_buy_product_bulk",
                        data: null,
                    });
                },
            },
            {
                permission:
                    ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_REJECT,
                key: "reject Bulk",
                icon: <CloseCircleOutlined />,
                onClick: () =>
                    handleModal({
                        data: null,
                        action: "reject_bulk_buy_product",
                    }),
                label: "Reject Products",
            },
            ...(shouldDisplayPayMenuItem()
                ? [
                      {
                          label: isLoadingSession
                              ? "Wait..."
                              : `Pay Customer Invoice (${validSelectedRows.length})`,
                          key: "pay-customer-invoice",
                          permission:
                              ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_PAY_CUSTOMER_INVOICE,
                          icon: <PayCircleOutlined />,
                          onClick: () => handlePayCustomerInvoice(),
                          disabled: isLoadingSession,
                      },
                  ]
                : []), // Only include Pay if due > 0
        ];

        return bulkItems.filter((x) =>
            checkActionPermission(x.permission, x, null),
        );
    };

    return { getActions };
};
