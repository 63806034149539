import { Tag } from "antd";
import {
    STATUS_REJECT,
    STATUS_PENDING,
    STATUS_APPROVED,
    STATUS_PURCHASED,
    STATUS_NOT_PURCHASED,
    STATUS_HANDOVER,
    STATUS_ARRIVED_AT_WAREHOUSE,
    STATUS_PREPARING_FOR_TRANSPORT,
    STATUS_HANDOVER_TO_AIRLINE,
    STATUS_CUSTOM_RELEASED,
    STATUS_AGENT_PROCESSED,
    STATUS_RECEIVED_BY_MOVEON,
    STATUS_DELIVERY_PENDING,
    STATUS_PRODUCT_CANCELLED,
    STATUS_CANCEL_INITIATED,
    STATUS_REFUND_INITIATED,
    STATUS_REFUNDED,
} from "@/consts/buy-product-statuses";
import { DisputeMandateStatusEnum } from "@/enums/disputeCollectionEnum";
import {
    InvoiceItemStatusEnum,
    OrderTypeEnum,
} from "@/types/InvoiceCollection";
import { InvoiceStatus } from "@/models/invoiceCollectionModel";

import { regionColors, statusColor, storeColors } from "@/consts/appConstants";
import {
    CampaignStatusEnum,
    PublishStatusEnum,
} from "@/enums/campaignCollectionEnums";
import { formatStatus } from "@/helpers/formatStatus";
type Status =
    | InvoiceItemStatusEnum
    | OrderTypeEnum
    | InvoiceStatus
    | DisputeMandateStatusEnum
    | string;

const StatusTag = (props: {
    text: string;
    slug: Status;
    minWidth?: number;
    color?: string;
}) => {
    const { text, slug, minWidth, color } = props;
    let colorCode = "default";

    switch (slug) {
        case "handover-to-shipping":
        case "handover-to-airline":
        case "handover-to Airline":
        case "arrived-at-destination-airport":
        case "customs-released":
        case "agent-processed":
        case "initial":
        case "buy-refund-initiated":
        case STATUS_PENDING:
        case STATUS_DELIVERY_PENDING:
        case CampaignStatusEnum.AWAITING_PUBLISH:
        case "Processing":
        case "processing":
            colorCode = "processing";
            break;

        case "buy-processed":
        case "received-by-moveon":
        case "arrived-at-warehouse":
        case "buy-purchased":
        case "buy-approved":
        case "resolved":
        case "Approved":
        case "approved":
        case "dispute-approved":
        case "invoice-payment-paid":
        case "request_approved":
        case "available":
        case STATUS_PURCHASED:
        case STATUS_APPROVED:
        case STATUS_HANDOVER:
        case STATUS_CUSTOM_RELEASED:
        case STATUS_AGENT_PROCESSED:
        case STATUS_RECEIVED_BY_MOVEON:
        case DisputeMandateStatusEnum.AGREED:
        case PublishStatusEnum.published:
        case CampaignStatusEnum.RUNNING:
        case "payment":
        case "finished":
            colorCode = "green";
            break;

        case "preparing-for-transport":
        case "buy-pending":
        case "Pending":
        case "buy-processing":
        case "request_pending":
        case STATUS_ARRIVED_AT_WAREHOUSE:
        case STATUS_PREPARING_FOR_TRANSPORT:
        case STATUS_HANDOVER_TO_AIRLINE:
        case "refund":
        case "cancel":
        case STATUS_CANCEL_INITIATED:
        case STATUS_REFUND_INITIATED:
        case STATUS_REFUNDED:
        case CampaignStatusEnum.UPCOMING:
        case "invoice-payment-unpaid":
            colorCode = "warning";
            break;

        case "buy-reject":
        case "rejected":
        case "Rejected":
        case "canceled":
        case "request_reject":
        case STATUS_NOT_PURCHASED:
        case STATUS_REJECT:
        case STATUS_PRODUCT_CANCELLED:
            colorCode = "error";
            break;

        case "closed":
        case CampaignStatusEnum.PAUSED:
            colorCode = "red";
            break;
        case CampaignStatusEnum.END:
        case "settlement":
            colorCode = "cyan";
            break;
        case "open-for-everyone":
        case "active":
        case "credit":
            colorCode = "#24b263";
            break;
        case "pending":
        case "dispute-pending":
            colorCode = "lime";
            break;
        case "debit":
            colorCode = "#C80036";
            break;
        case "BD":
        case "IN":
        case "AE":
        case "US":
        case "EU":
        case "CN":
            colorCode = regionColors[slug];
            break;

        case "BDT":
        case "AED":
        case "INR":
        case "EUR":
        case "USD":
        case "CNY":
            colorCode = regionColors[slug];
            break;

        case "daraz":
        case "amazon_ae":
        case "indiamart":
        case "ebay":
        case "pinduoduo":
        case "myntra":
        case "india_mart":
        case "tmall":
        case "yangkeduo":
        case "amazon_in":
        case "noon":
        case "gearbest":
        case "alibaba":
        case "taobao":
        case "flipkart":
        case "amazon":
        case "aliexpress":
        case "onesixeighteight":
            colorCode = storeColors[slug];
            break;

        // STATUS COLOR
        case "shipment-pending":
        case "shipment-approved":
        case "shipment-rejected":
        case "shipment-assigned-agent":
        case "shipment-shipping-started":
        case "shipment-not-arrived":
        case "shipment-shipping-cancelled":
        case "shipment-arrived-at-warehouse":
        case "shipment-handover-to-travel-agent":
        case "shipment-handover-to-moveon":
        case "shipment-preparing-for-transport":
        case "shipment-handover-to-ship":
        case "shipment-handover-to-airline":
        case "shipment-arrived-at-destination-port":
        case "shipment-arrived-at-destination-airport":
        case "shipment-customs-released":
        case "shipment-received-by-moveon":
        case "delivery-pending":
        case "delivery-processing":
        case "delivery-ready":
        case "delivery-shipped":
        case "delivery-failed":
        case "delivery-returned":
        case "delivered-to-customer":
        case "unsettled":
        case "partially-settled":
        case "settled":
        case "awaiting-fulfilment":
        case "fulfilled":
        case "partially-refunded":
        case "refunded":
        case "only-ship":
        case "buy-and-ship":
        case "cancelled":
        case "buy-cancelled":
        case "buy-handover-to-shipping":
        case "paid":
        case "unpaid":
        case "partial-paid":
        case "due":
            colorCode = statusColor[slug];
            break;
        default:
            colorCode = "gray";
            break;
    }

    return (
        <Tag
            style={{
                minWidth: minWidth || 70,
                textAlign: "center",
                fontWeight: "bold",
            }}
            color={color || colorCode}
        >
            {formatStatus(text).toUpperCase()}
        </Tag>
    );
};

export default StatusTag;
