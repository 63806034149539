import {
    Button,
    Flex,
    Form,
    InputNumber,
    Modal,
    Row,
    Select,
    Space,
    Typography,
    notification,
} from "antd";
import React, { useEffect } from "react";
import { DeleteTwoTone, PlusSquareTwoTone } from "@ant-design/icons";

import {
    useApprovedShippingProductForProduct,
    useDefaultAgentAssignWithPrice,
} from "@/lib/core-react/hooks/private/useShipping";
import { IDefaultWithPriceAssignAgentPayload } from "@/types/shipmentProductCollection";
import { ICategoryPriceRequestParams } from "@/types/agentCategoryPriceReadCollection";

import { WeightUnitEnum } from "@/enums/shipForMeContextEnums";
import { CommissionRateTypeEnums } from "@/enums/exchangeListCollectionEnums";

import { transformEnumToLabeledValue } from "@/utils/helpers";
import { ShipmentProductModel } from "@/models/shipmentProductCollectionModel";

interface IProps {
    open: boolean;
    priceReadId: number;
    agentCategoryPriceRead: ICategoryPriceRequestParams;
    selectedProductIds: React.Key[];
    selectedProducts: ShipmentProductModel[];
    onCancel: () => void;
}
export const AgentAssignWithPriceModal = ({
    open,
    onCancel,
    priceReadId,
    selectedProductIds,
    selectedProducts,
}: IProps) => {
    const {
        defaultAgentAssignWithPrice,
        isLoading: defaultAgentAssignWithPriceLoading,
    } = useDefaultAgentAssignWithPrice();
    const { approvedShippingProduct, isLoading } =
        useApprovedShippingProductForProduct();

    const [form] = Form.useForm();
    const okHandler = () => {};

    useEffect(() => {
        form.resetFields();
    }, [form, open]);

    const handleFormHandler = async (values: any) => {
        try {
            await Promise.all(
                selectedProducts
                    .filter(
                        (product) =>
                            product.getStatus() === "shipment-pending" ||
                            product.getStatus() === "shipment-rejected",
                    )
                    .map(async (product) => {
                        await approvedShippingProduct(product.getId());
                    }),
            );

            const payload: IDefaultWithPriceAssignAgentPayload = {
                agent_category_price_read_id: priceReadId,
                shipment_product_ids: [
                    ...selectedProductIds.map((id) => Number(id)),
                ],
                override: {
                    unit_type: values.unit_type,
                    rate: values.rate,
                    slot: values.slots,
                    commission: {
                        rate: values.commission.rate,
                        rate_type: values.commission.rate_type,
                        max_cap: values.commission.max_cap,
                    },
                },
            };
            const res = await defaultAgentAssignWithPrice(payload);
            notification["success"]({
                message: res.message,
            });
            onCancel();
        } catch (error: any) {
            notification["error"]({
                message: error.response.data.message,
            });
        }
    };

    return (
        <Modal
            footer={false}
            title="Agent Assign With Price"
            open={open}
            onOk={okHandler}
            onCancel={onCancel}
            width="500px"
        >
            <Form
                onFinish={handleFormHandler}
                form={form}
                layout="vertical"
                initialValues={{
                    commission: {
                        rate: 0,
                        rate_type: CommissionRateTypeEnums.PERCENTAGE,
                        max_cap: 0,
                    },
                }}
            >
                <div>
                    <Flex justify="space-between" gap={12}>
                        <Form.Item
                            name="unit_type"
                            label="Unit type"
                            style={{
                                width: "100%",
                            }}
                            rules={[
                                {
                                    required: true,
                                    message: "Unit type is required",
                                },
                            ]}
                        >
                            <Select
                                placeholder="Select"
                                options={transformEnumToLabeledValue(
                                    WeightUnitEnum,
                                )}
                            />
                        </Form.Item>

                        <Form.Item
                            name="rate"
                            label="Rate"
                            style={{
                                width: "100%",
                            }}
                            rules={[
                                {
                                    required: true,
                                    message: "Rate is required",
                                },
                            ]}
                        >
                            <InputNumber
                                style={{
                                    width: "100%",
                                }}
                                placeholder="0"
                            />
                        </Form.Item>
                    </Flex>
                    <Flex vertical gap={4}>
                        <Typography.Text strong>Commission</Typography.Text>
                        <Flex justify="space-between" gap={8}>
                            <Form.Item
                                style={{
                                    width: "100%",
                                }}
                                name={["commission", "rate_type"]}
                                rules={[
                                    { required: true, message: "Required" },
                                ]}
                            >
                                <Select
                                    placeholder="Rate type"
                                    options={transformEnumToLabeledValue(
                                        CommissionRateTypeEnums,
                                    )}
                                />
                            </Form.Item>
                            <Form.Item
                                style={{
                                    width: "100%",
                                }}
                                name={["commission", "rate"]}
                                rules={[
                                    { required: true, message: "Required" },
                                ]}
                            >
                                <InputNumber
                                    style={{
                                        width: "100%",
                                    }}
                                    placeholder="Rate"
                                />
                            </Form.Item>

                            <Form.Item
                                style={{
                                    width: "100%",
                                }}
                                name={["commission", "max_cap"]}
                            >
                                <InputNumber
                                    style={{
                                        width: "100%",
                                    }}
                                    placeholder="Max cap"
                                />
                            </Form.Item>
                        </Flex>
                    </Flex>

                    <Form.List name="slots">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(
                                    ({ key, name, ...restField }, index) => (
                                        <Space
                                            key={key}
                                            direction="vertical"
                                            size="middle"
                                            style={{
                                                display: "flex",
                                                margin: "0 10px",
                                            }}
                                        >
                                            <Row
                                                justify="space-between"
                                                style={{
                                                    padding: 5,
                                                    borderLeft:
                                                        "2px solid hotpink",
                                                }}
                                            >
                                                <Typography.Text mark>
                                                    Slot #{index + 1}
                                                </Typography.Text>

                                                {index > 0 && (
                                                    <DeleteTwoTone
                                                        twoToneColor="hotpink"
                                                        onClick={() =>
                                                            remove(name)
                                                        }
                                                    />
                                                )}
                                            </Row>
                                            <Flex gap={8}>
                                                <Form.Item
                                                    {...restField}
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                    name={[name, "min_amount"]}
                                                    label="Min Quantity"
                                                >
                                                    <InputNumber
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        placeholder="Min Quantity"
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                    name={[name, "max_amount"]}
                                                    label="Max Quantity"
                                                >
                                                    <InputNumber
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        placeholder="Max Quantity"
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                    name={[name, "rate"]}
                                                    label="Rate"
                                                >
                                                    <InputNumber
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        placeholder="Rate"
                                                    />
                                                </Form.Item>
                                            </Flex>
                                        </Space>
                                    ),
                                )}
                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() => {
                                            const lastVariationIndex =
                                                fields.length - 1;
                                            form.validateFields([
                                                [
                                                    "slots",
                                                    lastVariationIndex,
                                                    "min_amount",
                                                ],
                                                [
                                                    "slots",
                                                    lastVariationIndex,
                                                    "max_amount",
                                                ],
                                                [
                                                    "rate",
                                                    lastVariationIndex,
                                                    "rate",
                                                ],
                                            ])
                                                .then(() => {
                                                    add({
                                                        min_amount: "",
                                                        max_amount: "",
                                                        rate: "",
                                                    });
                                                })
                                                .catch((_error) => {});
                                        }}
                                        block
                                        icon={<PlusSquareTwoTone />}
                                    >
                                        Add more slot
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </div>
                <Flex justify="end">
                    <Form.Item noStyle style={{ textAlign: "right" }}>
                        <Button
                            loading={
                                defaultAgentAssignWithPriceLoading || isLoading
                            }
                            type="primary"
                            htmlType="submit"
                        >
                            Assign Agent
                        </Button>
                    </Form.Item>
                </Flex>
            </Form>
        </Modal>
    );
};
