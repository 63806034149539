import { Tabs, Typography, message, Tag, Table, Flex } from "antd";
import type { TabsProps } from "antd";
import { useInvoice } from "@/lib/core-react/hooks/private/useInvoice";
import { getErrorMessage } from "@/helpers/getErrorMessages";
import { useAtomValue } from "jotai";
import { memo, useEffect } from "react";
import LoaderSmall from "@/components/CustomFormElement/LoaderSmall";
import { StatusTag } from "@/components";
import {
    agentInvoiceDetailsAtom,
    customerInvoiceDetailsAtom,
} from "@/lib/core-react/store/store";
import { InvoiceModel } from "@/models/invoiceCollectionModel";
import { formatDateTime } from "@/utils/helpers";
import InvoiceInfo from "./InvoiceInfo";
import { ColumnsType } from "antd/es/table";
import { IInvoiceTransaction } from "@/types/InvoiceCollection";
import TransactionsAssociationTable from "@/pages/InvoiceManage/Invoice/component/TransactionsItemsTable";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ADMIN_INVOICE_PERMISSION_ENUM } from "@/consts/permission-enum/invoice-enum";

interface Props {
    customerInvoiceId: number | undefined;
    agentInvoiceId: number | undefined;
}
const { Text } = Typography;

const Invoice = ({ customerInvoiceId, agentInvoiceId }: Props) => {
    const customerInvoiceData = useAtomValue(customerInvoiceDetailsAtom);
    const agentInvoiceData = useAtomValue(agentInvoiceDetailsAtom);
    const {
        getInvoiceDetailsById: getCustomerInvoiceDetailsById,
        getAgentInvoiceDetailsById,
    } = useInvoice();

    useEffect(() => {
        if (customerInvoiceId) {
            getCustomerInvoiceDetailsById(customerInvoiceId);
        }
        if (agentInvoiceId) {
            getCustomerInvoiceDetailsById(agentInvoiceId);
        }
    }, []);

    const fetchDataForTab = async (key: string) => {
        try {
            if (key === "1") {
                if (customerInvoiceId) {
                    // await getCustomerInvoiceDetailsById(customerInvoiceId);
                }
            } else if (key === "2") {
                if (agentInvoiceId) {
                    await getAgentInvoiceDetailsById(agentInvoiceId);
                }
            }
        } catch (error) {
            message.error(getErrorMessage(error));
        }
    };

    const onTabChange = (key: string) => {
        fetchDataForTab(key);
    };

    const customerInvoiceModel =
        customerInvoiceData &&
        customerInvoiceData.data &&
        new InvoiceModel(customerInvoiceData.data);

    const agentInvoiceModel =
        agentInvoiceData &&
        agentInvoiceData.data &&
        new InvoiceModel(agentInvoiceData.data);

    // -------------------------UI----------------------------

    const expandedRowRender = (record) => {
        return (
            <TransactionsAssociationTable
                data={record?.associations?.data || []}
                currency={
                    customerInvoiceModel?.getCurrencyCode() ||
                    agentInvoiceModel?.getCurrencyCode() ||
                    ""
                }
                userId={customerInvoiceModel?.customer?.id}
            />
        );
    };

    const columns: ColumnsType<IInvoiceTransaction> = [
        {
            title: "Id",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "Transaction No",
            dataIndex: "transaction_number",
            key: "transactionNumber",
            render: (transactionNumber: string) => (
                <Text copyable>{transactionNumber}</Text>
            ),
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
            render: (_: string, record) => (
                <Text>
                    {customerInvoiceModel?.getCurrencyCode() ||
                        agentInvoiceModel?.getCurrencyCode()}
                    {record.amount}
                </Text>
            ),
        },
        {
            title: "Type",
            dataIndex: "type",
            key: "type",
            render: (type: string) => <StatusTag text={type} slug={type} />,
        },
        {
            title: "Descriptions",
            dataIndex: "description",
            key: "description",
            render: (description: string) => (
                <Text>{description || "N/A"}</Text>
            ),
        },
        {
            align: "center",
            title: "Created At",
            dataIndex: "created_at",
            key: "createdAt",
            render: (text: string) => (
                <Tag color="purple">{formatDateTime(text)}</Tag>
            ),
        },
    ];

    const items: TabsProps["items"] = [
        {
            key: "1",
            label: "Customer",
            children: (
                <>
                    {customerInvoiceData.isLoading ? (
                        LoaderSmall
                    ) : (
                        <Flex gap={12} vertical>
                            <InvoiceInfo InvoiceModel={customerInvoiceModel} />
                            <Table
                                title={() => "Transactions"}
                                expandable={{
                                    expandedRowRender,
                                }}
                                columns={columns}
                                dataSource={customerInvoiceModel?.getTransactions()}
                                rowKey="id"
                                pagination={false}
                                bordered
                                size="small"
                            />
                        </Flex>
                    )}
                </>
            ),
        },
    ];

    // Check if the user has the required permission to view the "Agent" tab
    const hasAgentPermission = checkActionPermission(
        ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_VIEW_AGENT_INVOICE,
        "CheckAgentInViewPermission",
        null,
    );

    if (hasAgentPermission) {
        // Only add the "Agent" tab if the user has the required permission
        items.push({
            key: "2",
            label: "Agent",
            children: (
                <div>
                    {agentInvoiceData.isLoading ? (
                        LoaderSmall
                    ) : (
                        <Flex gap={12} vertical>
                            <InvoiceInfo InvoiceModel={agentInvoiceModel} />

                            {/* Add other action buttons or content for the Agent tab here */}
                            <Table
                                title={() => "Transactions"}
                                expandable={{
                                    expandedRowRender,
                                    defaultExpandedRowKeys: ["0"],
                                }}
                                columns={columns}
                                dataSource={agentInvoiceModel?.getTransactions()}
                                rowKey="transactionNumber"
                                pagination={false}
                                bordered
                                size="small"
                            />
                        </Flex>
                    )}
                </div>
            ),
        });
    }

    // ADMIN_INVOICE_PERMISSION_ENUM

    return (
        <>
            <Tabs
                style={{
                    minHeight: 300,
                }}
                defaultActiveKey="1"
                items={items}
                onChange={onTabChange}
            />
        </>
    );
};

export default memo(Invoice);
