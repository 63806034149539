import {
    DownOutlined,
    EditOutlined,
    HddFilled,
    PlusOutlined,
} from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import {
    Button,
    Col,
    Dropdown,
    Form,
    Modal,
    Row,
    Table,
    Tag,
    notification,
} from "antd";
import FiltersComponent from "@/components/FiltersComponent";
import { RegionDataStatus } from "@/enums/regionCollectionEnums";
import useDataFilters from "@/hooks/useDataFilters";
import { useAtom } from "jotai";
import {
    useCreateRegion,
    useGetRegion,
    useUpdateRegion,
} from "@/lib/core-react/hooks/private/useRegion";
import { regionRegion } from "@/lib/core-react/store/store";
import { PaginationModel } from "@/models/pagination";
import { RegionCollectionModel } from "@/models/region";
import { useState, useEffect } from "react";
import { IFilterType } from "@/types/filters";
import { ApiHelperModel } from "@/models/apiHelper";
import useWindowWidth from "../../../lib/core-react/hooks/public/useWindowWidth";
import { formatDateTime } from "../../../utils/helpers";
import { MenuInfo } from "rc-menu/lib/interface";
import RegionModifications from "./components/RegionModifications";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ExtendedMenuItemType } from "@/types";
import { ADMIN_CORE_PERMISSION_ENUM } from "@/consts/permission-enum/admin-core-enum";
import { showError } from "@/helpers/showError";
import CommonError from "@/components/Error/CommonError";
import { RegionModel } from "@/models/regionCollectionModel";
const { Column } = Table;

const Region = () => {
    const { getRegion } = useGetRegion();
    const { updateRegion, isLoading: updateLoading } = useUpdateRegion();
    const { createRegion, isLoading: createLoading } = useCreateRegion();
    const { isMobile } = useWindowWidth();
    const [
        {
            data: regionStoreData,
            isLoading,
            refetch,
            error,
            code,
            unAuthorized,
        },
    ] = useAtom(regionRegion);
    const {
        filters,
        handleFilterChange,
        handelFilterClear,
        isFirstCall,
        isFetched,
        initializeAvailableFilter,
        refetch: refetchFromFilter,
    } = useDataFilters();

    const [form] = Form.useForm();

    // Api Call
    useEffect(() => {
        if (
            (filters && !isFetched && isFirstCall) ||
            refetch ||
            refetchFromFilter
        ) {
            if (filters) {
                ApiHelperModel.makeGetRequest(filters, getRegion);
            }
        }
    }, [isFirstCall, filters, isFetched, refetch, refetchFromFilter]);

    // Region
    const RegionData =
        regionStoreData && new RegionCollectionModel(regionStoreData);

    // Filter
    const filterData = RegionData?.filters;
    // Getting all available filters
    useEffect(() => {
        if (!isFetched && RegionData?.filters) {
            initializeAvailableFilter(filterData as IFilterType);
        }
    }, [isFetched, initializeAvailableFilter, RegionData?.filters]);

    // Update modal state
    const [isShowCreateUpdateModal, setIsShowCreateUpdateModal] =
        useState<boolean>(false);
    const [selectedRegion, setSeletedRegion] = useState<RegionModel>();

    // Handle Modal
    const handleMenuClick = (e: MenuInfo, record: RegionModel) => {
        setSeletedRegion(record);

        if (e.key === "update") {
            setIsShowCreateUpdateModal(true);
        }
    };

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getRegion,
        );
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        RegionData,
        handlePaginationChange,
    );

    // Filter Handler
    const handleProductFilter = () => {
        if (filters) {
            ApiHelperModel.makeGetRequest(filters, getRegion);
        }
    };

    const onFinish = async (value) => {
        try {
            const payload = {
                name: value.name,
                code: value.code,
                status: value.status,
                store: value.store,
                country_ids: value.country_ids,
                language_ids: value.language_ids,
                currency_id: parseInt(value.currency_id),
                store_type: value.store_type,
            };

            if (selectedRegion) {
                await updateRegion(selectedRegion?.id, payload);
                notification["success"]({
                    message: "Region Update successfully",
                });
            } else {
                await createRegion(payload);
                notification["success"]({
                    message: "Region Create successfully",
                });
            }
            setIsShowCreateUpdateModal(false);
        } catch (error: any) {
            showError(error, form);
        }
    };

    if (!isLoading && error) {
        return (
            <CommonError
                unAuthorized={unAuthorized}
                message={error}
                code={code}
            />
        );
    }

    return (
        <>
            <div>
                <PageHeader
                    ghost={false}
                    title="Region"
                    style={{ marginTop: "10px" }}
                    extra={[
                        checkActionPermission(
                            ADMIN_CORE_PERMISSION_ENUM.ADMIN_REGION_MANAGE,
                            <Button
                                key="1"
                                type="primary"
                                onClick={() => setIsShowCreateUpdateModal(true)}
                                icon={<PlusOutlined />}
                            >
                                Create a Region
                            </Button>,

                            null,
                        ),
                    ]}
                    onBack={() => window.history.back()}
                >
                    <div>
                        {filters && Object.keys(filters).length > 0 && (
                            <Row>
                                <Col span={24}>
                                    <FiltersComponent
                                        handleProductFilter={
                                            handleProductFilter
                                        }
                                        handleFilterChange={handleFilterChange}
                                        handelFilterClear={handelFilterClear}
                                        isFetched={isFetched}
                                        filters={filters}
                                        filtersData={filterData}
                                        isFromProductReceived={true}
                                    />
                                </Col>
                            </Row>
                        )}
                        <Table
                            bordered
                            style={{ marginTop: "15px" }}
                            loading={isLoading}
                            rowKey="id"
                            pagination={paginationConfig}
                            dataSource={RegionData?.data}
                            scroll={{ x: 1000 }}
                        >
                            <Column
                                width={70}
                                title="ID"
                                dataIndex="id"
                                key="id"
                            />
                            <Column title="Code" dataIndex="code" key="code" />
                            <Column title="Name" dataIndex="name" key="name" />
                            <Column
                                title="Currency"
                                dataIndex="currency"
                                key="currency"
                                render={(_, record: RegionModel) => (
                                    <p>
                                        {record.currency &&
                                            record.currency.name}
                                    </p>
                                )}
                            />
                            <Column
                                title="Country"
                                dataIndex="countries"
                                key="countries"
                                render={(_, record: RegionModel) =>
                                    record.countries &&
                                    record.countries.map((item, index, arr) => (
                                        <span key={index}>
                                            {item.name}
                                            {index + 1 === arr.length || ", "}
                                        </span>
                                    ))
                                }
                            />
                            <Column
                                title="Language"
                                dataIndex="languages"
                                key="languages"
                                render={(_, record: RegionModel) =>
                                    record.languages &&
                                    record.languages.data.map(
                                        (item, index, arr) => (
                                            <span key={index}>
                                                {item.name}
                                                {index + 1 === arr.length ||
                                                    ", "}
                                            </span>
                                        ),
                                    )
                                }
                            />
                            <Column
                                title="Store"
                                dataIndex="store"
                                key="store"
                            />
                            <Column
                                title="Store Type"
                                dataIndex="store_type"
                                key="store_type"
                            />
                            <Column
                                title="Status"
                                dataIndex="status"
                                key="status"
                                render={(_, record: RegionModel) => (
                                    <Tag
                                        color={
                                            record.status ===
                                            RegionDataStatus.active
                                                ? "green"
                                                : "red"
                                        }
                                        key={record.status}
                                    >
                                        {record.status}
                                    </Tag>
                                )}
                            />

                            <Column
                                title="Created at"
                                dataIndex="created_at"
                                key="created_at"
                                render={(created_at) => {
                                    return (
                                        <Tag color={"purple"}>
                                            {formatDateTime(created_at)}
                                        </Tag>
                                    );
                                }}
                            />
                            <Column
                                title="Action"
                                fixed={isMobile ? "right" : undefined}
                                width={isMobile ? 100 : 150}
                                key="action"
                                hidden={
                                    !checkActionPermission(
                                        ADMIN_CORE_PERMISSION_ENUM.ADMIN_REGION_MANAGE,
                                        "checkPermission",
                                        null,
                                    )
                                }
                                render={(_, record: RegionModel) => {
                                    const items: ExtendedMenuItemType[] = [
                                        {
                                            permission:
                                                ADMIN_CORE_PERMISSION_ENUM.ADMIN_REGION_MANAGE,
                                            label: "Update",
                                            key: "update",
                                            icon: <EditOutlined />,
                                            onClick: (e) =>
                                                handleMenuClick(e, record),
                                        },
                                    ];

                                    return (
                                        <Dropdown
                                            menu={{
                                                items: items.filter((x) =>
                                                    checkActionPermission(
                                                        x.permission,
                                                        x,
                                                        null,
                                                    ),
                                                ),
                                            }}
                                        >
                                            <Button icon={<HddFilled />}>
                                                {isMobile ? (
                                                    <DownOutlined />
                                                ) : (
                                                    <>
                                                        {" "}
                                                        Actions <DownOutlined />
                                                    </>
                                                )}
                                            </Button>
                                        </Dropdown>
                                    );
                                }}
                            />
                        </Table>
                    </div>
                </PageHeader>
            </div>

            <Modal
                title={`${selectedRegion ? "Update" : "Create"} Region`}
                open={isShowCreateUpdateModal}
                okText={selectedRegion ? "Update" : "Create"}
                onCancel={() => {
                    setIsShowCreateUpdateModal(false);
                    setSeletedRegion(undefined);
                }}
                okButtonProps={{ loading: createLoading || updateLoading }}
                onOk={form.submit}
                centered
                destroyOnClose={true}
            >
                <RegionModifications
                    data={selectedRegion}
                    form={form}
                    onFinish={onFinish}
                />
            </Modal>
        </>
    );
};

export default Region;
