import {
    CheckCircleOutlined,
    DownOutlined,
    EditOutlined,
    EnvironmentOutlined,
    ExclamationCircleOutlined,
    HddFilled,
    NotificationOutlined,
    PlusOutlined,
    TeamOutlined,
} from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import {
    Button,
    Col,
    Dropdown,
    Flex,
    Modal,
    Row,
    Table,
    Tag,
    Typography,
} from "antd";
import { useState, useEffect } from "react";
import { useAtom } from "jotai";

import useDataFilters from "@/hooks/useDataFilters";
import { destinationWarehouseCollectionAtom } from "@/lib/core-react/store/store";
import { useGetDestinationWarehouse } from "@/lib/core-react/hooks/private";
import { PaginationModel } from "@/models/pagination";
import { ApiHelperModel } from "@/models/apiHelper";
import {
    DestinationWarehouseCollectionModel,
    DestinationWarehouseModel,
} from "../../../models/destinationWarehouseCollectionModel";
import { IFilterType } from "@/types/filters";
import { DestinationWarehouseModalType } from "@/types/destinationWarehouseCollection";

import { getKeyByValue } from "./utils";
import FiltersComponent from "@/components/FiltersComponent";
import DestinationWarehouseModification from "./components/DestinationWarehouseModification";
import { DestinationWarehouseMembersTable } from "./components/MembersTable";
import { DestinationWarehouseInvitationsTable } from "./components/InvitationsTable";
import { DwAddressesTable } from "./components/AddressesTable";
import useWindowWidth from "../../../lib/core-react/hooks/public/useWindowWidth";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ExtendedMenuItemType } from "@/types";
import { DESTINATION_PERMISSION_ENUM } from "@/consts/permission-enum/destination-enum";
import { ADDRESS_PERMISSION_ENUM } from "@/consts/permission-enum/address-enum";
import CommonError from "../../../components/Error/CommonError";

const DestinationWarehouse = () => {
    const { getDestinationWarehouse } = useGetDestinationWarehouse();
    const [
        { data: warehouseData, isLoading, refetch, unAuthorized, error, code },
    ] = useAtom(destinationWarehouseCollectionAtom);

    const [modalOpen, setModalOpen] = useState<
        DestinationWarehouseModalType | undefined
    >(undefined);
    const [selectedDestinationWarehouse, setSelectedDestinationWarehouse] =
        useState<DestinationWarehouseModel | undefined>(undefined);
    const { isMobile } = useWindowWidth();
    const {
        filters,
        handleFilterChange,
        handelFilterClear,
        isFirstCall,
        isFetched,
        initializeAvailableFilter,
        refetch: refetchFromFilter,
    } = useDataFilters();

    // Api Call
    useEffect(() => {
        if (
            (filters && !isFetched && isFirstCall) ||
            refetch ||
            refetchFromFilter
        ) {
            ApiHelperModel.makeGetRequest(filters, getDestinationWarehouse);
        }
    }, [isFirstCall, filters, isFetched, refetch, refetchFromFilter]);

    // Warehouse
    const DestinationWarehouseCollectionData =
        warehouseData && new DestinationWarehouseCollectionModel(warehouseData);

    // Filter
    const filterData = DestinationWarehouseCollectionData?.getFilters();
    // Pagination
    const paginationData = DestinationWarehouseCollectionData?.getPagination();

    // Getting all available filters
    useEffect(() => {
        if (!isFetched && DestinationWarehouseCollectionData?.getFilters()) {
            initializeAvailableFilter(filterData as IFilterType);
        }
    }, [
        isFetched,
        initializeAvailableFilter,
        DestinationWarehouseCollectionData?.getFilters(),
    ]);

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getDestinationWarehouse,
        );
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        DestinationWarehouseCollectionData,
        handlePaginationChange,
    );

    // Filter Handler
    const handleProductFilter = () => {
        ApiHelperModel.makeGetRequest(filters, getDestinationWarehouse);
    };

    const onCancelModal = () => {
        setModalOpen(undefined);
        setSelectedDestinationWarehouse(undefined);
    };

    const { Text } = Typography;

    if (error) {
        return (
            <CommonError
                unAuthorized={unAuthorized}
                code={code}
                message={error}
                hidePageHeader={false}
            />
        );
    }

    const columns = [
        {
            title: "#SL",
            dataIndex: "id",
            key: "sl",
            align: "center" as const,
            width: 70,
            render: (
                _: string,
                __: DestinationWarehouseModel,
                index: number,
            ) => {
                return (
                    <Text>
                        {paginationData
                            ? (paginationData.current_page - 1) *
                                  paginationData.per_page +
                              index +
                              1
                            : ""}
                    </Text>
                );
            },
        },
        {
            title: "Name",
            dataIndex: "name",
            hidden: isMobile,
            key: "name",
            render: (_: string, record: DestinationWarehouseModel) => {
                return <Text>{record.getName()}</Text>;
            },
        },
        {
            title: "Country",
            dataIndex: "country",
            hidden: isMobile,
            key: "country",
            render: (_: string, record: DestinationWarehouseModel) => {
                return (
                    <Flex gap="small">
                        <img
                            src={record.getCountry()?.getFlag()}
                            style={{ width: 18, height: 18 }}
                            alt=""
                        />
                        {record.getCountry()?.getName()}
                    </Flex>
                );
            },
        },
        {
            title: "Status",
            dataIndex: "status",
            hidden: isMobile,
            key: "status",
            align: "center" as const,
            render: (_: string, record: DestinationWarehouseModel) => {
                return (
                    <Tag
                        icon={
                            record.getStatus() === "active" ? (
                                <CheckCircleOutlined />
                            ) : (
                                <ExclamationCircleOutlined />
                            )
                        }
                        color={
                            record.getStatus() === "active"
                                ? "success"
                                : "warning"
                        }
                    >
                        {record.getStatus()}
                    </Tag>
                );
            },
        },
        {
            title: "Warehouse Details",
            dataIndex: "name",
            hidden: !isMobile,
            key: "warehouse-details",
            render: (_, record: DestinationWarehouseModel) => {
                return (
                    <div>
                        <div style={{ marginBottom: "8px" }}>
                            <strong>Name:</strong>{" "}
                            <Text>{record.getName()}</Text>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "8px",
                            }}
                        >
                            <strong>Country:</strong>
                            <img
                                src={record.getCountry()?.getFlag()}
                                alt=""
                                style={{
                                    width: 18,
                                    height: 18,
                                    marginLeft: "8px",
                                }}
                            />
                            {record.getCountry()?.getName()}
                        </div>
                        <div>
                            <strong>Status:</strong>
                            <Tag
                                icon={
                                    record.getStatus() === "active" ? (
                                        <CheckCircleOutlined />
                                    ) : (
                                        <ExclamationCircleOutlined />
                                    )
                                }
                                color={
                                    record.getStatus() === "active"
                                        ? "success"
                                        : "warning"
                                }
                                style={{ marginLeft: "8px" }}
                            >
                                {record.getStatus()}
                            </Tag>
                        </div>
                    </div>
                );
            },
        },
        checkActionPermission(
            [
                DESTINATION_PERMISSION_ENUM.ADMIN_DW_MEMBER_INVITATION_VIEW,
                DESTINATION_PERMISSION_ENUM.ADMIN_DW_MEMBER_INVITATION_MANAGE,
                DESTINATION_PERMISSION_ENUM.ADMIN_DESTINATION_WAREHOUSE_MEMBER_VIEW,
                DESTINATION_PERMISSION_ENUM.ADMIN_DESTINATION_WAREHOUSE_MEMBER_MANAGE,
                ADDRESS_PERMISSION_ENUM.ADMIN_ADDRESS_VIEW,
                ADDRESS_PERMISSION_ENUM.ADMIN_ADDRESS_MANAGE,
            ],
            {
                title: "Additional",
                dataIndex: "invitations",
                key: "invitations",
                align: "center" as const,
                render: (_: string, record: DestinationWarehouseModel) => {
                    return (
                        <Flex gap="small" vertical>
                            {checkActionPermission(
                                [
                                    DESTINATION_PERMISSION_ENUM.ADMIN_DW_MEMBER_INVITATION_VIEW,
                                    DESTINATION_PERMISSION_ENUM.ADMIN_DW_MEMBER_INVITATION_MANAGE,
                                ],
                                <Button
                                    onClick={() => {
                                        setSelectedDestinationWarehouse(record);
                                        setModalOpen(
                                            DestinationWarehouseModalType.Invitations,
                                        );
                                    }}
                                    icon={<NotificationOutlined />}
                                >
                                    Invitations
                                </Button>,
                                null,
                            )}
                            {checkActionPermission(
                                [
                                    DESTINATION_PERMISSION_ENUM.ADMIN_DESTINATION_WAREHOUSE_MEMBER_VIEW,
                                    DESTINATION_PERMISSION_ENUM.ADMIN_DESTINATION_WAREHOUSE_MEMBER_MANAGE,
                                ],
                                <Button
                                    onClick={() => {
                                        setSelectedDestinationWarehouse(record);
                                        setModalOpen(
                                            DestinationWarehouseModalType.Members,
                                        );
                                    }}
                                    icon={<TeamOutlined />}
                                >
                                    Members
                                </Button>,
                                null,
                            )}

                            {checkActionPermission(
                                [
                                    ADDRESS_PERMISSION_ENUM.ADMIN_ADDRESS_VIEW,
                                    ADDRESS_PERMISSION_ENUM.ADMIN_ADDRESS_MANAGE,
                                ],

                                <Button
                                    onClick={() => {
                                        setSelectedDestinationWarehouse(record);
                                        setModalOpen(
                                            DestinationWarehouseModalType.Addresses,
                                        );
                                    }}
                                    icon={<EnvironmentOutlined />}
                                >
                                    Addresses
                                </Button>,
                                null,
                            )}
                        </Flex>
                    );
                },
            },
            <></>,
        ),
        checkActionPermission(
            [
                DESTINATION_PERMISSION_ENUM.ADMIN_DW_MEMBER_INVITATION_VIEW,
                DESTINATION_PERMISSION_ENUM.ADMIN_DW_MEMBER_INVITATION_MANAGE,
                DESTINATION_PERMISSION_ENUM.ADMIN_DESTINATION_WAREHOUSE_MEMBER_VIEW,
                DESTINATION_PERMISSION_ENUM.ADMIN_DESTINATION_WAREHOUSE_MEMBER_MANAGE,
                ADDRESS_PERMISSION_ENUM.ADMIN_ADDRESS_VIEW,
                ADDRESS_PERMISSION_ENUM.ADMIN_ADDRESS_MANAGE,
            ],
            {
                title: "Actions",
                dataIndex: "actions",
                key: "actions",
                width: isMobile ? 150 : undefined,
                fixed: isMobile ? "right" : undefined,
                render: (_: string, record: DestinationWarehouseModel) => {
                    const items: ExtendedMenuItemType[] = [
                        {
                            permission: [
                                DESTINATION_PERMISSION_ENUM.ADMIN_DESTINATION_WAREHOUSE_MANAGE,
                            ],
                            key: "1",
                            label: (
                                <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        setSelectedDestinationWarehouse(record);
                                        setModalOpen(
                                            DestinationWarehouseModalType.Update_Destination_Warehouse,
                                        );
                                    }}
                                >
                                    <EditOutlined />{" "}
                                    <span> Update Warehouse</span>
                                </div>
                            ),
                        },
                    ];

                    return (
                        <Dropdown
                            menu={{
                                items: items.filter((x) =>
                                    checkActionPermission(
                                        x.permission,
                                        x,
                                        null,
                                    ),
                                ),
                            }}
                        >
                            <Button icon={<HddFilled />}>
                                {isMobile ? (
                                    <DownOutlined />
                                ) : (
                                    <>
                                        {" "}
                                        Actions <DownOutlined />{" "}
                                    </>
                                )}
                            </Button>
                        </Dropdown>
                    );
                },
            },
            <></>,
        ),
    ];

    return (
        <>
            <div>
                <PageHeader
                    ghost={false}
                    title="Destination Warehouse"
                    style={{ marginTop: "10px" }}
                    extra={[
                        checkActionPermission(
                            DESTINATION_PERMISSION_ENUM.ADMIN_DESTINATION_WAREHOUSE_MANAGE,
                            <Button
                                key="1"
                                type="primary"
                                onClick={() =>
                                    setModalOpen(
                                        DestinationWarehouseModalType.Create_Destination_Warehouse,
                                    )
                                }
                                icon={<PlusOutlined />}
                            >
                                Create destination warehouse
                            </Button>,
                            null,
                        ),
                    ]}
                    onBack={() => window.history.back()}
                >
                    <div>
                        {filters && Object.keys(filters).length > 0 && (
                            <Row>
                                <Col span={24}>
                                    <FiltersComponent
                                        handleProductFilter={
                                            handleProductFilter
                                        }
                                        handleFilterChange={handleFilterChange}
                                        handelFilterClear={handelFilterClear}
                                        isFetched={isFetched}
                                        filters={filters}
                                        filtersData={filterData}
                                        isFromProductReceived={true}
                                    />
                                </Col>
                            </Row>
                        )}

                        <Table
                            bordered
                            style={{ marginTop: "15px" }}
                            loading={isLoading}
                            rowKey="id"
                            pagination={paginationConfig}
                            dataSource={DestinationWarehouseCollectionData?.getData()}
                            scroll={{ x: 1000 }}
                            //@ts-ignore
                            columns={columns}
                        />
                    </div>
                </PageHeader>
            </div>

            <Modal
                title={modalOpen && getKeyByValue(modalOpen)}
                open={Boolean(modalOpen)}
                destroyOnClose={true}
                footer={null}
                onCancel={onCancelModal}
                width={
                    (modalOpen === DestinationWarehouseModalType.Members ||
                        modalOpen ===
                            DestinationWarehouseModalType.Invitations) &&
                    !isMobile
                        ? "60vw"
                        : modalOpen ===
                                DestinationWarehouseModalType.Addresses &&
                            !isMobile
                          ? "40vw"
                          : undefined
                }
            >
                {modalOpen ===
                    DestinationWarehouseModalType.Update_Destination_Warehouse &&
                    selectedDestinationWarehouse && (
                        <DestinationWarehouseModification
                            onHide={onCancelModal}
                            data={selectedDestinationWarehouse}
                        />
                    )}

                {modalOpen ===
                    DestinationWarehouseModalType.Create_Destination_Warehouse && (
                    <DestinationWarehouseModification onHide={onCancelModal} />
                )}

                {modalOpen === DestinationWarehouseModalType.Members &&
                    selectedDestinationWarehouse && (
                        <DestinationWarehouseMembersTable
                            destinationWarehouseId={selectedDestinationWarehouse.getId()}
                        />
                    )}

                {modalOpen === DestinationWarehouseModalType.Invitations &&
                    selectedDestinationWarehouse && (
                        <DestinationWarehouseInvitationsTable
                            destinationWarehouseId={selectedDestinationWarehouse.getId()}
                        />
                    )}

                {modalOpen === DestinationWarehouseModalType.Addresses &&
                    selectedDestinationWarehouse && (
                        <DwAddressesTable
                            destinationWarehouse={selectedDestinationWarehouse}
                            // addressSchema={addressSchemaData}
                        />
                    )}
            </Modal>
        </>
    );
};

export default DestinationWarehouse;
