import React from "react";
import { Table, Tag, Typography, Image } from "antd";
import { PaymentSessionInvoiceModel } from "@/models/transactionDetailModels";
import { formatDateTime } from "@/utils/helpers";
import { StatusTag } from "@/components";

interface InvoiceTableProps {
    invoice: PaymentSessionInvoiceModel[];
    currency: string;
    region: string;
}

const InvoiceTable: React.FC<InvoiceTableProps> = ({
    invoice,
    currency,
    region,
}) => {
    const { Text, Paragraph } = Typography;

    // Table Columns Definition
    const columns = [
        {
            title: "Invoice Number",
            dataIndex: ["invoice", "invoiceNumber"],
            key: "invoiceNumber",
            render: (invoiceNumber: string) => {
                return (
                    <div>
                        <Paragraph>
                            <Text copyable={{ text: invoiceNumber }}>
                                {invoiceNumber}
                            </Text>
                        </Paragraph>
                        <Paragraph>
                            <Text>Region : </Text>
                            <StatusTag slug={region} text={region} />
                        </Paragraph>
                    </div>
                );
            },
        },

        {
            title: "Status",
            dataIndex: ["invoice", "status"],
            key: "createdAt",
            render: (status: string) => {
                return (
                    <div>
                        <StatusTag slug={status} text={status} />
                    </div>
                );
            },
        },
        {
            title: "Amount Details",
            dataIndex: ["invoice", "transactionSummary"],
            key: "amount",
            children: [
                {
                    title: "Paid",
                    dataIndex: ["invoice", "transactionSummary", "paid"],
                    key: "amount",
                    render: (paid: number) => {
                        return (
                            <>
                                <Text strong>
                                    {currency} {paid.toFixed(2)}
                                </Text>
                            </>
                        );
                    },
                },
                {
                    title: "Due",
                    dataIndex: ["invoice", "transactionSummary", "due"],
                    key: "amount",
                    render: (due: number) => {
                        return (
                            <>
                                <Text strong>
                                    {currency} {due.toFixed(2)}
                                </Text>
                            </>
                        );
                    },
                },
                {
                    title: "Over Paid",
                    dataIndex: ["invoice", "transactionSummary", "overpaid"],
                    key: "amount",
                    render: (overpaid: number) => {
                        return (
                            <>
                                <Text strong>
                                    {currency} {overpaid.toFixed(2)}
                                </Text>
                            </>
                        );
                    },
                },
            ],
        },
        {
            title: "Total Amount",
            dataIndex: ["invoice", "totalAmount"],
            key: "totalAmount",
            render: (amount: number) => `${currency} ${amount}`,
        },
        {
            title: "Created At",
            dataIndex: ["invoice", "createdAt"],
            key: "createdAt",
            render: (createdAt: string) => {
                return (
                    <div>
                        <Tag color="purple">{formatDateTime(createdAt)}</Tag>
                    </div>
                );
            },
        },
    ];

    const dataSource = invoice.map((item, index) => ({
        key: index,
        ...item,
    }));

    return (
        <Table
            columns={columns}
            dataSource={dataSource}
            bordered
            pagination={false}
            scroll={{ x: 1120 }}
            expandable={{
                expandedRowRender: (record) => {
                    if (record.invoice.buy_product) {
                        // Render Buy Product Table
                        return (
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                    gap: "16px",
                                    padding: "16px",
                                }}
                            >
                                {/* Left Side: Image */}
                                <div style={{ flexShrink: 0 }}>
                                    {record.invoice.buy_product
                                        .product_image ? (
                                        <Image
                                            width={150}
                                            src={
                                                record.invoice.buy_product
                                                    .product_image
                                            }
                                        />
                                    ) : (
                                        <Typography.Text type="secondary">
                                            No Image Available
                                        </Typography.Text>
                                    )}
                                </div>

                                {/* Right Side: Product Details */}
                                <div style={{ flexGrow: 1 }}>
                                    <Paragraph>
                                        <Text>Title : </Text>
                                        <Typography.Link
                                            href={
                                                record.invoice.buy_product
                                                    .product_link
                                            }
                                            target="_blank"
                                            strong
                                        >
                                            {
                                                record.invoice.buy_product
                                                    .product_title
                                            }
                                        </Typography.Link>
                                    </Paragraph>
                                    <Paragraph>
                                        <Text>Product Number : </Text>
                                        <Text
                                            strong
                                            copyable
                                            style={{ color: "#1890ff", cursor: "pointer" }}
                                            onClick={() => {
                                                window.open(
                                                    `/purchase/buy-products?product_number=${record.invoice.buy_product?.product_number}`,
                                                    "_blank",
                                                );
                                            }}
                                        >
                                            {
                                                record.invoice.buy_product
                                                    .product_number
                                            }
                                        </Text>
                                    </Paragraph>
                                    <Paragraph>
                                        <Text>Quantity : </Text>
                                        <Text strong>
                                            {
                                                record.invoice.buy_product
                                                    .total_quantity
                                            }
                                        </Text>
                                    </Paragraph>
                                    <Paragraph>
                                        <Text>Status : </Text>
                                        <StatusTag
                                            slug={
                                                record.invoice.buy_product
                                                    .status_label
                                            }
                                            text={
                                                record.invoice.buy_product
                                                    .status_label
                                            }
                                        />
                                    </Paragraph>
                                    <Paragraph>
                                        <Text>Shipping Provider : </Text>
                                        <Text strong>
                                            {
                                                record.invoice.buy_product
                                                    .shipping_provider
                                            }
                                        </Text>
                                    </Paragraph>
                                    <Paragraph>
                                        <Text>Created At : </Text>
                                        <Tag color="purple">
                                            {formatDateTime(
                                                record.invoice.buy_product
                                                    .created_at,
                                            )}
                                        </Tag>
                                    </Paragraph>
                                </div>
                            </div>
                        );
                    } else if (record.invoice.shipment_product) {
                        // Render Shipment Product Table
                        return (
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                    gap: "16px",
                                    padding: "16px",
                                }}
                            >
                                {/* Left Side: Shipment Image */}
                                <div style={{ flexShrink: 0 }}>
                                    {/* {record.invoice.shipment_product
                                        .images ? (
                                        <Image
                                            width={150}
                                            src={
                                                record.invoice.shipment_product
                                                    .images[0].image
                                            }
                                        />
                                    ) : (
                                        <Typography.Text type="secondary">
                                            No Image Available
                                        </Typography.Text>
                                    )} */}
                                </div>

                                {/* Right Side: Shipment Details */}
                                <div style={{ flexGrow: 1 }}>
                                    <Paragraph>
                                        <Text>Shipment Title : </Text>
                                        <Typography.Link
                                            href={
                                                record.invoice.shipment_product
                                                    .link
                                            }
                                            target="_blank"
                                            strong
                                        >
                                            {
                                                record.invoice.shipment_product
                                                    .title
                                            }
                                        </Typography.Link>
                                    </Paragraph>
                                    {/* <Paragraph>
                                        <Text>Tracking Number : </Text>
                                        <Text strong copyable>
                                            {
                                                record.invoice.shipment_product.
                                            }
                                        </Text>
                                    </Paragraph> */}
                                    {/* <Paragraph>
                                        <Text>Quantity : </Text>
                                        <Text strong>
                                            {
                                                record.invoice.shipment_product
                                            }
                                        </Text>
                                    </Paragraph> */}
                                    <Paragraph>
                                        <Text>Status : </Text>
                                        <StatusTag
                                            slug={
                                                record.invoice.shipment_product
                                                    .status_label
                                            }
                                            text={
                                                record.invoice.shipment_product
                                                    .status_label
                                            }
                                        />
                                    </Paragraph>
                                    {/* <Paragraph>
                                        <Text>Carrier : </Text>
                                        <Text strong>
                                            {
                                                record.invoice.shipment_product
                                                    .carrier
                                            }
                                        </Text>
                                    </Paragraph> */}
                                    {/* <Paragraph>
                                        <Text>Shipped At : </Text>
                                        <Tag color="blue">
                                            {formatDateTime(
                                                record.invoice.shipment_product.c
                                            )}
                                        </Tag>
                                    </Paragraph> */}
                                </div>
                            </div>
                        );
                    } else {
                        return (
                            <Typography.Text type="secondary">
                                No product details available
                            </Typography.Text>
                        );
                    }
                },
                rowExpandable: (record) =>
                    !!record.invoice.buy_product ||
                    !!record.invoice.shipment_product,
            }}
        />
    );
};

export default InvoiceTable;
