export enum ADMIN_USER_PERMISSION_ENUM {
    // Admin-user permissions
    ADMIN_USER_VIEW = "admin-user-view",
    ADMIN_USER_MANAGE = "admin-user-manage",

    // Customer-user permissions
    ADMIN_CUSTOMER_USER_VIEW = "admin-customer-user-view",
    ADMIN_CUSTOMER_USER_MANAGE = "admin-customer-user-manage",

    ADMIN_IMPERSONATE_SESSION_VIEW = "admin-impersonate-session-view",
    ADMIN_IMPERSONATE_SESSION_CREATE = "admin-impersonate-session-create",
}

export const manageAdminUserRoutePermissions = [
    ADMIN_USER_PERMISSION_ENUM.ADMIN_USER_VIEW,
    ADMIN_USER_PERMISSION_ENUM.ADMIN_USER_MANAGE,
];
export const manageAdminCustomerRoutePermission = [
    ADMIN_USER_PERMISSION_ENUM.ADMIN_CUSTOMER_USER_MANAGE,
    ADMIN_USER_PERMISSION_ENUM.ADMIN_CUSTOMER_USER_VIEW,
];

export const manageAdminImpersonateRoutePermission = [
    ADMIN_USER_PERMISSION_ENUM.ADMIN_IMPERSONATE_SESSION_VIEW,
    ADMIN_USER_PERMISSION_ENUM.ADMIN_IMPERSONATE_SESSION_CREATE,
];
