import type { TableColumnsType } from "antd";
import {
    Button,
    Dropdown,
    Flex,
    Form,
    Modal,
    notification,
    Space,
    Table,
    Tag,
    Tooltip,
} from "antd";
import { Typography } from "antd";
import { useEffect, useState } from "react";
import { useAtomValue } from "jotai";
import { PageHeader } from "@ant-design/pro-layout";

import { formatDateTime } from "@/utils/helpers";
import useDataFilters from "@/hooks/useDataFilters";
import { useGetShipmentOrder } from "@/lib/core-react/hooks/private/useShipping";
import { shipmentOrderCollectionAtom } from "@/lib/core-react/store/shipmentOrder/shipmentOrderAtom";
import { PaginationModel } from "@/models/pagination";
import { ApiHelperModel } from "@/models/apiHelper";
import { IFilterType } from "@/types/filters";

import FiltersComponent from "@/components/FiltersComponent";
import CommonError from "@/components/Error/CommonError";
import { ShipmentOrderModel } from "@/models/shipmentOrderCollectionModel";
import ShipmentProductsTable from "../ShipmentProducts/ShipmentProductsTable";
import { BREAK_POINTS } from "@/consts/appConstants";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import useActionsProps from "@/helpers/useActionsProps";
import {
    ControlOutlined,
    DownloadOutlined,
    DownOutlined,
    HddFilled,
    LoadingOutlined,
    PayCircleOutlined,
} from "@ant-design/icons";
import { ExtendedMenuItemType } from "@/types";
import OrderHandlerAssign from "@/pages/BuyAndShip/BuyOrders/components/orderHandlerAssignModal";
import { IOrderHandlerRequest } from "@/types/buyOrderCollection";
import { useOrderHandlerAssign } from "@/lib/core-react/hooks/private/usePurchase";
import { showError } from "@/helpers/showError";
import { ADMIN_CORE_PERMISSION_ENUM } from "@/consts/permission-enum/admin-core-enum";
import ShipmentOrderTableSummary from "./components/ShipmentOrderTableSummary";
import ImpersonateCreateModalTitle from "@/components/ImpersonateFormModalTitle";
import {
    ExpiredUnit,
    IImpersonateSessionData,
    IImpersonationSession,
    UserType,
} from "@/types/userCollection";
import { useHarvestJobCreate } from "@/lib/core-react/hooks/private/useHarvestJob";
import { IHarvestJobCreate } from "@/types/harvestBatchCollection";
import { HarvestActionEnums } from "@/enums/harvestJobCollectionEnums";
import { ADMIN_HARVEST_BATCH_PERMISSION_ENUM } from "@/consts/permission-enum/harvest-enum";
import { ADMIN_SHIPPING_PERMISSION_ENUM } from "@/consts/permission-enum/admin-shipping-enum";
import { IPaymentSessionPayPayload } from "@/types/paymentSession";
import { useInvoice } from "@/lib/core-react/hooks/private/useInvoice";
import { ADMIN_INVOICE_PERMISSION_ENUM } from "@/consts/permission-enum/invoice-enum";
import { RegionModel } from "@/models/regionCollectionModel";
import useRefetch from "@/hooks/useRefetch";
import { PayCustomerInvoiceModal } from "@/components/PayCustomerInvoice";
import { RoleModel } from "@/models/roleCollectionModel";
import {
    useCreateImpersonateSession,
    useGetRole,
} from "@/lib/core-react/hooks/private/useRole";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";
import { responseTypeQuery } from "@/filters/constant";
import { downloadImpersonateCredentialsAsTextFile } from "@/utils/helper";
import ImpersonateResultView from "@/components/ImpersonateSessionCreate/impersonateResultView";
import useOryAuth from "@/lib/core-react/utils/useOryAuth";
import ImpersonateIcon from "@/assets/ImpersonateIcon";
import { CustomerUserModel } from "@/models/customerUserCollectionModel";
import CustomerDetailsViews from "@/pages/CustomerManage/User/components/CustomerDetailsViews";

const { Text, Paragraph } = Typography;

const ShipmentOrders = () => {
    const [form] = Form.useForm(); // pay customer invoice form

    const {
        paySession,
        createSession,
        isLoading: isLoadingSession,
    } = useInvoice();
    const { createImpersonate } = useCreateImpersonateSession();
    const { getRole } = useGetRole();
    const { refetchListApi } = useRefetch();
    const { getShipmentOrder } = useGetShipmentOrder();
    const { createHarvest } = useHarvestJobCreate();
    const { orderHandlerAssign, isLoading: AssignLoading } =
        useOrderHandlerAssign();

    const {
        filters,
        handleFilterChange,
        handelFilterClear,
        isFirstCall,
        isFetched,
        initializeAvailableFilter,
        refetch: refetchFromFilter,
    } = useDataFilters();

    const {
        allOptionsData: { adminOptionsData },
        onFetchFilterApi,
    } = useFiltersApiData();

    const { getUserEmail } = useOryAuth();

    const email = getUserEmail();

    useEffect(() => {
        if (email) {
            onFetchFilterApi(filterResourceEnum.ADMIN, {
                keyword: email,
                ...responseTypeQuery.minimal,
            });
        }
    }, [email]);

    // State
    const {
        data: shipmentOrderCollection,
        isLoading: shipmentOrderLoading,
        refetch,
        error,
        unAuthorized,
        code,
    } = useAtomValue(shipmentOrderCollectionAtom);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [selectedRows, setSelectedRows] = useState<ShipmentOrderModel[]>([]);
    const [selectedOrderId, setSelectedOrderId] = useState<string>();
    const [orderHandlerId, setOrderHandlerId] = useState<number | null>();
    const [selectedPayInvoiceData, setSelectedPayInvoiceData] = useState<{
        region: RegionModel;
        user_id: number;
        token: string;
    }>();
    const [loadingImpersonate, setLoadingImpersonate] = useState<
        Record<number, boolean>
    >({});
    const [isShowModal, setIsShowModal] = useState(false);
    const [selectedCustomerUser, setSelectedCustomerUser] =
        useState<CustomerUserModel>();
    const [impersonateData, setImpersonateData] =
        useState<IImpersonateSessionData>();

    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    const actionsProps = useActionsProps();

    // Api Call
    useEffect(() => {
        if (
            (filters && !isFetched && isFirstCall) ||
            refetch ||
            refetchFromFilter
        ) {
            ApiHelperModel.makeGetRequest(filters, getShipmentOrder);
        }
    }, [isFirstCall, filters, isFetched, refetch, refetchFromFilter]);

    // Getting all available filters
    useEffect(() => {
        if (!isFetched && shipmentOrderCollection?.filters) {
            initializeAvailableFilter(
                shipmentOrderCollection.filters as IFilterType,
            );
            onClearBulkSelectedState();
        }
    }, [
        isFetched,
        initializeAvailableFilter,
        shipmentOrderCollection?.filters,
    ]);

    const onClearBulkSelectedState = () => {
        setSelectedRowKeys([]);
        setSelectedRows([]);
        setSelectedPayInvoiceData(undefined);
        setSelectedOrderId("");
    };

    const onClearSingleSelectedState = () => {
        setSelectedPayInvoiceData(undefined);
        setSelectedOrderId("");
    };

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getShipmentOrder,
        );
        onClearBulkSelectedState();
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        shipmentOrderCollection,
        handlePaginationChange,
    );

    // Filter Handler
    const handleFilter = () => {
        ApiHelperModel.makeGetRequest(filters, getShipmentOrder);
        onClearBulkSelectedState();
    };

    const AssignOrderHandler = (id: number) => {
        setOrderHandlerId(id);
        handleOpenModal();
    };

    const AssignBulkOrderHandler = () => {
        handleOpenModal();
    };

    const onFinishCreateImpersonate = async (
        shipmentOrder: ShipmentOrderModel,
    ) => {
        const shipmentOrderId = shipmentOrder.getId();
        const user = shipmentOrder.getCustomer();
        setLoadingImpersonate((prev) => ({ ...prev, [shipmentOrderId]: true }));
        setSelectedCustomerUser(user);
        let roleIds: number[] = [];
        if (user.type === UserType.Customer) {
            const roleCollectionData = await getRole(
                `is_customer=1&response_type=minimal`,
            );

            if (roleCollectionData) {
                const customerRole = roleCollectionData?.find(
                    (role: RoleModel) =>
                        role.name.toLowerCase() === "customer-role" ||
                        role.name.toLowerCase() === "customer role",
                );
                roleIds = [customerRole.id];
            }
        }
        try {
            const payload: IImpersonationSession = {
                user_type: UserType.Customer,
                impersonatee_id: user.id,
                impersonator_id: adminOptionsData.options[0].value as number,
                expired_unit: ExpiredUnit.Hour,
                expired_value: 1,
                intent: "",
                role_ids: roleIds,
            };

            const response = await createImpersonate(payload);
            setImpersonateData(response);

            if (response) {
                downloadImpersonateCredentialsAsTextFile(response);
                setIsShowModal(true);
            }
        } catch (error: any) {
            showError(error);
        } finally {
            setLoadingImpersonate((prev) => ({
                ...prev,
                [shipmentOrderId]: false,
            })); // Reset loading for this invoice
        }
    };

    const onSubmitHandlerAssign = async (values: {
        order_handler_id: string;
    }) => {
        const payload: IOrderHandlerRequest = {
            order_ids: selectedOrderId
                ? [Number(selectedOrderId)]
                : selectedRowKeys.map((key) => Number(key)),
            order_handler_id: Number(values.order_handler_id),
        };

        try {
            await orderHandlerAssign(payload);
            refetchListApi();
            setIsModalOpen(false);
            if (orderHandlerId) {
                notification["success"]({
                    message: "Update Assigned Order Handler",
                });
            } else {
                notification["success"]({
                    message: "Successfully Assigned Order Handler",
                });
            }
            onClearSingleSelectedState();
        } catch (error) {
            showError(error);
        }
    };

    const harvestJobCreateHandler = async (record: ShipmentOrderModel) => {
        const payload: IHarvestJobCreate<object, object> = {
            type: HarvestActionEnums.CUSTOMER_INVOICE,
            context: {
                filters: {
                    order_ids: [record.getId()],
                },
            },
            settings: {
                notification: {
                    email: true,
                    sms: true,
                },
            },
        };
        await createHarvest(payload);
    };

    const handlePayCustomerInvoice = async (rows: ShipmentOrderModel[]) => {
        const validRows = rows.filter((row) => {
            const products = row.getProducts();
            return products.some((product) => {
                const transactionSummary = product
                    .getCustomerInvoice()
                    ?.getTransactionSummary();
                const due = transactionSummary?.due;
                return typeof due === "number" && due > 0;
            });
        });

        // Extract unique user IDs and regions
        const uniqueCustomerIds = Array.from(
            new Set(validRows.map((row) => row.getCustomer().getId())),
        );
        const uniqueRegions = Array.from(
            new Set(validRows.map((row) => row.getProducts()[0].getRegion())),
        );

        const uniqueRegionIds = Array.from(
            new Set(validRows.map((row) => row.getProducts()[0].getRegionId())),
        );

        // Extract unique invoice IDs
        const uniqueInvoiceIds = Array.from(
            new Set(
                validRows
                    .flatMap((row) =>
                        row
                            .getProducts()
                            .map((product) => product.getCustomerInvoiceId()),
                    )
                    .filter((id) => id !== undefined && id !== null),
            ),
        );

        if (uniqueCustomerIds.length > 1) {
            return notification.error({
                message: "Error",
                description: "Selected rows must belong to a single customer",
            });
        }
        if (uniqueRegionIds.length > 1) {
            return notification.error({
                message: "Error",
                description: "Selected rows must belong to a single region",
            });
        }

        if (uniqueInvoiceIds.length === 0) {
            return notification.error({
                message: "Error",
                description: "No invoices have dues",
            });
        }

        const res = await createSession(uniqueCustomerIds[0], uniqueInvoiceIds);

        if (res) {
            setSelectedPayInvoiceData({
                region: uniqueRegions[0],
                user_id: uniqueCustomerIds[0],
                token: res.data.session_token,
            });
        }
    };

    const onFinishCustomerPay = async (values: IPaymentSessionPayPayload) => {
        if (selectedPayInvoiceData) {
            try {
                const { amount, gateway_id, ...restValues } = values;

                const payload = {
                    amount,
                    gateway_id,
                    gateway_data: { ...restValues },
                };

                await paySession(
                    selectedPayInvoiceData.user_id,
                    selectedPayInvoiceData.token,
                    payload,
                );
                await refetchListApi();
                notification.success({
                    message: "Payment initiated successfully",
                });
                onClearSingleSelectedState();
            } catch (error) {
                showError(error, form);
            }
        }
    };

    const columns: TableColumnsType<ShipmentOrderModel> = [
        {
            title: "Order ID",
            dataIndex: "orderId",
            key: "orderId",
            render: (_, record) => {
                return (
                    <>
                        <Space direction="vertical" size={"small"}>
                            <Paragraph>
                                <Text>Order.N : </Text>
                                <Text strong copyable>
                                    {record.getOrderNumber()}
                                </Text>
                            </Paragraph>
                        </Space>
                    </>
                );
            },
        },
        {
            title: "Customer info",
            key: "customerName",
            render(_, record) {
                const shipmentOrderId = record.getId();
                return (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        {/* Left-aligned content */}
                        <div>
                            <CustomerDetailsViews selectedUser={record.getCustomer()}/>
                            <Paragraph>
                                <Text>Email: </Text>
                                <Text strong>
                                    {record.getCustomer().getEmail()}
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                <Text>Phone: </Text>
                                <Text strong>
                                    <a
                                        href={`tel:${record.getCustomer().getPhone()}`}
                                    >
                                        {record.getCustomer().getPhone()}
                                    </a>
                                </Text>
                            </Paragraph>
                        </div>

                        {/* Right-aligned button */}
                        {checkActionPermission(
                            ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_SHIPMENT_IMPERSONATE_CREATE,
                            <Tooltip title="Click to impersonate this customer">
                                <Button
                                    type="dashed"
                                    onClick={() =>
                                        onFinishCreateImpersonate(record)
                                    }
                                    style={{
                                        width: 30,
                                        height: 30,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        border: "1px solid #FD384F",
                                        borderRadius: "50%",
                                        backgroundColor: "#FFF8F9",
                                    }}
                                    icon={
                                        loadingImpersonate[shipmentOrderId] ? (
                                            <LoadingOutlined />
                                        ) : (
                                            <ImpersonateIcon
                                                width={25}
                                                height={15}
                                                style={{ marginTop: -5 }}
                                            />
                                        )
                                    }
                                />
                            </Tooltip>,
                            null,
                        )}
                    </div>
                );
            },
        },
        {
            title: "Order Handler",
            key: "order_handler",
            render: (_text, record) => {
                return (
                    <>
                        <Space direction="vertical" size={"small"}>
                            <Paragraph>
                                <Text>Name: </Text>
                                <Text strong>
                                    {record
                                        ?.getProducts()[0]
                                        .getOrder()
                                        .getOrderHandler()
                                        ?.getName() || "N/A"}
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                <Text>Email: </Text>
                                <Text strong>
                                    {record
                                        ?.getProducts()[0]
                                        .getOrder()
                                        .getOrderHandler()
                                        ?.getEmail() || "N/A"}
                                </Text>
                            </Paragraph>
                        </Space>
                    </>
                );
            },
        },
        {
            title: "Created at",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (_value: string, record) => {
                return (
                    <Tag color="purple">
                        {formatDateTime(record.getCreatedAt())}
                    </Tag>
                );
            },
        },
        {
            ...actionsProps,
            key: "actions",
            hidden: !checkActionPermission(
                ADMIN_CORE_PERMISSION_ENUM.ASSIGN_ORDER_HANDLER,
                "checkActionPermission",
                null,
            ),
            render: (_: string, record) => {
                const orderHandlerId = record
                    ?.getProducts()[0]
                    ?.getOrder()
                    .getOrderHandler()
                    ?.getId();

                const menuItems: ExtendedMenuItemType[] = [
                    {
                        permission:
                            ADMIN_CORE_PERMISSION_ENUM.ASSIGN_ORDER_HANDLER,
                        label: orderHandlerId
                            ? "Update Order Handler"
                            : "Assign Order Handler",
                        key: "order-handler-assign",
                        icon: <ControlOutlined />,
                        onClick: () => {
                            AssignOrderHandler(Number(orderHandlerId));
                        },
                    },
                    {
                        permission:
                            ADMIN_HARVEST_BATCH_PERMISSION_ENUM.ADMIN_HARVEST_BATCH_CREATE,
                        key: "download-invoice",
                        icon: <DownloadOutlined />,
                        onClick: () => {
                            harvestJobCreateHandler(record);
                        },
                        label: "Download Invoice",
                    },
                    ...(record.hasProductWithDueInvoice()
                        ? [
                              {
                                  label: isLoadingSession
                                      ? "Wait..."
                                      : "Pay Customer Invoice",
                                  key: "pay-customer-invoice",
                                  permission:
                                      ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_PAY_CUSTOMER_INVOICE,
                                  icon: <PayCircleOutlined />,
                                  onClick: async () => {
                                      handlePayCustomerInvoice([record]);
                                  },
                                  disabled: isLoadingSession,
                              },
                          ]
                        : []), // Only include Pay if due > 0
                ];

                return (
                    <Dropdown
                        menu={{
                            items: menuItems,
                        }}
                        onOpenChange={() => {
                            setSelectedOrderId(record.id.toString());
                        }}
                    >
                        <Button type="primary" icon={<HddFilled />} />
                    </Dropdown>
                );
            },
        },
    ];

    const bulkItems: ExtendedMenuItemType[] = [
        {
            permission: ADMIN_CORE_PERMISSION_ENUM.ASSIGN_ORDER_HANDLER,
            key: "Order-Handler",
            icon: <ControlOutlined />,
            onClick: () => AssignBulkOrderHandler(),
            label: "Order Handler",
        },
        ...(selectedRows.some((row) => row.hasProductWithDueInvoice())
            ? [
                  {
                      label: isLoadingSession
                          ? "Wait..."
                          : "Pay Customer Invoices",
                      key: "pay-customer-invoices",
                      permission:
                          ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_PAY_CUSTOMER_INVOICE,
                      icon: <PayCircleOutlined />,
                      onClick: async () => {
                          handlePayCustomerInvoice(selectedRows);
                      },
                      disabled: isLoadingSession,
                  },
              ]
            : []),
    ];

    // ----------------------ui-----------------------------
    if (error || unAuthorized) {
        return (
            <CommonError
                unAuthorized={unAuthorized}
                message={error}
                code={code}
                hidePageHeader={false}
            />
        );
    }
    return (
        <>
            <div>
                <PageHeader
                    ghost={false}
                    onBack={() => window.history.back()}
                    title="Shipment Orders"
                >
                    <FiltersComponent
                        handleProductFilter={handleFilter}
                        handleFilterChange={handleFilterChange}
                        handelFilterClear={handelFilterClear}
                        isFetched={isFetched}
                        filters={filters}
                        filtersData={shipmentOrderCollection?.getFilters()}
                        isFromProductReceived={true}
                        isLoading={shipmentOrderLoading}
                    />

                    <Flex
                        vertical
                        gap="middle"
                        style={{
                            marginTop: 32,
                        }}
                    >
                        {selectedRows.length > 0 && (
                            <ShipmentOrderTableSummary
                                selectedRows={selectedRows}
                                onClear={() => {
                                    onClearSingleSelectedState();
                                    onClearBulkSelectedState();
                                }}
                            />
                        )}
                        <div>
                            <Dropdown
                                menu={{
                                    items: bulkItems.filter((x) =>
                                        checkActionPermission(
                                            x.permission,
                                            x,
                                            null,
                                        ),
                                    ),
                                }}
                                disabled={selectedRowKeys.length === 0}
                            >
                                <Button icon={<HddFilled />}>
                                    Bulk Actions
                                    <DownOutlined />
                                </Button>
                            </Dropdown>
                        </div>
                    </Flex>

                    <Table
                        bordered
                        style={{ marginTop: 24 }}
                        columns={columns}
                        expandable={{
                            expandedRowRender: (record) => {
                                return (
                                    <ShipmentProductsTable
                                        isOrderPage={true}
                                        data={record.getProducts()}
                                        isLoading={false}
                                        paginationConfig={paginationConfig}
                                        selectedRowKeys={[]}
                                        setSelectedRowKeys={() => {}}
                                        selectedRows={[]}
                                        setSelectedRows={() => {}}
                                        onClearBulkSelectedState={
                                            onClearBulkSelectedState
                                        }
                                    />
                                );
                            },
                        }}
                        rowSelection={{
                            selectedRowKeys,
                            onChange: (selectedRowKeys, selectedRows) => {
                                setSelectedRowKeys(selectedRowKeys);
                                setSelectedRows(selectedRows);
                            },
                        }}
                        rowKey={(key) => {
                            return key.getId();
                        }}
                        pagination={paginationConfig}
                        dataSource={shipmentOrderCollection?.getData()}
                        loading={shipmentOrderLoading}
                        scroll={{ x: BREAK_POINTS.XL }}
                    />
                    {isModalOpen && (
                        <OrderHandlerAssign
                            isModalOpen={isModalOpen}
                            handleCloseModal={handleCloseModal}
                            handleSelectChange={onSubmitHandlerAssign}
                            AssignLoading={AssignLoading}
                            orderHandlerId={orderHandlerId || undefined}
                        />
                    )}
                    {selectedCustomerUser && impersonateData && (
                        <Modal
                            title={
                                <ImpersonateCreateModalTitle
                                    name={selectedCustomerUser?.name || ""}
                                    email={selectedCustomerUser?.email || ""}
                                    title="Impersonate Customer"
                                />
                            }
                            visible={isShowModal}
                            onCancel={() => setIsShowModal(false)}
                            footer={null}
                            width={600}
                        >
                            <ImpersonateResultView response={impersonateData} />
                        </Modal>
                    )}
                </PageHeader>
            </div>

            <Modal
                width={"50%"}
                title="Pay Customer Invoice"
                open={Boolean(selectedPayInvoiceData)}
                onCancel={() => {
                    setSelectedPayInvoiceData(undefined);
                }}
                onOk={form.submit}
                okText="Submit"
                okButtonProps={{ loading: isLoadingSession }}
                centered
                destroyOnClose={true}
            >
                {selectedPayInvoiceData && (
                    <PayCustomerInvoiceModal
                        form={form}
                        data={selectedPayInvoiceData}
                        onFinish={onFinishCustomerPay}
                    />
                )}
            </Modal>
        </>
    );
};

export default ShipmentOrders;
