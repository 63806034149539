import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAtomValue } from "jotai";
import { Button, Card, Col, Flex, Modal, Row } from "antd";

import {
    useGetProductById,
    useGetProductByUrl,
} from "@/lib/core-react/hooks/private/useProduct";
import { inventoryProductDetailAtom } from "@/lib/core-react/store/store";

import ProductImageSlide from "./ProductImageSlide";
import ProductView from "./ProductView";
import ProductHeader from "./productHeader/ProductHeader";
import LoaderSpin from "@/components/LoaderSpin";
import { PageHeader } from "@ant-design/pro-layout";
import { defaultQuery } from "@/consts/defaultQuery";
import usePageQuery from "@/helpers/usePageQuery";
import { getFilteredQuery } from "@/helpers/getFilteredQuery";
import { IProductDetailParams } from "@/types/productDetailExtended";
import { useGetCurrencyByRegionCode } from "@/lib/core-react/hooks/private/useRegion";
import CommonError from "@/components/Error/CommonError";
import { PageOperationEnum } from "../types";
import { PlusOutlined } from "@ant-design/icons";
import AddProductToCampaign from "../newComponents/AddToCampaign";
import { IModalActionsType, IModalData } from "@/helpers/getModalTital";

const ProductDetail = () => {
    const location = useLocation();
    const {
        getProductDetailByUrl,
        isLoading: isLoadingProductByUrl,
        error: errorByUrl,
    } = useGetProductByUrl();
    const {
        getProductDetailById,
        isLoading: isLoadingProductById,
        error: errorById,
    } = useGetProductById();
    const { getCurrencies } = useGetCurrencyByRegionCode();

    const operation = new URLSearchParams(location.search).get("operation");
    const productUrl = new URLSearchParams(location.search).get("productUrl");
    const productId = new URLSearchParams(location.search).get("productId");
    const userId = new URLSearchParams(location.search).get("userId");
    const region =
        new URLSearchParams(location.search).get("region") ||
        defaultQuery.region;
    const campaignId = new URLSearchParams(location.search).get("campaignId");

    const productDetail = useAtomValue(inventoryProductDetailAtom);
    const [modalOpenType, setModalOpenType] =
        useState<IModalActionsType>(false);
    useEffect(() => {
        if (productUrl) {
            getProductDetailByUrl(
                getFilteredQuery({
                    url: productUrl,
                    region,
                    apply_exchange: 1,
                    user_id: Number(userId),
                    locale: defaultQuery.locale,
                }) as IProductDetailParams,
            );
        } else if (productId) {
            getProductDetailById(
                productId,
                getFilteredQuery({
                    region,
                    apply_exchange: 1,
                    user_id: Number(userId),
                    locale: defaultQuery.locale,
                }) as Omit<IProductDetailParams, "url">,
            );
        }
    }, [productId, productUrl]);

    useEffect(() => {
        if (region) {
            getCurrencies({
                region,
            });
        }
    }, [region]);

    const handleModal = async (payload: IModalData) => {
        setModalOpenType(payload.action);
    };

    // ------------------------------ui------------------------

    if (isLoadingProductById || isLoadingProductByUrl) {
        return <LoaderSpin />;
    }

    if (errorByUrl || errorById) {
        return (
            <CommonError
                hidePageHeader
                code={errorById?.code || errorByUrl?.code}
                message={errorById?.message || errorByUrl?.message}
            />
        );
    }
    if (productDetail) {
        return (
            <>
                {operation === PageOperationEnum.ADD_CAMPAIGN_PRODUCT && (
                    <Flex justify="end" style={{ marginBottom: 12 }}>
                        <Button
                            type="primary"
                            htmlType="button"
                            icon={<PlusOutlined />}
                            onClick={() =>
                                handleModal({
                                    action: "add_product_to_campaign",
                                })
                            }
                        >
                            Add To Campaign
                        </Button>
                    </Flex>
                )}
                <Card>
                    <Row>
                        <Col sm={24} lg={20}>
                            <ProductHeader product={productDetail} />
                        </Col>
                        <Col sm={24} lg={4}>
                            <ProductImageSlide product={productDetail} />
                        </Col>
                    </Row>
                    <ProductView product={productDetail} />
                </Card>

                {campaignId && region && (
                    <Modal
                        title="Add Configuration"
                        open={modalOpenType === "add_product_to_campaign"}
                        onCancel={() => {
                            handleModal({ action: false });
                        }}
                        footer={null}
                    >
                        <AddProductToCampaign
                            handleModal={handleModal}
                            region={region}
                            campaignId={campaignId}
                            selectedProducts={[productDetail.getId()]}
                        />
                    </Modal>
                )}
            </>
        );
    }
    return (
        <CommonError
            hidePageHeader
            code={404}
            message={"Product Url Or Id not found on the page url."}
        />
    );
};

const ProductDetailPage = () => {
    const { goBack } = usePageQuery();
    return (
        <PageHeader title="Product Details" onBack={() => goBack()}>
            <ProductDetail />
        </PageHeader>
    );
};

export default ProductDetailPage;
