import { Button, Flex, Popover, Space, Tag, Typography } from "antd";
import { tw } from "@/consts/theme/tailwindTheme";
import { BuyProductModel } from "@/models/buyProductCollectionModel";
import { EditOutlined } from "@ant-design/icons";
import ShippingSlotsTable from "./ShippingRateTable";
import { IModalData } from "@/helpers/getModalTital";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ADMIN_PURCHASE_PERMISSION_ENUM } from "@/consts/permission-enum/purchase-enum";
import { memo } from "react";
import { formatString } from "@/utils/helper";
import { useParams } from "react-router-dom";
import getPagePathname from "@/helpers/getPagePathname";
import { BUY_PRODUCT_DETAIL_PAGE } from "@/consts/pageRoute";
import { StatusTag } from "@/components";
import { RateTypeEnums } from "@/enums/shippingCoreEnums";
const { Paragraph, Text } = Typography;
interface Props {
    buyProduct: BuyProductModel;
    handleModal: (payload: IModalData) => void;
}
const AgentInfo = ({ buyProduct, handleModal }: Props) => {
    const { id } = useParams();
    const isVisible = getPagePathname() === `${BUY_PRODUCT_DETAIL_PAGE}/${id}`;

    const moveonCommissionRate = buyProduct
        .getMoveonShipping()
        ?.getCommissionRate();

    return (
        <div>
            <Space direction="vertical">
                {!isVisible && (
                    <Paragraph>
                        <Text
                            style={{
                                paddingRight: tw.spacing["0.5"],
                            }}
                        >
                            Purchase by :{" "}
                        </Text>
                        {buyProduct.getPurchaseCompanyName() ? (
                            <Space>
                                <Text strong>
                                    {buyProduct.getPurchaseCompanyName()}
                                </Text>

                                {checkActionPermission(
                                    ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_APPROVE,
                                    <Button
                                        style={{ padding: 0 }}
                                        type="link"
                                        onClick={() =>
                                            handleModal({
                                                action: "approve_and_assign_agent",
                                                data: buyProduct,
                                            })
                                        }
                                    >
                                        <EditOutlined />
                                    </Button>,
                                    null,
                                )}
                            </Space>
                        ) : (
                            <Text strong>N/A</Text>
                        )}
                    </Paragraph>
                )}

                {isVisible && (
                    <Paragraph>
                        <Text
                            style={{
                                paddingRight: tw.spacing["0.5"],
                            }}
                        >
                            Purchase Commission :{" "}
                        </Text>
                        <Text strong>
                            {buyProduct.getProductAgentAssociation()
                                ? buyProduct
                                      .getProductAgentAssociation()
                                      ?.getCommissionRate()
                                : "N/A"}
                        </Text>
                    </Paragraph>
                )}
                <Flex justify="start" gap={4} align="baseline">
                    <Text
                        style={{
                            paddingRight: tw.spacing["0.5"],
                        }}
                    >
                        Ship by :{" "}
                    </Text>

                    <Text strong>{buyProduct.getShipBy()}</Text>
                    {checkActionPermission(
                        ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_SHIPMENT_UPDATE,
                        <Button
                            style={{ padding: 0 }}
                            type="link"
                            onClick={() =>
                                handleModal({
                                    action: "update_agent_category_price_read",
                                    data: buyProduct,
                                })
                            }
                        >
                            <EditOutlined />
                        </Button>,
                        null,
                    )}
                </Flex>
                {buyProduct.getMoveonShipping()?.getContain() && (
                    <Paragraph>
                        <Text
                            style={{
                                paddingRight: tw.spacing["0.5"],
                            }}
                        >
                            Contain :{" "}
                        </Text>
                        <StatusTag
                            color="blue"
                            slug={`${buyProduct.getMoveonShipping()?.getContain()}`}
                            text={`${buyProduct.getMoveonShipping()?.getContain()}`}
                        />
                    </Paragraph>
                )}

                {isVisible && (
                    <Paragraph>
                        <Text
                            style={{
                                paddingRight: tw.spacing["0.5"],
                            }}
                        >
                            Source :{" "}
                        </Text>
                        <Text strong>{buyProduct.getSourceName()}</Text>
                    </Paragraph>
                )}
                <Paragraph>
                    <Text
                        style={{
                            paddingRight: tw.spacing["0.5"],
                        }}
                    >
                        Agent Warehouse :{" "}
                    </Text>
                    <Text strong>
                        {buyProduct.getMoveonShipping()?.getAgentWarehouse()
                            .name || "N/A"}
                    </Text>
                </Paragraph>

                <Paragraph>
                    <Text
                        style={{
                            paddingRight: tw.spacing["0.5"],
                        }}
                    >
                        Destination Warehouse :{" "}
                    </Text>
                    <Text strong>
                        {buyProduct.getDestinationWarehouseName()}
                    </Text>
                </Paragraph>
                <Paragraph>
                    <Text
                        style={{
                            paddingRight: tw.spacing["0.5"],
                        }}
                    >
                        Shipping :{" "}
                    </Text>
                    <Text strong>
                        {formatString(buyProduct.getShippingType())}/
                        {formatString(buyProduct.getShippingMode())}
                    </Text>
                </Paragraph>
                {/* <Paragraph>
                    <Text
                        style={{
                            paddingRight: tw.spacing["0.5"],
                        }}
                    >
                        Shipping Mode:
                    </Text>
                    <Text strong>{buyProduct.getShippingMode()}</Text>
                </Paragraph> */}
                <Paragraph>
                    <Text
                        style={{
                            paddingRight: tw.spacing["0.5"],
                        }}
                    >
                        Category :{" "}
                    </Text>
                    <Tag color="blue-inverse">
                        {buyProduct
                            .getMoveonShipping()
                            ?.getShippingCategoryName()}
                    </Tag>
                    {checkActionPermission(
                        ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_SHIPMENT_UPDATE,
                        <Button
                            style={{ padding: 0 }}
                            type="link"
                            onClick={() =>
                                handleModal({
                                    action: "update_agent_category_price_read",
                                    data: buyProduct,
                                })
                            }
                        >
                            <EditOutlined />
                        </Button>,
                        null,
                    )}
                </Paragraph>

                <Flex gap={4}>
                    <Text
                        style={{
                            paddingRight: tw.spacing["0.5"],
                        }}
                    >
                        Moveon Commission :{" "}
                    </Text>
                    {moveonCommissionRate
                        ? `${parseFloat(moveonCommissionRate.toString())}${
                              buyProduct
                                  .getMoveonShipping()
                                  ?.getCommissionRateType() ===
                              RateTypeEnums.PERCENTAGE
                                  ? "%"
                                  : buyProduct.getRegionCurrencyCode()
                          }
                    `
                        : "N/A"}
                </Flex>

                <Flex gap={4}>
                    <Text
                        style={{
                            paddingRight: tw.spacing["0.5"],
                        }}
                    >
                        Rate :{" "}
                    </Text>
                    {buyProduct.getShippingRate() &&
                    buyProduct.getRegionCurrencyCode() &&
                    buyProduct.getShippingRateUnitType() ? (
                        <Tag color="success">
                            <Flex gap={2}>
                                <Text strong>
                                    {buyProduct.getShippingRate()}
                                </Text>
                                <Text strong>
                                    {buyProduct.getRegionCurrencyCode()}
                                </Text>
                                <Text strong>/</Text>
                                <Text strong>
                                    {buyProduct.getShippingRateUnitType()}
                                </Text>
                            </Flex>
                        </Tag>
                    ) : (
                        "N/A"
                    )}
                </Flex>

                <div>
                    <Popover
                        content={
                            <ShippingSlotsTable
                                slots={
                                    buyProduct
                                        .getShipmentProduct()
                                        ?.getCustomerAssociation()
                                        ?.getAssociationSlots() ||
                                    buyProduct?.getMoveonShipping()?.getSlots()
                                }
                                regionCurrencyCode={buyProduct.getRegionCurrencyCode()}
                                unitType={buyProduct.getShippingRateUnitType()}
                            />
                        }
                        trigger="click"
                        placement="bottom"
                    >
                        <Button
                            style={{
                                padding: 0,
                            }}
                            type="link"
                        >
                            View Shipping Slots
                        </Button>
                    </Popover>
                </div>
            </Space>
        </div>
    );
};

export default memo(AgentInfo);
