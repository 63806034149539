import { IAgentCategoryPriceRead } from "./agentCategoryPriceReadCollection";
import { IBuyOrder } from "./buyProductCollection";
import { IFilterType } from "./filters";
import { IPagination } from "./pagination";
import { ICommonUser } from "./shared";
import { IStore } from "./storeCollection";

export interface IBuyProductVariationProperty {
    object: string;
    id: number;
    property_id: string;
    property_name: string;
    property_original_name: string;
    property_value_id: string;
    property_value_name: string;
    property_value_color: string | null;
    property_value_title: string;
    property_value_image: string | null;
    property_value_thumb: string | null;
}

export interface IBuyProductVariationPropertiesCollection {
    object: string;
    data: IBuyProductVariationProperty[];
}

export interface IBuyProductVariation {
    object: string;
    id: number;
    buy_product_id: number;
    cart_item_variation_id: number;
    sku_id: string;
    image: string | null;
    quantity: number;
    unit_price: number;
    total_price: number;
    original_unit_price: number;
    original_total_price: number;
    properties: IBuyProductVariationPropertiesCollection;
}

export interface IBuyProductVariationCollection {
    object: string;
    data: IBuyProductVariation[];
}

export interface IShippingCategory {
    object: string;
    id: string;
    parent_id: string | null;
    name: string;
    slug: string;
}

export interface IMoveOnShipping {
    object: string;
    id: number;
    shipping_type: string;
    shipping_mode: string;
    unit_type: string;
    rate: number;
    agent_category_price_read_id: number;
    shipping_category: IShippingCategory;
}

export interface ICurrency {
    object: string;
    id: number;
    code: string;
    name: string;
    symbol: string;
    symbol_native: string;
}

export interface IRegion {
    object: string;
    id: number;
    name: string;
    code: string;
    store_type: string;
    store: string;
    currency?: ICurrency;
}

export interface IBuyProduct {
    object: string;
    id: number;
    product_number: string;
    product_id: string;
    product_vid: string;
    cart_item_id: number;
    product_title: string;
    product_image: string;
    product_link: string;
    shipping_provider: string;
    total_quantity: number;
    status: string;
    region: IRegion;
    moveon_shipping?: IMoveOnShipping;
    product_variations: IBuyProductVariationCollection;
    store: IStore;
    created_at: string;
    order: IBuyOrder
  }
  
  export interface IVariantMismatch {
    object: string;
    id: number;
    buy_product_variation_id: number;
    mismatch_type: string;
    new_quantity: number;
    new_unit_price: string;
    new_original_unit_price: string;
    suggested_sku_id: null | string | number;
    buy_product_variation?: IBuyProductVariation;
    created_at: string;
}
export interface IVariantMismatchCollection {
    object: string;
    data: IVariantMismatch[];
}

export enum BuyProductChargeStatusEnum {
    CANCELLED = "cancelled",
    AWAITING_FULFILMENT = "awaiting-fulfilment",
}

export interface IBuyProductCharge {
    object: string;
    id: number;
    charge_type: string;
    charged_by: string;
    fx_rate: number;
    commission_rate: number;
    commission_rate_type: string;
    original_amount: number;
    total_amount: number;
    created_at: string;
    fulfilment_status: BuyProductChargeStatusEnum;
}
export interface IBuyProductChargeCollection {
    object: string;
    data: IBuyProductCharge[];
}

export interface IActionSequenceAddonService {
    object: string;
    id: number;
    name: string;
    addon_for: string;
    identifier: string;
    description: string;
    status: string;
    created_at: string | null;
}

export interface IActionSequenceAddonServiceCollection {
    object: string;
    data: IActionSequenceAddonService[];
}

// Interfaces
export interface IBuyActionShippingSlot {
    object: string;
    id: number;
    min_amount: number;
    max_amount: number;
    rate: number;
    created_at: string;
}

export interface IBuyActionShippingSlotCollection {
    object: string;
    data: IBuyActionShippingSlot[];
}

export interface IBuyActionShippingRate {
    object: string;
    id: number;
    rate: number;
    created_at: string;
    agent_category_price_read?: IAgentCategoryPriceRead;
    slots: IBuyActionShippingSlotCollection;
}

export interface IBuyActionShipping {
    object: string;
    id: number;
    agent_warehouse_id: number;
    shipping_category: IShippingCategory;
    agent_category_price_read_id: number;
    destination_warehouse_id: number;
    rate: string;
    created_at: string;
}

export interface IImage {
    object: string;
    id: number;
    title: string | null;
    alt: string | null;
    src: string;
    height: number | null;
    width: number | null;
    created_at: string;
}

export interface IImageCollection {
    object: string;
    data: IImage[];
}

export interface IActionSequence {
    object: string;
    id: number;
    type: string;
    perform_order: number;
    status: string;
    admin_note: string | null;
    customer_note: string | null;
    agent_note: string | null;
    images?: IImageCollection;
    variant_mismatches?: IVariantMismatchCollection | null;
    buy_charge?: null | IBuyProductCharge;
    minimum_order_quantity?: number;
    addons?: IActionSequenceAddonServiceCollection;
    created_at: string;
    shipping?: IBuyActionShipping;
    buy_action_shipping?: IBuyActionShippingRate;
}

export interface IActionSequenceCollection {
    object: string;
    data: IActionSequence[];
}

export interface IBuyAction {
    object: string;
    id: number;
    action_number: string;
    buy_product_id: number;
    status: string;
    admin_note: string | null;
    customer_note: string | null;
    agent_note: string | null;
    sequences: IActionSequenceCollection;
    buy_product: IBuyProduct;
    created_at: string;
    user:ICommonUser;
  }

export interface IBuyActionCollection {
    object: string;
    data: IBuyAction[];
    pagination: IPagination;
    filters: { [key: string]: IFilterType };
}

export interface IQuantityProps {
    sku_id: string;
    new_quantity: number;
    variant_id: number;
}
export interface IActionPerformPayloadSequenceVariant {
    variant_id?: number;
    original_unit_price?: number;
    sku_id?: string;
    new_quantity?: number;
}
export interface IActionPerformPayloadSequence {
    action_type: string;
    customer_note: string;
    sequence_id: number;
    variant_ids?: number[];
    variants?: IActionPerformPayloadSequenceVariant[];
}
export interface IActionPerformPayload {
    customer_note: string;
    action: string;
    sequences: IActionPerformPayloadSequence[];
}
