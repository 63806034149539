import {
    DownOutlined,
    EditOutlined,
    HddFilled,
    PlusOutlined,
} from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import {
    Button,
    Col,
    Dropdown,
    Form,
    Modal,
    Row,
    Table,
    Tag,
    notification,
} from "antd";
import { useState, useEffect } from "react";
import UpdateLanguage from "./components/LanguageModifictionas";
import { LanguageDataModel, LanguageModel } from "@/models/language";
import useDataFilters from "@/hooks/useDataFilters";
import { useAtom } from "jotai";
import { regionLanguage } from "@/lib/core-react/store/store";
import {
    useCreateLanguage,
    useGetLanguage,
    useUpdateLanguage,
} from "@/lib/core-react/hooks/private/useLanguage";
import { IFilterType } from "@/types/filters";
import FiltersComponent from "@/components/FiltersComponent";
import { LanguageDataStatus } from "@/enums/languageCollectionEnums";
import { PaginationModel } from "@/models/pagination";
import { ApiHelperModel } from "@/models/apiHelper";
import { formatDateTime } from "../../../utils/helpers";
import useWindowWidth from "../../../lib/core-react/hooks/public/useWindowWidth";
import { MenuInfo } from "rc-menu/lib/interface";
import { ILanguageUpdateRequest } from "@/types/language";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ExtendedMenuItemType } from "@/types";
import { ADMIN_CORE_PERMISSION_ENUM } from "@/consts/permission-enum/admin-core-enum";
import CommonError from "@/components/Error/CommonError";
import { showError } from "@/helpers/showError";
const { Column } = Table;

const Language = () => {
    const { getLanguage } = useGetLanguage();
    const { updateLanguage, isLoading: updateLoading } = useUpdateLanguage();
    const { createLanguage, isLoading: createLoading } = useCreateLanguage();
    const { isMobile } = useWindowWidth();

    const [form] = Form.useForm();

    const [
        {
            data: languageStoreData,
            isLoading,
            refetch,
            error,
            unAuthorized,
            code,
        },
    ] = useAtom(regionLanguage);

    const {
        filters,
        handleFilterChange,
        handelFilterClear,
        isFirstCall,
        isFetched,
        initializeAvailableFilter,
        refetch: refetchFromFilter,
    } = useDataFilters();

    // Api Call
    useEffect(() => {
        if (
            (filters && !isFetched && isFirstCall) ||
            refetch ||
            refetchFromFilter
        ) {
            ApiHelperModel.makeGetRequest(filters, getLanguage);
        }
    }, [isFirstCall, filters, isFetched, refetch, refetchFromFilter]);

    // Language
    const LanguageData =
        languageStoreData && new LanguageModel(languageStoreData);

    // Filter
    const filterData = LanguageData?.filters;
    // Getting all available filters
    useEffect(() => {
        if (!isFetched && LanguageData?.filters) {
            initializeAvailableFilter(filterData as IFilterType);
        }
    }, [isFetched, initializeAvailableFilter, LanguageData?.filters]);

    // Update modal state
    const [isShowCreateUpdateModal, setIsShowCreateUpdateModal] =
        useState<boolean>(false);
    const [seletedLanguage, setSeletedLanguage] = useState<LanguageDataModel>();
    const [rtlData, setRTLData] = useState(Boolean(seletedLanguage?.is_rtl));

    // Handle Modal
    const handleMenuClick = (e: MenuInfo, record: LanguageDataModel) => {
        setSeletedLanguage(record);

        if (e.key === "update") {
            setIsShowCreateUpdateModal(true);
        }
    };

    const onFinish = async (value) => {
        try {
            const payload: ILanguageUpdateRequest = {
                code: value.code,
                is_rtl: rtlData,
                name: value.name,
                status: value.status,
            };

            if (seletedLanguage) {
                await updateLanguage(seletedLanguage?.id as number, payload);
                setSeletedLanguage(undefined);
                notification["success"]({
                    message: "Language Update successfully",
                });
            } else {
                await createLanguage(payload);
                notification["success"]({
                    message: "Language Create successfully",
                });
            }
            setIsShowCreateUpdateModal(false);
        } catch (error: any) {
            showError(error, form);
        }
    };

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getLanguage,
        );
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        LanguageData,
        handlePaginationChange,
    );

    // Filter Handling
    const handleProductFilter = () => {
        ApiHelperModel.makeGetRequest(filters, getLanguage);
    };

    if (!isLoading && error) {
        return (
            <CommonError
                unAuthorized={unAuthorized}
                message={error}
                code={code}
            />
        );
    }

    return (
        <>
            <div>
                <PageHeader
                    ghost={false}
                    title="Region Language"
                    style={{ marginTop: "10px" }}
                    extra={[
                        checkActionPermission(
                            ADMIN_CORE_PERMISSION_ENUM.ADMIN_LANGUAGE_MANAGE,
                            <Button
                                key="1"
                                type="primary"
                                onClick={() => setIsShowCreateUpdateModal(true)}
                                icon={<PlusOutlined />}
                            >
                                Create a Language
                            </Button>,
                            null,
                        ),
                    ]}
                    onBack={() => window.history.back()}
                >
                    <div>
                        {filters && Object.keys(filters).length > 0 && (
                            <Row>
                                <Col span={24}>
                                    <FiltersComponent
                                        handleProductFilter={
                                            handleProductFilter
                                        }
                                        handleFilterChange={handleFilterChange}
                                        handelFilterClear={handelFilterClear}
                                        isFetched={isFetched}
                                        filters={filters}
                                        filtersData={filterData}
                                        isFromProductReceived={true}
                                    />
                                </Col>
                            </Row>
                        )}

                        <Table
                            style={{ marginTop: "15px" }}
                            loading={isLoading}
                            rowKey="id"
                            pagination={paginationConfig}
                            dataSource={LanguageData?.data}
                            scroll={{ x: 1000 }}
                        >
                            <Column
                                width={70}
                                title="ID"
                                dataIndex="id"
                                key="id"
                            />
                            <Column title="Code" dataIndex="code" key="code" />
                            <Column title="Name" dataIndex="name" key="name" />
                            <Column
                                title="Is RTL"
                                dataIndex="is_rtl"
                                key="is_rtl"
                                render={(_, record: LanguageDataModel) => (
                                    <Tag
                                        color={record.is_rtl ? "green" : "red"}
                                        key={record.status}
                                    >
                                        {record.is_rtl ? "true" : "false"}
                                    </Tag>
                                )}
                            />
                            <Column
                                title="Status"
                                dataIndex="status"
                                key="status"
                                render={(_, record: LanguageDataModel) => (
                                    <Tag
                                        color={
                                            record.status ===
                                            LanguageDataStatus.active // active
                                                ? "green"
                                                : "red"
                                        }
                                        key={record.status}
                                    >
                                        {record.status}
                                    </Tag>
                                )}
                            />
                            <Column
                                title="Created at"
                                dataIndex="created_at"
                                key="created_at"
                                render={(created_at) => {
                                    return (
                                        <Tag color={"purple"}>
                                            {formatDateTime(created_at)}
                                        </Tag>
                                    );
                                }}
                            />
                            <Column
                                title="Action"
                                key="action"
                                fixed={isMobile ? "right" : undefined}
                                width={isMobile ? 100 : 150}
                                hidden={
                                    !checkActionPermission(
                                        ADMIN_CORE_PERMISSION_ENUM.ADMIN_LANGUAGE_MANAGE,
                                        "checkPermission",
                                        null,
                                    )
                                }
                                render={(_, record: LanguageDataModel) => {
                                    const items: ExtendedMenuItemType[] = [
                                        {
                                            permission:
                                                ADMIN_CORE_PERMISSION_ENUM.ADMIN_LANGUAGE_MANAGE,
                                            label: "Update",
                                            key: "update",
                                            icon: <EditOutlined />,
                                            onClick: (e) =>
                                                handleMenuClick(e, record),
                                        },
                                    ];

                                    return (
                                        <Dropdown
                                            menu={{
                                                items: items.filter((x) =>
                                                    checkActionPermission(
                                                        x.permission,
                                                        x,
                                                        null,
                                                    ),
                                                ),
                                            }}
                                        >
                                            <Button icon={<HddFilled />}>
                                                {isMobile ? (
                                                    <DownOutlined />
                                                ) : (
                                                    <>
                                                        {" "}
                                                        Actions <DownOutlined />
                                                    </>
                                                )}
                                            </Button>
                                        </Dropdown>
                                    );
                                }}
                            />
                        </Table>
                    </div>
                </PageHeader>
            </div>

            <Modal
                title={`${seletedLanguage ? "Update" : "Create"} Language`}
                open={isShowCreateUpdateModal}
                okText={seletedLanguage ? "Update" : "Create"}
                okButtonProps={{ loading: createLoading || updateLoading }}
                onCancel={() => {
                    setIsShowCreateUpdateModal(false);
                    setSeletedLanguage(undefined);
                }}
                onOk={form.submit}
                centered
                destroyOnClose={true}
            >
                <UpdateLanguage
                    rtlData={rtlData}
                    setRTLData={setRTLData}
                    data={seletedLanguage}
                    form={form}
                    onFinish={onFinish}
                />
            </Modal>
        </>
    );
};

export default Language;
