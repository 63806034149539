import {
    IPaymentSession,
    IPaymentSessionInvoice,
    IPaymentSessionInvoices,
    IPaymentSessionResponse,
    IPaymentSessionRuleGroup,
    ISessionInvoice,
    ISessionInvoiceBuyProduct,
    ISessionInvoiceShipmentProduct,
    ISessionInvoiceShipmentProductPackage,
    ISessionInvoiceShipmentProductPackageCollection,
    ISessionInvoiceTransactionSummary,
} from "@/types/paymentSession";
import { CommonProductImageModel } from "./shared";
import { PaymentGatewayTypeEnums } from "@/enums/paymentGatewayCollectionEnums";
import { roundToTwoDecimal } from "@/helpers/roundToTwoDecimal";

export class SessionInvoiceBuyProductModel {
    object: string;
    id: number;
    product_number: string;
    product_id: string;
    product_title: string;
    product_image: string;
    shipping_provider: string;
    total_quantity: number;
    status: string;
    shipping_address: Record<string, string>;
    shipping_mode: string;
    shipping_type: string;
    created_at: string;

    constructor(data: ISessionInvoiceBuyProduct) {
        this.object = data.object;
        this.id = data.id;
        this.product_title = data.product_title;
        this.product_number = data.product_number;
        this.product_image = data.product_image;
        this.product_id = data.product_id;
        this.shipping_provider = data.shipping_provider;
        this.total_quantity = data.total_quantity;
        this.status = data.status;
        this.shipping_address = data.shipping_address;
        this.shipping_mode = data.shipping_mode;
        this.shipping_type = data.shipping_type;
        this.created_at = data.created_at;
    }

    getId = () => this.id;
    getProductTitle = () => this.product_title;
    getProductNumber = () => this.product_number;
    getProductImage = () => this.product_image;
    getProductId = () => this.product_id;
    getShippingAddress = () => this.shipping_address;
    getShippingProvider = () => this.shipping_provider;
    getTotalQuantity = () => this.total_quantity;
    getStatus = () => this.status;
    getShippingMode = () => this.shipping_mode;
    getShippingType = () => this.shipping_type;
    getCreatedAt = () => this.created_at;
}

export class SessionInvoiceShipmentProductPackageModel {
    object: string;
    id: number;
    package_number: string;
    product_price: number;
    quantity: number;
    weight: number;
    length: number;
    width: number;
    height: number;
    cbm: number;
    contains: string;
    contains_2: string | null;
    hs_code: string | null;
    carton_number: string;
    fulfillment_status: string;

    constructor(data: ISessionInvoiceShipmentProductPackage) {
        this.object = data.object;
        this.id = data.id;
        this.package_number = data.package_number;
        this.product_price = data.product_price;
        this.quantity = data.quantity;
        this.weight = data.weight;
        this.length = data.length;
        this.width = data.width;
        this.height = data.height;
        this.cbm = data.cbm;
        this.contains = data.contains;
        this.contains_2 = data.contains_2;
        this.hs_code = data.hs_code;
        this.carton_number = data.carton_number;
        this.fulfillment_status = data.fulfillment_status;
    }

    getQuantity = () => this.quantity;
    getWeight = () => this.weight;
    getLength = () => this.length;
    getWidth = () => this.width;
    getHeight = () => this.height;
    getCbm = () => this.cbm;
    getContains = () => this.contains;
    getCartonNumber = () => this.carton_number;
}

export class SessionInvoiceShipmentProductPackageCollectionModel {
    object: string;
    data: SessionInvoiceShipmentProductPackageModel[];

    constructor(data: ISessionInvoiceShipmentProductPackageCollection) {
        this.object = data.object;
        this.data = data.data.map(
            (d) => new SessionInvoiceShipmentProductPackageModel(d),
        );
    }
    getData = () => this.data;
}

export class SessionInvoiceShipmentProductModel {
    object: string;
    id: number;
    product_number: string;
    images: CommonProductImageModel;
    shipping_address: Record<string, string>;
    shipping_mode: string;
    shipping_type: string;
    status: string;
    packages: SessionInvoiceShipmentProductPackageCollectionModel;

    constructor(data: ISessionInvoiceShipmentProduct) {
        this.object = data.object;
        this.id = data.id;
        this.product_number = data.product_number;
        this.images = new CommonProductImageModel(data.images);
        this.shipping_address = data.shipping_address;
        this.shipping_mode = data.shipping_mode;
        this.shipping_type = data.shipping_type;
        this.status = data.status;
        this.packages = new SessionInvoiceShipmentProductPackageCollectionModel(
            data.packages,
        );
    }

    getId = () => this.id;
    getProductNumber = () => this.product_number;
    getImages = () => this.images;
    getShippingAddress = () => this.shipping_address;
    getShippingMode = () => this.shipping_mode;
    getShippingType = () => this.shipping_type;
    getStatus = () => this.status;

    // processesed
    getTotalQuantity = () => {
        return this.packages
            .getData()
            .map((d) => d.getQuantity())
            .reduce((total, quantity) => total + quantity, 0);
    };

    getTotalWeight = () => {
        return Number(
            this.packages
                .getData()
                .map((d) => d.getWeight())
                .reduce((total, weight) => total + weight, 0)
                .toFixed(2),
        );
    };
}

export class SessionInvoiceTransactionSummaryModel {
    paid: number;
    due: number;
    overpaid: number;

    constructor(data: ISessionInvoiceTransactionSummary) {
        this.paid = data.paid;
        this.due = data.due;
        this.overpaid = data.overpaid;
    }

    getPaid = () => this.paid;
    getDue = () => this.due;
    getOverpaid = () => this.overpaid;
}

export class SessionInvoiceModel {
    object: string;
    id: number;
    invoice_number: string;
    status: string;
    total_amount: number;
    initial_amount: number;
    refundable_amount: number;
    transaction_summary: SessionInvoiceTransactionSummaryModel;
    shipment_product?: SessionInvoiceShipmentProductModel;
    buy_product?: SessionInvoiceBuyProductModel;
    created_at: string;

    constructor(data: ISessionInvoice) {
        this.object = data.object;
        this.id = data.id;
        this.invoice_number = data.invoice_number;
        this.status = data.status;
        this.total_amount = data.total_amount;
        this.initial_amount = data.initial_amount;
        this.refundable_amount = data.refundable_amount;
        this.transaction_summary = new SessionInvoiceTransactionSummaryModel(
            data.transaction_summary,
        );
        this.shipment_product =
            data.shipment_product &&
            new SessionInvoiceShipmentProductModel(data.shipment_product);
        this.buy_product =
            data.buy_product &&
            new SessionInvoiceBuyProductModel(data.buy_product);
        this.created_at = data.created_at;
    }

    getInvoiceNumber = () => this.invoice_number;
    getId = () => this.id;
    getStatus = () => this.status;
    getTotalAmount = () => this.total_amount;
    getInitialAmount = () => this.initial_amount;
    getRefundableAmount = () => this.refundable_amount;
    getTransactionSummary = () => this.transaction_summary;
    getShipmentProduct = () => this.shipment_product;
    getBuyProduct = () => this.buy_product;
    getCreatedAt = () => this.created_at;

    // processed response
    getProductDetails = () => {
        const image = "";
        let title: string = "";
        let quantity: number = 0;
        let weight: number = 0;

        if (this.buy_product) {
            quantity = this.buy_product.getTotalQuantity();
            title = this.buy_product.getProductTitle();
        } else if (this.shipment_product) {
            quantity = this.shipment_product.getTotalQuantity();
            weight = this.shipment_product.getTotalWeight();
        }

        return {
            image,
            title,
            quantity,
            weight,
        };
    };

    getSessionInvoiceTotalQuantity = () => {
        if (this.buy_product) {
            return this.buy_product.getTotalQuantity();
        } else if (this.shipment_product) {
            return this.shipment_product.getTotalQuantity();
        }
    };
}

export class PaymentSessionInvoiceModel {
    object: string;
    invoice: SessionInvoiceModel;
    created_at: string;

    constructor(data: IPaymentSessionInvoice) {
        this.object = data.object;
        this.invoice = new SessionInvoiceModel(data.invoice);
        this.created_at = data.created_at;
    }

    getInvoice = () => this.invoice;
}

export class PaymentSessionInvoicesModel {
    object: string;
    data: PaymentSessionInvoiceModel[];

    constructor(data: IPaymentSessionInvoices) {
        this.object = data.object;
        this.data = data.data.map((d) => new PaymentSessionInvoiceModel(d));
    }

    getData = () => this.data;
}

export class PaymentSessionRuleGroupModel {
    object: string;
    is_wallet_enabled: number;
    wallet_payment_percentage: number;
    is_automatic_gateway_enabled: number;
    automatic_gateway_payment_percentage: number;
    is_bank_deposit_enabled: number;
    bank_deposit_payment_percentage: number;
    is_mobile_merchant_enabled: number;
    mobile_merchant_payment_percentage: number;

    constructor(data: IPaymentSessionRuleGroup) {
        this.object = data.object;
        this.is_wallet_enabled = data.is_wallet_enabled;
        this.wallet_payment_percentage = data.wallet_payment_percentage;
        this.is_automatic_gateway_enabled = data.is_automatic_gateway_enabled;
        this.automatic_gateway_payment_percentage =
            data.automatic_gateway_payment_percentage;
        this.is_bank_deposit_enabled = data.is_bank_deposit_enabled;
        this.bank_deposit_payment_percentage =
            data.bank_deposit_payment_percentage;
        this.is_mobile_merchant_enabled = data.is_mobile_merchant_enabled;
        this.mobile_merchant_payment_percentage =
            data.mobile_merchant_payment_percentage;
    }

    getIsWalletEnabled = () => this.is_wallet_enabled;
    getWalletPaymentPercentage = () => this.wallet_payment_percentage;
    getIsAutomaticGatewayEnabled = () => this.is_automatic_gateway_enabled;
    getAutomaticGatewayPaymentPercentage = () =>
        this.automatic_gateway_payment_percentage;
    getIsBankDepositEnabled = () => this.is_bank_deposit_enabled;
    getBankDepositPaymentPercentage = () =>
        this.bank_deposit_payment_percentage;
    getIsMobileMerchantEnabled = () => this.is_mobile_merchant_enabled;
    getMobileMerchantPaymentPercentage = () =>
        this.mobile_merchant_payment_percentage;
    getPercentageByType = (type: PaymentGatewayTypeEnums) => {
        if (type === PaymentGatewayTypeEnums.WALLET)
            return this.getWalletPaymentPercentage();
        if (type === PaymentGatewayTypeEnums.AUTOMATIC_PAYMENT)
            return this.getAutomaticGatewayPaymentPercentage();
        if (type === PaymentGatewayTypeEnums.BANK_DEPOSIT)
            return this.getBankDepositPaymentPercentage();
        if (type === PaymentGatewayTypeEnums.MOBILE_MERCHANT)
            return this.getMobileMerchantPaymentPercentage();
    };
}

export class PaymentSessionModel {
    object: string;
    session_token: string;
    session_invoices: PaymentSessionInvoicesModel;
    rule_group: PaymentSessionRuleGroupModel;
    created_at: string;

    constructor(data: IPaymentSession) {
        this.object = data.object;
        this.session_token = data.session_token;
        this.session_invoices = new PaymentSessionInvoicesModel(
            data.session_invoices,
        );
        this.rule_group = new PaymentSessionRuleGroupModel(data.rule_group);
        this.created_at = data.created_at;
    }

    getSessionToken = () => this.session_token;
    getSessionInvoices = () => this.session_invoices;
    getCreatedAt = () => this.created_at;
    getRuleGroup = () => this.rule_group;

    getInvoiceType = () => {
        const type = this.session_invoices.getData().length
            ? this.session_invoices.getData()[0].getInvoice().getBuyProduct() &&
              this.session_invoices
                  .getData()[0]
                  .getInvoice()
                  .getShipmentProduct()
                ? "both"
                : this.session_invoices
                        .getData()[0]
                        .getInvoice()
                        .getBuyProduct()
                  ? "buyProduct"
                  : this.session_invoices
                          .getData()[0]
                          .getInvoice()
                          .getShipmentProduct()
                    ? "shipmentProduct"
                    : ""
            : "";
        return type;
    };

    getTotalDue = () => {
        const totalDue = this.session_invoices
            .getData()
            .map((invoice) =>
                invoice.getInvoice().getTransactionSummary().getDue(),
            )
            .reduce((total, due) => total + due, 0);

        return roundToTwoDecimal(totalDue);
    };

    getTotalQuantity = () => {
        return this.session_invoices.getData().reduce((total, d) => {
            const quantity =
                d.getInvoice?.().getSessionInvoiceTotalQuantity?.() || 0;
            return total + quantity;
        }, 0);
    };

    getAmountToPay = (type: string) => {
        const totalDue = this.getTotalDue();
        let amountToPay = 0;
        if (type === PaymentGatewayTypeEnums.WALLET) {
            amountToPay =
                (totalDue * this.rule_group.getWalletPaymentPercentage()) / 100;
        } else if (type === PaymentGatewayTypeEnums.AUTOMATIC_PAYMENT) {
            amountToPay =
                (totalDue *
                    this.rule_group.getAutomaticGatewayPaymentPercentage()) /
                100;
        } else if (type === PaymentGatewayTypeEnums.MOBILE_MERCHANT) {
            amountToPay =
                (totalDue *
                    this.rule_group.getMobileMerchantPaymentPercentage()) /
                100;
        } else if (type === PaymentGatewayTypeEnums.BANK_DEPOSIT) {
            amountToPay =
                (totalDue * this.rule_group.getBankDepositPaymentPercentage()) /
                100;
        }

        amountToPay = Math.ceil(amountToPay);
        return amountToPay;
    };
    getPercentageByType(type: string) {
        if (type === PaymentGatewayTypeEnums.WALLET) {
            return this.getRuleGroup().getWalletPaymentPercentage();
        } else if (type === PaymentGatewayTypeEnums.AUTOMATIC_PAYMENT) {
            return this.getRuleGroup().getAutomaticGatewayPaymentPercentage();
        } else if (type === PaymentGatewayTypeEnums.MOBILE_MERCHANT) {
            return this.getRuleGroup().getMobileMerchantPaymentPercentage();
        } else if (type === PaymentGatewayTypeEnums.BANK_DEPOSIT) {
            return this.getRuleGroup().getBankDepositPaymentPercentage();
        }
    }
}

export class PaymentSessionResponseModel {
    message: string;
    data: PaymentSessionModel;

    constructor(data: IPaymentSessionResponse) {
        this.message = data.message;
        this.data = new PaymentSessionModel(data.data);
    }

    getData() {
        return this.data;
    }
}
