import { useEffect, useState } from "react";
import { PageHeader } from "@ant-design/pro-layout";
import { useAtomValue } from "jotai";

import { useGetShipmentProducts } from "@/lib/core-react/hooks/private/useShipping";
import { shipmentProductsAtom } from "@/lib/core-react/store/store";
import useDataFilters from "@/hooks/useDataFilters";
import { PaginationModel } from "@/models/pagination";
import { ApiHelperModel } from "@/models/apiHelper";
import {
    ShipmentProductCollectionModel,
    ShipmentProductModel,
} from "@/models/shipmentProductCollectionModel";
import { IFilterType } from "@/types/filters";

import FiltersComponent from "@/components/FiltersComponent";
import ShipmentProductsTable from "./ShipmentProductsTable";
import { HarvestActionEnums } from "@/enums/harvestJobCollectionEnums";

const ShipmentProducts = () => {
    const { getShipmentProducts } = useGetShipmentProducts();

    const {
        data: shipmentProductCollectionData,
        isLoading,
        refetch,
    } = useAtomValue(shipmentProductsAtom);

    const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
    const [selectedRows, setSelectedRows] = useState<ShipmentProductModel[]>(
        [],
    );
    const onClearBulkSelectedState = () => {
        setSelectedRowKeys([]);
        setSelectedRows([]);
    };

    const {
        filters,
        handleFilterChange,
        handelFilterClear,
        isFirstCall,
        isFetched,
        initializeAvailableFilter,
        refetch: refetchFromFilter,
    } = useDataFilters();

    // Api Call
    useEffect(() => {
        if (
            (filters && !isFetched && isFirstCall) ||
            refetch ||
            refetchFromFilter
        ) {
            ApiHelperModel.makeGetRequest(filters, getShipmentProducts);
        }
    }, [isFirstCall, filters, isFetched, refetch, refetchFromFilter]);

    const shipmentProductCollection =
        shipmentProductCollectionData &&
        new ShipmentProductCollectionModel(shipmentProductCollectionData);

    const filterData = shipmentProductCollection?.filters;

    useEffect(() => {
        if (!isFetched && shipmentProductCollection?.filters) {
            initializeAvailableFilter(filterData as IFilterType);
        }
    }, [
        isFetched,
        initializeAvailableFilter,
        shipmentProductCollection?.filters,
    ]);

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getShipmentProducts,
        );
        onClearBulkSelectedState();
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        shipmentProductCollection,
        handlePaginationChange,
    );

    // Filter Handler
    const handleFilter = () => {
        ApiHelperModel.makeGetRequest(filters, getShipmentProducts);
        onClearBulkSelectedState();
    };

    return (
        <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title="Shipment Products"
            style={{ marginTop: "10px" }}
        >
            <FiltersComponent
                isLoading={isLoading}
                handleProductFilter={handleFilter}
                handleFilterChange={handleFilterChange}
                handelFilterClear={handelFilterClear}
                isFetched={isFetched}
                filters={filters}
                filtersData={filterData}
                isFromProductReceived={true}
                harvestKey={
                    HarvestActionEnums.SHIPMENT_BUY_PRODUCT_EXPORT_HARVEST
                }
            />

            <ShipmentProductsTable
                isOrderPage={false}
                data={shipmentProductCollection?.getAllProducts()}
                isLoading={isLoading}
                paginationConfig={paginationConfig}
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                onClearBulkSelectedState={onClearBulkSelectedState}
            />
        </PageHeader>
    );
};

export default ShipmentProducts;
