import { useEffect, useRef, useState } from "react";
import {
    Alert,
    Avatar,
    Button,
    Card,
    Empty,
    Form,
    Select,
    Skeleton,
    Tag,
    TreeSelect,
    Typography,
} from "antd";
import { useGetShippingCategory } from "@/lib/core-react/hooks/private";
import {
    agentCategoryPriceReadCollectionAtom,
    shippingCategoryAtom,
} from "@/lib/core-react/store/store";
import { useAtom, useAtomValue } from "jotai";
import { ShippingCategoryCollectionModel } from "@/models/shippingCategory";
import { transformEnumToLabeledValue } from "@/utils/helpers";
import { ShippingModesEnums, ShippingTypesEnums } from "@/enums/shippingEnums";
import { AgentCategoryPriceReadCollectionModel } from "@/models/agentCategoryPriceReadCollectionModel";
import { useGetCategoryPrices } from "@/lib/core-react/hooks/private/useShippingAgent";
import { IShippingUpdatePayload } from "@/types/buyOrderCollection";
import { useBuyProductsShippingUpdate } from "@/lib/core-react/hooks/private/usePurchase";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";
import { MoveonShippingModel } from "@/models/buyProductCollectionModel";
import { RegionModel } from "@/models/regionCollectionModel";
import { IModalData } from "@/helpers/getModalTital";
import QueryString from "qs";
import { showError } from "@/helpers/showError";
import { useParams } from "react-router-dom";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { showSuccessAlert } from "@/helpers/showSuccess";
import useRefetch from "@/hooks/useRefetch";
interface IProps {
    shipping: MoveonShippingModel | undefined;
    userId: number;
    region: RegionModel | undefined;
    buyProductId: number;
    hideSubmitButton?: boolean;
    onAgentCompanyPriceIdChange?: (id: number | undefined) => void;
    handleModal: (payload: IModalData) => void;
}

const ShippingUpdateModal = ({
    shipping,
    userId,
    region,
    buyProductId,
    hideSubmitButton,
    onAgentCompanyPriceIdChange,
    handleModal,
}: IProps) => {
    const { Text } = Typography;
    const [form] = Form.useForm();
    const {
        allOptionsData: {
            shippingCountryOptionsData,
            destinationWarehouseOptionsData,
        },
        onFetchFilterApi,
    } = useFiltersApiData();
    const { refetchDetailApi, refetchListApi } = useRefetch();
    const { getShippingCategory } = useGetShippingCategory();
    const { getCategoryPrices } = useGetCategoryPrices();
    const { buyProductShippingUpdate, isLoading: updateLoading } =
        useBuyProductsShippingUpdate();
    const { id } = useParams();

    const [shippingType, setShippingType] = useState<string>("air");
    const [shippingMode, setShippingMode] = useState<string>("cargo");

    const [shippingCategoryId, setShippingCategoryId] = useState<
        string | undefined
    >(undefined);

    const [{ data: shippingCategoryAtomData, isLoading }] =
        useAtom(shippingCategoryAtom);

    const {
        data: categoryPriceReadCollection,
        isLoading: categoryPriceReadLoading,
    } = useAtomValue(agentCategoryPriceReadCollectionAtom);

    const [destinationWarehouse, setDestinationWarehouse] = useState<
        string | undefined
    >();

    const [shippingCountry, setSetShippingCountry] = useState<
        number | undefined
    >();

    // Add a ref to track if initial fetch has been made
    const initialFetchMade = useRef(false);
    const previousRegionId = useRef<number | null>(null);

    useEffect(() => {
        // Only fetch if we have a region and haven't fetched before
        // or if the region has changed
        if (
            region?.id &&
            (!initialFetchMade.current ||
                previousRegionId.current !== region.id)
        ) {
            // Update refs
            initialFetchMade.current = true;
            previousRegionId.current = region.id;

            // Make API calls
            getShippingCategory(
                QueryString.stringify({
                    per_page: 500,
                    region_id: region.id,
                }),
            );

            Promise.resolve().then(() => {
                onFetchFilterApi(filterResourceEnum.DESTINATION_WAREHOUSE, {
                    region_id: region.id,
                });
            });

            Promise.resolve().then(() => {
                onFetchFilterApi(filterResourceEnum.SHIPPING_COUNTRY, {
                    region_id: region.id,
                });
            });
        }
    }, [region?.id]);

    useEffect(() => {
        if (shippingCategoryId && shipping && region) {
            const destinationWarehouseId = Number(destinationWarehouse)
                ? Number(destinationWarehouse)
                : shipping.getDestinationWarehouse()?.getId();

            const shippingCountryId = Number(shippingCountry)
                ? Number(shippingCountry)
                : shipping.getShippingCountryId();

            getCategoryPrices({
                user_id: userId,
                region: region.code,
                destination_warehouse_id: destinationWarehouseId,
                shipping_category_id: shippingCategoryId,
                shipping_country_id: shippingCountryId,
                shipping_mode: shippingMode || shipping.getShippingMode(),
                shipping_type: shippingType || shipping.getShippingType(),
                per_page: 500,
            });
        }
    }, [
        shippingType,
        shippingMode,
        shippingCategoryId,
        destinationWarehouse,
        shippingCountry,
        region?.code,
        userId,
    ]);

    const shippingCategoryOptions =
        shippingCategoryAtomData &&
        new ShippingCategoryCollectionModel(
            shippingCategoryAtomData,
        ).getShippingCategoryTreeIds();

    const categoryPriceReadCollectionData =
        categoryPriceReadCollection &&
        new AgentCategoryPriceReadCollectionModel(categoryPriceReadCollection);
    const [agentCompanyPriceId, setAgentCompanyPriceId] = useState<
        number | undefined
    >(undefined);

    useEffect(() => {
        if (onAgentCompanyPriceIdChange) {
            onAgentCompanyPriceIdChange(agentCompanyPriceId);
        }
    }, [agentCompanyPriceId, onAgentCompanyPriceIdChange]);

    useEffect(() => {
        if (shipping) {
            setShippingCategoryId(shipping.getShippingCategoryId());
            form.setFieldValue(
                "shipping_category_id",
                shipping.getShippingCategoryId(),
            );
        }
    }, [shipping]);

    const onFinish = async () => {
        const payload: IShippingUpdatePayload = {
            agent_category_price_read_id: Number(agentCompanyPriceId),
        };

        try {
            await buyProductShippingUpdate(buyProductId, payload);
            form.resetFields();
            setAgentCompanyPriceId(undefined);
            setShippingCategoryId(undefined);
            showSuccessAlert("Successfully Updated Shipping Info");
            handleModal({
                action: false,
                data: null,
            });
            if (id) {
                await refetchDetailApi(id);
            } else {
                refetchListApi();
            }
        } catch (error: any) {
            showError(error);
        }
    };

    const [expandedId, setExpandedId] = useState(null);

    const toggleExpand = (id) => {
        setExpandedId(expandedId === id ? null : id);
    };

    if (!shipping) {
        return (
            <Empty
                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                imageStyle={{ height: 60, marginTop: 32 }}
                description={<Alert message="Shipping No Found" type="error" />}
            ></Empty>
        );
    }

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <Form
                name="shippingUpdate"
                form={form}
                onFinish={onFinish}
                layout="vertical"
                initialValues={{
                    shippingCountry: shipping.getShippingCountryId(),
                    destinationWarehouse: shipping?.getDestinationWarehouseId(),
                    shipping_type: shipping.shipping_type,
                    shipping_mode: shipping.shipping_mode,
                    rate: shipping.rate,
                    shipping_category_id: shippingCategoryId,
                }}
            >
                <Form.Item
                    label="Select a Shipping Country"
                    name="shippingCountry"
                    rules={[
                        {
                            required: true,
                            message: "Shipping Country is required",
                        },
                    ]}
                >
                    {shippingCountryOptionsData.isLoading ? (
                        <Skeleton.Input
                            style={{ width: 300 }}
                            active
                            size="small"
                        />
                    ) : (
                        <Select
                            loading={shippingCountryOptionsData.isLoading}
                            placeholder="Select a Shipping Country"
                            onChange={(value) => setSetShippingCountry(value)}
                            options={shippingCountryOptionsData.options}
                        />
                    )}
                </Form.Item>

                <Form.Item
                    label="Select the Destinations Warehouse"
                    name="destinationWarehouse"
                    rules={[
                        {
                            required: true,
                            message: "Destinations Warehouse is required",
                        },
                    ]}
                >
                    {destinationWarehouseOptionsData.isLoading ? (
                        <Skeleton.Input
                            style={{ width: 300 }}
                            active
                            size="small"
                        />
                    ) : (
                        <Select
                            placeholder="Please select a destination Warehouse"
                            options={destinationWarehouseOptionsData.options}
                            onChange={(value) => setDestinationWarehouse(value)}
                        />
                    )}
                </Form.Item>

                <Form.Item name="shipping_type" label="Shipping Type">
                    <Select
                        loading={isLoading}
                        placeholder="Please select a shipping type"
                        onChange={(value) => setShippingType(value)}
                        options={transformEnumToLabeledValue(
                            ShippingTypesEnums,
                        )}
                    />
                </Form.Item>

                <Form.Item name="shipping_mode" label="Shipping Mode">
                    <Select
                        loading={isLoading}
                        placeholder="Please select a shipping mode"
                        onChange={(value) => setShippingMode(value)}
                        options={transformEnumToLabeledValue(
                            ShippingModesEnums,
                        )}
                    />
                </Form.Item>

                <Form.Item
                    label="Select Shipping Category"
                    name="shipping_category_id"
                    rules={[
                        {
                            required: false,
                            message: "Shipping Category is required",
                        },
                    ]}
                >
                    <TreeSelect
                        onChange={(value) => {
                            form.setFieldValue("shipping_category_id", value);
                            setShippingCategoryId(value);
                        }}
                        showSearch
                        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                        placeholder="Please select a shipping category"
                        allowClear
                        loading={isLoading}
                        // defaultValue={"9cfb4fa4-b3be-494c-bff4-7f5e4f586329"}
                        treeLine={{ showLeafIcon: true }}
                        treeData={shippingCategoryOptions}
                        filterTreeNode={(searchValue, treeNode) => {
                            const title = treeNode.title;
                            return (
                                typeof title === "string" &&
                                title
                                    .toLowerCase()
                                    .includes(searchValue.toLowerCase())
                            );
                        }}
                    />
                </Form.Item>
                <div>
                    <Card
                        title={<Text strong>Select Cargo Company</Text>}
                        style={{ backgroundColor: "#F7F8FA" }}
                    >
                        <div style={{ maxHeight: "220px", overflowY: "auto" }}>
                            {categoryPriceReadLoading ? (
                                <Skeleton active />
                            ) : !categoryPriceReadCollectionData ? (
                                <div>Please select a category</div>
                            ) : categoryPriceReadCollectionData.getData()
                                  .length === 0 ? (
                                <div>
                                    Shipping partner is not available for this
                                    category!
                                </div>
                            ) : (
                                categoryPriceReadCollectionData
                                    .getData()
                                    .map((data) => {
                                        const isExpanded =
                                            expandedId === data.getId();

                                        return (
                                            <div
                                                key={data.getId()}
                                                style={{ marginBottom: "16px" }}
                                            >
                                                <div
                                                    onClick={() => {
                                                        setAgentCompanyPriceId(
                                                            data.getId(),
                                                        );
                                                    }}
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "space-between",
                                                        alignItems: "center",
                                                        gap: 10,
                                                        backgroundColor:
                                                            "white",
                                                        borderRadius: "5px",
                                                        padding: "16px",
                                                        cursor: "pointer",
                                                        border:
                                                            agentCompanyPriceId ===
                                                            data.getId()
                                                                ? "1px solid green"
                                                                : "",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            gap: "12px",
                                                        }}
                                                    >
                                                        <Avatar
                                                            size={50}
                                                            shape="square"
                                                            style={{
                                                                backgroundColor:
                                                                    "#00897B",
                                                                color: "white",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                    "20px",
                                                            }}
                                                        >
                                                            {
                                                                data
                                                                    .getAgentWarehouse()
                                                                    .getName()[0]
                                                            }
                                                        </Avatar>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexDirection:
                                                                    "column",
                                                                justifyContent:
                                                                    "space-between",
                                                            }}
                                                        >
                                                            <Text
                                                                style={{
                                                                    fontSize:
                                                                        "16px",
                                                                }}
                                                            >
                                                                {data
                                                                    .getAgentWarehouse()
                                                                    .getCompanyName()}
                                                            </Text>
                                                            <div
                                                                style={{
                                                                    display:
                                                                        "flex",
                                                                    gap: "16px",
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        borderRight:
                                                                            "2px solid #DDD",
                                                                        paddingRight:
                                                                            "16px",
                                                                        fontSize:
                                                                            "12px",
                                                                    }}
                                                                >
                                                                    {data
                                                                        .getAgentWarehouse()
                                                                        .getName()}
                                                                </div>
                                                                <div>
                                                                    <Text
                                                                        strong
                                                                    >
                                                                        300+{" "}
                                                                    </Text>
                                                                    <Text
                                                                        style={{
                                                                            color: "#707070",
                                                                            fontSize:
                                                                                "14px",
                                                                        }}
                                                                    >
                                                                        Shipping
                                                                        Delivered
                                                                    </Text>
                                                                </div>
                                                            </div>
                                                            <div
                                                                style={{
                                                                    marginTop:
                                                                        -5,
                                                                }}
                                                            >
                                                                <Tag color="green">
                                                                    {
                                                                        data.contain
                                                                    }
                                                                </Tag>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection:
                                                                "column",
                                                            gap: "7px",
                                                        }}
                                                    >
                                                        <Text strong>
                                                            {data.getRate()}/
                                                            {data.getUnitType()}
                                                        </Text>
                                                        <Text
                                                            style={{
                                                                fontSize:
                                                                    "12px",
                                                                color: "#6b7386",
                                                            }}
                                                        >
                                                            Tax included
                                                        </Text>
                                                    </div>
                                                </div>
                                                <div
                                                    onClick={() =>
                                                        toggleExpand(
                                                            data.getId(),
                                                        )
                                                    }
                                                    style={{
                                                        textAlign: "center",
                                                        cursor: "pointer",
                                                        marginTop: "8px",
                                                        backgroundColor:
                                                            "white",
                                                        borderRadius: "5px",
                                                    }}
                                                >
                                                    {isExpanded ? (
                                                        <UpOutlined />
                                                    ) : (
                                                        <DownOutlined />
                                                    )}
                                                </div>
                                                {isExpanded && (
                                                    <div
                                                        style={{
                                                            padding: "16px",
                                                            backgroundColor:
                                                                "white",
                                                            borderRadius: "5px",
                                                            marginTop: "8px",
                                                        }}
                                                    >
                                                        <Text strong>
                                                            Price Slots:{" "}
                                                            {data
                                                                .getSlots()
                                                                ?.getData()
                                                                .length
                                                                ? ""
                                                                : "No slots found"}
                                                        </Text>
                                                        <div>
                                                            {data
                                                                .getSlots()
                                                                ?.getData()
                                                                .map((slot) => (
                                                                    <div
                                                                        key={
                                                                            slot.id
                                                                        }
                                                                        style={{
                                                                            margin: "8px 0",
                                                                        }}
                                                                    >
                                                                        <Text>{`From ${slot.min_amount} to ${slot.max_amount}: Rate ${slot.rate}`}</Text>
                                                                    </div>
                                                                ))}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })
                            )}
                        </div>
                    </Card>
                </div>

                <Form.Item
                    style={{
                        textAlign: "center",
                        width: "100%",
                        marginTop: "10px",
                    }}
                >
                    {!hideSubmitButton && (
                        <Button
                            loading={updateLoading}
                            disabled={
                                updateLoading ||
                                !shippingCategoryId ||
                                !agentCompanyPriceId
                            }
                            type="primary"
                            htmlType="submit"
                            style={{
                                width: "100%",
                                opacity:
                                    !shippingCategoryId || !agentCompanyPriceId
                                        ? "0.5"
                                        : "1",
                            }}
                        >
                            Submit
                        </Button>
                    )}
                </Form.Item>
            </Form>
        </div>
    );
};

export default ShippingUpdateModal;
