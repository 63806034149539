import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { PageHeader } from "@ant-design/pro-layout";
import {
    Button,
    Col,
    Divider,
    Dropdown,
    Modal,
    Row,
    Space,
    Table,
    Tag,
    Typography,
} from "antd";
import {
    ArrowRightOutlined,
    DownOutlined,
    EditOutlined,
    HddFilled,
} from "@ant-design/icons";

import { useGetAgentWarehouse } from "@/lib/core-react/hooks/private";
import { agentWarehouseCollectionAtom } from "@/lib/core-react/store/store";
import useDataFilters from "@/hooks/useDataFilters";

import { IFilterType } from "@/types/filters";
import {
    AgentWarehouseCollectionModel,
    AgentWarehouseModel,
    ShippingCommissionRateModel,
} from "@/models/agentWarehouseCollectionModel";
import { PaginationModel } from "@/models/pagination";
import { ApiHelperModel } from "@/models/apiHelper";
import { AgentCompanyVerificationStatusEnums } from "@/enums/shared";

import { UpdateAgentWarehouse } from "./components";
import FiltersComponent from "@/components/FiltersComponent";
import useWindowWidth from "@/lib/core-react/hooks/public/useWindowWidth";
import { ExtendedMenuItemType } from "@/types";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ADMIN_SHIPPING_AGENT_PERMISSION_ENUM } from "@/consts/permission-enum/admin-shipping-enum";
import CommonError from "@/components/Error/CommonError";
import { StatusTag } from "@/components";
import { formatString } from "@/utils/helper";
import { ViewDetails } from "./components/viewDetails";
import UpdateCommission from "./components/UpdateComission";

const AgentWarehouse = () => {
    const { getAgentWarehouses } = useGetAgentWarehouse();
    const [
        {
            data: agentWarehouseCollectionData,
            isLoading,
            refetch,
            error,
            unAuthorized,
        },
    ] = useAtom(agentWarehouseCollectionAtom);

    const { isMobile } = useWindowWidth();

    const {
        filters,
        handleFilterChange,
        handelFilterClear,
        isFirstCall,
        isFetched,
        initializeAvailableFilter,
    } = useDataFilters();

    const onClearFilter = () => {
        ApiHelperModel.makeGetRequest({}, getAgentWarehouses);
        handelFilterClear();
    };
    // Api Call
    useEffect(() => {
        if ((!isFetched && isFirstCall) || refetch) {
            ApiHelperModel.makeGetRequest({}, getAgentWarehouses);
        }
    }, [isFirstCall, isFetched, refetch]);

    const AgentWarehouseCollectionData =
        agentWarehouseCollectionData &&
        new AgentWarehouseCollectionModel(agentWarehouseCollectionData);

    // Filter
    const filterData = AgentWarehouseCollectionData?.getFilters();
    // Pagination
    const paginationData = AgentWarehouseCollectionData?.getPagination();

    const [selectedAgentWarehouse, setSelectedAgentWarehouse] = useState<
        AgentWarehouseModel | undefined
    >(undefined);
    const [modalOpen, setModalOpen] = useState<
        "viewDetails" | "update" | "updateCommission" | undefined
    >(undefined);

    const handleMenuClick = (record: AgentWarehouseModel) => {
        setModalOpen("update");
        setSelectedAgentWarehouse(record);
    };

    // Getting all available filters
    useEffect(() => {
        if (!isFetched && AgentWarehouseCollectionData?.getFilters()) {
            initializeAvailableFilter(filterData as IFilterType);
        }
    }, [
        isFetched,
        initializeAvailableFilter,
        AgentWarehouseCollectionData?.getFilters(),
    ]);

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getAgentWarehouses,
        );
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        AgentWarehouseCollectionData,
        handlePaginationChange,
    );

    // Filter Handler
    const handleProductFilter = () => {
        ApiHelperModel.makeGetRequest(filters, getAgentWarehouses);
    };

    const [isViewMoreModalOpen, setIsViewMoreModalOpen] = useState(false);
    const [selectedCommissions, setSelectedCommissions] = useState<
        ShippingCommissionRateModel[]
    >([]);

    const handleViewMoreClick = (
        commissions: ShippingCommissionRateModel[],
    ) => {
        setSelectedCommissions(commissions);
        setIsViewMoreModalOpen(true);
    };

    const renderCommissionInfo = (record: AgentWarehouseModel) => {
        const commissions = record.getShippingCommissions()?.getData() || [];

        return (
            <>
                {commissions.length > 0 ? (
                    <Space direction="vertical" align="start">
                        <Space
                            direction="vertical"
                            key={commissions[0]?.getId()}
                        >
                            <Space>
                                <Typography.Text strong>
                                    Region:{" "}
                                </Typography.Text>
                                <Typography.Text>
                                    <Tag color="blue">
                                        {commissions[0]?.getRegion().code}
                                    </Tag>
                                </Typography.Text>
                            </Space>
                            <Space>
                                <Typography.Text strong>
                                    Commission Rate:{" "}
                                </Typography.Text>
                                <Typography.Text>
                                    <Tag color="green">
                                        {commissions[0]?.getRate()}
                                    </Tag>
                                </Typography.Text>
                            </Space>
                            <Space>
                                <Typography.Text strong>
                                    Commission Type:{" "}
                                </Typography.Text>
                                <Typography.Text>
                                    <Tag color="purple">
                                        {commissions[0]?.getRateType()}
                                    </Tag>
                                </Typography.Text>
                            </Space>
                        </Space>
                        {commissions.length > 1 && (
                            <Button
                                type="link"
                                onClick={() => handleViewMoreClick(commissions)}
                            >
                                View More <ArrowRightOutlined />
                            </Button>
                        )}
                    </Space>
                ) : (
                    <Typography.Text>
                        <Tag color="red">No Commission Found</Tag>
                    </Typography.Text>
                )}
            </>
        );
    };

    // ------------------------------ui------------------------
    if (unAuthorized || error) {
        return <CommonError unAuthorized={unAuthorized} message={error} />;
    }

    const columns = [
        {
            title: "#SL",
            dataIndex: "id",
            key: "sl",
            width: 70,
            align: "center" as const,
            render: (_: string, __: AgentWarehouseModel, index: number) => {
                return (
                    <div>
                        {paginationData
                            ? (paginationData.current_page - 1) *
                                  paginationData.per_page +
                              index +
                              1
                            : ""}
                    </div>
                );
            },
        },
        {
            title: "Warehouse info",
            dataIndex: "name",
            key: "name",
            render: (_: string, record: AgentWarehouseModel) => {
                return (
                    <Space direction="vertical" wrap>
                        <Space>
                            <Typography.Text strong>Name:</Typography.Text>
                            <Typography.Text copyable>
                                {record.getName()}
                            </Typography.Text>
                        </Space>
                        <Space>
                            <Typography.Text strong>
                                Visible Name:
                            </Typography.Text>
                            <Typography.Text>
                                {record.getAliasName()}
                            </Typography.Text>
                        </Space>
                        <Space>
                            <Typography.Text strong>
                                Shipping mark:
                            </Typography.Text>
                            <Typography.Text copyable>
                                {record.getShippingMark()}
                            </Typography.Text>
                        </Space>
                        <Space>
                            <Typography.Text strong>
                                Contact person:
                            </Typography.Text>
                            <Typography.Text>
                                {record.getContactPerson()}
                            </Typography.Text>
                        </Space>
                        <Space>
                            <Typography.Text strong>Country:</Typography.Text>
                            <Space align="center">
                                <img
                                    src={record.getCountry().getFlag()}
                                    style={{ width: 18, height: 18 }}
                                    alt=""
                                />
                                {record.getCountry().getName()}
                            </Space>
                        </Space>
                        <Space>
                            <a
                                onClick={() => {
                                    setSelectedAgentWarehouse(record);
                                    setModalOpen("viewDetails");
                                }}
                            >
                                View Details <ArrowRightOutlined />
                            </a>
                        </Space>
                    </Space>
                );
            },
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (_: string, record: AgentWarehouseModel) => {
                return (
                    <>
                        <Space>
                            <Typography.Text strong>
                                Verification status:{" "}
                            </Typography.Text>
                            <Typography.Text>
                                <StatusTag
                                    slug={record.getVerificationStatus()}
                                    text={record.getVerificationStatus()}
                                />
                            </Typography.Text>
                        </Space>
                        <Divider />
                        <Space>
                            <Typography.Text strong>
                                Operation status:{" "}
                            </Typography.Text>
                            <Typography.Text>
                                <StatusTag
                                    slug={record.getOperationStatus()}
                                    text={formatString(
                                        record.getOperationStatus(),
                                    )}
                                />
                            </Typography.Text>
                        </Space>
                    </>
                );
            },
        },
        {
            title: "Company info",
            dataIndex: "agent_company",
            width: 300,
            key: "agent-company",
            render: (_: string, record: AgentWarehouseModel) => {
                return (
                    <Space direction="vertical">
                        <Space>
                            <Typography.Text strong>
                                Company Name:{" "}
                            </Typography.Text>
                            <Typography.Text copyable>
                                {record.getAgentCompany().getPrimaryName()}
                            </Typography.Text>
                        </Space>
                        <Space>
                            <Typography.Text strong>
                                Visible Name:{" "}
                            </Typography.Text>
                            <Typography.Text>
                                {record.getAgentCompany().getSecondaryName()}
                            </Typography.Text>
                        </Space>
                        <Space></Space>
                        <Space>
                            <Typography.Text strong>Type: </Typography.Text>
                            <Typography.Text
                                style={{ textTransform: "capitalize" }}
                            >
                                {record.getAgentCompany().getCompanyType()
                                    ? record
                                          .getAgentCompany()
                                          .getCompanyType()
                                          .replaceAll("-", " ")
                                    : ""}
                            </Typography.Text>
                        </Space>
                        <Space>
                            <Typography.Text strong>
                                Verification status:{" "}
                            </Typography.Text>
                            {record
                                .getAgentCompany()
                                .getVerificationStatus() ? (
                                <Tag
                                    style={{ textTransform: "capitalize" }}
                                    color={
                                        record
                                            .getAgentCompany()
                                            .getVerificationStatus() ===
                                        AgentCompanyVerificationStatusEnums.APPROVED
                                            ? "green"
                                            : "red"
                                    }
                                >
                                    {record
                                        .getAgentCompany()
                                        .getVerificationStatus()}
                                </Tag>
                            ) : (
                                ""
                            )}
                        </Space>
                    </Space>
                );
            },
        },
        {
            title: "Commission info",
            dataIndex: "commission",
            key: "commission",
            render: (_, record: AgentWarehouseModel) =>
                renderCommissionInfo(record),
        },
        {
            title: "Actions",
            key: "actions",
            fixed: "right" as const,
            width: isMobile ? 100 : 200,
            align: "center" as const,
            hidden: !checkActionPermission(
                ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.AGENT_WAREHOUSE_ADMIN_MANAGE,
                "checkActionPermission",
                null,
            ),
            render: (_: string, record: AgentWarehouseModel) => {
                const items: ExtendedMenuItemType[] = [
                    {
                        permission:
                            ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.AGENT_WAREHOUSE_ADMIN_MANAGE,
                        icon: <EditOutlined />,
                        label: "Update",
                        key: "update",
                        onClick: () => {
                            handleMenuClick(record);
                            setModalOpen("update");
                        },
                    },
                    {
                        permission:
                            ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.AGENT_WAREHOUSE_ADMIN_MANAGE,
                        icon: <EditOutlined />,
                        label: selectedAgentWarehouse
                            ?.getShippingCommissions()
                            ?.getData()?.length
                            ? "Update Commission"
                            : "Add Commission",
                        key: "update-commission",
                        onClick: () => {
                            handleMenuClick(record);
                            setModalOpen("updateCommission");
                        },
                    },
                ];

                return (
                    <Dropdown
                        overlayStyle={{
                            width: 200,
                        }}
                        menu={{
                            items: items.filter((x) =>
                                checkActionPermission(x.permission, x, null),
                            ),
                        }}
                        onOpenChange={() => setSelectedAgentWarehouse(record)}
                    >
                        <Button icon={<HddFilled />}>
                            {isMobile ? (
                                <DownOutlined />
                            ) : (
                                <>
                                    Actions <DownOutlined />{" "}
                                </>
                            )}
                        </Button>
                    </Dropdown>
                );
            },
        },
    ];

    return (
        <>
            <Row>
                <Col span={24}>
                    <PageHeader
                        style={{ marginTop: "10px" }}
                        ghost={false}
                        title="Agent Warehouses"
                        onBack={() => window.history.back()}
                    >
                        {filters && Object.keys(filters).length > 0 && (
                            <Row>
                                <Col span={24}>
                                    <FiltersComponent
                                        handleProductFilter={
                                            handleProductFilter
                                        }
                                        handleFilterChange={handleFilterChange}
                                        handelFilterClear={onClearFilter}
                                        isFetched={isFetched}
                                        filters={filters}
                                        filtersData={filterData}
                                        isFromProductReceived={true}
                                    />
                                </Col>
                            </Row>
                        )}
                        <div style={{ marginTop: 10 }}>
                            <Table
                                //@ts-ignore
                                columns={columns}
                                dataSource={AgentWarehouseCollectionData?.getData()}
                                bordered
                                loading={isLoading}
                                rowKey="id"
                                pagination={paginationConfig}
                                scroll={{ x: 1450 }}
                            />
                        </div>
                    </PageHeader>
                </Col>
            </Row>

            <Modal
                title={
                    modalOpen === "update"
                        ? `Update Agent WareHouse`
                        : modalOpen === "viewDetails"
                          ? `View Details (${selectedAgentWarehouse?.getName()})`
                          : modalOpen === "updateCommission"
                            ? selectedAgentWarehouse
                                  ?.getShippingCommissions()
                                  ?.getData()?.length
                                ? `Update Commission`
                                : `Add Commission`
                            : ""
                }
                open={Boolean(selectedAgentWarehouse) && Boolean(modalOpen)}
                onCancel={() => setModalOpen(undefined)}
                footer={null}
                width={
                    isMobile
                        ? "90vw"
                        : modalOpen === "viewDetails"
                          ? "50vw"
                          : modalOpen == "update"
                            ? "25vw"
                            : undefined
                }
            >
                {modalOpen === "update" && selectedAgentWarehouse && (
                    <UpdateAgentWarehouse
                        setIsShowUpdateModal={() => setModalOpen(undefined)}
                        selectedAgentWarehouse={selectedAgentWarehouse}
                    />
                )}
                {modalOpen === "updateCommission" && selectedAgentWarehouse && (
                    <UpdateCommission
                        setIsShowUpdateCommissionModal={() =>
                            setModalOpen(undefined)
                        }
                        selectedAgentWarehouse={selectedAgentWarehouse}
                    />
                )}
                {modalOpen === "viewDetails" && selectedAgentWarehouse && (
                    <ViewDetails warehouse={selectedAgentWarehouse} />
                )}
            </Modal>

            <Modal
                title="All Commissions"
                open={isViewMoreModalOpen}
                onCancel={() => setIsViewMoreModalOpen(false)}
                footer={null}
            >
                {selectedCommissions.map((commission, index) => (
                    <div
                        key={commission.getId()}
                        style={{
                            border: "1px solid #f0f0f0",
                            borderRadius: "8px",
                            padding: "16px",
                            marginBottom: "12px",
                            backgroundColor: "#fafafa",
                        }}
                    >
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Commission #{index + 1}
                        </Typography.Title>
                        <Space
                            direction="vertical"
                            size="small"
                            style={{ marginTop: "8px" }}
                        >
                            <Space>
                                <Typography.Text strong>
                                    Region:
                                </Typography.Text>
                                <Typography.Text>
                                    <Tag color="blue">
                                        {commission.getRegion().code}
                                    </Tag>
                                </Typography.Text>
                            </Space>
                            <Space>
                                <Typography.Text strong>
                                    Commission Rate:
                                </Typography.Text>
                                <Typography.Text>
                                    <Tag color="green">
                                        {commission.getRate()}
                                    </Tag>
                                </Typography.Text>
                            </Space>
                            <Space>
                                <Typography.Text strong>
                                    Commission Type:
                                </Typography.Text>
                                <Typography.Text>
                                    <Tag color="purple">
                                        {commission.getRateType()}
                                    </Tag>
                                </Typography.Text>
                            </Space>
                        </Space>
                    </div>
                ))}
            </Modal>
        </>
    );
};

export default AgentWarehouse;
