// Enum for admin permissions in the Purchase module
export enum ADMIN_PURCHASE_PERMISSION_ENUM {
    // Buy-product permissions

    ADMIN_BUY_PRODUCT_VIEW = "admin-buy-product-view",
    ADMIN_BUY_PRODUCT_ADD = "admin-buy-product-add",
    ADMIN_BUY_PRODUCT_APPROVE = "admin-buy-product-approve",
    ADMIN_BUY_PRODUCT_CANCEL = "admin-buy-product-cancel",
    ADMIN_BUY_PRODUCT_REJECT = "admin-buy-product-reject",
    ADMIN_BUY_PRODUCT_VARIATION_ADD = "admin-buy-product-variation-add",
    ADMIN_BUY_PRODUCT_VARIATION_UPDATE = "admin-buy-product-variation-update",
    ADMIN_BUY_PRODUCT_VARIATION_DELETE = "admin-buy-product-variation-delete",

    ADMIN_BUY_PRODUCT_FX_UPDATE = "admin-buy-product-fx-update",
    ADMIN_BUY_PRODUCT_SHIPMENT_UPDATE = "admin-buy-product-shipment-update",

    // Buy Product Charge permissions
    ADMIN_BUY_PRODUCT_CHARGE_ADD = "admin-buy-product-charge-add",
    ADMIN_BUY_PRODUCT_CHARGE_UPDATE = "admin-buy-product-charge-update",
    ADMIN_BUY_PRODUCT_CHARGE_DELETE = "admin-buy-product-charge-delete",

    // Purchase Commission permission
    ADMIN_PURCHASE_COMMISSION_VIEW = "admin-purchase-commission-view",
    ADMIN_PURCHASE_COMMISSION_MANAGE = "admin-purchase-commission-manage",
    ADMIN_BUY_PRODUCT_IMPERSONATE_CREATE = "admin-buy-product-impersonate-create",
}

export const buyProductManageRoutePermissions = [
    ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VIEW,
];
export const buyProductOrderManageRoutePermissions = [
    ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VIEW,
];
export const buyProductOrderEditRoutePermissions = [
    ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_ADD,
    ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VARIATION_UPDATE,
    ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VARIATION_ADD,
];

export const buyProductOrderCreateRoutePermissions = [
    ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_ADD,
];
