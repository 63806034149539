import { useEffect, useState } from "react";
import {
    Avatar,
    Card,
    Col,
    DatePicker,
    Flex,
    Form,
    FormInstance,
    Input,
    Radio,
    Row,
    Tabs,
    Typography,
} from "antd";
import { PageLoading } from "@ant-design/pro-layout";
import { useAtomValue } from "jotai";

import { useInvoice } from "@/lib/core-react/hooks/private/useInvoice";
import { useGetPaymentGateway } from "@/lib/core-react/hooks/private/usePayment";
import {
    paymentGatewayCollectionAtom,
    paymentSessionResponseAtom,
    walletOverviewCollectionAtom,
} from "@/lib/core-react/store/store";
import { useUserWalletOverviewBalance } from "@/lib/core-react/hooks/private/useWallet";
import {
    getIsCurrentUserSuperAdmin,
    getUserPermissionFromLocalStorage,
} from "@/utils/helper";
import { ADMIN_WALLET_PERMISSION_ENUM } from "@/consts/permission-enum/wallet-enum";

import {
    PaymentGatewayCollectionModel,
    PaymentGatewayModel,
} from "@/models/paymentGatewayCollectionModel";
import { RegionModel } from "@/models/regionCollectionModel";
import { PaymentSessionResponseModel } from "@/models/paymentSessionModel";
import { WalletOverviewModel } from "@/models/walletOverviewCollectionModel";
import { PaymentGatewayTypeEnums } from "@/enums/paymentGatewayCollectionEnums";

import { MultiChunkUpload } from "../MultiChunkUpload";
import ShippingSummary from "./components/ShippingSummary";
import { PAYMENT_GATEWAY_PERMISSION_ENUM } from "@/consts/permission-enum/payment-enum";

interface IProps {
    form: FormInstance<any>;
    data: {
        region: RegionModel;
        user_id: number;
        token: string;
    };
    onFinish: (values: any) => Promise<void>;
}
export const PayCustomerInvoiceModal = ({ form, data, onFinish }: IProps) => {
    const { getSession } = useInvoice();
    const { getPaymentGateway } = useGetPaymentGateway();
    const userPermissions = getUserPermissionFromLocalStorage();
    const { getUserWalletOverviewBalance } = useUserWalletOverviewBalance();

    const [activeTabKey, setActiveTabKey] = useState("1");

    const walletOverviewCollectionData = useAtomValue(
        walletOverviewCollectionAtom,
    );
    const paymentSessionResponseData = useAtomValue(paymentSessionResponseAtom);
    const paymentGatewayCollectionData = useAtomValue(
        paymentGatewayCollectionAtom,
    );

    const PaymentGatewayCollectionData =
        paymentGatewayCollectionData.data &&
        new PaymentGatewayCollectionModel(paymentGatewayCollectionData.data);
    const PaymentSessionData =
        paymentSessionResponseData.data &&
        new PaymentSessionResponseModel(paymentSessionResponseData.data);
    const WalletOverviewCollectionData =
        walletOverviewCollectionData.data &&
        new WalletOverviewModel(walletOverviewCollectionData.data);

    useEffect(() => {
        getSession(data.user_id, data.token);
        if (
            getIsCurrentUserSuperAdmin() ||
            userPermissions.includes(
                PAYMENT_GATEWAY_PERMISSION_ENUM.PAYMENT_GATEWAY_VIEW,
            )
        )
            getPaymentGateway(`region=${data.region.getId()}`);

        if (
            getIsCurrentUserSuperAdmin() ||
            userPermissions.includes(
                ADMIN_WALLET_PERMISSION_ENUM.ADMIN_WALLET_VIEW,
            )
        )
            getUserWalletOverviewBalance(data.user_id);
    }, [data]);

    useEffect(() => {
        form.resetFields();
    }, [data]);

    const gateway_id = Form.useWatch("gateway_id", form);
    const selectedPaymentGateway =
        PaymentGatewayCollectionData?.getPaymentGatewayById(gateway_id);

    const wallet_id = Form.useWatch("wallet_id", form);
    const selectedAvailableWallet =
        WalletOverviewCollectionData?.getAvailableById(wallet_id);

    useEffect(() => {
        if (gateway_id) {
            form.setFieldValue(`wallet_id`, "");
        }
        if (wallet_id) {
            form.setFieldValue(`gateway_id`, "");
        }
    }, [gateway_id, wallet_id]);

    const minAmountToPay =
        selectedPaymentGateway && PaymentSessionData
            ? getAmountWithChargeToPay(
                  PaymentSessionData.getData().getAmountToPay(
                      selectedPaymentGateway.getType(),
                  ),
                  selectedPaymentGateway,
              )
            : selectedAvailableWallet && PaymentSessionData
              ? PaymentSessionData.getData().getAmountToPay(
                    PaymentGatewayTypeEnums.WALLET,
                )
              : 0;

    const gatewayParitalPaymentPercentage =
        selectedPaymentGateway &&
        PaymentSessionData?.getData()
            .getRuleGroup()
            .getPercentageByType(selectedPaymentGateway.getType());

    const walletParitalPaymentPercentage =
        selectedAvailableWallet &&
        PaymentSessionData?.getData()
            .getRuleGroup()
            .getPercentageByType(PaymentGatewayTypeEnums.WALLET);

    if (
        paymentSessionResponseData.isLoading ||
        paymentGatewayCollectionData.isLoading
    )
        return <PageLoading />;

    return (
        <Row gutter={16}>
            <Col xs={24} lg={18}>
                <Form
                    form={form}
                    onFinish={onFinish}
                    layout="vertical"
                    name="Pay Customer Invoice"
                >
                    <Tabs
                        centered
                        defaultActiveKey={activeTabKey}
                        type="card"
                        onChange={(activeKey) => {
                            setActiveTabKey(activeKey);
                            form.resetFields();
                        }}
                        items={[
                            {
                                label: `Bank Deposit`,
                                key: "1",
                                children: (
                                    <Form.Item
                                        label={"Select gateway"}
                                        name="gateway_id"
                                        rules={[
                                            {
                                                required:
                                                    activeTabKey === "1"
                                                        ? true
                                                        : false,
                                                message:
                                                    "Please select a gateway",
                                            },
                                        ]}
                                    >
                                        {!PaymentGatewayCollectionData ||
                                        PaymentGatewayCollectionData?.getBankDepositData()
                                            .length === 0 ? (
                                            "No bank deposit gateway available."
                                        ) : (
                                            <Radio.Group
                                                size="large"
                                                style={{
                                                    maxHeight: "50vh",
                                                    overflowY: "auto",
                                                }}
                                            >
                                                {PaymentGatewayCollectionData?.getBankDepositData().map(
                                                    (d) => {
                                                        const logoCollection =
                                                            d.getLogo();
                                                        const logoUrl =
                                                            logoCollection
                                                                ?.getData()
                                                                .map(
                                                                    (logo) =>
                                                                        logo.getSmall()
                                                                            ?.src,
                                                                )
                                                                .find(
                                                                    (url) =>
                                                                        url,
                                                                );

                                                        return (
                                                            <Radio.Button
                                                                value={d.getId()}
                                                                key={d.getId()}
                                                            >
                                                                <Avatar
                                                                    shape="circle"
                                                                    size={20}
                                                                    style={{
                                                                        objectFit:
                                                                            "cover",
                                                                    }}
                                                                    src={
                                                                        logoUrl
                                                                    }
                                                                />{" "}
                                                                {d.getName()}
                                                            </Radio.Button>
                                                        );
                                                    },
                                                )}
                                            </Radio.Group>
                                        )}
                                    </Form.Item>
                                ),
                                disabled: Boolean(
                                    !PaymentSessionData?.getData()
                                        .getRuleGroup()
                                        .getIsBankDepositEnabled(),
                                ),
                            },
                            {
                                label: `Wallet`,
                                key: "2",
                                children: (
                                    <Form.Item
                                        label={"Select wallet"}
                                        name="wallet_id"
                                        rules={[
                                            {
                                                required:
                                                    activeTabKey === "2"
                                                        ? true
                                                        : false,
                                                message:
                                                    "Please select a wallet",
                                            },
                                        ]}
                                    >
                                        {!WalletOverviewCollectionData ||
                                        WalletOverviewCollectionData?.getAvailable().getData()
                                            .length === 0 ? (
                                            "No wallet available."
                                        ) : (
                                            <Radio.Group
                                                size="large"
                                                style={{
                                                    maxHeight: "50vh",
                                                    overflowY: "auto",
                                                }}
                                            >
                                                {WalletOverviewCollectionData?.getAvailable()
                                                    .getData()
                                                    .map((d) => {
                                                        return (
                                                            <Radio.Button
                                                                value={d.getId()}
                                                                key={d.getId()}
                                                                disabled={
                                                                    d.getBalance() ===
                                                                        0 ||
                                                                    d
                                                                        .getCurrency()
                                                                        .getId() !==
                                                                        data.region
                                                                            .getCurrency()
                                                                            ?.getId()
                                                                }
                                                            >
                                                                {d
                                                                    .getCurrency()
                                                                    .getCode()}{" "}
                                                                {d.getBalance()}
                                                            </Radio.Button>
                                                        );
                                                    })}
                                            </Radio.Group>
                                        )}
                                    </Form.Item>
                                ),
                                disabled: Boolean(
                                    !PaymentSessionData?.getData()
                                        .getRuleGroup()
                                        .getIsWalletEnabled(),
                                ),
                            },
                            {
                                label: `Mobile Merchant`,
                                key: "3",
                                children: (
                                    <Form.Item
                                        label={"Select mobile merchant"}
                                        name="gateway_id"
                                        rules={[
                                            {
                                                required:
                                                    activeTabKey === "3"
                                                        ? true
                                                        : false,
                                                message:
                                                    "Please select a mobile merchant",
                                            },
                                        ]}
                                    >
                                        {!PaymentGatewayCollectionData ||
                                        PaymentGatewayCollectionData?.getMobileMerchantPaymentData()
                                            .length === 0 ? (
                                            "No mobile merchant gateway available."
                                        ) : (
                                            <Radio.Group
                                                size="large"
                                                style={{
                                                    maxHeight: "50vh",
                                                    overflowY: "auto",
                                                }}
                                            >
                                                {PaymentGatewayCollectionData?.getMobileMerchantPaymentData().map(
                                                    (d) => {
                                                        const logoCollection =
                                                            d.getLogo();
                                                        const logoUrl =
                                                            logoCollection
                                                                ?.getData()
                                                                .map(
                                                                    (logo) =>
                                                                        logo.getSmall()
                                                                            ?.src,
                                                                )
                                                                .find(
                                                                    (url) =>
                                                                        url,
                                                                );

                                                        return (
                                                            <Radio.Button
                                                                value={d.getId()}
                                                                key={d.getId()}
                                                            >
                                                                <Avatar
                                                                    shape="circle"
                                                                    size={20}
                                                                    style={{
                                                                        objectFit:
                                                                            "cover",
                                                                    }}
                                                                    src={
                                                                        logoUrl
                                                                    }
                                                                />{" "}
                                                                {d.getName()}
                                                            </Radio.Button>
                                                        );
                                                    },
                                                )}
                                            </Radio.Group>
                                        )}
                                    </Form.Item>
                                ),
                                disabled: Boolean(
                                    !PaymentSessionData?.getData()
                                        .getRuleGroup()
                                        .getIsMobileMerchantEnabled(),
                                ),
                            },
                        ]}
                    />

                    <Row gutter={16}>
                        <Col span={24}>
                            <Flex gap="middle" vertical>
                                {selectedPaymentGateway &&
                                selectedPaymentGateway.getMerchantData() &&
                                Object.keys(
                                    selectedPaymentGateway.getMerchantData(),
                                ).length > 0 ? (
                                    <Card title="Merchant Info">
                                        {selectedPaymentGateway?.getMerchantData() &&
                                            Object.entries(
                                                selectedPaymentGateway.getMerchantData() ||
                                                    {},
                                            ).map(([key, value]) => {
                                                return (
                                                    <div
                                                        key={key}
                                                        style={{
                                                            display: "flex",
                                                            gap: "10px",
                                                            textTransform:
                                                                "capitalize",
                                                        }}
                                                    >
                                                        <Typography.Text strong>
                                                            {key}:
                                                        </Typography.Text>
                                                        <Typography.Text>
                                                            {value}
                                                        </Typography.Text>
                                                    </div>
                                                );
                                            })}
                                    </Card>
                                ) : (
                                    ""
                                )}
                                <div
                                    style={{
                                        maxHeight: "300px",
                                        overflowY: "auto",
                                    }}
                                >
                                    {Object.entries(
                                        selectedPaymentGateway?.getFormSchema() ||
                                            {},
                                    ).map(([key, value]) => {
                                        const {
                                            label,
                                            is_required,
                                            placeholder,
                                            type,
                                        } = value;

                                        return (
                                            <div>
                                                <Form.Item
                                                    key={key}
                                                    label={label}
                                                    name={key}
                                                    rules={[
                                                        {
                                                            required:
                                                                is_required,
                                                            message: `${label} is required`,
                                                        },
                                                    ]}
                                                >
                                                    {type === "string-input" ? (
                                                        <Input
                                                            placeholder={
                                                                placeholder
                                                            }
                                                        />
                                                    ) : type === "date" ? (
                                                        <DatePicker
                                                            format="YYYY-MM-DD"
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                        />
                                                    ) : type ===
                                                      "image-attachment" ? (
                                                        <MultiChunkUpload
                                                            fieldName={key}
                                                            form={form}
                                                        />
                                                    ) : (
                                                        ""
                                                    )}
                                                </Form.Item>
                                            </div>
                                        );
                                    })}
                                </div>
                            </Flex>
                        </Col>

                        <Form.Item
                            help={
                                <Flex vertical>
                                    <div>
                                        {gatewayParitalPaymentPercentage &&
                                        gatewayParitalPaymentPercentage < 100
                                            ? `${gatewayParitalPaymentPercentage}% Partial Pay Available`
                                            : walletParitalPaymentPercentage &&
                                                walletParitalPaymentPercentage <
                                                    100
                                              ? `${walletParitalPaymentPercentage}% Partial Pay Available`
                                              : ``}
                                    </div>
                                    <div>
                                        {minAmountToPay
                                            ? `Minimum amount is ${data.region
                                                  .getCurrency()
                                                  ?.getSymbolNative()}${minAmountToPay}`
                                            : ""}
                                    </div>
                                </Flex>
                            }
                            label={
                                selectedPaymentGateway?.getFee()
                                    ? `Amount (Including charge: ${selectedPaymentGateway?.getFee()}${selectedPaymentGateway.getFeeType() === "percentage" ? "%" : "/-"})`
                                    : `Amount`
                            }
                            name="amount"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your amount",
                                },
                                {
                                    validator: (_, value) => {
                                        if (
                                            minAmountToPay &&
                                            value &&
                                            value < minAmountToPay
                                        ) {
                                            return Promise.reject(
                                                new Error(
                                                    `Minimum amount is ${minAmountToPay}`,
                                                ),
                                            );
                                        }
                                        return Promise.resolve();
                                    },
                                },
                            ]}
                        >
                            <Input
                                type="number"
                                min={minAmountToPay}
                                prefix={data.region
                                    .getCurrency()
                                    ?.getSymbolNative()}
                                placeholder="0"
                            />
                        </Form.Item>
                    </Row>
                </Form>
            </Col>
            <Col xs={24} lg={6}>
                {PaymentSessionData && (
                    <ShippingSummary
                        sessionData={PaymentSessionData.getData()}
                        region={data.region}
                    />
                )}
            </Col>
        </Row>
    );
};

const getAmountWithChargeToPay = (
    amount: number,
    selectedPaymentGateway: PaymentGatewayModel,
) => {
    const feeType = selectedPaymentGateway?.fee_type;
    const fee = Number(selectedPaymentGateway?.fee);

    if (!isNaN(fee) && feeType === "fixed") {
        return amount + fee;
    } else if (!isNaN(fee) && feeType === "percentage" && amount) {
        const charge = (amount * fee) / 100;
        return amount + Number(charge.toFixed(2));
    }
};
