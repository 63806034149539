import { Col, Image, Modal, Row, Skeleton } from "antd";
import useWindowWidth from "@/lib/core-react/hooks/public/useWindowWidth";
import { RfwImagesModel } from "@/models/rfqRequestCollectionModel";
import { v4 } from "uuid";
interface IProps {
    rfq_images: RfwImagesModel[];
    open: boolean;
    cancelHandler: () => void;
}
const ImagesModal = ({ rfq_images, open, cancelHandler }: IProps) => {
    const { isMobile } = useWindowWidth();
    return (
        <Modal
            title="Images List"
            open={open}
            width={isMobile ? "90vw" : "60vw"}
            destroyOnClose={true}
            onCancel={cancelHandler}
            footer={false}
        >
            <div
                style={{
                    minHeight: 400,
                    overflow: "auto",
                    padding: "16px",
                    borderRadius: 4,
                    border: "1px solid rgba(140, 140, 140, 0.35)",
                }}
            >
                <Row gutter={20} align={"middle"}>
                    <Image.PreviewGroup>
                        {rfq_images.map((item) => {
                            const imageName = item?.getImage()?.getImageName();
                            const imageSrc = item?.getImage()?.getImageSrc();
                            return (
                                <Col key={v4()}>
                                    <>
                                        {imageSrc ? (
                                            <Image
                                                height={100}
                                                width={100}
                                                wrapperStyle={{
                                                    marginBottom: 4,
                                                }}
                                                alt={imageName || ""}
                                                src={imageSrc || ""}
                                                preview={true}
                                                style={{
                                                    borderRadius: 4,
                                                    boxShadow:
                                                        "#f0f0f0 1px 1px 1px 1px",
                                                }}
                                            />
                                        ) : (
                                            <Skeleton.Image
                                                active={true}
                                                style={{
                                                    width: 100,
                                                    height: 100,
                                                    borderRadius: 4,
                                                    marginBottom: 4,
                                                }}
                                            />
                                        )}
                                    </>
                                </Col>
                            );
                        })}
                    </Image.PreviewGroup>
                </Row>
            </div>
        </Modal>
    );
};

export default ImagesModal;
