import { Alert, Divider } from "antd";

import { StyledProductView } from "./index.styled";
import { ProductDetailExtendedModel } from "@/models/productDetailExtendedModel";

import ProductSpecification from "./ProductSpecification";
import ProductVariations from "../ProductVariations";
import { useLocation } from "react-router-dom";
import { BuyOrderOperationEnum } from "@/enums/buyOrderCollectionEnums";
import { ShipmentProductOperationEnum } from "@/enums/shipmentProductCollectionEnums";
import { ProductDescription } from "../ProductDescription/ProductDescription";

type Props = {
    product: ProductDetailExtendedModel;
};
const ProductView = ({ product }: Props) => {
    const location = useLocation();
    const operation = new URLSearchParams(location.search).get("operation");
    return (
        <StyledProductView>
            {product.getVariation().getSkus().getData().length > 1 ? (
                <ProductVariations variations={product.getVariation()} />
            ) : (
                <Alert
                    message="Variations not available for this product"
                    type="info"
                />
            )}
            <Divider style={{ marginTop: 15, marginBottom: 15 }} />
            {operation === BuyOrderOperationEnum.PurchaseCartItemAdd ||
            operation ===
                ShipmentProductOperationEnum.AddShipmentProductPackage ? (
                ""
            ) : (
                <ProductSpecification
                    productSpec={product.getSpecifications() || []}
                />
            )}
            <ProductDescription productId={product.getId()} />
        </StyledProductView>
    );
};

export default ProductView;
