import { CustomerUserModel } from "@/models/customerUserCollectionModel";
import CustomerDetailsViews from "@/pages/CustomerManage/User/components/CustomerDetailsViews";
import { Alert, Space, Typography } from "antd";
import { memo } from "react";
const { Text, Paragraph, Link } = Typography;

interface Props {
    user: CustomerUserModel | undefined;
}

const CustomerInfo = ({ user }: Props) => {
    if (!user) {
        return <Alert message="Customer Not Available" />;
    }
    return (
        <Space direction="vertical" size={"small"}>
            {user.name && (
                <CustomerDetailsViews selectedUser={user}/>
            )}
            {user.email && (
                <Paragraph>
                    <Text>Email: </Text>
                    <Link href={`mailto:${user.email}`}>
                        <Text strong copyable>
                            {user.email}
                        </Text>
                    </Link>
                </Paragraph>
            )}
            {user.phone && (
                <Paragraph>
                    <Text>Phone: </Text>

                    <Link href={`tel:${user.phone}`}>
                        <Text strong copyable>
                            {user.phone}
                        </Text>
                    </Link>
                </Paragraph>
            )}
            {user.shipping_mark && (
                <Paragraph>
                    <Text>Shipping Mark: </Text>

                    <Text strong>{user.shipping_mark}</Text>
                </Paragraph>
            )}
        </Space>
    );
};

export default memo(CustomerInfo);
