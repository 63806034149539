export const FALLBACK_IMAGE_URL =
    "https://i.ibb.co/VDvYS53/output-onlinepngtools.png";

export const SUPER_ADMIN_SLUG = "super-admin";
export const ORDER_HANDLER_SLUG = "Order Handler";

export const DEFAULT_REGION_ID = "3";

export const inventoryProductsIgnoreKeys = [
    "region",
    "operation",
    "userId",
    "region_id",
];
export const acceptInventoryProductsQueryKeys = [
    "region",
    "locale",
    "image_url",
    "keyword",
    "seller_names",
    "category_names",
    "brand_names",
    "country_codes",
    "rating_avg",
    "price",
    "properties",
    "sort",
    "page",
    "per_page",
    "shops",
] as const;

export type AcceptInventoryProductsQueryKeys =
    (typeof acceptInventoryProductsQueryKeys)[number];

export const BREAK_POINTS = {
    XXL: 1920,
    XL: 1440,
    LG: 1280,
    MD: 768,
    SM: 360,
};

export const regionColors: { [key: string]: string } = {
    BD: "#006A4E",
    IN: "#FF671F",
    AE: "#009739",
    US: "#B31942",
    EU: "#003399",
    CN: "#EE1C25",
};

export const storeColors: { [key: string]: string } = {
    daraz: "#F25C05",
    amazon_ae: "#FFAA33",
    indiamart: "#1E90FF",
    ebay: "#E53238",
    pinduoduo: "#C8102E",
    myntra: "#AA00AA",
    india_mart: "#FF7F50",
    tmall: "#FF8000",
    yangkeduo: "#FFA500",
    amazon_in: "#FF5722",
    noon: "#FFD700",
    gearbest: "#FF3030",
    onesixeighteight: "#FF6347",
    alibaba: "#FF7F00",
    taobao: "#FF4500",
    flipkart: "#2874F0",
    amazon: "#FF9900",
    aliexpress: "#FF6347",
};

export const currencyKey: "code" | "symbol" | "symbol_native" = "code";
export const currencyColors: { [key: string]: string } = {
    // South Asia
    BDT: "#006A4E", // Bangladesh - Dark Green (flag color)
    INR: "#FF9933", // India - Orange (flag color)
    PKR: "#006600", // Pakistan - Green (flag color)
    LKR: "#F18A00", // Sri Lanka - Saffron Orange (flag color)

    // Middle East
    AED: "#00732F", // UAE - Green (flag color)
    SAR: "#006C35", // Saudi Arabia - Green (flag color)
    QAR: "#8B0000", // Qatar - Maroon (flag color)

    // Europe
    EUR: "#0044CC", // European Union - Blue (flag color)
    GBP: "#00247D", // United Kingdom - Dark Blue (flag color)
    CHF: "#D52B1E", // Switzerland - Red (flag color)

    // Americas
    USD: "#3C3B6E", // USA - Navy Blue (flag color)
    CAD: "#FF0000", // Canada - Red (flag color)
    MXN: "#006341", // Mexico - Green (flag color)

    // East Asia
    CNY: "#FF0000", // China - Red (flag color)
    JPY: "#BC002D", // Japan - Red (flag color)
    KRW: "#003478", // South Korea - Navy Blue (flag color)

    // Africa
    ZAR: "#007847", // South Africa - Green (flag color)
    NGN: "#008751", // Nigeria - Green (flag color)
    EGP: "#F4C300", // Egypt - Gold (flag color)

    // Oceania
    AUD: "#00247D", // Australia - Dark Blue (flag color)
    NZD: "#00247D", // New Zealand - Dark Blue (flag color)
};

export const statusColor: { [key: string]: string } = {
    "shipment-pending": "#f39c12", // Orange
    "shipment-approved": "#27ae60", // Green
    "shipment-rejected": "#e74c3c", // Red
    "shipment-assigned-agent": "#2980b9", // Blue
    "shipment-shipping-started": "#8e44ad", // Purple
    "shipment-not-arrived": "#95a5a6", // Gray
    "shipment-shipping-cancelled": "#c0392b", // Dark Red
    "shipment-arrived-at-warehouse": "#16a085", // Teal
    "shipment-handover-to-travel-agent": "#7f8c8d", // Dark Gray
    "shipment-handover-to-moveon": "#3498db", // Light Blue
    "shipment-preparing-for-transport": "#34495e", // Dark Blue
    "shipment-handover-to-ship": "#2ecc71", // Bright Green
    "shipment-handover-to-airline": "#9b59b6", // Bright Purple
    "shipment-arrived-at-destination-port": "#1abc9c", // Aqua
    "shipment-arrived-at-destination-airport": "#2c3e50", // Navy
    "shipment-customs-released": "#d35400", // Pumpkin
    "shipment-received-by-moveon": "#7d3c98", // Violet
    "delivery-pending": "#f1c40f", // Yellow
    "delivery-processing": "#2980b9", // Blue
    "delivery-ready": "#27ae60", // Green
    "delivery-shipped": "#8e44ad", // Purple
    "delivery-failed": "#e74c3c", // Red
    "delivery-returned": "#c0392b", // Dark Red
    "delivered-to-customer": "#2ecc71", // Green

    unpaid: "#e67e22", // Carrot Orange
    "partial-paid": "#3498db", // Light Blue
    due: "#f39c12", // Bright Yellow (for due to stand out more distinctly)
    paid: "green-inverse", // Green
    unsettled: "#f39c12", // Orange
    "partially-settled": "#9b59b6", // Bright Purple
    settled: "#27ae60", // Green
    "awaiting-fulfilment": "#27ae60", // Red
    fulfilled: "#1abc9c", // Aqua
    "partially-refunded": "#8e44ad", // Purple
    refunded: "#c0392b", // Dark Red
    "only-ship": "#34495e", // Dark Blue
    "buy-and-ship": "#7d3c98", // Violet
    cancelled: "#e74c3c", // red
    "buy-cancelled": "#e74c3c", // red
    "buy-handover-to-shipping": "#27ae60", // red
};
