export const SUPER_ADMIN = "super-admin";
export const MOVEON_ADMIN_PERMISSIONS_STORAGE_ITY_KEY =
    "__moveon_global_admin_permissions__";
export const MOVEON_ADMIN_USER_KEY = "__moveon_admin_user__";
export const MOVEON_ADMIN_TOKEN_KEY = "__moveon_admin_token__";

export const FILE_UPLOAD_URL = `${import.meta.env.VITE_COMMERCE_SERVICE_HOST}/api/storage/admin/asset/v1/assets`;
export const FILE_UPLOAD_URL_TOKEN = `${import.meta.env.VITE_ORY_TOKEN}`;
export const MAX_FILE_SIZE = 20;
export const NOVU_APPLICATION_IDENTIFIER =
    import.meta.env.VITE_NOVU_APPLICATION_IDENTIFIER || "";
