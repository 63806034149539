import {
    Alert,
    Button,
    Dropdown,
    Flex,
    Form,
    Modal,
    notification,
    Select,
    Spin,
    Table,
    Tooltip,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { Typography } from "antd/lib";
import { StatusTag } from "@/components";
import {
    ShipmentProductPackageModel,
    ShipmentTrackingModel,
} from "@/models/shipmentProductPackageCollection";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { useNavigate } from "react-router-dom";
import {
    DeleteTwoTone,
    EditOutlined,
    EditTwoTone,
    HddFilled,
    PlusOutlined,
    PlusSquareTwoTone,
    RightOutlined,
} from "@ant-design/icons";
import { ShipmentProductOperationEnum } from "@/enums/shipmentProductCollectionEnums";
import { showError } from "@/helpers/showError";
import { memo, Suspense, useEffect, useMemo, useState } from "react";
import {
    useShipmentProductAddPackage,
    useShipmentProductAttachPackageImage,
    useShipmentProductAttachTrackingId,
    useShipmentProductDeletePackage,
    useShipmentProductUpdatePackage,
    useShipmentProductUpdateTrackingId,
} from "@/lib/core-react/hooks/private/useShipping";
import { ExtendedMenuItemType } from "@/types";
import { ADMIN_SHIPPING_PERMISSION_ENUM } from "@/consts/permission-enum/admin-shipping-enum";
import {
    ContainsEnum,
    DimensionUnitEnum,
    ProductSourceEnum,
    WeightUnitEnum,
} from "@/enums/shipForMeContextEnums";
import {
    AddOwnSourceCartonModal,
    CartonVariationsModal,
    TrackingModificationModal,
} from "../ShipmentProducts";
import { IModalActionsType, IModalData } from "@/helpers/getModalTital";
import { UpdateCartonForm } from "./UpdateCartonForm";
import useWindowWidth from "@/lib/core-react/hooks/public/useWindowWidth";
import ShipmentProductPackagesQuickView from "./ShipmentProductPackagesQuickView";
import { tw } from "@/consts/theme/tailwindTheme";
import { MultiChunkUpload } from "@/components/MultiChunkUpload";
import { IShipmentProductAttachImagesPayload } from "@/types/shipmentProductCollection";
import useRefetch from "@/hooks/useRefetch";
import { transformEnumToLabeledValue } from "@/utils/helpers";
import { getErrorMessage } from "@/helpers/getErrorMessages";
import Paragraph from "antd/lib/typography/Paragraph";

interface Props {
    packages: ShipmentProductPackageModel[];
    unitType?: string;
    shipmentProductId: number;
    regionCode: string;
    productUrl: string;
    customerId: number | undefined;
    productSource: string;
    shipmentNumber: string;
    isDetailPage?: boolean;
}

const { Text } = Typography;

const ShipmentProductPackages: React.FC<Props> = (props) => {
    const {
        packages,
        shipmentProductId,
        productUrl,
        regionCode,
        customerId,
        productSource,
        isDetailPage = true,
    } = props;
    const navigate = useNavigate();
    const { isMobile } = useWindowWidth();

    const [selectedPackage, setSelectedPackage] =
        useState<ShipmentProductPackageModel>();
    const [trackingModalOpen, setTrackingModalOpen] = useState<boolean>(false);
    const [selectedTracking, setSelectedTracking] = useState<
        ShipmentTrackingModel | undefined
    >(undefined);

    const [variationsModalOpen, setVariationsModalOpen] =
        useState<boolean>(false);
    const { refetchListApi } = useRefetch();
    const [form] = Form.useForm();

    const {
        addPackage: addShipmentProductPackage,
        isLoading: isLoadingAddShipmentProductPackage,
    } = useShipmentProductAddPackage();
    const { isLoading: isLoadingUpdateShipmentProductPackage, updatePackage } =
        useShipmentProductUpdatePackage();

    const { deletePackage, isLoading: _deletePackageLoading } =
        useShipmentProductDeletePackage();

    const {
        attachTrackingId,
        isLoading: isLoadingAttachShipmentProductTrackingId,
    } = useShipmentProductAttachTrackingId();

    const {
        updateTrackingId,
        isLoading: isLoadingUpdateShipmentProductTrackingId,
    } = useShipmentProductUpdateTrackingId();
    const {
        attachImage: attachShipmentProductPackageImage,
        isLoading: isLoadingAttachShipmentProductPackageImage,
    } = useShipmentProductAttachPackageImage();

    const [packagesData, setPackagesData] = useState<
        ShipmentProductPackageModel[]
    >([]);

    const [isExpand, setIsExpand] = useState(false);

    // ---------------------------logic---------------------------------
    const computedPackagesData = useMemo(() => {
        const data =
            packages.length > 4 && !isExpand ? packages.slice(0, 5) : packages;
        return data;
    }, [isExpand, packages]);

    useEffect(() => {
        if (packagesData !== computedPackagesData) {
            setPackagesData(computedPackagesData);
        }
    }, [computedPackagesData, packagesData]);

    const [modalOpenType, setModalOpenType] =
        useState<IModalActionsType>(false);

    const handleModal = async (payload: IModalData) => {
        setModalOpenType(payload.action);

        switch (payload.action) {
            case "attach_images_shipment_product":
            case "update_package_shipment_product":
                setSelectedPackage(payload.data);
                break;

            case "add_tracking_shipment_product":
                setTrackingModalOpen(true);
                setSelectedPackage(payload.data);
                break;
            default:
                break;
        }
    };

    const handleAttachCartonImage = async () => {
        try {
            if (selectedPackage && shipmentProductId) {
                const images = form.getFieldValue("images") as string[];

                const payload: IShipmentProductAttachImagesPayload = {
                    images: images,
                    shipment_product_ids: [shipmentProductId],
                    packages: [
                        {
                            id: selectedPackage.getId(),
                            images: [...images],
                        },
                    ],
                };

                await attachShipmentProductPackageImage(payload);
                notification["success"]({
                    message: "Attached carton images successfully.",
                });

                handleModal({
                    action: false,
                    data: null,
                });

                await refetchListApi();
            } else {
                throw new Error("Shipment product id not found");
            }
        } catch (err) {
            showError(err, form);
        }
    };

    const handleAddCarton = () => {
        if (productSource === ProductSourceEnum.OWN_SOURCE) {
            handleModal({
                action: "won_source_add_package_shipment_product",
            });
        } else {
            const routePath = `/service/inventory/product-detail?operation=${ShipmentProductOperationEnum.AddShipmentProductPackage}&shipmentProductId=${shipmentProductId}&region=${regionCode}&userId=${customerId}&productUrl=${productUrl}`;

            navigate(routePath);
        }
    };

    const handleUpdatePackage = async () => {
        try {
            const trigger = await form.validateFields();

            if (selectedPackage) {
                await updatePackage(
                    shipmentProductId,
                    selectedPackage.getId(),
                    { ...trigger },
                );
                notification["success"]({
                    message: "Update carton successfully",
                });
            }
            handleModal({
                action: false,
            });
        } catch (err) {
            showError(err);
        }
    };

    const handleOwnSourceAddPackage = async () => {
        try {
            const trigger = await form.validateFields();
            if (modalOpenType === "won_source_add_package_shipment_product") {
                await addShipmentProductPackage(shipmentProductId, {
                    ...trigger,
                });
                notification["success"]({
                    message: "Add carton successfully",
                });
            }

            handleModal({
                action: false,
            });
        } catch (err) {
            showError(err);
        }
    };

    const handleTracking = (tracking: ShipmentTrackingModel) => {
        setSelectedTracking(tracking);
        setTrackingModalOpen(true);
    };
    const handleAttachTracking = async () => {
        try {
            if (!selectedPackage) {
                throw new Error("Package not Found");
            }
            const values = await form.validateFields();

            await attachTrackingId(shipmentProductId, {
                trackings: [
                    {
                        tracking: values.tracking,
                        package_id: selectedPackage.getId(),
                    },
                ],
            });

            form.resetFields();
            setTrackingModalOpen(false);

            notification["success"]({
                message: "Add tracking successfully.",
            });
        } catch (err) {
            showError(err, form);
        }
    };

    const handleUpdateTracking = async () => {
        try {
            if (selectedTracking) {
                const trigger = await form.validateFields();

                await updateTrackingId(
                    shipmentProductId,
                    selectedTracking.getId(),
                    {
                        ...trigger,
                    },
                );
                form.resetFields();
                notification["success"]({
                    message: "Update tracking successfully.",
                });
                refetchListApi();
                setTrackingModalOpen(false);
            } else {
                throw new Error(
                    "Shipment product number or tracking id not found!",
                );
            }
        } catch (err) {
            showError(err);
        }
    };

    const handleDeletePackage = async (packageId) => {
        if (!packageId) {
            throw new Error("Carton Id Not Found");
        }

        Modal.confirm({
            title: "Are you sure you want to Delete this Carton?",
            content: "Select Ok, otherwise the process cannot success.",
            onOk: async () => {
                try {
                    await deletePackage(shipmentProductId, packageId);
                    return Promise.resolve();
                } catch (error) {
                    showError(error);
                    return Promise.resolve();
                }
            },
            onCancel() {
                notification["info"]({
                    message: "Product cancellation was aborted.",
                });
            },
        });
    };

    // -----------------------UI--------------------------------
    const getActions = (pkg: ShipmentProductPackageModel) => {
        const items: ExtendedMenuItemType[] = [
            {
                permission: [
                    ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_MANAGE_PACKAGE,
                ],
                icon: <EditTwoTone />,
                label: "Update Carton",
                key: "update",
                onClick: () =>
                    handleModal({
                        action: "update_package_shipment_product",
                        data: pkg,
                    }),
            },
            {
                permission: [
                    ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_MANAGE_PACKAGE,
                ],
                icon: <DeleteTwoTone />,
                label: "Delete Carton",
                key: "delete",
                onClick: () => handleDeletePackage(pkg.getId()),
            },
            {
                permission:
                    ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_MANAGE_PACKAGE_TRACKING,
                icon: <PlusSquareTwoTone />,
                label: "Add Tracking",
                key: "add_tracking_shipment_product",
                onClick: () =>
                    handleModal({
                        action: "add_tracking_shipment_product",
                        data: pkg,
                    }),
                disabled: pkg.getTrackings().length > 0,
            },

            {
                permission: [
                    ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_ATTACH_IMAGES,
                ],
                icon: <PlusSquareTwoTone />,
                label: "Attach Image",
                key: "attach_carton_image",
                onClick: () => {
                    handleModal({
                        action: "attach_images_shipment_product",
                    });
                },
            },
        ];

        return items;
    };

    const [editingRow, setEditingRow] = useState<number>(-1);

    const handleUpdatePackageContains = async (newValue: string) => {
        if (selectedPackage) {
            const payload = {
                quantity: selectedPackage.getQuantity(),
                weight: {
                    amount: selectedPackage.getWeight(),
                    unit: WeightUnitEnum.KG,
                },
                length: {
                    amount: selectedPackage.getLength(),
                    unit: DimensionUnitEnum.CM,
                },
                width: {
                    amount: selectedPackage.getWidth(),
                    unit: DimensionUnitEnum.CM,
                },
                contains: newValue,
                height: {
                    amount: selectedPackage.getHeight(),
                    unit: DimensionUnitEnum.CM,
                },
                price: {
                    amount: selectedPackage.getProductPrice(),
                    currency:
                        selectedPackage.getProductPriceCurrency()?.getCode() ||
                        "BDT",
                },
                variations: selectedPackage.getVariations().map((variation) => {
                    return {
                        quantity: variation.getQuantity(),
                        properties: variation
                            .getProperties()
                            .map((property) => {
                                return {
                                    property_name: property.getPropertyName(),
                                    property_value_name:
                                        property.getPropertyValueName(),
                                };
                            }),
                    };
                }),
            };
            try {
                await updatePackage(
                    shipmentProductId,
                    selectedPackage.getId(),
                    payload,
                );
                notification["success"]({
                    message: "Updated carton contains successfully",
                });
            } catch (err) {
                notification["error"]({
                    message: getErrorMessage(err),
                });
            }
        }
        setEditingRow(-1); // Exit edit mode for the current row
    };

    const columns: ColumnsType<ShipmentProductPackageModel> = [
        {
            align: "center",
            title: <Tooltip title="Weight">Weight</Tooltip>,
            dataIndex: "quantity",
            key: "quantity",
            render: (_target, record: ShipmentProductPackageModel) => (
                <Typography>
                    <Text>
                        {record.getWeight()}
                        <small>kg</small>
                    </Text>
                </Typography>
            ),
        },
        {
            align: "center",
            title: "Contains",
            dataIndex: "contains",
            key: "contains",
            render: (text, record) => {
                const isEditing = editingRow === record.getId();
                const value = text;

                return (
                    <Flex wrap="wrap" style={{ textTransform: "capitalize" }}>
                        {isEditing ? (
                            <Select
                                defaultValue={value}
                                onChange={(newValue) =>
                                    handleUpdatePackageContains(newValue)
                                }
                                onBlur={() => setEditingRow(-1)}
                                options={transformEnumToLabeledValue(
                                    ContainsEnum,
                                )}
                            />
                        ) : (
                            <Text
                                editable={{
                                    onStart: () => {
                                        setEditingRow(record.getId());
                                        setSelectedPackage(record);
                                    },
                                }}
                            >
                                {value}
                            </Text>
                        )}
                    </Flex>
                );
            },
        },
        {
            align: "center",
            title: "Trackings",
            key: "Trackings",

            render: (_, record) => (
                <>
                    {record.getTrackings().length > 0
                        ? record.getTrackings().map((tracking) => {
                              return (
                                  <>
                                      <Paragraph>
                                          <Text copyable>
                                              {tracking.getTracking()}
                                          </Text>
                                          {checkActionPermission(
                                              ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_MANAGE_PACKAGE,

                                              <Button
                                                  type="link"
                                                  htmlType="button"
                                                  onClick={() =>
                                                      handleTracking(tracking)
                                                  }
                                                  icon={<EditOutlined />}
                                              ></Button>,

                                              null,
                                          )}
                                      </Paragraph>
                                  </>
                              );
                          })
                        : "N/A"}
                </>
            ),
        },
        {
            align: "center",
            title: "Update status",
            dataIndex: "fulfillment_status",
            key: "fulfillment_status",
            render: (target) => (
                <Typography>
                    <StatusTag slug={target} text={target} />
                </Typography>
            ),
        },
        {
            align: "center",
            title: "A",
            dataIndex: "actions",
            key: "actions",
            hidden:
                !isDetailPage &&
                !checkActionPermission(
                    [
                        ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_MANAGE_PACKAGE,
                        ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_MANAGE_PACKAGE_TRACKING,
                        ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_ATTACH_IMAGES,
                    ],
                    "checkActionPermission",
                    null,
                ),
            render: (_, record) => (
                <Dropdown
                    menu={{
                        items: getActions(record).filter((x) =>
                            checkActionPermission(x.permission, x, null),
                        ),
                    }}
                >
                    <Button type="primary" icon={<HddFilled />} />
                </Dropdown>
            ),
        },
    ];

    return (
        <div>
            <Table
                title={() => {
                    return (
                        <Flex align="center" justify="space-between">
                            <Flex align="center">
                                {isDetailPage && (
                                    <Typography.Text strong>
                                        Carton
                                    </Typography.Text>
                                )}

                                <Button
                                    onClick={() =>
                                        handleModal({
                                            action: "quick_view_package_shipment_product",
                                        })
                                    }
                                    hidden={false}
                                    type="link"
                                >
                                    Quick View
                                </Button>
                            </Flex>

                            {checkActionPermission(
                                ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_MANAGE_PACKAGE,

                                <Button
                                    onClick={handleAddCarton}
                                    hidden={false}
                                    type="link"
                                    icon={<PlusOutlined />}
                                >
                                    Add
                                </Button>,

                                null,
                            )}
                        </Flex>
                    );
                }}
                bordered
                size="small"
                rowHoverable={false}
                pagination={false}
                columns={columns}
                dataSource={packagesData}
                rowKey={(r) => r.getId()}
                scroll={{ x: 400 }}
            />

            <Flex>
                {packagesData.length > 4 ? (
                    <Button
                        style={{
                            border: tw.borderWidth[0],
                            marginTop: tw.spacing[2],
                        }}
                        onClick={() => setIsExpand(!isExpand)}
                    >
                        <RightOutlined
                            style={{
                                transform: `rotate(-${tw.rotate[isExpand ? 90 : 0]})`,
                                transition: tw.transitionProperty.transform,
                                transitionDuration: tw.transitionDelay[300],
                            }}
                        />
                        See {!isExpand ? "more" : "less"}
                    </Button>
                ) : (
                    <></>
                )}
            </Flex>

            <Modal
                title="Carton"
                open={modalOpenType === "quick_view_package_shipment_product"}
                centered
                okText="Update"
                width={isMobile ? "100%" : 1100}
                footer={false}
                onCancel={() =>
                    handleModal({
                        action: false,
                    })
                }
            >
                <Suspense fallback={<Spin />}>
                    <ShipmentProductPackagesQuickView {...props} />
                </Suspense>
            </Modal>
            <Modal
                title="Edit Carton"
                open={modalOpenType === "update_package_shipment_product"}
                centered
                okText="Update"
                onOk={() => handleUpdatePackage()}
                onCancel={() =>
                    handleModal({
                        action: false,
                    })
                }
                okButtonProps={{
                    loading: isLoadingUpdateShipmentProductPackage,
                    disabled: isLoadingUpdateShipmentProductPackage,
                }}
            >
                {selectedPackage ? (
                    <UpdateCartonForm
                        packageData={selectedPackage}
                        form={form}
                        onOk={handleOwnSourceAddPackage}
                    />
                ) : (
                    <Alert type="error" message="Carton Not found to edit" />
                )}
            </Modal>
            <Modal
                title="Add Carton"
                open={
                    modalOpenType === "won_source_add_package_shipment_product"
                }
                centered
                okText="Add"
                onOk={() => handleOwnSourceAddPackage()}
                onCancel={() =>
                    handleModal({
                        action: false,
                    })
                }
                okButtonProps={{
                    loading: isLoadingAddShipmentProductPackage,
                    disabled: isLoadingAddShipmentProductPackage,
                }}
            >
                <AddOwnSourceCartonModal
                    form={form}
                    onOk={handleOwnSourceAddPackage}
                />
            </Modal>

            {selectedPackage && (
                <Modal
                    open={variationsModalOpen}
                    centered
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => {
                        setVariationsModalOpen(false);
                        setSelectedPackage(undefined);
                    }}
                >
                    <CartonVariationsModal productPackage={selectedPackage} />
                </Modal>
            )}

            <Modal
                title={selectedTracking ? `Update Tracking` : `Add Tracking`}
                open={trackingModalOpen}
                centered
                okText={selectedTracking ? `Update` : `Add`}
                onOk={
                    selectedTracking
                        ? handleUpdateTracking
                        : handleAttachTracking
                }
                onCancel={() => {
                    setTrackingModalOpen(false);
                }}
                okButtonProps={{
                    loading:
                        isLoadingAttachShipmentProductTrackingId ||
                        isLoadingUpdateShipmentProductTrackingId,
                }}
            >
                <TrackingModificationModal
                    tracking={selectedTracking}
                    form={form}
                    onOk={handleOwnSourceAddPackage}
                />
            </Modal>

            <Modal
                title="Attach Carton Image"
                open={modalOpenType === "attach_images_shipment_product"}
                centered
                destroyOnClose={true}
                okText="Attach"
                onOk={handleAttachCartonImage}
                onCancel={() => {
                    handleModal({
                        action: false,
                        data: null,
                    });
                }}
                okButtonProps={{
                    loading: isLoadingAttachShipmentProductPackageImage,
                }}
            >
                <MultiChunkUpload fieldName="images" form={form} />
            </Modal>
        </div>
    );
};

export default memo(ShipmentProductPackages);
