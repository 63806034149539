import { PageHeader } from "@ant-design/pro-layout";
import {
    Col,
    Modal,
    Row,
    Table,
    Tabs,
} from "antd";
import { useState, useEffect } from "react";
import { useAtom } from "jotai";

import { wishlistAtom } from "@/lib/core-react/store/store";
import useDataFilters from "@/hooks/useDataFilters";
import { ApiHelperModel } from "@/models/apiHelper";
import { PaginationModel } from "@/models/pagination";
import { IFilterType } from "@/types/filters";

import FiltersComponent from "@/components/FiltersComponent";
import { useGetWishlist } from "@/lib/core-react/hooks/private/useWishlist";
import { WishlistCollectionModel } from "@/models/wishlistCollectionModel";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import {
    IProductGallery,
} from "@/types/wishlistCollection";
import ImageWithPlaceholder from "@/components/ImageWithPlaceholder";
import WishlistTableColumns from "./components/wishListTable";


const Wishlists = () => {
    const {
        allOptionsData: { regionOptionsData },
        onFetchFilterApi,
    } = useFiltersApiData();
    const { getWishlist } = useGetWishlist();
    const [{ data, isLoading, refetch }] = useAtom(wishlistAtom);

    const [selectedRegion, setSelectedRegion] = useState<string | undefined>();
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [modalImages, setModalImages] = useState<IProductGallery[] | undefined>([]);


    useEffect(() => {
        onFetchFilterApi(filterResourceEnum.REGION);
    }, []);

    useEffect(() => {
        if (regionOptionsData.options.length) {
            const firstRegionId = regionOptionsData.options[0].value.toString();
            setSelectedRegion(firstRegionId);
        }
    }, [regionOptionsData.options.length]);

    const {
        filters,
        handleFilterChange,
        handelFilterClear,
        isFirstCall,
        isFetched,
        initializeAvailableFilter,
    } = useDataFilters();

    useEffect(() => {
        if (selectedRegion) {
            getWishlist(`region_id=${selectedRegion}`);
        }
    }, [selectedRegion]);

    useEffect(() => {
        if (filters && !isFetched && isFirstCall && refetch) {
            ApiHelperModel.makeGetRequest(filters, getWishlist);
        }
    }, [filters, isFetched, isFirstCall, refetch]);

    const WishlistData = data && new WishlistCollectionModel(data);

    // Filter
    const filterData = WishlistData?.filters;

 
    let filterWithoutRegin: IFilterType | undefined = undefined;
    if (filterData) {
        const { region_id: _, ...rest } = filterData as IFilterType;
        filterWithoutRegin = rest;
    }

    // Getting all available filters
    useEffect(() => {
        if (!isFetched && WishlistData?.filters) {
            initializeAvailableFilter(filterWithoutRegin as IFilterType);
        }
    }, [isFetched, initializeAvailableFilter, WishlistData?.filters]);

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getWishlist,
        );
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        WishlistData,
        handlePaginationChange,
    );

    // Filter Handler
    const handleProductFilter = () => {
        ApiHelperModel.makeGetRequest(filters, getWishlist);
    };

    const onChangeRegion = (key: string) => {
        setSelectedRegion(key);
    };

    const openGalleryModal = (images: IProductGallery[] | undefined) => {
        setModalImages(images);
        setIsModalVisible(true);
    };

    const closeGalleryModal = () => {
        setIsModalVisible(false);
    };

       // Pagination
       const wishlistColumns = WishlistTableColumns(data?.pagination, openGalleryModal);


    function removeRegionFilter(filters: IFilterType) {
        const updatedFilters = JSON.parse(JSON.stringify(filters));
        if (Object.prototype.hasOwnProperty.call(updatedFilters, "region_id")) {
            delete updatedFilters.region_id;
        }

        return updatedFilters;
    }

    return (
        <>
            <div>
                <PageHeader
                    ghost={false}
                    title="Wishlists"
                    style={{ marginTop: "10px" }}
                    onBack={() => window.history.back()}
                >
                    <Tabs
                        type="card"
                        size="large"
                        activeKey={selectedRegion}
                        tabPosition="top"
                        animated
                        centered
                        onChange={onChangeRegion}
                        items={regionOptionsData.options.map((region) => {
                            return {
                                label: region.label,
                                key: region.value.toString(),
                                children: (
                                    <>
                                        {filters &&
                                            filterData &&
                                            Object.keys(filters).length > 0 && (
                                                <Row>
                                                    <Col span={24}>
                                                        <FiltersComponent
                                                            handleProductFilter={
                                                                handleProductFilter
                                                            }
                                                            handleFilterChange={
                                                                handleFilterChange
                                                            }
                                                            handelFilterClear={
                                                                handelFilterClear
                                                            }
                                                            isFetched={
                                                                isFetched
                                                            }
                                                            filters={filters}
                                                            filtersData={removeRegionFilter(
                                                                filterData,
                                                            )}
                                                            isFromProductReceived={
                                                                true
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                            )}
                                        <Table
                                            loading={isLoading}
                                            rowKey="id"
                                            bordered={true}
                                            dataSource={
                                                WishlistData?.getProcessData() ||
                                                []
                                            }
                                            columns={wishlistColumns}
                                            pagination={paginationConfig}
                                        />
                                    </>
                                ),
                            };
                        })}
                    />
                </PageHeader>
                {modalImages && (
                    <Modal
                        title="Product Gallery"
                        visible={isModalVisible}
                        onCancel={closeGalleryModal}
                        footer={null}
                        width={800}
                    >
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {modalImages.map((image) => (
                                <div key={image.id} style={{ margin: 10 }}>
                                    <ImageWithPlaceholder
                                        style={{
                                            width: 60,
                                        }}
                                        src={image.url}
                                    />
                                </div>
                            ))}
                        </div>
                    </Modal>
                )}
            </div>
        </>
        
    );
    
};


export default Wishlists;
