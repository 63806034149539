import { IPagination } from "@/types/pagination";
import { IFilterType } from "@/types/filters";
import {
    ShippingCartCollectionEnums,
    ShippingCartItemCollectionEnums,
    ShippingCartItemPackageCollectionEnums,
} from "@/enums/shippingCartCollectionEnums";
import {
    IMeasurement,
    IPrice,
    IRegion,
    IShippingCart,
    IShippingCartCollection,
    IShippingCartExtended,
    IShippingCartItem,
    IShippingCartItemCollection,
    IShippingCartItemPackage,
    IShippingCartItemPackageCollection,
    IShippingCartItemPackageVariationCollection,
} from "@/types/shippingCartCollection";
import { ShippingModeEnums, ShippingTypeEnums } from "@/enums/shippingCoreEnums";
import { CommonUserModel } from "./shared";

export class ShippingCartItemPackageModel {
    public readonly object: ShippingCartItemPackageCollectionEnums.ShippingCartItemPackage;
    public readonly id: number;
    public readonly quantity: number;
    public readonly weight: IMeasurement;
    public readonly length: IMeasurement;
    public readonly width: IMeasurement;
    public readonly height: IMeasurement;
    public readonly price: IPrice;
    public readonly contains: string;
    public readonly hs_code: string | null;
    public readonly hs_code_description: string | null;
    public readonly variations: IShippingCartItemPackageVariationCollection;

    constructor(dataItem: IShippingCartItemPackage) {
        this.object = dataItem.object;
        this.id = dataItem.id;
        this.quantity = dataItem.quantity;
        this.weight = dataItem.weight;
        this.length = dataItem.length;
        this.width = dataItem.width;
        this.height = dataItem.height;
        this.price = dataItem.price;
        this.contains = dataItem.contains;
        this.hs_code = dataItem.hs_code;
        this.hs_code_description = dataItem.hs_code_description;
        this.variations = dataItem.variations;
    }
}

export class ShippingCartItemPackageCollectionModel {
    public readonly object: ShippingCartItemPackageCollectionEnums.ShippingCartItemPackageCollection;
    public readonly data: ShippingCartItemPackageModel[];

    constructor(data: IShippingCartItemPackageCollection) {
        this.object = data.object;
        this.data = data.data.map(
            (item) => new ShippingCartItemPackageModel(item),
        );
    }
}

export class ShippingCartItemModel {
    public readonly object: ShippingCartItemCollectionEnums.ShippingCartItem;
    public readonly id: number;
    public readonly shipping_country_id: number;
    public readonly destination_country_id: number;
    public readonly destination_warehouse_id: number;
    public readonly shipping_type: ShippingTypeEnums;
    public readonly shipping_mode: ShippingModeEnums;
    public readonly link: string;
    public readonly title: string;
    public readonly images: string[];
    public readonly shipping_category_id: string;
    public readonly packages: IShippingCartItemPackageCollection;
    public readonly addon_service_ids: number[] | null;
    public readonly shipping_address_id: number;
    public readonly shipping_address: IFilterType;
    public readonly shipment_instruction: string | null;
    public readonly product_source: string;

    constructor(dataItem: IShippingCartItem) {
        this.object = dataItem.object;
        this.id = dataItem.id;
        this.shipping_country_id = dataItem.shipping_country_id;
        this.destination_country_id = dataItem.destination_country_id;
        this.destination_warehouse_id = dataItem.destination_warehouse_id;
        this.shipping_country_id = dataItem.shipping_country_id;
        this.shipping_type = dataItem.shipping_type;
        this.shipping_mode = dataItem.shipping_mode;
        this.title = dataItem.title;
        this.link = dataItem.link;
        this.images = dataItem.images;
        this.shipping_category_id = dataItem.shipping_category_id;
        this.packages = dataItem.packages;
        this.addon_service_ids = dataItem.addon_service_ids;
        this.shipping_address_id = dataItem.shipping_address_id;
        this.shipping_address = dataItem.shipping_address;
        this.shipment_instruction = dataItem.shipment_instruction;
        this.product_source = dataItem.product_source;
    }

    getShippingAddress() {
        const keysToRemove = ["id", "flag", "code", "object"];

        const filteredAddress = Object.keys(this.shipping_address)
            .filter((key) => !keysToRemove.includes(key))
            .reduce((obj: IFilterType, key) => {
                obj[key] = this.shipping_address[key];
                return obj;
            }, {});

        return filteredAddress;
    }
}

export class ShippingCartItemCollectionModel {
    public readonly object: ShippingCartItemCollectionEnums.ShippingCartItemCollection;
    public readonly data: ShippingCartItemModel[];

    constructor(data: IShippingCartItemCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => new ShippingCartItemModel(item));
    }
}

export class ShippingCartModel {
  public readonly object: ShippingCartItemCollectionEnums.ShippingCartItem;
  public readonly id: number;
  public readonly user: CommonUserModel | null;
  public readonly guest_id: string | null;
  public readonly region: IRegion;
  public readonly cart_items: ShippingCartItemCollectionModel;
  public readonly created_at: string;

  constructor(dataItem: IShippingCart) {
    this.object = dataItem.object;
    this.id = dataItem.id;
    this.user = dataItem.user && new CommonUserModel(dataItem.user);
    this.guest_id = dataItem.guest_id;
    this.region = dataItem.region;
    this.cart_items = new ShippingCartItemCollectionModel(dataItem.cart_items);
    this.created_at = dataItem.created_at;
  }

    getCartItem() {
        return this.cart_items;
    }
}

export class ShippingCartCollectionModel {
    public readonly object: ShippingCartCollectionEnums.ShippingCartCollection;
    public readonly data: ShippingCartModel[];
    public readonly pagination: IPagination;
    public readonly filters: IFilterType;

    constructor(data: IShippingCartCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => new ShippingCartModel(item));
        this.pagination = data.pagination;
        this.filters = data.filters;
    }

  getData(){
    return this.data;
  }

  getProcessData(): IShippingCartExtended[] {
    return this.getData().flatMap((shippingCart) =>
      shippingCart.getCartItem().data.map((cart) => ({
        ...cart,
        user: shippingCart.user,
        region: shippingCart.region,
        guest_id: shippingCart.guest_id,
        created_at: shippingCart.created_at,
        purchaseCartId: shippingCart.id,
        purchaseCartItemId: cart.id,
      }))
    ) as unknown as IShippingCartExtended[];
  }
  
}
