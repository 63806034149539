import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";

import QueryString from "qs";
import { paginationQuery, responseTypeQuery } from "@/filters/constant";
import { useTransactions } from "@/lib/core-react/hooks/private/useTransactions";
import {
    useGetAdminsMinimal,
    useGetCustomersMinimal,
    useGetOrderHandlerMinimal,
} from "@/lib/core-react/hooks/private/minimal/useBulkUserMinimal";
import { useGetExchangeGroupMinimal } from "@/lib/core-react/hooks/private/minimal/useExchangeGroupMinimal";
import { useGetExchangeMinimal } from "@/lib/core-react/hooks/private/minimal/useExchangeMinimal";
import { useGetCountryMinimal } from "@/lib/core-react/hooks/private/minimal/useCountryMinimal";
import { useGetRegionMinimal } from "@/lib/core-react/hooks/private/minimal/useRegionMinimal";
import { useGetCurrencyMinimal } from "@/lib/core-react/hooks/private/minimal/useCurrencyMinimal";
import { useGetLanguageMinimal } from "@/lib/core-react/hooks/private/minimal/useLanguageMinimal";
import { useGetAgentCompanyMinimal } from "@/lib/core-react/hooks/private/minimal/useAgentCompanyMinimal";
import { useGetDestinationWarehouseMinimal } from "@/lib/core-react/hooks/private/minimal/useDestinationWarehouseMinimal";
import {
    useGetPayoutGatewaysMinimal,
    useGetPayoutRequestsMinimal,
} from "@/lib/core-react/hooks/private/minimal/useWalletMinimal";
import { useGetStoreMinimal } from "@/lib/core-react/hooks/private/minimal/useStoreMinimal";
import { useGetAgentWarehouseMinimal } from "@/lib/core-react/hooks/private/minimal/useAgentWarehouseMinimal";
import { useGetShippingCountryMinimal } from "@/lib/core-react/hooks/private/minimal/useShippingCountryMinimal";
import { useGetShippingCategoryMinimal } from "@/lib/core-react/hooks/private/minimal/useShippingCategoryMinimal";
import { useGetBaseShippingCategoryMinimal } from "@/lib/core-react/hooks/private/minimal/useBaseShippingCategoryMinimal";
import { useGetPaymentGatewayMinimal } from "@/lib/core-react/hooks/private/minimal/usePaymentMinimal";
import { useGetRoleMinimal } from "@/lib/core-react/hooks/private/minimal/useRoleMinimal";
import { useGetStripeSlugCampaignMinimal } from "@/lib/core-react/hooks/private/minimal/useStripiSlugCampaignMinimal";
import { useAddonServicesListMinimal } from "@/lib/core-react/hooks/private/minimal/useAddonServiceMinimal";

const useFiltersApiData = (queryParams: Record<string, any> = {}) => {
    const { getAdmins, adminOptionsData } = useGetAdminsMinimal();
    const { getOrderHandlers, orderHandlerOptionsData } =
        useGetOrderHandlerMinimal();
    const { getCustomers, customerOptionsData, usersResponse } =
        useGetCustomersMinimal();
    const { getExchangeGroup, exchangeGroupOptionsData } =
        useGetExchangeGroupMinimal();
    const { getExchange, exchangeListOptionsData } = useGetExchangeMinimal();
    const { getCountry, countryOptionsData } = useGetCountryMinimal();
    const { getRegion, regionOptionsData } = useGetRegionMinimal();
    const { getCurrency, currencyOptionsData } = useGetCurrencyMinimal();
    const { getLanguage, languageOptionsData } = useGetLanguageMinimal();
    const { getAgentCompanies, agentCompanyOptionsData } =
        useGetAgentCompanyMinimal();
    const { getDestinationWarehouse, destinationWarehouseOptionsData } =
        useGetDestinationWarehouseMinimal();
    const { getPayoutRequests, payoutRequestOptionsData } =
        useGetPayoutRequestsMinimal();

    const { getPayoutGateways, payoutGatewayOptionsData } =
        useGetPayoutGatewaysMinimal();
    const { getStore, storeOptionsData } = useGetStoreMinimal();
    const { getAgentWarehouses, agentWarehouseOptionsData } =
        useGetAgentWarehouseMinimal();
    const { getShippingCountry, shippingCountryOptionsData } =
        useGetShippingCountryMinimal();

    const { getShippingCategory, shippingCategoryOptionsData } =
        useGetShippingCategoryMinimal();

    const { getBaseShippingCategory, baseShippingCategoryOptionsData } =
        useGetBaseShippingCategoryMinimal();

    const { getPaymentGateway, paymentGatewayOptionsData } =
        useGetPaymentGatewayMinimal();

    const { getTransactions } = useTransactions();

    const { getRole, roleOptionsData } = useGetRoleMinimal();
    const { getStrapSlugCampaignMinimal, strapiSlugCampaignOptionData } =
        useGetStripeSlugCampaignMinimal();
    const { getAddonServices, addonServiceOptionsData } =
        useAddonServicesListMinimal();

    // ----------------options data------------------

    const allOptionsData = {
        adminOptionsData,
        customerOptionsData,
        exchangeGroupOptionsData,
        exchangeListOptionsData,
        countryOptionsData,
        regionOptionsData,
        currencyOptionsData,
        languageOptionsData,
        agentCompanyOptionsData,
        destinationWarehouseOptionsData,
        payoutRequestOptionsData,
        payoutGatewayOptionsData,
        storeOptionsData,
        agentWarehouseOptionsData,
        shippingCountryOptionsData,
        shippingCategoryOptionsData,
        baseShippingCategoryOptionsData,
        paymentGatewayOptionsData,
        roleOptionsData,
        strapiSlugCampaignOptionData,
        usersResponse,
        addonServiceOptionsData,
        orderHandlerOptionsData,
    };

    // ----------------on request ------------------

    const onFetchFilterApi = async (
        resource: filterResourceEnum,
        queryObject: Record<string, any> = {},
    ) => {
        try {
            const query = {
                ...(resource !== filterResourceEnum.CUSTOMER &&
                resource !== filterResourceEnum.USER
                    ? paginationQuery
                    : {}),
                ...responseTypeQuery.minimal,
                ...queryParams,
                ...queryObject,
            };
            const queryString = QueryString.stringify(query);

            switch (resource) {
                case filterResourceEnum.COUNTRY:
                    return await getCountry(queryString);
                case filterResourceEnum.LANGUAGE:
                    return await getLanguage(queryString);

                case filterResourceEnum.ORDER_HANDLER:
                    return await getOrderHandlers(queryString);

                case filterResourceEnum.REGION:
                    return await getRegion(queryString);

                case filterResourceEnum.CURRENCY:
                    return await getCurrency(queryString);

                case filterResourceEnum.AGENT_WAREHOUSE:
                    return await getAgentWarehouses(queryString);

                case filterResourceEnum.DESTINATION_WAREHOUSE:
                    return await getDestinationWarehouse(queryString);

                case filterResourceEnum.ADMIN:
                    return await getAdmins(queryString);

                case filterResourceEnum.AGENT_ROLE:
                    return await getRole(queryString);

                case filterResourceEnum.CUSTOMER:
                case filterResourceEnum.USER:
                    return await getCustomers(queryString);

                case filterResourceEnum.PAYOUT_GATEWAY:
                    return await getPayoutGateways(queryString);

                case filterResourceEnum.PAYOUT_ACCOUNT:
                    return await getPayoutRequests(queryString);

                case filterResourceEnum.SHIPPING_COUNTRY:
                    return await getShippingCountry(queryString);

                case filterResourceEnum.AGENT_COMPANY:
                    return await getAgentCompanies(queryString);

                case filterResourceEnum.STORE:
                    return await getStore(queryString);

                case filterResourceEnum.EXCHANGE_GROUP:
                    return await getExchangeGroup(query);

                case filterResourceEnum.EXCHANGE_LIST:
                    return await getExchange(query);

                case filterResourceEnum.SHIPPING_CATEGORY:
                    return await getShippingCategory(
                        QueryString.stringify({
                            ...paginationQuery,
                            ...queryParams,
                            ...queryObject,
                        }),
                    );

                case filterResourceEnum.BASE_SHIPPING_CATEGORY:
                    return await getBaseShippingCategory(
                        QueryString.stringify({
                            ...paginationQuery,
                            ...queryParams,
                            ...queryObject,
                        }),
                    );

                case filterResourceEnum.GATEWAY:
                    return await getPaymentGateway(queryString);

                case filterResourceEnum.GATEWAY_TRANSACTION:
                    return await getTransactions(queryString);
                case filterResourceEnum.STRAPI_SLUG_CAMPAIGN:
                    return await getStrapSlugCampaignMinimal(queryString);
                case filterResourceEnum.ADDON_SERVICE_LIST:
                    return await getAddonServices(queryString);

                default:
                    throw new Error(`Unknown resource: ${resource}`);
            }
        } catch (_error) {
            // console.error("Error fetching options:", error);
            return null;
        }
    };

    return { onFetchFilterApi, allOptionsData } as const;
};

export default useFiltersApiData;
