import { AgentCompanyCollectionEnums } from "@/enums/agentCompanyCollectionEnums";
import {
    AgentCompanyOperationStatusEnums,
    AgentCompanyVerificationStatusEnums,
} from "@/enums/shared";
import {
    IAddress,
    IShipmentImage,
    IShippingAddress,
} from "@/types/shipmentProductCollection";

export class ShipmentImageModel {
    public readonly object: "ShipmentImage";
    public readonly id: number;
    public readonly title: string | null;
    public readonly alt: string | null;
    public readonly src: string;
    public readonly display_order: number;
    public readonly type: string;

    constructor(data: IShipmentImage) {
        this.object = data.object;
        this.id = data.id;
        this.title = data.title;
        this.alt = data.alt;
        this.src = data.src;
        this.display_order = data.display_order;
        this.type = data.type;
    }

    getObject(): "ShipmentImage" {
        return this.object;
    }

    getId(): number {
        return this.id;
    }

    getTitle(): string | null {
        return this.title;
    }

    getAlt(): string | null {
        return this.alt;
    }

    getSrc(): string {
        return this.src;
    }

    getDisplayOrder(): number {
        return this.display_order;
    }

    getType(): string {
        return this.type;
    }
}

export class ShippingAddressModel {
    public readonly id: number;
    public readonly address: IAddress;
    public readonly is_default_billing: number;
    public readonly is_default_shipping: number;

    constructor(data: IShippingAddress) {
        this.id = data.id;
        this.address = data.address;
        this.is_default_billing = data.is_default_billing;
        this.is_default_shipping = data.is_default_shipping;
    }

    getId(): number {
        return this.id;
    }

    getAddress(): IAddress {
        return this.address;
    }

    getIsDefaultBilling(): number {
        return this.is_default_billing;
    }

    getIsDefaultShipping(): number {
        return this.is_default_shipping;
    }
}

export class AddressModel {
    public readonly area: string;
    public readonly email: string;
    public readonly phone: string;
    public readonly country: string;
    public readonly district: string;
    public readonly address_1: string;
    public readonly address_2: string;
    public readonly last_name: string;
    public readonly first_name: string;
    public readonly postal_code: string;

    constructor(data: IAddress) {
        this.area = data.area;
        this.email = data.email;
        this.phone = data.phone;
        this.country = data.country;
        this.district = data.district;
        this.address_1 = data.address_1;
        this.address_2 = data.address_2;
        this.last_name = data.last_name;
        this.first_name = data.first_name;
        this.postal_code = data.postal_code;
    }

    getArea(): string {
        return this.area;
    }

    getEmail(): string {
        return this.email;
    }

    getPhone(): string {
        return this.phone;
    }

    getCountry(): string {
        return this.country;
    }

    getDistrict(): string {
        return this.district;
    }

    getAddress1(): string {
        return this.address_1;
    }

    getAddress2(): string {
        return this.address_2;
    }

    getLastName(): string {
        return this.last_name;
    }

    getFirstName(): string {
        return this.first_name;
    }

    getPostalCode(): string {
        return this.postal_code;
    }
}

export class AgentCompanyModel {
    object: AgentCompanyCollectionEnums.AgentCompany;
    id: number;
    primary_name: string;
    secondary_name: string;
    slug: string;
    operation_status: AgentCompanyOperationStatusEnums;
    verification_status: AgentCompanyVerificationStatusEnums;
    registered_country: number;
    company_type: string;
    director_name: string;
    designation: string;
    business_phone: string;
    years_of_experience: string;
    number_of_people_in_team: string;
    address: any;

    constructor(dataItem: any) {
        this.object = dataItem.object;
        this.id = dataItem.id;
        this.primary_name = dataItem.primary_name;
        this.secondary_name = dataItem.secondary_name;
        this.slug = dataItem.slug;
        this.operation_status = dataItem.operation_status;
        this.verification_status = dataItem.verification_status;
        this.registered_country = dataItem.registered_country;
        this.company_type = dataItem.company_type;
        this.director_name = dataItem.director_name;
        this.designation = dataItem.designation;
        this.business_phone = dataItem.business_phone;
        this.years_of_experience = dataItem.years_of_experience;
        this.number_of_people_in_team = dataItem.number_of_people_in_team;
        this.address = dataItem.address;
    }

    getId() {
        return this.id;
    }
    getPrimaryName() {
        return this.primary_name;
    }
    getSecondaryName() {
        return this.secondary_name;
    }
    getSlug() {
        return this.slug;
    }
    getOperationStatus() {
        return this.operation_status;
    }
    getVerificationStatus() {
        return this.verification_status;
    }
    getRegisteredCountry() {
        return this.registered_country;
    }

    getCompanyType() {
        return this.company_type;
    }
    getDirectorName() {
        return this.director_name;
    }
    getDesignation() {
        return this.designation;
    }
    getBusinessPhone() {
        return this.business_phone;
    }
    getYearsOfExperience() {
        return this.years_of_experience;
    }
    getNumberOfPeopleInTeam() {
        return this.number_of_people_in_team;
    }
}
