import { PageHeader } from "@ant-design/pro-layout";
import { Col, Row, Table, Tag, Typography } from "antd";
import { StatusTag } from "@/components";
import FiltersComponent from "@/components/FiltersComponent";
import useDataFilters from "@/hooks/useDataFilters";
import { useAtom } from "jotai";
import useWindowWidth from "@/lib/core-react/hooks/public/useWindowWidth";
import { impersonateSessions } from "@/lib/core-react/store/store";
import { ApiHelperModel } from "@/models/apiHelper";
import { PaginationModel } from "@/models/pagination";
import { ImpersonateSessionModel } from "@/models/userCollectionModel";
import { useEffect } from "react";
import { IFilterType } from "@/types/filters";
import { useGetImpersonateSessions } from "@/lib/core-react/hooks/private/useRole";
import CommonError from "@/components/Error/CommonError";

export const ImpersonateSessions = () => {
    const { getSessions } = useGetImpersonateSessions();
    const [
        {
            data: impersonateSessionsData,
            isLoading,
            refetch,
            error,
            unAuthorized,
            code,
        },
    ] = useAtom(impersonateSessions);
    // const [{ data: impersonateSessionDetailData }] =
    //   useAtom(impersonateSessionsDetails);
    const { isMobile } = useWindowWidth();

    //const { getSessionDetail } = useGetImpersonateSessionsDetail()

    const { Text } = Typography;
    const {
        filters,
        isFirstCall,
        isFetched,
        initializeAvailableFilter,
        handleFilterChange,
        handelFilterClear,
        refetch: refetchFromFilter,
    } = useDataFilters();

    // Api Call
    useEffect(() => {
        if (
            (filters && !isFetched && isFirstCall) ||
            refetch ||
            refetchFromFilter
        ) {
            ApiHelperModel.makeGetRequest(filters, getSessions);
        }
    }, [isFirstCall, filters, isFetched, refetch, refetchFromFilter]);

    // Filter
    const filterData = impersonateSessionsData?.filters;

    // Pagination
    const paginationData = impersonateSessionsData?.pagination;

    // Getting all available filters
    useEffect(() => {
        if (!isFetched && impersonateSessionsData?.filters) {
            initializeAvailableFilter(filterData as IFilterType);
        }
    }, [
        isFetched,
        initializeAvailableFilter,
        impersonateSessionsData?.filters,
    ]);

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getSessions,
        );
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        impersonateSessionsData,
        handlePaginationChange,
    );

    // Filter Handler
    const handleProductFilter = () => {
        ApiHelperModel.makeGetRequest(filters, getSessions);
    };

    const columns = [
        {
            title: "#SL",
            dataIndex: "id",
            key: "sl",
            align: "center" as const,
            width: 70,
            render: (_: string, __: ImpersonateSessionModel, index: number) => {
                return (
                    <div>
                        {paginationData
                            ? (paginationData.current_page - 1) *
                                  paginationData.per_page +
                              index +
                              1
                            : ""}
                    </div>
                );
            },
        },
        {
            title: "Impersonatee User",
            dataIndex: "impersonatee",
            key: "impersonatee",
            render: (_impersonate, record: ImpersonateSessionModel) => {
                const user = record.getImpersonate();
                return (
                    <>
                        <span style={{ fontWeight: "bold" }}>Name: </span>{" "}
                        <Text>{user.getName()}</Text>
                        <br />
                        <span style={{ fontWeight: "bold" }}>Email: </span>
                        <Text>{user.getEmail()}</Text>
                        <br />
                        <span style={{ fontWeight: "bold" }}>User Type: </span>
                        <Text>{user.getType()}</Text>
                        <br />
                    </>
                );
            },
        },
        {
            title: "Impersonator User",
            dataIndex: "impersonator",
            key: "impersonator",
            render: (_impersonator, record: ImpersonateSessionModel) => {
                const user = record.getImpersonator();
                return (
                    <>
                        <span style={{ fontWeight: "bold" }}>Name: </span>{" "}
                        <Text>{user.getName()}</Text>
                        <br />
                        <span style={{ fontWeight: "bold" }}>Email: </span>
                        <Text>{user.getEmail()}</Text>
                        <br />
                        <span style={{ fontWeight: "bold" }}>User Type: </span>
                        <Text>{user.getType()}</Text>
                        <br />
                    </>
                );
            },
        },
        {
            title: "Acquired User",
            dataIndex: "acquired_by",
            key: "acquired_by",
            render: (_acquired_by, record: ImpersonateSessionModel) => {
                const user = record.getAcquiredBy();
                return (
                    <>
                        <span style={{ fontWeight: "bold" }}>Name: </span>{" "}
                        <Text>{user.getName()}</Text>
                        <br />
                        <span style={{ fontWeight: "bold" }}>Email: </span>
                        <Text>{user.getEmail()}</Text>
                        <br />
                        <span style={{ fontWeight: "bold" }}>User Type: </span>
                        <Text>{user.getType()}</Text>
                        <br />
                    </>
                );
            },
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "is_active",
            render: (status: string) => {
                return (
                    <>
                        <StatusTag
                            text={status.toLowerCase()}
                            slug={status.toLowerCase()}
                        />
                    </>
                );
            },
        },
        {
            title: "Created At",
            dataIndex: "created_at",
            hidden: isMobile,
            key: "created_at",
            render: (_created_at: string, record: ImpersonateSessionModel) => {
                return (
                    <>
                        <Tag color="purple">{record.getCreatedAt()}</Tag>
                    </>
                );
            },
        },
        {
            title: "Expire At",
            dataIndex: "expire_at",
            hidden: isMobile,
            key: "expire_at",
            render: (_expire_at: string, record: ImpersonateSessionModel) => {
                return (
                    <>
                        <Tag color="red">{record.getExpireAt()}</Tag>
                    </>
                );
            },
        },
    ];

    if (!isLoading && error) {
        return (
            <CommonError
                unAuthorized={unAuthorized}
                message={error}
                code={code}
            />
        );
    }

    return (
        <>
            <Row>
                <Col span={24}>
                    <PageHeader
                        ghost={false}
                        style={{ marginTop: "10px" }}
                        title={"Impersonate sessions"}
                        onBack={() => window.history.back()}
                    >
                        {filters && Object.keys(filters).length > 0 && (
                            <Row>
                                <Col span={24}>
                                    <FiltersComponent
                                        handleProductFilter={
                                            handleProductFilter
                                        }
                                        handleFilterChange={handleFilterChange}
                                        handelFilterClear={handelFilterClear}
                                        isFetched={isFetched}
                                        filters={filters}
                                        filtersData={filterData}
                                        isFromProductReceived={true}
                                    />
                                </Col>
                            </Row>
                        )}
                        <Table
                            style={{ marginTop: 10 }}
                            //@ts-ignore
                            columns={columns}
                            dataSource={impersonateSessionsData?.data}
                            loading={isLoading}
                            size="middle"
                            pagination={paginationConfig}
                            rowKey="id"
                            scroll={{ x: 700 }}
                        />
                    </PageHeader>
                </Col>
            </Row>
        </>
    );
};
