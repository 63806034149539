import {
    IDestinationWarehouse,
    IDestinationWarehouseCollection,
    IDestinationWarehouseMember,
    IDestinationWarehouseMemberCollection,
    IDestinationWarehouseMembershipInvitation,
    IDestinationWarehouseMembershipInvitationCollection,
    IDestinationWarehouseRole,
    IDestinationWarehouseRoleCollection,
} from "@/types/destinationWarehouseCollection";
import { PaginationModel } from "./paginationModel";
import { IFilterType } from "@/types/filters";
import { CommonUserModel } from "./shared";
import { formatDateTime } from "@/utils/helpers";
import { SelectProps } from "antd";
import { CountryModel } from "./countryCollectionModel";
import { RegionModel } from "@/models/regionCollectionModel";

export class DestinationWarehouseMembershipInvitationModel {
    object: string;
    id: number;
    user_email: string;
    roles: DestinationWarehouseRoleCollectionModel;
    status: string;
    expires_at: string;

    constructor(dataItem: IDestinationWarehouseMembershipInvitation) {
        this.object = dataItem.object;
        this.id = dataItem.id;
        this.user_email = dataItem.user_email;
        this.roles = new DestinationWarehouseRoleCollectionModel(
            dataItem.roles,
        );
        this.status = dataItem.status;
        this.expires_at = dataItem.expires_at;
    }

    getId = () => this.id;
    getUserEmail = () => this.user_email;
    getRoles = () => this.roles;
    getStatus = () => this.status;
    getExpiresAt = () => formatDateTime(this.expires_at);
}

export class DestinationWarehouseMembershipInvitationCollectionModel {
    object: string;
    data: DestinationWarehouseMembershipInvitationModel[];
    pagination: PaginationModel;

    constructor(data: IDestinationWarehouseMembershipInvitationCollection) {
        this.object = data.object;
        this.data = data.data.map(
            (item) => new DestinationWarehouseMembershipInvitationModel(item),
        );
        this.pagination = new PaginationModel(data.pagination);
    }

    getData = () => this.data;
    getPagination = () => this.pagination;
    getMembershipInvitationById(
        id: number,
    ): DestinationWarehouseMembershipInvitationModel | undefined {
        return this.data.find((item) => item.id === id);
    }
}

export class DestinationWarehouseRoleModel {
    object: string;
    id: number;
    name: string;
    label: string;

    constructor(data: IDestinationWarehouseRole) {
        this.object = data.object;
        this.id = data.id;
        this.name = data.name;
        this.label = data.label;
    }

    getId = () => this.id;
    getName = () => this.name;
    getLabel = () => this.label;
}

export class DestinationWarehouseRoleCollectionModel {
    object: string;
    data: DestinationWarehouseRoleModel[];

    constructor(data: IDestinationWarehouseRoleCollection) {
        this.object = data.object;
        this.data = data.data.map(
            (data) => new DestinationWarehouseRoleModel(data),
        );
    }

    getData = () => this.data;
    getRoleIds = () => this.getData().map((role) => role.id);
    getRolesLabeledValue = () => {
        return this.getData().map((item) => ({
            label: item.getLabel(),
            value: item.getId(),
        }));
    };
}

export class DestinationWarehouseMemberModel {
    object: string;
    id: number;
    user: CommonUserModel;
    status: string;
    member_since: string;
    roles: DestinationWarehouseRoleCollectionModel;

    constructor(dataItem: IDestinationWarehouseMember) {
        this.object = dataItem.object;
        this.id = dataItem.id;
        this.user = new CommonUserModel(dataItem.user);
        this.status = dataItem.status;
        this.member_since = dataItem.member_since;
        this.roles = new DestinationWarehouseRoleCollectionModel(
            dataItem.roles,
        );
    }

    getId = () => this.id;
    getUser() {
        return this.user;
    }
    getStatus() {
        return this.status;
    }
    getMemberSince() {
        return formatDateTime(this.member_since);
    }
    getRoles() {
        return this.roles;
    }
}

export class DestinationWarehouseMemberCollectionModel {
    object: string;
    data: DestinationWarehouseMemberModel[];
    pagination: PaginationModel;

    constructor(data: IDestinationWarehouseMemberCollection) {
        this.object = data.object;
        this.data = data.data.map(
            (item) => new DestinationWarehouseMemberModel(item),
        );
        this.pagination = new PaginationModel(data.pagination);
    }

    getData = () => this.data;
    getPagination = () => this.pagination;

    getMemberById(id: number): DestinationWarehouseMemberModel | undefined {
        return this.data.find((item) => item.id === id);
    }
}

export class DestinationWarehouseModel {
    object: string;
    id: number;
    slug: string;
    name: string;
    status: string;
    country: CountryModel | undefined;
    region: RegionModel | undefined;

    constructor(data: IDestinationWarehouse) {
        this.object = data.object;
        this.id = data.id;
        this.slug = data.slug;
        this.name = data.name;
        this.status = data.status;
        this.country = data.country && new CountryModel(data.country);
        this.region = data.region && new RegionModel(data.region);
    }

    getId = () => this.id;
    getSlug = () => this.slug;
    getName = () => this.name;
    getStatus = () => this.status;
    getCountry = () => this.country;
    getRegion = () => this.region;
}
export class DestinationWarehouseCollectionModel {
    object: string;
    data: DestinationWarehouseModel[];
    pagination?: PaginationModel;
    filters?: IFilterType;

    constructor(data: IDestinationWarehouseCollection) {
        this.object = data.object;
        this.data = data.data.map(
            (item) => new DestinationWarehouseModel(item),
        );
        this.pagination =
            data.pagination && new PaginationModel(data.pagination);
        this.filters = data.filters;
    }

    getData = () => this.data;
    getPagination = () => this.pagination;
    getFilters = () => this.filters;

    getDataAsLabelValue() {
        return this.data.map((warehouse) => {
            return {
                value: warehouse.getId(),
                label: warehouse.getName(),
                country: warehouse.getCountry(),
            };
        });
    }

    getDestinationWarehouseOptions(): SelectProps["options"] {
        return this.data && this.data.length > 0
            ? this.data.map((warehouse) => {
                  return {
                      value: warehouse.getId(),
                      label: warehouse.getName(),
                      country: warehouse.getCountry(),
                  };
              })
            : [];
    }
}
