import UpdateFxForm from "./components/UpdateFxModal";
import { BuyProductModel } from "@/models/buyProductCollectionModel";
import {
    getModalTitle,
    getModalWidth,
    IModalActionsType,
    IModalData,
} from "../../../helpers/getModalTital";
import { BuyProductAgentAssignModal, UpdateShippingPrice } from "./components";
import TakeActionsTable from "./components/TakeActionsModal";
import React, { Key, ReactNode, Suspense } from "react";
import { Modal, Spin } from "antd";
import ShippingUpdateModal from "../components/ShippingUpdateModal";
import ApproveRfqProductModal from "./components/ApproveRfqProduct";
import CancelFormBuyProduct from "./components/CancelFormBuyProduct";

interface Props {
    buyProduct: BuyProductModel | undefined;
    isLoading: boolean;
    modalOpenType: IModalActionsType;
    handleModal: (payload: IModalData) => void;
    onCancel: () => void;
    children: ReactNode;
    selectedRowKeys: Key[];
}

const BuyProductsModalContainerForActions: React.FC<Props> = ({
    children,
    modalOpenType,
    isLoading,
    buyProduct,
    handleModal,
    selectedRowKeys,
    onCancel,
}) => {
    return (
        <>
            {children}

            {/* Update FX Modal */}
            <Modal
                title={getModalTitle(modalOpenType)}
                open={modalOpenType === "update_fx"}
                confirmLoading={isLoading}
                destroyOnClose={true}
                width={getModalWidth(modalOpenType)}
                onCancel={onCancel}
                footer={false}
            >
                {buyProduct && modalOpenType === "update_fx" && (
                    <UpdateFxForm
                        handleModal={handleModal}
                        fxData={{
                            product_id: buyProduct.getId(),
                            fxRate: buyProduct.getCustomerFx(),
                            commission_rate:
                                buyProduct.getCustomerCommissionRate(),
                            commission_type:
                                buyProduct.getCustomerCommissionRateType(),
                            totalFxAndCommission:
                                buyProduct.getCustomerTotalCommissionAndFx(),
                            currencyCode: buyProduct.getRegionCurrencyCode(),
                        }}
                    />
                )}
            </Modal>

            {/* Take Actions Modal */}
            <Modal
                title={getModalTitle(modalOpenType)}
                open={modalOpenType === "take_actions_buy_product"}
                confirmLoading={isLoading}
                destroyOnClose={true}
                width={getModalWidth(modalOpenType)}
                onCancel={onCancel}
                footer={false}
            >
                {buyProduct && modalOpenType === "take_actions_buy_product" && (
                    <Suspense fallback={<Spin />}>
                        <TakeActionsTable
                            handleModal={handleModal}
                            selectedProduct={buyProduct}
                        />
                    </Suspense>
                )}
            </Modal>

            {/* Approve RFQ Product Modal */}
            <Modal
                title={getModalTitle(modalOpenType)}
                open={modalOpenType === "approve_rfq_product"}
                confirmLoading={isLoading}
                destroyOnClose={true}
                width={getModalWidth(modalOpenType)}
                onCancel={onCancel}
                footer={false}
            >
                {buyProduct && modalOpenType === "approve_rfq_product" && (
                    <Suspense fallback={<Spin />}>
                        <ApproveRfqProductModal
                            handleModal={handleModal}
                            productId={Number(buyProduct.getId())}
                        />
                    </Suspense>
                )}
            </Modal>

            {/* Update Shipping Price Modal */}
            <Modal
                title={getModalTitle(modalOpenType)}
                open={modalOpenType === "update_shipping_price_buy_product"}
                confirmLoading={isLoading}
                destroyOnClose={true}
                width={getModalWidth(modalOpenType)}
                onCancel={onCancel}
                footer={false}
            >
                {buyProduct &&
                    modalOpenType === "update_shipping_price_buy_product" && (
                        <Suspense fallback={<Spin />}>
                            <UpdateShippingPrice
                                handleModal={handleModal}
                                selectedProduct={buyProduct}
                            />
                        </Suspense>
                    )}
            </Modal>

            {/* Update Agent Category Price Modal */}
            <Modal
                title={getModalTitle(modalOpenType)}
                open={modalOpenType === "update_agent_category_price_read"}
                confirmLoading={isLoading}
                destroyOnClose={true}
                width={getModalWidth(modalOpenType)}
                onCancel={onCancel}
                footer={false}
            >
                {buyProduct &&
                    modalOpenType === "update_agent_category_price_read" && (
                        <Suspense fallback={<Spin />}>
                            <ShippingUpdateModal
                                shipping={buyProduct.getMoveonShipping()}
                                userId={buyProduct.getUserId()}
                                region={buyProduct.getRegion()}
                                buyProductId={buyProduct.getId()}
                                handleModal={handleModal}
                            />
                        </Suspense>
                    )}
            </Modal>

            <Modal
                title={getModalTitle(modalOpenType)}
                open={modalOpenType === "approve_and_assign_agent"}
                confirmLoading={isLoading}
                destroyOnClose={true}
                width={"90%"}
                onCancel={onCancel}
                footer={false}
            >
                {buyProduct && modalOpenType === "approve_and_assign_agent" && (
                    <Suspense fallback={<Spin />}>
                        <BuyProductAgentAssignModal
                            handleModal={handleModal}
                            selectedRowKeys={selectedRowKeys}
                            productId={Number(buyProduct.getId())}
                            storeId={buyProduct.getStore()?.getId()}
                            isBulk={false}
                        />
                    </Suspense>
                )}
            </Modal>

            <Modal
                open={modalOpenType === "cancel_buy_product"}
                onCancel={onCancel}
                footer={null}
                destroyOnClose={true}
                title={"Cancel Product"}
            >
                {buyProduct && (
                    <CancelFormBuyProduct
                        onCancel={onCancel}
                        buyProductId={Number(buyProduct.id)}
                    />
                )}
            </Modal>
        </>
    );
};

export default BuyProductsModalContainerForActions;
