import { Rate, Typography, Space, Tag, Popover, Flex } from "antd";
import { ProductDetailExtendedModel } from "@/models/productDetailExtendedModel";
import ShippingText from "@/components/ShippingText";
import { originalLinkToMoveOnLinkConversion } from "@/utils/helpers";

const { Text, Link, Title } = Typography;

interface IProps {
    product: ProductDetailExtendedModel;
}

const ProductHeader = ({ product }: IProps) => {
    const globalUrl = originalLinkToMoveOnLinkConversion(
        product.getId(),
        product.getTitle(),
    );

    return (
        <>
            <Space direction="vertical" style={{ width: "100%" }}>
                <Title level={5}>{product.getTitle()}</Title>
                <Space size="large" align="center">
                    <Rate
                        style={{ fontSize: 16 }}
                        defaultValue={product.getRatingsAverage()}
                        disabled
                    />
                    <Text>{`${product.getRatingsCount() || 0} reviews`}</Text>
                    <Text>
                        Sales: <Text strong>{product.getSales()}</Text>
                    </Text>
                    <Text>
                        In Stock: <Text strong>{product.getStock()}</Text>
                    </Text>
                    {product.getVendorLink() && (
                        <Popover
                            content={
                                <Text copyable>{product.getVendorLink()}</Text>
                            }
                        >
                            <Link
                                href={`${product.getVendorLink()}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Original Product Link
                            </Link>
                        </Popover>
                    )}
                </Space>
                <Flex vertical gap={8}>
                    {/* <ShippingText label="Discount Price">
                    <Tag color="magenta-inverse">
                        {product.getPrice().getBasePriceTargetCurrency()}{" "}
                        {product.getPrice().getDiscountPrice()}

                    </Tag>
                </ShippingText> */}
                    <ShippingText label="Source Price">
                        <Tag color="blue-inverse">
                            {product.getStore()?.getCurrencyCode()}{" "}
                            {product.getPrice().getOriginalRawPrice()}
                        </Tag>
                    </ShippingText>
                    <Text copyable={{ text: globalUrl }}>
                        {" "}
                        <a target="_blank" href={globalUrl}>
                            MoveOn Global link
                        </a>
                    </Text>
                </Flex>
            </Space>
        </>
    );
};

export default ProductHeader;
