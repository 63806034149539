import { useGetProductById } from "@/lib/core-react/hooks/private/useProduct";
import { Button, Card, Flex, Form, notification, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import { Input } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { useNavigate } from "react-router-dom";
import { getErrorMessage } from "@/helpers/getErrorMessages";
import { defaultQuery } from "@/consts/defaultQuery";
import { Link } from "react-router-dom";
import { useAtom } from "jotai";
import { inventoryProductDetailAtom } from "@/lib/core-react/store/store";
import GetProductLink from "./GetProductLink";
import { useState } from "react";

const MATCH = {
    PRODUCTS: "products",
};

const OriginalProductLink = () => {
    const navigate = useNavigate();
    const [form] = useForm();
    const { getProductDetailById, isLoading } = useGetProductById();

    const [productDetailResult] = useAtom(inventoryProductDetailAtom);
    const [vendorLink, setVendorLink] = useState<string | null>(null);

    const onFinish = (values) => {
        handleSearch(values.product_link);
    };

    const handleSearch = async (submittedUrl) => {
        try {
            if (submittedUrl) {
                const urlParams = new URLSearchParams(
                    new URL(submittedUrl).search,
                );
                const embeddedUrl = urlParams.get("url");

                if (embeddedUrl) {
                    setVendorLink(embeddedUrl);
                    return;
                }

                const regex = /^[A-Z0-9]{13}/;
                const match = submittedUrl.match(regex);
                const trimUrl = submittedUrl.trim().split("/").filter(Boolean);
                const lastPart = match
                    ? match[0]
                    : trimUrl
                          .filter(Boolean)
                          .map((slug, index) => {
                              if (slug === MATCH.PRODUCTS) {
                                  return trimUrl[index + 1].split("-")[0];
                              }
                          })
                          .filter(Boolean)
                          .join("");

                if (!lastPart) {
                    throw new Error(
                        "Please Provide a Valid Moveon Product Detail URL",
                    );
                }

                const firstPart = trimUrl[trimUrl.length - 1];
                const productId = `${firstPart}${lastPart}`;

                await getProductDetailById(productId, {
                    region: defaultQuery.region,
                    locale: defaultQuery.locale,
                    apply_exchange: 1,
                });
            }
        } catch (error) {
            notification.error({
                message: getErrorMessage(error),
            });
        }
    };

    const handleValuesChange = () => {
        // Clear vendorLink whenever the input value changes
        setVendorLink(null);
    };

    return (
        <PageHeader
            onBack={() => navigate(-1)}
            title={"Find Original Product Link"}
        >
            <Flex
                vertical
                gap={24}
                style={{
                    marginTop: 20,
                }}
            >
                <Form
                    onFinish={onFinish}
                    onValuesChange={handleValuesChange}
                    form={form}
                >
                    <Flex gap={12}>
                        <Form.Item
                            style={{ width: "100%" }}
                            name={"product_link"}
                        >
                            <Input
                                style={{ width: "100%" }}
                                placeholder="Paste Moveon Product URL"
                                allowClear
                            />
                        </Form.Item>

                        <Button
                            loading={isLoading}
                            type="primary"
                            htmlType="submit"
                        >
                            Search
                        </Button>
                    </Flex>
                </Form>

                {(vendorLink || productDetailResult) && (
                    <Flex gap={16} vertical>
                        <Flex vertical gap={12}>
                            <Card>
                                <Flex vertical gap={8}>
                                    <Typography>
                                        <Typography.Text>
                                            Original Link:{" "}
                                        </Typography.Text>
                                        <Link
                                            target="_blank"
                                            to={`${vendorLink || productDetailResult?.getVendorLink()}`}
                                        >
                                            <Typography.Link copyable strong>
                                                {vendorLink ||
                                                    productDetailResult?.getVendorLink()}
                                            </Typography.Link>
                                        </Link>
                                    </Typography>
                                </Flex>
                            </Card>
                        </Flex>
                    </Flex>
                )}
            </Flex>

            <GetProductLink />
        </PageHeader>
    );
};

export default OriginalProductLink;
