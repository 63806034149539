import { useState, useEffect } from "react";
import {
    Modal,
    Button,
    Typography,
    Spin,
    Divider,
    Row,
    Col,
    message,
    notification,
} from "antd";
import { useGetDisputeSettlementPreview } from "@/lib/core-react/hooks/private/useDispute";
import { DisputeSettlementDataModel } from "@/models/disputeSettlementPreviewModel";

const { Title, Text } = Typography;

const DisputeSettlementPreviewModal = ({
    isVisible,
    onClose,
    disputeId,
    onCompleteRefund,
    currency,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isCompleting, setIsCompleting] = useState(false);
    const [previewData, setPreviewData] =
        useState<DisputeSettlementDataModel>();
    const { getDisputeSettlementPreview } = useGetDisputeSettlementPreview();

    useEffect(() => {
        if (isVisible) {
            fetchPreviewData();
        }
    }, [isVisible]);

    const fetchPreviewData = async () => {
        setIsLoading(true);
        try {
            const response = await getDisputeSettlementPreview(disputeId);
            setPreviewData(response.data);
        } catch (_error) {
            notification.error({
                message: "Error",
                description: "Failed to fetch dispute settlement preview data",
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleCompleteRefund = async () => {
        setIsCompleting(true);
        try {
            await onCompleteRefund(disputeId);
            message.success("Refund completed successfully.");
            onClose();
        } catch (_error) {
            /* empty */
        } finally {
            setIsCompleting(false);
        }
    };

    const renderDataRow = (label, value) => (
        <Row style={{ marginBottom: 16 }}>
            <Col span={12}>
                <Text strong>{label}:</Text>
            </Col>
            <Col span={12}>
                <Text>
                    {value} {currency}
                </Text>
            </Col>
        </Row>
    );

    const renderContent = () => {
        if (isLoading) {
            return (
                <div style={{ textAlign: "center", padding: "20px" }}>
                    <Spin size="large" />
                </div>
            );
        }

        if (!previewData) {
            return <Text>No preview data available</Text>;
        }

        return (
            <>
                <Title level={4}>Purchase</Title>
                {renderDataRow(
                    "Agent Agreed",
                    previewData?.purchase?.agent_agreed ?? "N/A",
                )}
                {renderDataRow(
                    "Moveon Agreed",
                    previewData?.purchase?.moveon_agreed.toFixed(2) ?? "N/A",
                )}
                {renderDataRow(
                    "Customer Receive",
                    previewData?.purchase?.customer_receive.toFixed(2) ?? "N/A",
                )}

                <Divider />

                <Title level={4}>Summary</Title>
                {renderDataRow(
                    "Customer Total Receive",
                    previewData?.summary?.customer_total_receive.toFixed(2) ??
                        "N/A",
                )}
                {renderDataRow(
                    "Moveon Total Agreed",
                    previewData?.summary?.moveon_total_agreed.toFixed(2) ??
                        "N/A",
                )}

                {previewData.shipping && previewData.shipping.length > 0 && (
                    <>
                        <Divider />
                        <Title level={4}>Shipping</Title>
                        <Text>
                            Shipping information is available but not displayed
                            in this preview.
                        </Text>
                    </>
                )}
            </>
        );
    };

    return (
        <Modal
            title="Dispute Settlement Preview"
            visible={isVisible}
            onCancel={onClose}
            footer={[
                <Button key="cancel" onClick={onClose} disabled={isCompleting}>
                    Cancel
                </Button>,
                <Button
                    key="complete"
                    type="primary"
                    onClick={handleCompleteRefund}
                    loading={isCompleting}
                    disabled={isLoading}
                >
                    Complete Refund
                </Button>,
            ]}
            width={500}
            closable={!isCompleting}
            maskClosable={!isCompleting}
        >
            {renderContent()}
        </Modal>
    );
};

export default DisputeSettlementPreviewModal;
