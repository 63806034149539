import {
    Alert,
    Button,
    Card,
    Col,
    Dropdown,
    Empty,
    Flex,
    Form,
    Modal,
    notification,
    Row,
    Space,
    Tooltip,
    Typography,
} from "antd";
import { useAtom } from "jotai/index";
import {
    useGetBuyProduct,
    useRejectBuyProduct,
    useSetCurrentFx,
} from "@/lib/core-react/hooks/private/usePurchase";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PageHeader } from "@ant-design/pro-layout";
import { EditOutlined, HddFilled, LoadingOutlined } from "@ant-design/icons";
import { buyProductAtom } from "@/lib/core-react/store/buyProducts/buyProductsAtoms";
import { BuyProductModel } from "@/models/buyProductCollectionModel";
import CommonError from "@/components/Error/CommonError";
import BuyProductInfo from "../components/BuyProductInfo";
import { tw } from "@/consts/theme/tailwindTheme";
import { useBuyProductActions } from "./useActions";
import { IModalActionsType, IModalData } from "@/helpers/getModalTital";
import BuyProductVariationsTable from "../components/BuyProductVariationsTable";
import CustomerInfo from "./components/CustomerInfo";
import OrderInfo from "../components/OrderInfo";
import BuyProductDetailTitle from "../components/BuyProductDetailTitle";
import useWindowWidth from "@/lib/core-react/hooks/public/useWindowWidth";
import { TimelineTracking } from "@/pages/Shipping/newComponents/TimelineTracking";
import { useBuyProductGetTimelineTracking } from "@/lib/core-react/hooks/private";
import { buyProductTimelineTrackingAtom } from "@/lib/core-react/store/buyOrderAtoms/buyOrderAtoms";
import { TimelineTrackingModel } from "@/models/timelineTrackingModel";
import BuyProductsModalContainerForActions from "./BuyProductsModalContainerForActions";
import { showError } from "@/helpers/showError";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ADMIN_PURCHASE_PERMISSION_ENUM } from "@/consts/permission-enum/purchase-enum";
import BuyProductShippingInfo from "../components/BuyProductShippingInfo";
import Invoice from "../components/Invoice";
import ActionViewDetailsModal from "../ActionsNeeded/ActionViewDetailsModal";
import LoaderSpin from "@/components/LoaderSpin";
import useRefetch from "@/hooks/useRefetch";
import { RejectPayload } from "@/types/buyOrderCollection";
import RejectProductModal from "@/pages/Shipping/components/handleRejectModal";
import useDataFilters from "@/hooks/useDataFilters";
import {
    ExpiredUnit,
    IImpersonateSessionData,
    IImpersonationSession,
    UserType,
} from "@/types/userCollection";
import ImpersonateCreateModalTitle from "@/components/ImpersonateFormModalTitle";
import { RegionModel } from "@/models/regionCollectionModel";
import { PayCustomerInvoiceModal } from "@/components/PayCustomerInvoice";
import { useInvoice } from "@/lib/core-react/hooks/private/useInvoice";
import { IPaymentSessionPayPayload } from "@/types/paymentSession";
import CustomerShippingAddress from "./components/CustomerShippingAddress";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";
import { responseTypeQuery } from "@/filters/constant";
import {
    useCreateImpersonateSession,
    useGetRole,
} from "@/lib/core-react/hooks/private/useRole";
import { downloadImpersonateCredentialsAsTextFile } from "@/utils/helper";
import ImpersonateResultView from "@/components/ImpersonateSessionCreate/impersonateResultView";
import { RoleModel } from "@/models/roleCollectionModel";
import useOryAuth from "@/lib/core-react/utils/useOryAuth";
import ImpersonateIcon from "@/assets/ImpersonateIcon";
import BuyProductCartoonAndPackage from "../components/BuyProductCatoonAndPackage";
import { BuyProductPackage } from "../components/BuyProductPackage";
import { RateTypeEnums } from "@/enums/shippingCoreEnums";
import { ICommonUser } from "@/types/shared";
const BuyProductDetail = () => {
    const [form] = Form.useForm(); // pay now form

    const { createImpersonate, isLoading: impersonateLoading } =
        useCreateImpersonateSession();
    const { getRole } = useGetRole();

    const { id } = useParams();
    const { isMobile } = useWindowWidth();

    const { Text, Paragraph } = Typography;
    const { handelSubFilterClear } = useDataFilters();
    const { getBuyProduct } = useGetBuyProduct();
    const { paySession, isLoading: isLoadingPaySession } = useInvoice();
    const { setCurrentFx } = useSetCurrentFx();
    const { rejectBuyProduct, isLoading: isRejectedLoading } =
        useRejectBuyProduct();
    const { refetchDetailApi } = useRefetch();
    const { getTimelineTracking } = useBuyProductGetTimelineTracking();
    const [isShowModal, setIsShowModal] = useState(false);
    const [selectedCustomerUser, setSelectedCustomerUser] = useState<ICommonUser>();
    const [impersonateData, setImpersonateData] =
        useState<IImpersonateSessionData>();
    const [selectedPayInvoiceData, setSelectedPayInvoiceData] = useState<{
        region: RegionModel;
        user_id: number;
        token: string;
    }>();
    const [modalOpenType, setModalOpenType] =
        useState<IModalActionsType>(false);
    const [selectedbuyProduct, setSelectedProduct] = useState<
        BuyProductModel | undefined
    >(undefined);

    const [
        { data: timelineTrackingData, isLoading: isLoadingTimelineTracking },
    ] = useAtom(buyProductTimelineTrackingAtom);

    const [
        { data: buyProductDetailData, error, isLoading, code, unAuthorized },
    ] = useAtom(buyProductAtom);

    const {
        allOptionsData: { adminOptionsData },
        onFetchFilterApi,
    } = useFiltersApiData();

    const handleTracking = (productId: number) => {
        getTimelineTracking(productId);
    };

    const { getUserEmail } = useOryAuth();

    const email = getUserEmail();

    useEffect(() => {
        if (email) {
            onFetchFilterApi(filterResourceEnum.ADMIN, {
                keyword: email,
                ...responseTypeQuery.minimal,
            });
        }
    }, [email]);

    const onFinish = async (user: ICommonUser) => {
        setSelectedCustomerUser(user);
        let roleIds: number[] = [];
        if (user.type === UserType.Customer) {
            const roleCollectionData = await getRole(
                `is_customer=1&response_type=minimal`,
            );

            if (roleCollectionData) {
                const customerRole = roleCollectionData?.find(
                    (role: RoleModel) =>
                        role.name.toLowerCase() === "customer-role" ||
                        role.name.toLowerCase() === "customer role",
                );
                roleIds = [customerRole.id];
            }
        }
        try {
            const payload: IImpersonationSession = {
                user_type: UserType.Customer,
                impersonatee_id: user.id,
                impersonator_id: adminOptionsData.options[0].value as number,
                expired_unit: ExpiredUnit.Hour,
                expired_value: 1,
                intent: "",
                role_ids: roleIds,
            };

            const response = await createImpersonate(payload);
            setImpersonateData(response);

            if (response) {
                downloadImpersonateCredentialsAsTextFile(response);
                setIsShowModal(true);
            }
        } catch (error: any) {
            showError(error);
        }
    };

    const handleModal = async (payload: IModalData) => {
        setModalOpenType(payload.action);
        switch (payload.action) {
            case "set_current_fx":
                await handleSetCurrentFx(payload.data.productId);
                break;

            case "reject_buy_product":
                setSelectedProduct(payload.data.productId);
                break;
            case "pay-invoice":
                setSelectedPayInvoiceData(payload.data);
                break;
            default:
                break;
        }
    };

    const handleSetCurrentFx = async (productId) => {
        if (productId) {
            Modal.confirm({
                title: "Are you sure to set the current FX ?",
                content: "Select Ok, otherwise the process cannot success.",
                onOk: async () => {
                    try {
                        await setCurrentFx(productId);

                        notification["success"]({
                            message: "Update current fx successfully",
                        });
                        await refetchDetailApi(productId);
                        return Promise.resolve();
                    } catch (_error) {
                        showError(_error);
                        return Promise.reject();
                    }
                },
                onCancel() {
                    notification["info"]({
                        message: "Product Reject was aborted.",
                    });
                },
            });
        }
    };

    const handleRejectOk = async (value: { reject_reason: string }) => {
        try {
            const payload: RejectPayload = {
                reject_reason: value.reject_reason,
            };
            if (!selectedbuyProduct) {
                throw new Error("Product id not found");
            }
            await rejectBuyProduct(Number(selectedbuyProduct.getId()), payload);
            notification.success({
                message: "Successfully rejected shipment product.",
            });
            setModalOpenType(false);
            await refetchDetailApi(selectedbuyProduct.getId());
        } catch (error) {
            showError(error);
        }
    };

    useEffect(() => {
        if (id) {
            getBuyProduct(Number(id));
        }
    }, [id]);

    const onFinishCustomerPay = async (values: IPaymentSessionPayPayload) => {
        if (selectedPayInvoiceData) {
            try {
                const { amount, gateway_id, wallet_id, ...restValues } = values;
                if (
                    typeof wallet_id === "undefined" &&
                    typeof gateway_id === "undefined"
                ) {
                    return notification.error({
                        message: "Please select a gateway or wallet",
                    });
                }

                const key = gateway_id ? "gateway_id" : "wallet_id";
                const id = gateway_id || wallet_id;

                const payload = {
                    amount,
                    [key]: id,
                    ...(Object.keys(restValues).length > 0 && {
                        gateway_data: { ...restValues },
                    }),
                };

                await paySession(
                    selectedPayInvoiceData.user_id,
                    selectedPayInvoiceData.token,
                    payload,
                );
                if (id) {
                    await refetchDetailApi(id);
                }
                setSelectedPayInvoiceData(undefined);
                setModalOpenType(false);
                notification.success({
                    message: "Payment initiated successfully",
                });
            } catch (error) {
                showError(error, form);
            }
        }
    };

    const buyProduct =
        buyProductDetailData && new BuyProductModel(buyProductDetailData);

    const moveonCommissionRate = buyProduct
        ?.getMoveonShipping()
        ?.getCommissionRate();

    const { getActions } = useBuyProductActions({
        handleModal,
    });

    if (isLoading) {
        return <LoaderSpin />;
    }

    if (!isLoading && error) {
        return (
            <CommonError
                unAuthorized={unAuthorized}
                message={error}
                code={code}
            />
        );
    }
    const onCancel = () => {
        handelSubFilterClear();
        handleModal({
            action: false,
            data: null,
        });
    };
    const onCancelModal = () => {
        setSelectedProduct(undefined);
        setModalOpenType(false);
        handleModal({
            action: false,
            data: undefined,
        });
    };

    return (
        <>
            <PageHeader
                ghost={false}
                style={{ width: "100%", paddingBottom: 32 }}
                title={buyProduct ? `#${buyProduct.getProductNumber()}` : ""}
                // onBack={() => window.history.back()}
                subTitle={<BuyProductDetailTitle buyProduct={buyProduct} />}
            >
                <BuyProductsModalContainerForActions
                    onCancel={onCancel}
                    selectedRowKeys={[]}
                    modalOpenType={modalOpenType}
                    buyProduct={buyProduct}
                    handleModal={handleModal}
                    isLoading={false}
                >
                    {buyProduct && (
                        <div>
                            <Flex
                                justify="end"
                                style={{
                                    marginBottom: 24,
                                    marginRight: isMobile ? 0 : 24,
                                }}
                            >
                                <Dropdown
                                    menu={{
                                        items: getActions(buyProduct),
                                    }}
                                >
                                    <Button>
                                        Actions <HddFilled />
                                    </Button>
                                </Dropdown>
                            </Flex>
                            <Flex vertical gap={24}>
                                <Row
                                    gutter={24}
                                    style={{
                                        width: "100%",
                                        margin: isMobile ? 0 : undefined,
                                    }}
                                >
                                    <Col
                                        span={isMobile ? 24 : 12}
                                        style={{
                                            padding: isMobile ? 0 : undefined,
                                        }}
                                    >
                                        <Flex
                                            style={{
                                                marginRight: isMobile ? 0 : 24,
                                            }}
                                            gap={24}
                                            vertical
                                        >
                                            <Card title="Product Info">
                                                <BuyProductInfo
                                                    buyProduct={buyProduct}
                                                />
                                            </Card>
                                            <Card>
                                                <BuyProductVariationsTable
                                                    isListPage={false}
                                                    isBuyOrderListPage={false}
                                                    buyProduct={buyProduct}
                                                />
                                            </Card>

                                            <Card title="Purchase">
                                                <Space
                                                    direction="vertical"
                                                    size={"small"}
                                                >
                                                    <Paragraph
                                                        style={{
                                                            margin: tw
                                                                .spacing[0],
                                                        }}
                                                    >
                                                        <Text>
                                                            Purchase by:
                                                        </Text>
                                                        {buyProduct.getPurchaseCompanyName() ? (
                                                            <Space>
                                                                <Text strong>
                                                                    {buyProduct.getPurchaseCompanyName()}
                                                                </Text>

                                                                {checkActionPermission(
                                                                    ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_APPROVE,
                                                                    <Button
                                                                        style={{
                                                                            padding: 0,
                                                                        }}
                                                                        type="link"
                                                                        onClick={() =>
                                                                            handleModal(
                                                                                {
                                                                                    action: "approve_and_assign_agent",
                                                                                    data: buyProduct,
                                                                                },
                                                                            )
                                                                        }
                                                                    >
                                                                        <EditOutlined />
                                                                    </Button>,
                                                                    null,
                                                                )}
                                                            </Space>
                                                        ) : (
                                                            <Text strong>
                                                                N/A
                                                            </Text>
                                                        )}
                                                    </Paragraph>

                                                    <Paragraph
                                                        style={{
                                                            margin: tw
                                                                .spacing[0],
                                                        }}
                                                    >
                                                        <Text>
                                                            Moveon Commission:
                                                        </Text>
                                                        <Text strong>
                                                            {moveonCommissionRate
                                                                ? `${parseFloat(moveonCommissionRate.toString())}${
                                                                      buyProduct
                                                                          .getMoveonShipping()
                                                                          ?.getCommissionRateType() ===
                                                                      RateTypeEnums.PERCENTAGE
                                                                          ? "%"
                                                                          : buyProduct.getRegionCurrencyCode()
                                                                  }
                    `
                                                                : "N/A"}
                                                        </Text>
                                                    </Paragraph>
                                                </Space>
                                            </Card>

                                            <Card title="Shipping">
                                                <BuyProductShippingInfo
                                                    handleModal={handleModal}
                                                    buyProductModel={buyProduct}
                                                />
                                            </Card>

                                            <Card title="Packages">
                                                {buyProduct
                                                    .getShipmentProduct()
                                                    ?.getPackages() ? (
                                                    <BuyProductCartoonAndPackage
                                                        packages={buyProduct
                                                            .getShipmentProduct()
                                                            ?.getPackages()}
                                                    />
                                                ) : (
                                                    <BuyProductPackage
                                                        buyProductPackage={buyProduct.getBuyProductPackage()}
                                                    />
                                                )}
                                            </Card>

                                            <Card
                                                styles={{
                                                    body: {
                                                        paddingTop: 0,
                                                    },
                                                }}
                                                title="Invoice"
                                            >
                                                <Invoice
                                                    customerInvoiceId={buyProduct.getCustomerInvoiceId()}
                                                    agentInvoiceId={buyProduct.getAgentInvoiceId()}
                                                />
                                            </Card>
                                        </Flex>
                                    </Col>

                                    <Col
                                        span={isMobile ? 24 : 12}
                                        style={{
                                            padding: isMobile ? 0 : undefined,
                                        }}
                                    >
                                        <Flex gap={24} vertical>
                                            <Card
                                                title="Customer"
                                                extra={
                                                    <div>
                                                        {checkActionPermission(
                                                            ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_IMPERSONATE_CREATE,
                                                            <Tooltip title="Click to impersonate this customer">
                                                                <Button
                                                                    type="dashed"
                                                                    onClick={() => {
                                                                      if(buyProduct.user){
                                                                        onFinish(
                                                                            buyProduct?.user,
                                                                        );
                                                                      }
                                                                    }}
                                                                    loading={
                                                                        impersonateLoading
                                                                    }
                                                                    style={{
                                                                        width: 30,
                                                                        height: 30,
                                                                        display:
                                                                            "flex",
                                                                        alignItems:
                                                                            "center",
                                                                        justifyContent:
                                                                            "center",
                                                                        border: "1px solid #FD384F",
                                                                        borderRadius:
                                                                            "50%",
                                                                        backgroundColor:
                                                                            "#FFF8F9",
                                                                    }}
                                                                    icon={
                                                                        impersonateLoading ? (
                                                                            <LoadingOutlined />
                                                                        ) : (
                                                                            <ImpersonateIcon
                                                                                width={
                                                                                    25
                                                                                }
                                                                                height={
                                                                                    15
                                                                                }
                                                                                style={{
                                                                                    marginTop:
                                                                                        -5,
                                                                                }}
                                                                            />
                                                                        )
                                                                    }
                                                                ></Button>
                                                            </Tooltip>,
                                                            null,
                                                        )}
                                                    </div>
                                                }
                                            >
                                                <CustomerInfo
                                                    user={buyProduct.getUser()}
                                                />
                                            </Card>
                                            <Card title="Shipping Address">
                                                <CustomerShippingAddress
                                                    shippingAddress={buyProduct.getShippingAddress()}
                                                />
                                            </Card>
                                            {buyProduct.getOrder() && (
                                                <Card title="Order">
                                                    <OrderInfo
                                                        oderNumber={buyProduct
                                                            .getOrder()
                                                            ?.getOrderNumber()}
                                                        oderStatus={buyProduct
                                                            .getOrder()
                                                            ?.getOrderStatus()}
                                                        oderType={buyProduct
                                                            .getOrder()
                                                            ?.getOrderType()}
                                                        orderCreatedAt={buyProduct
                                                            .getOrder()
                                                            ?.getCreatedAt()}
                                                        paymentStatus={buyProduct
                                                            .getOrder()
                                                            ?.getPaymentStatus()}
                                                    />
                                                </Card>
                                            )}
                                            <Card
                                                title="Actions"
                                                styles={{
                                                    body: {
                                                        padding: 0,
                                                    },
                                                }}
                                            >
                                                {" "}
                                                {buyProduct.getBuyActions()
                                                    .length ? (
                                                    <ActionViewDetailsModal
                                                        buyProductId={buyProduct.getId()}
                                                    />
                                                ) : (
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent:
                                                                "center",
                                                            alignItems:
                                                                "center",
                                                            height: "25vh",
                                                        }}
                                                    >
                                                        <Empty
                                                            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                                            imageStyle={{
                                                                height: 60,
                                                                marginTop: 10,
                                                            }}
                                                            description={
                                                                <Alert
                                                                    message="No Action in this product"
                                                                    type="info"
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                )}
                                            </Card>

                                            {buyProduct && (
                                                <Card title="Timeline">
                                                    <TimelineTracking
                                                        TimelineTrackingData={
                                                            timelineTrackingData &&
                                                            new TimelineTrackingModel(
                                                                timelineTrackingData,
                                                            )
                                                        }
                                                        productId={buyProduct.getId()}
                                                        productNumber={buyProduct.getProductNumber()}
                                                        isLoading={
                                                            isLoadingTimelineTracking
                                                        }
                                                        handleTracking={
                                                            handleTracking
                                                        }
                                                        title="Timeline"
                                                    />
                                                </Card>
                                            )}
                                        </Flex>
                                    </Col>
                                </Row>
                            </Flex>
                        </div>
                    )}
                </BuyProductsModalContainerForActions>
            </PageHeader>
            <Modal
                width={"40%"}
                open={modalOpenType === "reject_buy_product"}
                onCancel={() => {
                    handelSubFilterClear();
                    handleModal({
                        action: false,
                        data: null,
                    });
                }}
                footer={null}
                destroyOnClose={true}
                title={"Confirm Reject"}
            >
                {buyProduct && (
                    <RejectProductModal
                        onClose={onCancelModal}
                        handleOk={handleRejectOk}
                        loading={isRejectedLoading}
                    />
                )}
            </Modal>
            {isShowModal && impersonateData && (
                <Modal
                    title={
                        <ImpersonateCreateModalTitle
                            name={selectedCustomerUser?.name || ""}
                            email={selectedCustomerUser?.email || ""}
                            title="Impersonate Customer"
                        />
                    }
                    open={isShowModal}
                    onCancel={() => setIsShowModal(false)}
                    footer={null}
                    width={600}
                >
                    <ImpersonateResultView response={impersonateData} />
                </Modal>
            )}

            <Modal
                width={"50%"}
                title="Pay Customer Invoice"
                open={
                    modalOpenType === "pay-invoice" &&
                    Boolean(selectedPayInvoiceData)
                }
                onCancel={() => {
                    handelSubFilterClear();
                    handleModal({
                        action: false,
                        data: null,
                    });
                }}
                onOk={form.submit}
                okText="Submit"
                okButtonProps={{ loading: isLoadingPaySession }}
                centered
                destroyOnClose={true}
            >
                {selectedPayInvoiceData && (
                    <PayCustomerInvoiceModal
                        form={form}
                        data={selectedPayInvoiceData}
                        onFinish={onFinishCustomerPay}
                    />
                )}
            </Modal>
        </>
    );
};
export default BuyProductDetail;
