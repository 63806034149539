import { BaseResource } from "../public";
import { ResponsePromise } from "@/lib/core/request/types/typings";
import qs from "qs";
import { IPayoutGateWayFormRequest } from "@/types/payoutGatewayCollection";

class WalletResource extends BaseResource {
    pathBalance = `/api/wallet/admin/wallet/v1/master/balance`;
    path = `/api/wallet/admin/wallet/v1`;
    pathPayoutGateWay = `/api/wallet/admin/payout-gateway/v1/payout-gateways`;
    pathPayoutRequests = `/api/wallet/admin/payout-request/v1/payout-requests`;
    pathPayoutRequestUpdate = `/api/wallet/admin/payout-request/v1/payout-requests`;

    getOverviewBalance(
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.pathBalance}`;
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    getTransactions(
        walletId: number,
        filters?: { [key: string]: any },
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        let path = `${this.path}/master/${walletId}/transactions`;
        if (filters && Object.keys(filters).length > 0) {
            path = `${path}?${qs.stringify(filters)}`;
        }
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    getOverviewAgentCompanyBalance(
        agentCompanyId: number,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}/agent-company/${agentCompanyId}/balance`;
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    getTransactionsAgentCompany(
        agentCompanyId: number,
        walletId: number,
        params?: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        let path = `${this.path}/agent-company/${agentCompanyId}/${walletId}/transactions`;
        if (params) {
            path = `${path}?${params}`;
        }
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    getOverviewUserBalance(
        userId: number,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}/user/${userId}/balance`;
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    getTransactionsUser(
        userId: number,
        walletId: number,
        params?: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        let path = `${this.path}/user/${userId}/${walletId}/transactions`;
        if (params) {
            path = `${path}?${params}`;
        }
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    //Payout Gateways
    getPayoutGateWays(
        params?: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        let path = `${this.pathPayoutGateWay}`;
        if (params) {
            path = `${path}?${params}`;
        }
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    createPayoutGateWays(
        payload: IPayoutGateWayFormRequest,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.pathPayoutGateWay}`;
        return this.client.request("POST", path, payload, {}, customHeaders);
    }

    updatePayoutGateWays(
        id: number,
        payload: IPayoutGateWayFormRequest,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.pathPayoutGateWay}/${id}`;
        return this.client.request("PUT", path, payload, {}, customHeaders);
    }

    //Payout Gateways For Customer
    getPayoutGateWaysForCustomer(
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.pathPayoutGateWay}`;
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    //Payout requests
    getPayoutRequests(
        params?: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        let path = `${this.pathPayoutRequests}`;
        if (params) {
            path = `${path}?${params}`;
        }
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    updatePayoutRequest(
        id: number,
        action: "processing" | "reject",
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.pathPayoutRequests}/${id}/${action}`;
        return this.client.request("PUT", path, {}, undefined, customHeaders);
    }

    approvePayoutRequest(
        id: number,
        payload: {
            approved_amount: number;
            admin_note: null | string;
        },
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.pathPayoutRequests}/${id}/approve`;
        return this.client.request("PUT", path, payload, {}, customHeaders);
    }
}

export default WalletResource;
