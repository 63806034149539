import { useEffect } from "react";
import { PageHeader } from "@ant-design/pro-layout";
import { Col, Row, Table, } from "antd";
import { useAtom } from "jotai";
import { purchaseCartCollectionAtom } from "@/lib/core-react/store/store";
import useDataFilters from "@/hooks/useDataFilters";

import { useGetCartList } from "@/lib/core-react/hooks/private/usePurchaseCart";
import { IFilterType } from "@/types/filters";
import { PaginationModel } from "@/models/pagination";
import FiltersComponent from "@/components/FiltersComponent";
import CommonError from "@/components/Error/CommonError";
import { getPurchaseCartColumns } from "./component/purchaseCartTable";


const PurchaseCart = () => {
    const { getCartList } = useGetCartList();
    const [
        {
            data: purchaseCartCollectionData,
            isLoading,
            error,
            unAuthorized,
            code,
        },
    ] = useAtom(purchaseCartCollectionAtom);

    const {
        filters,
        handleFilterChange,
        handelFilterClear,
        isFirstCall,
        isFetched,
        initializeAvailableFilter,
        refetch: refetchFromFilter,
    } = useDataFilters();

    // Api Call
    useEffect(() => {
        if ((filters && !isFetched && isFirstCall) || refetchFromFilter) {
            getCartList(filters as IFilterType);
        }
    }, [isFirstCall, filters, isFetched, refetchFromFilter]);

    const filterData = purchaseCartCollectionData?.filters;

    useEffect(() => {
        if (!isFetched && filterData) {
            initializeAvailableFilter(filterData as IFilterType);
        }
    }, [isFetched, initializeAvailableFilter, filterData]);

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };

        handleFilterChange(pageInfo); // Updates the state

        // Call the API directly with updated page info
        getCartList({ ...filters, ...pageInfo } as IFilterType);
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        purchaseCartCollectionData,
        handlePaginationChange,
    );

    // Filter Handler
    const handleProductFilter = () => {
        getCartList(filters as IFilterType);
    };

    const columns = getPurchaseCartColumns(purchaseCartCollectionData?.pagination);


    if (!isLoading && error) {
        return (
            <CommonError
                unAuthorized={unAuthorized}
                message={error}
                code={code}
            />
        );
    }

    return (
        <>
            <Row>
                <Col span={24}>
                    <PageHeader
                        ghost={false}
                        style={{ marginTop: "10px" }}
                        title={"Purchase Carts"}
                        onBack={() => window.history.back()}
                    >
                        {filters && Object.keys(filters).length > 0 && (
                            <Row>
                                <Col style={{ marginBottom: "10px" }} span={24}>
                                    <FiltersComponent
                                        handleProductFilter={
                                            handleProductFilter
                                        }
                                        handleFilterChange={handleFilterChange}
                                        handelFilterClear={handelFilterClear}
                                        isFetched={isFetched}
                                        filters={filters}
                                        filtersData={filterData}
                                        isFromProductReceived={true}
                                    />
                                </Col>
                            </Row>
                        )}

                        <Table
                            columns={columns}
                            dataSource={purchaseCartCollectionData?.getProcessData()}
                            loading={isLoading}
                            bordered={true}
                            pagination={paginationConfig}
                            rowKey="id"
                            style={{ marginTop: 10 }}
                            scroll={{ x: 1440 }}
                        />
                    </PageHeader>
                </Col>
            </Row>
        </>
    );
};

export default PurchaseCart;
