import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { PageHeader } from "@ant-design/pro-layout";
import {
    Button,
    Col,
    Descriptions,
    Dropdown,
    Flex,
    Image,
    Popconfirm,
    Row,
    Space,
    Table,
    TableColumnsType,
    Tag,
    Tooltip,
    Typography,
} from "antd";
import moment from "moment";

import { disputeCollectionAtom } from "@/lib/core-react/store/store";
import useDataFilters from "@/hooks/useDataFilters";
import { IFilterType } from "@/types/filters";
import { PaginationModel } from "@/models/pagination";
import { ApiHelperModel } from "@/models/apiHelper";
import FiltersComponent from "@/components/FiltersComponent";
import {
    useCompleteRefund,
    useDeleteDispute,
    useGetDisputes,
} from "@/lib/core-react/hooks/private/useDispute";
import { StatusTag } from "@/components";
import {
    DisputeCollectionModel,
    DisputeModel,
} from "@/models/disputeCollectionModel";
import {
    CheckCircleOutlined,
    DownOutlined,
    EyeFilled,
    HddFilled,
    PlusOutlined,
} from "@ant-design/icons";
import useWindowWidth from "../../../lib/core-react/hooks/public/useWindowWidth";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ADMIN_REFUND_PERMISSION_ENUM } from "@/consts/permission-enum/refund-enum";
import CommonError from "../../../components/Error/CommonError";
import Paragraph from "antd/lib/typography/Paragraph";
import StoreIcon from "@/assets/storeIcon";
import { tw } from "@/consts/theme/tailwindTheme";
import { showError } from "@/helpers/showError";
import { ExtendedMenuItemType } from "@/types";
import { FiTrash } from "react-icons/fi";
import DisputeSettlementPreviewModal from "./components/DisputeSettlementPreviewModal";
import { truncateText } from "@/utils/helpers";
import CustomerDetailsViews from "@/pages/CustomerManage/User/components/CustomerDetailsViews";
const { Text } = Typography;

const RefundDispute = () => {
    const navigate = useNavigate();
    const { getDisputes } = useGetDisputes();
    const { deleteDispute } = useDeleteDispute();
    const { completeRefund } = useCompleteRefund();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedDisputeId, setSelectedDisputeId] = useState<number>();

    const { isMobile } = useWindowWidth();
    const [
        {
            data: disputeCollectionData,
            isLoading: disputeLoading,
            refetch,
            unAuthorized,
        },
    ] = useAtom(disputeCollectionAtom);
    const disputeCollectionModelData =
        disputeCollectionData &&
        new DisputeCollectionModel(disputeCollectionData);

    const {
        filters,
        handleFilterChange,
        handelFilterClear,
        isFirstCall,
        isFetched,
        initializeAvailableFilter,
        refetch: refetchFromFilter,
    } = useDataFilters();

    // Api Call
    useEffect(() => {
        if ((!isFetched && isFirstCall) || refetch || refetchFromFilter) {
            ApiHelperModel.makeGetRequest({}, getDisputes);
        }
    }, [isFirstCall, isFetched, refetch, refetchFromFilter]);

    const filterData = disputeCollectionModelData?.getFilters();

    // Getting all available filters
    useEffect(() => {
        if (!isFetched && disputeCollectionModelData?.filters) {
            initializeAvailableFilter(filterData as IFilterType);
        }
    }, [
        isFetched,
        initializeAvailableFilter,
        disputeCollectionModelData?.getFilters(),
    ]);

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getDisputes,
        );
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        disputeCollectionModelData,
        handlePaginationChange,
    );

    // Filter Handler
    const handleProductFilter = () => {
        ApiHelperModel.makeGetRequest(filters, getDisputes);
    };

    const disputeLists = disputeCollectionModelData?.getData();

    // delete refund
    const handleDeleteDispute = async (id: number) => {
        try {
            await deleteDispute(id);
            ApiHelperModel.makeGetRequest({}, getDisputes);
        } catch (error) {
            showError(error);
        }
    };

    // Update the handleCompleteRefund function
    const handleCompleteRefund = async (id: number) => {
        setSelectedDisputeId(id);
        setIsModalVisible(true);
    };

    const onConfirmCompleteRefund = async (id: number) => {
        try {
            await completeRefund(id);
            ApiHelperModel.makeGetRequest({}, getDisputes);
        } catch (error) {
            showError(error);
        }
    };

    if (unAuthorized) {
        return <CommonError unAuthorized={unAuthorized} />;
    }

    const columns: TableColumnsType<DisputeModel> = [
        {
            title: "#ID",
            dataIndex: "id",
            width: 60,
            key: "id",
            align: "center" as const,
            render: (_: string, _record, index: number) => {
                return <div key={index}>{_record.getId()}</div>;
            },
        },

        {
            title: "Dispute info",
            dataIndex: "dispute_id",
            width: 270,
            key: "id",
            render: (_tags, record) => {
                const disputeNumber = record?.getDisputeNumber();
                const regionCode = record?.getRegionCode();
                return (
                    <Row>
                        <Col>
                            <Space direction="vertical" size={2}>
                                <Descriptions size="small" column={1}>
                                    <Descriptions.Item
                                        label={
                                            <span
                                                style={{ fontWeight: "bold" }}
                                            >
                                                D/N
                                            </span>
                                        }
                                    >
                                        <Text copyable>{disputeNumber}</Text>
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label={
                                            <span
                                                style={{ fontWeight: "bold" }}
                                            >
                                                Region
                                            </span>
                                        }
                                    >
                                        <StatusTag
                                            slug={regionCode}
                                            text={regionCode}
                                        />
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label={
                                            <span
                                                style={{ fontWeight: "bold" }}
                                            >
                                                Status
                                            </span>
                                        }
                                    >
                                        <StatusTag
                                            slug={record.getStatus()}
                                            text={record.getStatus()}
                                        />
                                    </Descriptions.Item>
                                </Descriptions>
                            </Space>
                        </Col>
                    </Row>
                );
            },
        },
        {
            title: "Buy Product info",
            dataIndex: "buy_product",
            width: 360,
            key: "id",
            render: (_tags: any, record) => {
                const productTitle = record.getBuyProduct().getProductTitle();
                const productLink = record.getBuyProduct().getProductLink();
                const status = record.getBuyProduct().getStatus();
                const productImage = record.getBuyProduct().getProductImage();
                const productNumber = record.getBuyProduct().getProductNumber();
                const orderHandlerName = record
                    .getBuyProduct()
                    ?.getOrder()
                    ?.getOrderHandler()
                    ?.getName();
                const orderNumber = record
                    .getBuyProduct()
                    ?.getOrder()
                    ?.getOrderNumber();
                const storeName = record.getBuyProduct()?.getStore()?.getName();
                const sellerName = record
                    .getBuyProduct()
                    ?.getProductSeller()?.seller_name;
                return (
                    <>
                        <div>
                            <Space direction="vertical">
                                <Flex gap={14}>
                                    <div>
                                        <Image
                                            style={{
                                                borderRadius:
                                                    tw.borderRadius.lg,
                                            }}
                                            width={80}
                                            src={productImage}
                                        />
                                    </div>

                                    <Space direction="vertical">
                                        <Paragraph
                                            strong
                                            style={{ margin: tw.spacing[0] }}
                                            copyable
                                        >
                                            {productNumber}
                                        </Paragraph>
                                        <Paragraph
                                            style={{ margin: tw.spacing[0] }}
                                        >
                                            <Text style={{ fontWeight: "600" }}>
                                                {" "}
                                                Status:{" "}
                                            </Text>
                                            <StatusTag
                                                slug={status}
                                                text={status}
                                            />
                                        </Paragraph>

                                        <Paragraph
                                            style={{ margin: tw.spacing[0] }}
                                        >
                                            <Text style={{ fontWeight: "600" }}>
                                                {" "}
                                                Order Number:{" "}
                                            </Text>

                                            <Text copyable>{orderNumber}</Text>
                                        </Paragraph>
                                        <Paragraph
                                            style={{ margin: tw.spacing[0] }}
                                        >
                                            <Text style={{ fontWeight: "600" }}>
                                                {" "}
                                                Order Handler:{" "}
                                            </Text>

                                            <Text>{orderHandlerName}</Text>
                                        </Paragraph>
                                        <Paragraph
                                            style={{ margin: tw.spacing[0] }}
                                        >
                                            <Flex align="center">
                                                <Text
                                                    style={{
                                                        fontWeight: "600",
                                                    }}
                                                >
                                                    {" "}
                                                    Store:{" "}
                                                </Text>
                                                <StoreIcon
                                                    style={{
                                                        border: "1px solid #f7f7f7",
                                                        width: "25px",
                                                        height: "25px",
                                                        padding: "4px",
                                                        borderRadius: "100%",
                                                    }}
                                                />
                                                <Text> {storeName} </Text>
                                            </Flex>
                                        </Paragraph>
                                        <Paragraph
                                            style={{ margin: tw.spacing[0] }}
                                        >
                                            <Text style={{ fontWeight: "600" }}>
                                                {" "}
                                                Seller:{" "}
                                            </Text>

                                            <Text>{sellerName}</Text>
                                        </Paragraph>
                                    </Space>
                                </Flex>

                                <Space direction="vertical">
                                    <Paragraph
                                        style={{ margin: tw.spacing[0] }}
                                    >
                                        <Text
                                            strong
                                            style={{ maxWidth: 350 }}
                                            ellipsis={{ tooltip: productTitle }}
                                        >
                                            <a
                                                href={productLink}
                                                target="_blank"
                                            >
                                                {" "}
                                                {productTitle}
                                            </a>
                                        </Text>
                                    </Paragraph>
                                </Space>
                            </Space>
                        </div>
                    </>
                );
            },
        },

        {
            title: "User",
            dataIndex: "user",
            width: 220,
            key: "id",
            render: (_record, data: DisputeModel) => {
                return (
                    <Row>
                        <Col>
                            <Space direction="vertical" size={1}>
                                <Descriptions size="small" column={1}>
                                    <Descriptions.Item>
                                        <CustomerDetailsViews
                                            selectedUser={data.getUser()}
                                        />
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label={
                                            <span
                                                style={{ fontWeight: "bold" }}
                                            >
                                                Email
                                            </span>
                                        }
                                    >
                                        <Tooltip
                                            title={data.getUser().getEmail()}
                                        >
                                            <Text
                                                copyable={{
                                                    text: data
                                                        .getUser()
                                                        ?.getEmail(),
                                                }}
                                            >
                                                {truncateText(
                                                    data.getUser()?.getEmail(),
                                                    15,
                                                )}
                                            </Text>
                                        </Tooltip>
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label={
                                            <span
                                                style={{ fontWeight: "bold" }}
                                            >
                                                Phone
                                            </span>
                                        }
                                    >
                                        <Text copyable>
                                            {data.getUser()?.getPhone()}
                                        </Text>
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label={
                                            <span
                                                style={{ fontWeight: "bold" }}
                                            >
                                                Shipping Mark
                                            </span>
                                        }
                                    >
                                        <Text>
                                            {data?.getUser().getShippingMark()}
                                        </Text>
                                    </Descriptions.Item>
                                </Descriptions>
                            </Space>
                        </Col>
                    </Row>
                );
            },
        },
        {
            title: "Assigned",
            dataIndex: "assigned",
            width: 250,
            key: "id",
            render: (_tags: any, record) => {
                // will be implemented later once the purchase company and shipping company is available in the API response
                const purchaseCompany = record
                    .getPurchaseAgentCompany()
                    ?.getPrimaryName();
                return (
                    <Row>
                        <Col>
                            <Space direction="vertical" size={1}>
                                <Descriptions size="small" column={1}>
                                    <Descriptions.Item
                                        label={
                                            <span
                                                style={{ fontWeight: "bold" }}
                                            >
                                                Purchase Company
                                            </span>
                                        }
                                    >
                                        <Text>{purchaseCompany}</Text>
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label={
                                            <span
                                                style={{ fontWeight: "bold" }}
                                            >
                                                Shipping Company
                                            </span>
                                        }
                                    >
                                        <Text></Text>
                                    </Descriptions.Item>
                                </Descriptions>
                            </Space>
                        </Col>
                    </Row>
                );
            },
        },
        {
            title: "Created At",
            dataIndex: "created_at",
            width: 150,
            key: "id",
            render: (_tags: any, record) => {
                const createdAt = moment(record.getCreatedAt()).format(
                    "YYYY-MM-DD hh:mm A ",
                );
                return (
                    <Row>
                        <Col>
                            <Space direction="vertical" size={1}>
                                <Descriptions size="small" column={1}>
                                    <Descriptions.Item>
                                        <Tag color="purple">{createdAt}</Tag>
                                    </Descriptions.Item>
                                </Descriptions>
                            </Space>
                        </Col>
                    </Row>
                );
            },
        },

        {
            title: "Actions",
            dataIndex: "actions",
            fixed: "right",
            width: isMobile ? 80 : 150,
            key: "id",
            align: "center",
            render: (_tags, record: DisputeModel) => {
                const uiActions = record.getUIActions();
                const menuItems: ExtendedMenuItemType[] = [
                    {
                        permission:
                            ADMIN_REFUND_PERMISSION_ENUM.ADMIN_DISPUTE_VIEW,
                        label: "View details",
                        key: "view_details",
                        icon: <EyeFilled />,
                        onClick: () => {
                            navigate(
                                `/refund/dispute/details/${record.getId()}/${record.getBuyProduct().getId()}`,
                            );
                        },
                    },
                    {
                        permission:
                            ADMIN_REFUND_PERMISSION_ENUM.ADMIN_DISPUTE_VIEW,
                        label: "Conversation",
                        key: "conversation",
                        icon: <HddFilled />,
                        onClick: () => {},
                    },
                    // Only show Complete action if can_complete is true
                    ...(uiActions?.can_complete
                        ? [
                              {
                                  permission:
                                      ADMIN_REFUND_PERMISSION_ENUM.ADMIN_DISPUTE_VIEW,
                                  label: "Complete",
                                  key: "complete",
                                  icon: <CheckCircleOutlined />,
                                  onClick: () => {
                                      handleCompleteRefund(record.getId());
                                  },
                              },
                          ]
                        : []),
                    // Only show Cancel action if can_cancel is true
                    ...(uiActions?.can_cancel
                        ? [
                              {
                                  key: "cancel_refund",
                                  permission:
                                      ADMIN_REFUND_PERMISSION_ENUM.ADMIN_DISPUTE_VIEW,
                                  label: (
                                      <Popconfirm
                                          title="Cancel this refund"
                                          description="Are you sure to cancel?"
                                          onConfirm={() =>
                                              handleDeleteDispute(
                                                  record.getId(),
                                              )
                                          }
                                          onCancel={() => {}}
                                          okText="Yes"
                                          placement="topLeft"
                                          cancelText="No"
                                          style={{
                                              color: "red",
                                              display: "flex",
                                              alignItems: "center",
                                          }}
                                      >
                                          <FiTrash style={{ color: "red" }} />{" "}
                                          <Text type="danger">
                                              Cancel Refund
                                          </Text>
                                      </Popconfirm>
                                  ),
                              },
                          ]
                        : []),
                ];
                // Only render the dropdown if there are available actions
                if (menuItems.length === 0) {
                    return null;
                }

                return (
                    <>
                        <Dropdown
                            menu={{
                                items: menuItems.filter(
                                    (item) => item !== null,
                                ),
                            }}
                        >
                            <Button icon={<HddFilled />}>
                                {isMobile ? (
                                    <DownOutlined />
                                ) : (
                                    <>
                                        Actions <DownOutlined />{" "}
                                    </>
                                )}
                            </Button>
                        </Dropdown>
                    </>
                );
            },
        },
    ];

    return (
        <>
            <Row>
                <Col span={24}>
                    <PageHeader
                        style={{ marginTop: "10px" }}
                        ghost={false}
                        title="Discounts"
                        extra={[
                            checkActionPermission(
                                ADMIN_REFUND_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_DISPUTE_CREATE,
                                <Button
                                    key="1"
                                    type="dashed"
                                    onClick={() =>
                                        navigate("/refund-manage/refund")
                                    }
                                    icon={<PlusOutlined />}
                                >
                                    Create a Refund
                                </Button>,
                                null,
                            ),
                        ]}
                        onBack={() => window.history.back()}
                    >
                        {filters && Object.keys(filters).length > 0 && (
                            <Row>
                                <Col span={24}>
                                    <FiltersComponent
                                        handleProductFilter={
                                            handleProductFilter
                                        }
                                        handleFilterChange={handleFilterChange}
                                        handelFilterClear={handelFilterClear}
                                        isFetched={isFetched}
                                        filters={filters}
                                        filtersData={filterData}
                                        isFromProductReceived={true}
                                    />
                                </Col>
                            </Row>
                        )}

                        <div style={{ marginTop: 20 }}>
                            <Table
                                style={{ overflow: "auto" }}
                                columns={columns}
                                dataSource={disputeLists}
                                bordered
                                loading={disputeLoading}
                                rowKey="id"
                                scroll={{ x: 1000 }}
                                pagination={paginationConfig}
                            />
                        </div>
                    </PageHeader>
                </Col>
            </Row>
            <DisputeSettlementPreviewModal
                isVisible={isModalVisible}
                onClose={() => setIsModalVisible(false)}
                disputeId={selectedDisputeId}
                onCompleteRefund={onConfirmCompleteRefund}
                currency={
                    disputeLists &&
                    disputeLists
                        .find(
                            (dispute) => dispute.getId() === selectedDisputeId,
                        )
                        ?.getRegion()
                        ?.currency?.getCurrencyCode()
                }
            />
        </>
    );
};

export default RefundDispute;
