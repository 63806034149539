import { PaymentSessionModel } from "@/models/paymentSessionModel";
import ShippingSummeryProduct from "./ShippingSummaryProduct";
import { Divider, Flex, Typography } from "antd";
import { RegionModel } from "@/models/regionCollectionModel";

interface IProps {
    sessionData: PaymentSessionModel;
    region: RegionModel;
}

const ShippingSummary = ({ sessionData, region }: IProps) => {
    const items = sessionData.getSessionInvoices().getData();
    return (
        <Flex vertical gap={6}>
            <Typography.Text strong>Summary</Typography.Text>
            <Flex vertical gap={2}>
                <div>
                    Total Quantity:{" "}
                    <Typography.Text strong>
                        {sessionData.getTotalQuantity()}
                    </Typography.Text>
                </div>
                <div>
                    Total Due:{" "}
                    <Typography.Text strong>
                        {region.getCurrency()?.getSymbolNative()}
                        {sessionData.getTotalDue()}
                    </Typography.Text>
                </div>
            </Flex>
            <Divider />
            <Flex
                vertical
                style={{ maxHeight: "384px", overflowY: "auto" }}
                gap={6}
            >
                {items.map((item, index) => {
                    return (
                        <ShippingSummeryProduct
                            data={item.getInvoice()}
                            index={index + 1}
                            key={index}
                        />
                    );
                })}
            </Flex>
        </Flex>
    );
};

export default ShippingSummary;
