import {
    Alert,
    Button,
    Col,
    Flex,
    Form,
    InputNumber,
    Row,
    Select,
    Typography,
} from "antd";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { IModalData } from "@/helpers/getModalTital";
import { showError } from "@/helpers/showError";
import { showSuccessAlert } from "@/helpers/showSuccess";
import { ShipmentProductModel } from "@/models/shipmentProductCollectionModel.ts";
import useRefetch from "@/hooks/useRefetch.ts";
import { transformEnumToLabeledValue } from "@/utils/helpers.ts";
import { CommissionRateTypeEnums } from "@/enums/exchangeListCollectionEnums.ts";
import { usePutChangeAgentRateShipmentProduct } from "@/lib/core-react/hooks/private/useShipping.ts";
import { getCustomerRateCalc } from "@/pages/Shipping/ShipmentProducts/utils.tsx";

interface SequenceSlot {
    min_amount: number;
    max_amount: number;
    rate: number;
}

interface ICommission {
    rate: number;
    rate_type: string;
    max_cap: number | null;
}

export interface IShippingRateUpdatePayload {
    agent_rate: number;
    slots: SequenceSlot[] | null;
    commission: ICommission;
}

interface IShippingRateUpdateValues {
    new_rate: number;
    slots: SequenceSlot[] | null;
    commission: ICommission;
}

interface IProps {
    handleModal: (payload: IModalData) => void;
    selectedProduct: ShipmentProductModel;
}

const ShipmentProductChangeShippingRate: React.FC<IProps> = ({
    selectedProduct,
    handleModal,
}) => {
    const [form] = Form.useForm();
    const { refetchListApi } = useRefetch();
    const { changeAgentRate, isLoading } =
        usePutChangeAgentRateShipmentProduct();

    const rateValue: number = Form.useWatch("new_rate", form);
    const commission = Form.useWatch("commission", form);
    const commissionRateType: "percentage" | "fixed" = commission
        ? commission.rate_type
        : "percentage";
    const commissionRateValue: number = commission
        ? commission.rate
            ? commission.rate
            : 0
        : 0;

    const onFinish = async (values: IShippingRateUpdateValues) => {
        const payload: IShippingRateUpdatePayload = {
            agent_rate: values.new_rate,
            slots:
                values.slots && values.slots.length
                    ? values.slots.map((slot) => ({
                          min_amount: slot.min_amount,
                          max_amount: slot.max_amount,
                          rate: slot.rate,
                      }))
                    : null,
            commission: {
                rate: values.commission.rate,
                rate_type: values.commission.rate_type,
                max_cap: values.commission.max_cap,
            },
        };

        try {
            await changeAgentRate(selectedProduct.getId(), payload);
            showSuccessAlert("successfully Changed Agent Rate");
            handleModal({
                action: false,
                data: null,
            });
            await refetchListApi();
        } catch (error: any) {
            showError(error, form);
        }
    };

    return (
        <Row>
            <Col span={24}>
                <Form
                    form={form}
                    onFinish={onFinish}
                    layout="vertical"
                    initialValues={{
                        new_rate:
                            selectedProduct.getAgentAssociation()?.getRate() ||
                            0,
                        slots: selectedProduct
                            .getAgentAssociation()
                            ?.getAssociationSlots().length
                            ? selectedProduct
                                  .getAgentAssociation()
                                  ?.getAssociationSlots()
                                  .map((rate) => ({
                                      min_amount: rate.min_amount,
                                      max_amount: rate.max_amount,
                                      rate: rate.rate,
                                  }))
                            : [],
                        commission: {
                            rate:
                                selectedProduct
                                    .getMoveonAssociation()
                                    ?.getRate() || 0,
                            rate_type:
                                selectedProduct
                                    .getMoveonAssociation()
                                    ?.getRateType() || "",
                            max_cap: selectedProduct
                                .getMoveonAssociation()
                                ?.getMaxCap(),
                        },
                    }}
                >
                    <Form.Item
                        label="New Rate"
                        name="new_rate"
                        rules={[
                            {
                                required: true,
                                message: "Please enter a new rate",
                            },
                        ]}
                    >
                        <InputNumber style={{ width: "100%" }} />
                    </Form.Item>

                    <Flex vertical gap={4} style={{ marginTop: "8px" }}>
                        <Typography.Text strong>Commission:</Typography.Text>
                        <Flex justify="space-between" gap={8}>
                            <Form.Item
                                style={{
                                    width: "100%",
                                    marginBottom: "4px",
                                }}
                                label="Type"
                                name={["commission", "rate_type"]}
                                rules={[
                                    { required: true, message: "Required" },
                                ]}
                            >
                                <Select
                                    placeholder="Rate Type"
                                    options={transformEnumToLabeledValue(
                                        CommissionRateTypeEnums,
                                    )}
                                />
                            </Form.Item>
                            <Form.Item
                                style={{
                                    width: "100%",
                                    marginBottom: 0,
                                }}
                                label="Rate"
                                name={["commission", "rate"]}
                                rules={[
                                    { required: true, message: "Required" },
                                ]}
                            >
                                <InputNumber
                                    style={{
                                        width: "100%",
                                    }}
                                    placeholder="Rate"
                                />
                            </Form.Item>

                            <Form.Item
                                style={{
                                    width: "100%",
                                    marginBottom: 0,
                                }}
                                label="Max Cap"
                                name={["commission", "max_cap"]}
                            >
                                <InputNumber
                                    style={{
                                        width: "100%",
                                    }}
                                    placeholder="Max cap"
                                />
                            </Form.Item>
                        </Flex>
                    </Flex>
                    <Flex
                        style={{
                            paddingBottom: "8px",
                        }}
                    >
                        <Typography.Text style={{ color: "#1677ff" }}>
                            Customer Rate:{" "}
                            {parseFloat(
                                getCustomerRateCalc(
                                    commissionRateType,
                                    rateValue,
                                    commissionRateValue,
                                ).toFixed(2),
                            )}
                        </Typography.Text>
                    </Flex>

                    <Form.List name="slots">
                        {(fields, { add, remove }) => (
                            <div>
                                <div>
                                    {fields.length > 0 ? (
                                        fields.map(
                                            (
                                                { key, name, ...restField },
                                                index,
                                            ) => {
                                                return (
                                                    <div key={key}>
                                                        <Typography.Paragraph
                                                            strong
                                                        >
                                                            Slot No: {index + 1}
                                                        </Typography.Paragraph>
                                                        <Flex
                                                            gap={12}
                                                            align="inherit"
                                                        >
                                                            <Form.Item
                                                                {...restField}
                                                                label="Min"
                                                                name={[
                                                                    name,
                                                                    "min_amount",
                                                                ]}
                                                                rules={[
                                                                    {
                                                                        required:
                                                                            true,
                                                                        message:
                                                                            "min amount required",
                                                                    },
                                                                    {
                                                                        validator:
                                                                            (
                                                                                _,
                                                                                value,
                                                                            ) => {
                                                                                if (
                                                                                    value >
                                                                                    0
                                                                                ) {
                                                                                    return Promise.resolve();
                                                                                }
                                                                                return Promise.reject(
                                                                                    new Error(
                                                                                        "Amount must be greater than 0",
                                                                                    ),
                                                                                );
                                                                            },
                                                                    },
                                                                ]}
                                                                style={{
                                                                    marginBottom: 0,
                                                                }}
                                                            >
                                                                <InputNumber
                                                                    style={{
                                                                        width: "100%",
                                                                    }}
                                                                />
                                                            </Form.Item>
                                                            <Form.Item
                                                                {...restField}
                                                                label="Max"
                                                                name={[
                                                                    name,
                                                                    "max_amount",
                                                                ]}
                                                                rules={[
                                                                    {
                                                                        required:
                                                                            true,
                                                                        message:
                                                                            "max amount required",
                                                                    },
                                                                    {
                                                                        validator:
                                                                            (
                                                                                _,
                                                                                value,
                                                                            ) => {
                                                                                if (
                                                                                    value >
                                                                                    0
                                                                                ) {
                                                                                    return Promise.resolve();
                                                                                }
                                                                                return Promise.reject(
                                                                                    new Error(
                                                                                        "Amount must be greater than 0",
                                                                                    ),
                                                                                );
                                                                            },
                                                                    },
                                                                ]}
                                                                style={{
                                                                    marginBottom: 0,
                                                                }}
                                                            >
                                                                <InputNumber
                                                                    style={{
                                                                        width: "100%",
                                                                    }}
                                                                />
                                                            </Form.Item>
                                                            <Form.Item
                                                                {...restField}
                                                                label="Rate"
                                                                name={[
                                                                    name,
                                                                    "rate",
                                                                ]}
                                                                rules={[
                                                                    {
                                                                        required:
                                                                            true,
                                                                        message:
                                                                            "rate is required",
                                                                    },
                                                                    {
                                                                        validator:
                                                                            (
                                                                                _,
                                                                                value,
                                                                            ) => {
                                                                                if (
                                                                                    value >
                                                                                    0
                                                                                ) {
                                                                                    return Promise.resolve();
                                                                                }
                                                                                return Promise.reject(
                                                                                    new Error(
                                                                                        "Amount must be greater than 0",
                                                                                    ),
                                                                                );
                                                                            },
                                                                    },
                                                                ]}
                                                                style={{
                                                                    marginBottom: 0,
                                                                }}
                                                                shouldUpdate
                                                            >
                                                                <InputNumber
                                                                    style={{
                                                                        width: "100%",
                                                                    }}
                                                                />
                                                            </Form.Item>

                                                            <Button
                                                                type="link"
                                                                onClick={() =>
                                                                    remove(name)
                                                                }
                                                                icon={
                                                                    <MinusCircleOutlined />
                                                                }
                                                                danger
                                                                style={{
                                                                    marginLeft:
                                                                        "16px",
                                                                    marginTop: 28,
                                                                }}
                                                            />
                                                        </Flex>
                                                        <Form.Item
                                                            shouldUpdate
                                                            style={{
                                                                marginBottom:
                                                                    "8px",
                                                            }}
                                                        >
                                                            {() => {
                                                                const slotRate =
                                                                    form.getFieldValue(
                                                                        [
                                                                            "slots",
                                                                            index,
                                                                            "rate",
                                                                        ],
                                                                    ) || 0;
                                                                return (
                                                                    <Flex>
                                                                        <Typography.Text
                                                                            style={{
                                                                                color: "#1677ff",
                                                                            }}
                                                                        >
                                                                            Customer
                                                                            Rate:{" "}
                                                                            {parseFloat(
                                                                                getCustomerRateCalc(
                                                                                    commissionRateType,
                                                                                    slotRate,
                                                                                    commissionRateValue,
                                                                                ).toFixed(
                                                                                    2,
                                                                                ),
                                                                            )}
                                                                        </Typography.Text>
                                                                    </Flex>
                                                                );
                                                            }}
                                                        </Form.Item>
                                                    </div>
                                                );
                                            },
                                        )
                                    ) : (
                                        <Alert
                                            message={`No slots available. Please click "Add Slots" to add new slots.`}
                                            type="info"
                                        />
                                    )}
                                </div>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    style={{ marginTop: "20px" }}
                                >
                                    <PlusOutlined /> Add Slots
                                </Button>
                            </div>
                        )}
                    </Form.List>

                    <Form.Item noStyle>
                        <Flex justify="end">
                            <Button
                                loading={isLoading}
                                disabled={isLoading}
                                type="primary"
                                htmlType="submit"
                            >
                                Update
                            </Button>
                        </Flex>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    );
};

export default ShipmentProductChangeShippingRate;
