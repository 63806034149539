import { Row, Col, Card } from "antd";
import OriginalProductLink from "./FindOriginalLink";

const Tools = () => {
    return (
        <div>
            <Row gutter={[16, 16]}>
                {/* First Column */}
                <Col xs={24} sm={24} md={12} lg={12}>
                    <Card>
                        <OriginalProductLink />
                    </Card>
                </Col>

                {/* Add more columns here */}
                <Col xs={24} sm={24} md={12} lg={12}>
                    {/* Example of another component */}
                    {/* <div>Another Component</div> */}
                </Col>
            </Row>
        </div>
    );
};

export default Tools;
