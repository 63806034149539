import {
    Button,
    Flex,
    Modal,
    notification,
    Table,
    TableColumnsType,
    Typography,
} from "antd";
import { BuyProductChargeModel } from "@/models/buyActionCollectionModel";
import {
    useCancelVariationsAndCharges,
    useGetBuyProduct,
} from "@/lib/core-react/hooks/private/usePurchase";
import { showError } from "@/helpers/showError";
import { ReactNode, useState } from "react";
import { roundToTwoDecimal } from "@/helpers/roundToTwoDecimal";
import { DeleteTwoTone } from "@ant-design/icons";
import { showSuccessAlert } from "@/helpers/showSuccess";
import { cancelVariationsAndChargesTypeEnum } from "@/types/buyProductCollection";
import { formatString } from "@/utils/helper";
import { BuyProductChargeStatusEnum } from "@/types/buyActionCollection";
const { Text } = Typography;

interface IProps {
    buyProductCharges: BuyProductChargeModel[] | undefined;
    originalCurrencyCode?: string;
    regionCurrencyCode?: string;
    title?: ReactNode;
    isListPage: boolean;
    buyProductId: number | undefined;
}

const TEXT_SIZE = "12px";

const BuyProductCharges: React.FC<IProps> = ({
    buyProductCharges,
    buyProductId,
    originalCurrencyCode,
    regionCurrencyCode,
    isListPage,
}) => {
    const [loadingChargeId, setLoadingChargeId] = useState<number | null>(null);
    const { getBuyProduct } = useGetBuyProduct();
    const { cancelVariationsCharges, isLoading: isDeleteLoading } =
        useCancelVariationsAndCharges();

    const handleCancelVariant = async (charge: BuyProductChargeModel) => {
        Modal.confirm({
            title: "Are you sure, you want to cancel this charge?",
            content: "Select Ok, to cancel the charge.",
            onOk: async () => {
                try {
                    if (!buyProductId) {
                        throw new Error("buyProductId not found");
                    }
                    setLoadingChargeId(charge.getId());
                    await cancelVariationsCharges(buyProductId, {
                        type: cancelVariationsAndChargesTypeEnum.SPECIFIC_CHARGES,
                        charge_ids: [charge.getId()],
                        variation_ids: [],
                    });
                    showSuccessAlert("Charge Cancel successfully");
                    await getBuyProduct(buyProductId);
                    setLoadingChargeId(null);
                    return Promise.resolve();
                } catch (error) {
                    showError(error);
                    return Promise.reject();
                }
            },
            onCancel() {
                notification["info"]({
                    message: "Product cancellation was aborted.",
                });
            },
        });
    };

    const columns: TableColumnsType<BuyProductChargeModel> = [
        {
            title: <Text style={{ fontSize: TEXT_SIZE }}>Type</Text>,
            key: "Type",
            align: "center",
            render: (_, record) => {
                return (
                    <Text style={{ fontSize: TEXT_SIZE }}>
                        {formatString(record.getChargeType())}
                    </Text>
                );
            },
        },

        {
            title: <Text style={{ fontSize: TEXT_SIZE }}>Total Amount</Text>,
            key: "Total_Amount",
            align: "center",
            render: (_: string, record) => {
                return (
                    <Flex gap={2} justify="center">
                        <Text style={{ fontSize: TEXT_SIZE }} strong>
                            {roundToTwoDecimal(record.getTotalAmount())}
                        </Text>
                        <Text style={{ fontSize: TEXT_SIZE }} strong>
                            {regionCurrencyCode}
                        </Text>
                    </Flex>
                );
            },
        },
        {
            title: <Text style={{ fontSize: TEXT_SIZE }}>Original Amount</Text>,
            key: "Original_Amount",
            align: "center",
            render: (_: string, record) => {
                return (
                    <Flex gap={2} justify="center">
                        <Text style={{ fontSize: TEXT_SIZE }} strong>
                            {roundToTwoDecimal(record.getOriginalAmount())}
                        </Text>
                        <Text style={{ fontSize: TEXT_SIZE }} strong>
                            {originalCurrencyCode}
                        </Text>
                    </Flex>
                );
            },
        },
        {
            title: `A`,
            key: "actions",
            align: "center",
            hidden: isListPage,

            render: (_: string, record) => {
                const isCanceled = record.getFulfilmentStatus() === BuyProductChargeStatusEnum.CANCELLED;
                return (
                   <>
                   {
                        isCanceled ? (
                            <Text style={{ fontSize: TEXT_SIZE }} type="danger">
                                 Cancelled
                            </Text>
                          ) : (
                            <Button
                                 type="link"
                                 icon={<DeleteTwoTone />}
                                 loading={ isDeleteLoading && loadingChargeId === record.getId()}
                                 onClick={() => handleCancelVariant(record)}
                            >
                                 Cancel
                            </Button>
                        )
                   }
                   </>
                );
            },
        },
    ];

    return (
        <>
            <Table
                rowKey={(r) => r.getId()}
                bordered
                size="small"
                rowHoverable={false}
                dataSource={buyProductCharges}
                columns={columns}
                scroll={{ x: 400 }}
                pagination={false}
                locale={{
                    emptyText:
                        !buyProductCharges || buyProductCharges.length === 0 ? (
                            <Text type="danger">No Charges available.</Text>
                        ) : (
                            "Loading data..."
                        ),
                }}
            />
        </>
    );
};

export default BuyProductCharges;
