import React, { useEffect } from "react";
import { Button, Form, FormInstance, Modal, Select, notification } from "antd";
import { ShippingTypesEnums } from "@/enums/shippingEnums";
import { useUpdateShippingType } from "@/lib/core-react/hooks/private/useShipping";
import { transformEnumToLabeledValue } from "@/utils/helpers";
import { getError } from "@/lib/core-react/hooks/utils/errors";

interface IProps {
    productIds: number[];
    isOpen: boolean;
    onCancel: (isMutate?: boolean) => void;
    existingType: ShippingTypesEnums | undefined;
}

export const ChangeShippingTypeModal = ({
    isOpen,
    onCancel,
    productIds,
    existingType,
}: IProps) => {
    const { updateShippingType, isLoading } = useUpdateShippingType();
    const formRef: React.Ref<FormInstance<any>> = React.createRef();
    const [form] = Form.useForm();
    useEffect(() => {
        return () => {
            form.resetFields();
        };
    }, [form]);

    // Ok handler
    const onOkHandler = async (data: { shipping_type: ShippingTypesEnums }) => {
        const payload: {
            shipment_product_ids: number[];
            shipping_type: ShippingTypesEnums;
        } = {
            shipment_product_ids: productIds,
            shipping_type: data.shipping_type,
        };

        try {
            const data = await updateShippingType(payload);
            if (data.response.status === 200) {
                notification["success"]({
                    message: data.message,
                });
                onCancel();
            }
        } catch (error) {
            notification["error"]({
                message: getError(error),
            });
        }
    };

    return (
        <Modal
            title="Change Shipping Type"
            open={isOpen}
            destroyOnClose={true}
            confirmLoading={isLoading}
            onCancel={() => onCancel()}
            footer={false}
        >
            <Form
                onFinish={onOkHandler}
                form={form}
                layout="vertical"
                name="shipping_type"
                ref={formRef}
                initialValues={{
                    shipping_type: existingType,
                }}
            >
                <Form.Item name="shipping_type" label="Shipping Type">
                    <Select
                        loading={isLoading}
                        placeholder="Please select a shipping type"
                        options={transformEnumToLabeledValue(
                            ShippingTypesEnums,
                        )}
                    />
                </Form.Item>

                <Form.Item>
                    <Button
                        loading={isLoading}
                        type="primary"
                        htmlType="submit"
                        style={{ width: "100%" }}
                    >
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};
